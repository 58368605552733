import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslationService } from "@core/services/translation.service";
import { TranslationKey } from "@auto/translations.models";
import { Subject } from "rxjs";
import { ErrorEnum } from "@auto/dto.models";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  texts?: any;
  httpErrorStream$ = new Subject<ErrorEnum>();

  constructor(
    private snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {}

  showSuccess(message: string) {
    this.snackBar.open(message, "OK!", {
      duration: 2000,
    });
  }

  showSuccessTranslationKey(
    key: TranslationKey,
    params?: { [key: string]: string | number }
  ) {
    const message = this.translationService.fromString(key, params);
    this.showSuccess(message);
  }

  showError(message: string) {
    if (!this.texts) {
      this.texts = this.getTexts();
    }
    this.snackBar.open(message, `${this.texts.error}!`, {
      panelClass: "error",
      duration: 2000,
    });
  }

  showErrorTranslationKey(
    key: TranslationKey,
    params?: { [key: string]: string | number }
  ) {
    const message = this.translationService.fromString(key, params);
    this.showError(message);
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      error: dict.COMMON_UI.KEY_WORD.ERROR,
    };
  }
}

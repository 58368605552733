<div class="col-container">
  <div class="col-auto-flex">
    <mat-form-field>
      <mat-label *ngIf="dateLabel">{{ dateLabel }}</mat-label>
      <mat-label
        translate="COMMON_UI.KEY_WORD.DATE_START"
        *ngIf="!dateLabel"
      ></mat-label>
      <input matInput [matDatepicker]="picker" [formControl]="startDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <ng-container *ngIf="type != 'date'">
    <div class="col-auto-flex">
      <mat-form-field class="time">
        <mat-label translate="COMMON_UI.KEY_WORD.DATE_H_SHORT"></mat-label>
        <input matInput [formControl]="startH" />
      </mat-form-field>
      <span class="h-m-separator">.</span>
      <mat-form-field class="time">
        <mat-label translate="COMMON_UI.KEY_WORD.DATE_MIN_SHORT"></mat-label>
        <input matInput [formControl]="startMin" />
      </mat-form-field>
    </div>

    <div class="col-auto-flex">
      <span class="start-stop-separator">&ndash;</span>
    </div>

    <div class="col-auto-flex" *ngIf="showEndDate || differentEndDate">
      <mat-form-field>
        <mat-label translate="COMMON_UI.KEY_WORD.DATE_END"></mat-label>
        <input matInput [matDatepicker]="endPicker" [formControl]="endDate" />
        <mat-datepicker-toggle
          matSuffix
          [for]="endPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-auto-flex">
      <mat-form-field class="time">
        <mat-label translate="COMMON_UI.KEY_WORD.DATE_H_SHORT"></mat-label>
        <input matInput [formControl]="endH" />
      </mat-form-field>
      <span class="h-m-separator">.</span>
      <mat-form-field class="time">
        <mat-label translate="COMMON_UI.KEY_WORD.DATE_MIN_SHORT"></mat-label>
        <input matInput [formControl]="endMin" />
      </mat-form-field>
    </div>

    <div
      class="col-auto-flex toggle"
      *ngIf="!differentEndDate && endDate.valid"
    >
      <mat-slide-toggle
        color="primary"
        [checked]="showEndDate"
        (change)="changeShowEndDateToggleValue($event)"
      >
        <span translate="COMMON_UI.KEY_WORD.DATA_SHOW_END_DATE"></span>
      </mat-slide-toggle>
    </div>

    <div class="col-auto-flex" *ngIf="showTimeZone">
      <mat-form-field>
        <mat-label translate="COMMON_UI.KEY_WORD.DATE_TIMEZONE"></mat-label>
        <select matNativeControl [formControl]="startTimeZone">
          <option *ngFor="let i of timeZoneOptions" [value]="i">
            {{ i }}
          </option>
        </select>
      </mat-form-field>
    </div>

    <div class="col-auto-flex toggle">
      <mat-slide-toggle
        color="primary"
        [checked]="showTimeZone"
        (change)="changeTimeZoneToggleValue($event)"
        ><span translate="COMMON_UI.KEY_WORD.DATE_SHOW_TIMEZONE"></span>
      </mat-slide-toggle>
    </div>
  </ng-container>
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LandingRouteComponent } from "./components";

@NgModule({
  declarations: [LandingRouteComponent],
  imports: [CommonModule],
})
export class LandingModule {
  constructor() {}
}

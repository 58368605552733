import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { TRANSLATIONS } from "@auto/translations.models";
import { ROUTE_MAP } from "@core/routing";
import { NavLink, TranslationService } from "@core/services";

@Component({
  selector: "user-settings-menu",
  templateUrl: "./user-settings-menu.component.html",
  styleUrls: ["./user-settings-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsMenuComponent implements OnInit {
  tabLinks: NavLink[] = [];

  constructor(private tx: TranslationService) {}

  ngOnInit(): void {
    this.tabLinks = this.getTabLinks();
  }

  private getTabLinks() {
    let links: NavLink[] = [
      {
        name: this.tx.fromString(TRANSLATIONS.UI.USER.PROFILE.TAB_NAV.BASIC),
        url: ROUTE_MAP.PRI.USER_DETAILS.PROFILE,
      },
      {
        name: this.tx.fromString(TRANSLATIONS.UI.USER.PROFILE.TAB_NAV.EMAIL),
        url: ROUTE_MAP.PRI.USER_DETAILS.EMAILS,
      },
      {
        name: this.tx.fromString(
          TRANSLATIONS.UI.USER.PROFILE.TAB_NAV.LINKED_ACCOUNTS
        ),
        url: ROUTE_MAP.PRI.USER_DETAILS.LINKED_ACCOUNTS,
      },
    ];
    return links;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { NavLink } from "@core/services/navigation.service";
import { UserService } from "@core/services/user.service";
import { ControlStateService } from "@shared/services/control-state.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { filter } from "rxjs";

type Tabs = "LIST" | "CALENDAR" | "SETTINGS" | "FAMILY";
const TAB_CS_KEY = "EVENTS_VIEW_TYPE_ALL";

@Component({
  templateUrl: "./events-route-all-groups.component.html",
  styleUrls: ["./events-route-all-groups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsRouteAllGroupsComponent implements OnInit {
  activeTabId!: Tabs;
  tabLinks: NavLink[] = [];
  toggled = true;
  private currentUserId = "";

  constructor(
    private cdr: ChangeDetectorRef,
    private cs: ControlStateService,
    private ts: TranslationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.activeTabId = this.cs.get(TAB_CS_KEY, "LIST");
    this.tabLinks = this.getTabLinks();
    LifeCyclesUtil.sub(
      this,
      this.userService.user$.pipe(
        filter((user) => !!user && user.id !== this.currentUserId)
      ),
      (user) => {
        if (this.currentUserId) {
          this.toggled = false;
          this.cdr.detectChanges();
          setTimeout(() => {
            this.toggled = true;
            this.cdr.detectChanges();
          });
        }
        this.currentUserId = user!.id;
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private clickTab(link: NavLink) {
    this.activeTabId = link.id as Tabs;
    this.tabLinks = this.getTabLinks();
    this.cs.set(TAB_CS_KEY, link.id);
    this.cdr.detectChanges();
  }

  private getTabLinks() {
    let links: NavLink[] = [
      {
        name: this.ts.fromString(TRANSLATIONS.UI.GROUP.EVENTS.VIEW_TYPE_LIST),
        id: "LIST",
        isActive: this.activeTabId === "LIST",
        url: (i) => this.clickTab(i),
      },
      {
        name: this.ts.fromString(
          TRANSLATIONS.UI.GROUP.EVENTS.VIEW_TYPE_CALENDAR
        ),
        id: "CALENDAR",
        isActive: this.activeTabId === "CALENDAR",
        url: (i) => this.clickTab(i),
      },
      // Add settings and family calendar here
    ];
    return links;
  }
}

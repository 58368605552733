import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { SharedModule } from "@shared/shared.module";
import {
  EventsCalendarComponent,
  EventsEditorComponent,
  EventsListComponent,
  EventsRouteAllGroupsComponent,
  EventsSignUpComponent,
  EventsSignUpsComponent,
  EventsAddNewEventDialogComponent,
  EventsRoutePageComponent,
  EventsRouteAfterAddedComponent,
  EventsCogMenuComponent,
  IlmoCalendarMonthViewComponent,
  EventsSignUpsDialogComponent,
  CustomCalendarDateFormatter,
  EventsDateTimeViewComponent,
  EventsListItemComponent,
  EventsEventTypeComponent,
  EventsRouteEditorComponent,
} from "./components";
import {
  CalendarDateFormatter,
  CalendarModule,
  DateAdapter,
} from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { MatButtonModule } from "@angular/material/button";
import { registerLocaleData } from "@angular/common";
import localeFi from "../locales/fi";
import { MatDialogModule } from "@angular/material/dialog";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { EventsDeleteDialogComponent } from "./components/events-delete-dialog/events-delete-dialog.component";
import { MatRadioModule } from "@angular/material/radio";
import { EventsSignUpAdditionalComponent } from "./components/events-sign-up-additional/events-sign-up-additional.component";

registerLocaleData(localeFi);

@NgModule({
  declarations: [
    EventsEditorComponent,
    EventsCalendarComponent,
    EventsListComponent,
    EventsAddNewEventDialogComponent,
    EventsRouteAllGroupsComponent,
    EventsSignUpComponent,
    EventsRoutePageComponent,
    IlmoCalendarMonthViewComponent,
    EventsRouteAfterAddedComponent,
    EventsSignUpsDialogComponent,
    EventsSignUpsComponent,
    EventsDeleteDialogComponent,
    EventsCogMenuComponent,
    EventsListItemComponent,
    EventsDateTimeViewComponent,
    EventsEventTypeComponent,
    EventsRouteEditorComponent,
    EventsSignUpAdditionalComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    SharedModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    FormsModule,
    MatRadioModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [
    { provide: CalendarDateFormatter, useClass: CustomCalendarDateFormatter },
  ],
  exports: [
    EventsCalendarComponent,
    EventsListComponent,
    EventsDateTimeViewComponent,
    IlmoCalendarMonthViewComponent,
  ],
})
export class EventsModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  BasicEventDTO,
  BasicEventsDTO,
  EventDataDTO,
  FullGroupDTO,
  GroupTypeEnum,
  StatusDTO,
} from "@auto/dto.models";
import { TranslationService } from "@core/services/translation.service";
import { EventsService } from "@events/services";
import { SharedFormEditorConfig } from "@shared/components/shared-form-editor/shared-form-editor.model";
import { CurrentGroupService } from "@shared/services/current-group.service";
import { ToasterService } from "@shared/services/toaster.service";
import { EventsEditorUtils } from "./events-editor.utils";
import { firstValueFrom, Observable } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";
import { EventsAddNewEventDialogComponent } from "../events-add-new-event-dialog/events-add-new-event-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";

@Component({
  selector: "events-event-editor",
  templateUrl: "./events-editor.component.html",
  styleUrls: ["./events-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsEditorComponent implements OnInit {
  config!: SharedFormEditorConfig;

  event?: EventDataDTO;
  saveDefaultCallback!: () =>
    | Observable<BasicEventsDTO | StatusDTO>
    | Promise<true>;
  saveAndSeparateCallback?: () =>
    | Observable<BasicEventsDTO | StatusDTO>
    | Promise<true>;

  disableSave = true;

  isMyOwnGroup = false;
  groupName = "";
  @Input() isEditing: boolean = false;
  @Input() groupId!: string;
  @Input() startDate?: Date;
  @Input() eventId?: string;
  @Output() eventSaved = new EventEmitter<BasicEventDTO[] | true>();

  constructor(
    private currentGroupService: CurrentGroupService,
    private eventEditorUtils: EventsEditorUtils,
    private eventsService: EventsService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    if (this.eventId) {
      // This must be replaced in case of copy from another event
      this.isEditing = true;
      this.event = (
        await firstValueFrom(
          this.eventsService.getEvent(this.eventId, this.groupId)
        )
      ).event;
    }
    await this.initializeForm();
    if (this.eventId) {
      this.eventEditorUtils.patchFromEvent(this.config.form, this.event!);
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private async initializeForm() {
    const eventTypes = await firstValueFrom(
      this.eventsService.getEventTypesOfGroup(this.groupId)
    );
    let currentGroup = this.currentGroupService.currentGroup$
      .value as FullGroupDTO;

    if (!currentGroup) {
      currentGroup = await firstValueFrom(
        this.currentGroupService.getGroup(this.groupId)
      );
    }

    this.isMyOwnGroup = currentGroup.type == GroupTypeEnum.PERSONAL;
    this.groupName = currentGroup.shortName;
    const form = this.eventEditorUtils.getForm(
      currentGroup.calendars[0].id,
      "SINGLE",
      this.startDate
    );
    this.config = this.eventEditorUtils.formGroupToConfig(
      form,
      currentGroup.id,
      this.event?.id
    );

    this.saveDefaultCallback = this.eventEditorUtils.getSaveCallback(
      form,
      this.groupId,
      this.eventSaved,
      this.isEditing ? this.event?.id : undefined
    );

    if (this.isEditing && this.event?.seriesId) {
      this.saveAndSeparateCallback = this.saveDefaultCallback;
      this.saveDefaultCallback = this.eventEditorUtils.getUpdateSeriesCallback(
        form,
        this.groupId,
        this.eventSaved,
        this.event!.id
      );
    }
    LifeCyclesUtil.sub([this, this.cdr], form.valueChanges, (changes) => {
      this.disableSave = !form.valid;
    });
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivationEnd,
  ActivationStart,
  Router,
} from "@angular/router";
import { assert } from "@shared/utils/assert.func";
import { subsToUrl } from "@shared/utils/subs-to-url.func";
import { BehaviorSubject } from "rxjs";

export type Link = string | ({ [key: string]: any } & { PATH: string });

export type NavLink = {
  url: Link | ((arg: NavLink) => void);
  activeUrl?: string; // if other link is to be used to determine if this is active
  id?: string;
  name: string;
  icon?: string;
  doesOverflow?: boolean;
  isLastVisible?: boolean;
  isActive?: boolean;
  cls?: string;
};

export type SecondNavType = "GROUP" | "ADMIN";

const SECOND_NAV_SORT = ["GROUP", "ADMIN"];

@Injectable({ providedIn: "root" })
export class NavigationService {
  secondNav$ = new BehaviorSubject<NavLink[] | false>(false);
  private secondNavs: { [key in SecondNavType]?: NavLink[] } = {};
  activePath$ = new BehaviorSubject<string>("");
  data$ = new BehaviorSubject<any>(undefined);
  params$ = new BehaviorSubject<{ [key: string]: any }>({});

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    router.events.subscribe((event) => {
      let route: any;
      if ("snapshot" in event) {
        route = event.snapshot;
        while (route.firstChild) {
          route = route.firstChild;
        }
      }
      // Changed this to ActivationEnd, that I think works better
      if (event instanceof ActivationEnd) {
        this.params$.next(route.params || {});
        this.data$.next(event.snapshot.data);
        const path = "/" + route.routeConfig.path;
        if (path !== this.activePath$.value) {
          this.activePath$.next(path);
        }
      }
    });
  }

  /**
   * Second level navigation management
   */
  clearSecondNav(key: SecondNavType) {
    if (key in this.secondNavs) {
      delete this.secondNavs[key];
      this.emitCurrentSecondNav();
    }
  }

  setQueryParams(params: any) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  setSecondNav(key: SecondNavType, links: NavLink[]) {
    this.secondNavs[key] = links;
    this.emitCurrentSecondNav();
  }

  private emitCurrentSecondNav() {
    for (let i in SECOND_NAV_SORT) {
      const key = SECOND_NAV_SORT[i];
      if (key in this.secondNavs) {
        this.secondNav$.next((this.secondNavs as any)[key]);
        return;
      }
    }
    if (this.secondNav$.value) {
      this.secondNav$.next(false);
    }
  }

  /**
   * Navigating
   */
  goto(
    path: Link,
    params: { [key: string]: any } = {},
    queryStringParams?: { [key: string]: string | number | boolean }
  ) {
    const navPath = this.getLink(path, params)
      .split("/")
      .filter((i) => i);
    navPath[0] = "/" + navPath[0];
    this.router.navigate(navPath, {
      queryParams: queryStringParams,
      skipLocationChange: false,
    });
  }

  getLink(path: Link, params: { [key: string]: any } = {}): string {
    if (!(typeof path === "string")) {
      return this.getLink(path.PATH, params);
    }
    // fill to params all current params, so they can be neglected when navigating to another route
    for (const i in this.params$.value) {
      if (!(i in params) && this.params$.value.hasOwnProperty(i)) {
        params[i] = this.params$.value[i];
      }
    }
    assert(path);
    return subsToUrl(path, params);
  }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { firstValueFrom } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CanActivateUserLoggedIn implements CanActivate {
  constructor(private service: UserService, private navService: NavigationService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return firstValueFrom(
      this.service.isLoggingIn$.pipe(
        map((i) => {
          if (i === true) {
            return false;
          }
          if (this.service.user) {
            return true;
          }
          this.navService.goto(ROUTE_MAP.PUB.LOGIN_OR_REGISTER);
          return false;
        }),
        filter((i) => i)
      )
    );
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  GetUserDTO,
  GroupTypeEnum,
  StatusEnum,
  UserGroupDTO,
} from "@auto/dto.models";
import { TranslationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { GroupService } from "@group/services";
import { ConfirmActionService, ToasterService } from "@shared/services";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "group-route-my-groups",
  templateUrl: "./group-route-my-groups.component.html",
  styleUrls: ["./group-route-my-groups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupRouteMyGroupsComponent implements OnInit {
  groups: UserGroupDTO[] = [];
  texts = this.getTexts();
  userId: string = "";

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private confirmActionService: ConfirmActionService,
    private translationService: TranslationService,
    private groupService: GroupService,
    private toastService: ToasterService
  ) {}

  ngOnInit() {
    this.getGroups();
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private getGroups() {
    LifeCyclesUtil.sub(
      this,
      this.userService.user$,
      (user: GetUserDTO | undefined) => {
        if (user) {
          this.userId = user.id;
          this.groups = user.groups.filter(
            (g) => g.groupType !== GroupTypeEnum.PERSONAL
          );
          this.cdr.detectChanges();
        }
      }
    );
  }

  async leaveGroup(group: UserGroupDTO) {
    if (
      await this.confirmActionService.confirm({
        texts: {
          cancel: this.texts.no,
          confirm: this.texts.yes,
          title: this.texts.resignConfirmation,
        },
      })
    ) {
      const result = await firstValueFrom(
        this.groupService.leaveGroup(group.groupId, this.userId)
      );
      if (result.status === StatusEnum.FAILED) {
        this.toastService.showError(
          this.texts.resignFromGroupFailed.replace(
            "{groupName}",
            group.groupName
          )
        );
      } else {
        this.toastService.showSuccess(
          this.texts.resignedFromGroup.replace("{groupName}", group.groupName)
        );
        await this.userService.refresh();
        this.getGroups();
      }
    }
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      deleted: dict.COMMON_UI.OK.DELETED,
      error: dict.COMMON_UI.ERROR.ACTION_FAILED,
      notJoined: dict.UI.GROUP.MEMBERS.NOT_JOINED,
      resignConfirmation: dict.UI.GROUP.MEMBERS.RESIGN_FROM_GROUP_CONFIRMATION,
      yes: dict.COMMON_UI.KEY_WORD.YES,
      no: dict.COMMON_UI.KEY_WORD.NO,
      resignedFromGroup: dict.UI.GROUP.MEMBERS.RESIGNED_FROM_GROUP,
      resignFromGroupFailed: dict.UI.GROUP.MEMBERS.RESIGN_FROM_GROUP_FAILED,
    };
  }
}

<h2>Find users</h2>
<shared-form-editor [config]="config"></shared-form-editor>

<div class="col-container" *ngFor="let u of users">
  <div class="col-xs-2">{{ u.givenName }} {{ u.familyName }}</div>
  <div class="col-xs-4">
    <button
      mat-flat-button
      color="primary"
      (click)="viewUser(u)"
      class="tiny first-action-button"
    >
      View
    </button>
    <button mat-flat-button color="primary" (click)="pickUser(u)" class="tiny">
      Pick
    </button>
  </div>
</div>

<h2>Create user</h2>
<user-login-or-register
  [showRegister]="true"
  registerText="Add user"
  [registerCallback]="registerCallback()"
></user-login-or-register>

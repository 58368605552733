import { AdminService } from "@admin/services/admin.service";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ErrorLogDTO } from "@auto/dto.models";
import { firstValueFrom } from "rxjs";

@Component({
  templateUrl: "./admin-route-errors.component.html",
  styleUrls: ["./admin-route-errors.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminRouteErrorsComponent implements OnInit {
  errors?: ErrorLogDTO[];
  constructor(private service: AdminService, private cdr: ChangeDetectorRef) {}

  async ngOnInit() {
    this.errors = (
      await firstValueFrom(this.service.getErrorLogs())
    ).collection;
    this.cdr.detectChanges();
  }

  viewTrace(i: ErrorLogDTO) {
    let w = window.open("");
    //(w as any).document.write(i.trace!.replace(/(?:\r\n|\r|\n)/g, "<br/>"));
    (w as any).document.write(`<pre>${i.trace}</pre>`);
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "core-footer",
  templateUrl: "./core-footer.component.html",
  styleUrls: ["./core-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreFooterComponent implements OnInit {
  thisYear = new Date().getFullYear();
  constructor() {}

  ngOnInit(): void {}
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { RegionalFormattingService } from "@shared/services";

@Component({
  selector: "events-datetime-view",
  templateUrl: "./events-datetime-view.component.html",
  styleUrls: ["./events-datetime-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsDateTimeViewComponent implements OnInit {
  @Input() start!: number;
  @Input() end?: number;
  @Input() timeZone?: string;
  constructor(
    private reg: RegionalFormattingService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {}

  getDate(ts: number, compareTime = 0): string {
    if (compareTime == 0) {
      return this.reg.defaultRelativeDateTime(ts);
    }
    if (this.reg.defaultDate(ts) == this.reg.defaultDate(compareTime)) {
      return this.reg.defaultTime(ts);
    }
    return this.getDate(ts);
  }

  getTimeZone() {
    if (!this.timeZone) {
      return "";
    }
    const now = new Date(this.start);
    const eventTime = new Date(
      new Date(this.start).toLocaleString("en-US", {
        timeZone: this.timeZone,
      })
    );
    const timeZoneTime = eventTime.getTime();
    if (now.getTime() === timeZoneTime) {
      return "";
    }

    now.setHours(eventTime.getHours());
    now.setMinutes(eventTime.getMinutes());

    const eventTimeStr = this.reg.defaultTime(now.getTime());
    return this.translationService.fromString(
      TRANSLATIONS.UI.GROUP.EVENTS.START_TIME_IN_TIME_ZONE,
      {
        timeZone: this.timeZone,
        eventTime: eventTimeStr,
      }
    );
  }
}

import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { BehaviorSubject, Subject } from "rxjs";

export interface CS_VALUE {
  key: string;
  value: any;
}

const PREFIX = "SMC_CMOS_CSVALUE";

@Injectable({
  providedIn: "root",
})
export class ControlStateService {
  controlStates$ = new Subject<CS_VALUE>();

  constructor() {}

  set<T>(key: string, value: T) {
    const strValue = JSON.stringify({
      value,
    });
    localStorage.setItem(PREFIX + key, strValue);
    this.controlStates$.next({
      key,
      value,
    });
  }

  get<T>(key: string, defaultValue: T) {
    const value = localStorage.getItem(PREFIX + key);
    if (value === null) {
      return defaultValue;
    }
    return JSON.parse(value)["value"];
  }
}

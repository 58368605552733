import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from "@angular/core";
import { EventsEditorUtils } from "../events-editor/events-editor.utils";

@Component({
  selector: "events-event-type",
  templateUrl: "./events-event-type.component.html",
  styleUrls: ["./events-event-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsEventTypeComponent implements OnInit {
  @Input() eventTypeId!: string;
  @Input() groupId!: string; // When customized event types are available
  style = "";

  constructor() {}

  ngOnInit(): void {
    const colors = EventsEditorUtils.getGlobalEventTypeColors(this.eventTypeId);
    let str = "";
    if (colors.color) {
      str = "color: " + colors.color + ";";
    }
    if (colors.backgroundColor) {
      str += "background-color: " + colors.backgroundColor + ";";
    }
    this.style = str;
  }
}

import { Injectable } from "@angular/core";
import {
  EventDataDTO,
  EventTypeDTO,
  NewEventDTO,
  SingleSignUpDTO,
} from "@auto/dto.models";
import { EventApiService, SignUpApiService, TRANSLATIONS } from "@auto/index";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { firstValueFrom, merge, Observable, of } from "rxjs";
import { map, tap, filter, switchMap } from "rxjs/operators";
import { EventInStore } from ".";
import { EventsStore } from "./events.store";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  private userId: string = "";
  constructor(
    private store: EventsStore,
    private api: EventApiService,
    private signUpApi: SignUpApiService,
    private translationService: TranslationService,
    private userService: UserService
  ) {
    LifeCyclesUtil.sub(
      this,
      this.userService.user$.pipe(filter((i) => !!i && i.id !== this.userId)),
      () => {
        this.store.clearStore();
      }
    );
  }

  // Need to via forkJoin when there can be custom event types for group
  getEventTypesOfGroup(groupId: string) {
    if (this.store.globalEventTypes.obs$.value.length === 0) {
      this.api
        .getGlobalEventTypes()
        .pipe(
          map((i) =>
            i.collection
              .map((j) => this.translateEventType(j))
              .sort((a, b) => (a.name || "" < (b.name || "") ? -1 : 1))
          )
        )
        .subscribe((i) => {
          this.store.globalEventTypes.set(i);
        });
    }
    return this.store.globalEventTypes.obs$.pipe(filter((i) => i.length > 0));
  }

  createEvent(groupId: string, dto: NewEventDTO) {
    return this.api
      .createEvent(groupId, dto)
      .pipe(tap((i) => this.store.clearStore(groupId)));
  }

  updateEvent(groupId: string, eventId: string, dto: NewEventDTO) {
    return this.api
      .updateEvent(groupId, eventId, dto)
      .pipe(tap((i) => this.store.clearStore(groupId)));
  }

  updateEventSeries(groupId: string, eventId: string, dto: NewEventDTO) {
    return this.api
      .updateEventSeries(groupId, eventId, dto)
      .pipe(tap((i) => this.store.clearStore(groupId)));
  }

  getEvent(eventId: string, groupId?: string): Observable<EventInStore> {
    if (!this.store.events.has(eventId)) {
      if (!groupId) {
        throw Error("Group id must be given if event not in store");
      }
      return this.api.getEvent(groupId, eventId).pipe(
        tap((i) => this.store.events.set(eventId, { event: i })),
        switchMap((i) => this.getEvent(eventId, groupId))
      );
    }
    const currentValue = this.store.events.get(eventId);
    const updateStream$ = this.store.events.obs$.pipe(
      filter((i) => i.key === eventId),
      map((i) => i.value)
    );
    return merge(of(currentValue), updateStream$);
  }

  private translateEventType(type: EventTypeDTO) {
    type.name = this.translationService.fromString(
      (TRANSLATIONS.UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES as any)[type.id]
    );
    return type;
  }

  getComingEventsOfGroup(groupId: string): Observable<string[]> {
    return this.store.groupsComingEvents.subscribeToValue(groupId, () =>
      this.api.getComingEventsOfGroup(groupId).pipe(
        tap((i) => {
          i.collection.forEach((i) => {
            this.store.events.set(i.id, { event: i });
          });
          this.store.groupsComingEventsLastKey.set(groupId, "");
        }),
        map((i) => i.collection.map((i) => i.id))
      )
    );
  }

  getComingEvensOfMyDefaultGroups(groupIds: string[]): Observable<string[]> {
    if (this.store.myComingEvents.obs$.value.length === 0) {
      this.store.myComingEventsLoaded.next(false);
      this.api.getMyNextEvents().subscribe((i) => {
        i.collection.forEach((i) => {
          this.store.events.set(i.id, { event: i });
        });
        this.store.myComingEvents.set(i.collection.map((i) => i.id));
        this.store.myComingEventsLoaded.next(true);
      });
    }
    return this.store.myComingEvents.obs$;
  }

  setEvents(events: EventDataDTO[]) {
    events.forEach((i) => {
      this.store.events.set(i.id, { event: i });
    });
  }

  refreshComingEventsOfGroup(groupId: string) {
    this.store.groupsComingEvents.clear(groupId);
    this.store.groupsComingEventsLastKey.set(groupId, undefined);
    // This might emit before the request is made, confirm!
    return firstValueFrom(this.getComingEventsOfGroup(groupId));
  }

  refreshEventsSignUps(
    groupId: string,
    eventId: string,
    myStatus?: {
      status: string;
      message?: string;
    }
  ) {
    return this.api.getEventsSignUps(groupId, eventId).pipe(
      tap((i) => {
        this.store.setEventsSignUps(
          eventId,
          i.collection,
          myStatus || this.getMySignUpStatus(i.collection)
        );
      })
    );
  }

  getEventsSignUps(eventId: string): Observable<SingleSignUpDTO[]> {
    if (this.store.hasSignUps(eventId)) {
      const currentValue = this.store.events.get(eventId).signUps!;
      const updateStream$ = this.store.events.obs$.pipe(
        filter((i) => i.key === eventId),
        map((i) => i.value.signUps!)
      );
      return merge(of(currentValue), updateStream$);
    }
    const groupId = this.store.events.get(eventId).event.groupId;
    return this.api.getEventsSignUps(groupId, eventId).pipe(
      tap((i) =>
        this.store.setEventsSignUps(
          eventId,
          i.collection,
          this.getMySignUpStatus(i.collection)
        )
      ),
      switchMap((i) => this.getEventsSignUps(eventId))
    );
  }

  private getMySignUpStatus(signUps: SingleSignUpDTO[]):
    | {
        status: string;
        message?: string;
      }
    | undefined {
    const myId = this.userService.user!.id;
    const mySignUp = signUps.find((i) => i.userId === myId);
    if (mySignUp) {
      return {
        status: mySignUp.status,
        message: mySignUp.message,
      };
    }
    return undefined;
  }

  areMyEventsLoaded(): Observable<boolean> {
    return this.store.myComingEventsLoaded;
  }

  groupsComingEventsLastKey(groupId: string): Observable<string | undefined> {
    return this.store.groupsComingEventsLastKey.subscribeToValue(groupId, () =>
      of(undefined)
    );
  }

  signUpToEvent(
    groupId: string,
    eventId: string,
    status: string,
    message?: string
  ) {
    return firstValueFrom(
      this.signUpApi
        .signUpToEvent(groupId, eventId, {
          status,
          message,
        })
        .pipe(
          switchMap((i) =>
            this.refreshEventsSignUps(groupId, eventId, {
              status,
              message,
            })
          )
        )
    );
  }

  signUpAdditionalToEvent(
    groupId: string,
    eventId: string,
    name: string,
    message?: string
  ) {
    return firstValueFrom(
      this.signUpApi
        .signUpAdditionalToEvent(groupId, eventId, {
          name,
          message,
          status: "YES",
        })
        .pipe(switchMap((i) => this.refreshEventsSignUps(groupId, eventId)))
    );
  }

  removeSignUpOfRemovedMember(
    groupId: string,
    eventId: string,
    userId: string
  ) {
    return this.signUpApi
      .removeSignUpOfRemovedMember(groupId, eventId, userId)
      .pipe(switchMap((i) => this.refreshEventsSignUps(groupId, eventId)));
  }

  removeSignUpOfAdditional(groupId: string, eventId: string, signUpId: string) {
    return this.signUpApi
      .removeSignUpAdditional(groupId, signUpId)
      .pipe(switchMap((i) => this.refreshEventsSignUps(groupId, eventId)));
  }

  signOtherMemberUpToEvent(
    groupId: string,
    eventId: string,
    userId: string,
    status: string,
    message?: string
  ) {
    return firstValueFrom(
      this.signUpApi
        .signUpToEventAsAdmin(groupId, eventId, {
          userId,
          status,
          message,
        })
        .pipe(switchMap((i) => this.refreshEventsSignUps(groupId, eventId)))
    );
  }
}

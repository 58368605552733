// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.

export enum UserPermissions {
  SYS_CREATE_EVENT = "SYS_CREATE_EVENT",
  SYS_CREATE_GROUP = "SYS_CREATE_GROUP",
  SYS_ADMIN_MANAGE_USERS = "SYS_ADMIN_MANAGE_USERS",
  SYS_ADMIN_READ_ERROR_LOGS = "SYS_ADMIN_READ_ERROR_LOGS",
  SYS_ADMIN_BROWSE_USERS = "SYS_ADMIN_BROWSE_USERS",
  SYS_ADMIN_PICK_USER = "SYS_ADMIN_PICK_USER",
}

export enum GroupPermissions {
  GRP_ADD_MEMBER = "GRP_ADD_MEMBER",
  GRP_ENROLL_EXTRA_TO_EVENT = "GRP_ENROLL_EXTRA_TO_EVENT",
  GRP_ENROLL_TO_EVENT = "GRP_ENROLL_TO_EVENT",
  GRP_ENROLL_OTHERS = "GRP_ENROLL_OTHERS",
  GRP_MANAGE_EVENT = "GRP_MANAGE_EVENT",
  GRP_MANAGE_FORUM = "GRP_MANAGE_FORUM",
  GRP_MANAGE_MEMBER = "GRP_MANAGE_MEMBER",
  GRP_MANAGE_SETTINGS = "GRP_MANAGE_SETTINGS",
  GRP_POST_TO_FORUM = "GRP_POST_TO_FORUM",
  GRP_SEE_ENROLLED_AMOUNT = "GRP_SEE_ENROLLED_AMOUNT",
  GRP_SEE_ENROLLED_NAME = "GRP_SEE_ENROLLED_NAME",
  GRP_SEE_MEMBERS_IN_GROUPINGS = "GRP_SEE_MEMBERS_IN_GROUPINGS",
  GRP_SEE_MEMBER_DATA = "GRP_SEE_MEMBER_DATA",
  GRP_SEE_MEMBER_NAME = "GRP_SEE_MEMBER_NAME",
  GRP_SEE_STATISTICS = "GRP_SEE_STATISTICS",
  GRP_SEND_TO_MAILING_LIST = "GRP_SEND_TO_MAILING_LIST",
}

export enum RoleTypeEnum {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}

export enum GroupTypeEnum {
  PERSONAL = "PERSONAL",
  TEAM = "TEAM",
}

export enum StatusEnum {
  OK = "OK",
  FAILED = "FAILED",
}

export enum ErrorEnum {
  GROUPING_WITH_NAME_EXISTS = "GROUPING_WITH_NAME_EXISTS",
  ALREADY_SLAVE = "ALREADY_SLAVE",
  CREATE_USER_USERNAME_EXISTS = "CREATE_USER_USERNAME_EXISTS",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  BAD_REQUEST = "BAD_REQUEST",
  INVITATION_ALREADY_ACCEPTED = "INVITATION_ALREADY_ACCEPTED",
  ALREADY_MEMBER = "ALREADY_MEMBER",
  USER_NOT_DELETED = "USER_NOT_DELETED",
}

export enum ErrorLogSourceEnum {
  API_LAMBDA = "API_LAMBDA",
  SES_NOTIFICATIONS_LAMBDA = "SES_NOTIFICATIONS_LAMBDA",
}

export interface Recurrence {
  type: string;
  daySchema: number[];
  weekSchema?: number[];
  lastEventAt: number;
}

export interface SignUpStatus {
  status: string;
  message?: string;
}

export interface NewUserDTO {
  username?: string;
  password?: string;
  givenName: string;
  familyName: string;
  birthYear: number;
  emails?: string[];
  locale?: string;
  language?: string;
}

export interface LoginRequestDTO {
  username: string;
  password: string;
}

export interface LoginResponseDTO {
  sessionId: string;
}

export interface UserEmailDTO {
  email: string;
  isVerified: boolean;
}

export interface UserEmailsDTO {
  collection: UserEmailDTO[];
}

export interface UserGroupDTO {
  roleType: RoleTypeEnum;
  roleName?: string;
  roleId?: string;
  displayName: string;
  joined: number;
  permissions: GroupPermissions[];
  isDefault: boolean;
  groupId: string;
  groupName: string;
  groupType: string;
  groupShortName: string;
}

export interface BasicUserDTO {
  id?: string;
  givenName?: string;
  familyName?: string;
  slaves?: BasicUserDTO[];
}

export interface GetUserDTO {
  id: string;
  joined: number;
  username?: string;
  givenName: string;
  familyName: string;
  birthYear: number;
  groups: UserGroupDTO[];
  sessionId?: string;
  returnAllEvents?: boolean;
  locale?: string;
  language?: string;
  permissions?: UserPermissions[];
  slaves?: BasicUserDTO[];
  masters?: BasicUserDTO[];
  master?: BasicUserDTO;
}

export interface UpdateUserSettingsDTO {
  givenName: string;
  familyName: string;
  birthYear: number;
  locale: string;
  language: string;
}

export interface ConfirmUserEmailDTO {
  success: boolean;
}

export interface CreateSlavelUserDTO {
  givenName: string;
  familyName: string;
  birthYear: number;
}

export interface IdDTO {
  id: string;
}

export interface StatusDTO {
  status?: StatusEnum;
  ids?: string[];
  id?: string;
  message?: string;
  detail?: object;
}

export interface NameDTO {
  name: string;
}

export interface NameAndIdDTO {
  name: string;
  id: string;
}

export interface NamesAndIdsDTO {
  collection: NameAndIdDTO[];
}

export interface GroupMemberDTO {
  roleType: RoleTypeEnum;
  roleName?: string;
  roleId?: string;
  id: string;
  givenName: string;
  familyName: string;
  joined: number;
  displayName: string;
}

export interface UpdateGroupMemberDTO {
  displayName?: string;
  roleType: RoleTypeEnum;
}

export interface CalendarDTO {
  id: string;
  name: string;
}

export interface GroupDTO {
  id: string;
  name: string;
  shortName: string;
  type: GroupTypeEnum;
  allowJoinRequests: boolean;
  allowAutomaticJoin: boolean;
  allowSignUpAdditional: boolean;
}

export interface GroupingDTO {
  id: string;
  name: string;
}

export interface UpdateGroupDTO {
  name: string;
  shortName: string;
  allowJoinRequests: boolean;
  allowAutomaticJoin?: boolean;
}

export interface FullGroupDTO {
  id: string;
  name: string;
  shortName: string;
  type: GroupTypeEnum;
  allowJoinRequests: boolean;
  allowAutomaticJoin: boolean;
  allowSignUpAdditional: boolean;
  calendars: CalendarDTO[];
  features?: string[];
  groupings?: GroupingDTO[];
}

export interface GroupMembersDTO {
  collection: GroupMemberDTO[];
}

export interface GroupMemberInvitationDTO {
  displayName: string;
  email: string;
}

export interface GroupMembersInvitationDTO {
  roleType: RoleTypeEnum;
  roleName?: string;
  roleId?: string;
  language: string;
  message: string;
  members: GroupMemberInvitationDTO[];
}

export interface GroupingMapDTO {
  name: string;
  id: string;
  memberIds: string[];
}

export interface GroupingMapsDTO {
  collection: GroupingMapDTO[];
}

export interface GroupInvitationDTO {
  displayName: string;
  email: string;
  created: number;
  message: string;
  accepted: boolean;
  groupName: string;
}

export interface GroupingLimitationDTO {
  groupingId: string;
  maxSignUp?: number;
}

export interface EventLocationDTO {
  name: string;
  address?: string;
  url?: string;
  latitude?: number;
  longitude?: number;
}

export interface NewEventDTO {
  calendarId: string;
  name: string;
  startTime: number;
  endTime: number;
  startTimeZoneId?: string;
  endTimeZoneId?: string;
  description?: string;
  eventTypeId: string;
  latestSignUpTime?: number;
  maxSignUp?: number;
  groupingLimitations?: GroupingLimitationDTO[];
  recurrence?: Recurrence;
  location?: EventLocationDTO;
  locationSuffix?: string;
}

export interface SignUpToEventRequestDTO {
  status: string;
  message?: string;
  groupingId?: string;
}

export interface SignUpToAdditionalEventRequestDTO {
  status: string;
  message?: string;
  name: string;
}

export interface SignUpToEventAsAdminRequestDTO {
  status: string;
  message?: string;
  groupingId?: string;
  userId: string;
}

export interface SignUpToEventResponseDTO {
  yesCount: number;
}

export interface BasicEventDTO {
  calendarId: string;
  name: string;
  startTime: number;
  endTime: number;
  startTimeZoneId?: string;
  endTimeZoneId?: string;
  description?: string;
  eventTypeId: string;
  latestSignUpTime?: number;
  maxSignUp?: number;
  groupingLimitations?: GroupingLimitationDTO[];
  recurrence?: Recurrence;
  location?: EventLocationDTO;
  locationSuffix?: string;
  groupId: string;
  seriesId?: string;
  id: string;
}

export interface BasicEventsDTO {
  collection: BasicEventDTO[];
}

export interface EventDataDTO {
  calendarId: string;
  name: string;
  startTime: number;
  endTime: number;
  startTimeZoneId?: string;
  endTimeZoneId?: string;
  description?: string;
  eventTypeId: string;
  latestSignUpTime?: number;
  maxSignUp?: number;
  groupingLimitations?: GroupingLimitationDTO[];
  recurrence?: Recurrence;
  location?: EventLocationDTO;
  locationSuffix?: string;
  groupId: string;
  seriesId?: string;
  id: string;
  mySignUpStatus?: SignUpStatus;
  signUpStatusCounts?: object;
}

export interface EventListDTO {
  collection: EventDataDTO[];
}

export interface SingleSignUpDTO {
  status: string;
  message?: string;
  userId: string;
  signedUpById: string;
  signUpTime: number;
  isExternal?: boolean;
  name?: string;
}

export interface SignUpsDTO {
  collection: SingleSignUpDTO[];
}

export interface EventTypeDTO {
  id: string;
  isGlobal: boolean;
  name?: string;
}

export interface EventTypesDTO {
  collection: EventTypeDTO[];
}

export interface ErrorLogDTO {
  created: number;
  message: string;
  source: ErrorLogSourceEnum;
  trace?: string;
  path?: string;
  username?: string;
}

export interface ErrorLogsDTO {
  collection: ErrorLogDTO[];
}

export interface PerfLogChildDTO {
  func?: string;
  start?: number;
  end?: number;
  children?: PerfLogChildDTO[];
}

export interface PerfLogDTO {
  func: string;
  created: number;
  start: number;
  end: number;
  username?: string;
  route: string;
  routePattern: string;
  routeParams?: object;
  children: PerfLogChildDTO[];
}

export interface PerfLogsDTO {
  collection: PerfLogDTO[];
}

export interface SearchUserDTO {
  searchStr: string;
}

export interface FoundUserDTO {
  givenName: string;
  familyName: string;
  id: string;
}

export interface FoundUsersDTO {
  collection: FoundUserDTO[];
}

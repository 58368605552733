<div #busyLayer class="busy-layer">
  <div class="icon">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="toolbar">
  <ng-container *ngIf="!isGroupRestriction">
    <img
      (click)="home()"
      class="clickable toolbar__item toolbar__item--title logo"
      src="./assets/images/ilmo.png"
      alt="ILMO"
    />
    <span class="global__flex-spacer"></span>
  </ng-container>

  <ng-container *ngIf="isLoggedIn">
    <core-group-selector
      class="toolbar__item"
      [selectedGroup]="selectedGroup"
      [groups]="groups"
      (onSelect)="selectGroup($any($event))"
    >
    </core-group-selector>

    <ng-container *ngIf="isGroupRestriction">
      <span class="global__flex-spacer"></span>
    </ng-container>

    <span
      class="toolbar__spacer hidden-to-s"
      *ngIf="!isGroupRestriction"
    ></span>

    <ng-container *ngIf="!isGroupRestriction">
      <ng-container *sharedUserPermission="$any('SYS_CREATE_GROUP')">
        <span
          class="toolbar__item hidden-to-m"
          (click)="newGroup()"
          data-t-id="top-nav-new-group"
        >
          <span
            class="toolbar__item__text--with-icon"
            translate="COMMON_UI.KEY_WORD.NEW_GROUP"
          ></span>
          <mat-icon
            class="toolbar__item__text--icon"
            fontSet="fa"
            fontIcon="fa-plus-square"
          >
          </mat-icon>
        </span>
        <span class="toolbar__spacer hidden-to-m"></span>
      </ng-container>
    </ng-container>

    <ng-container *sharedUserPermission="$any('SYS_CREATE_EVENT')">
      <span class="toolbar__item hidden-to-s" (click)="newEvent()">
        <span
          class="toolbar__item__text--with-icon"
          translate="COMMON_UI.KEY_WORD.NEW_EVENT"
        ></span>
        <mat-icon
          class="toolbar__item__text--icon"
          fontSet="fa"
          fontIcon="fa-calendar-plus"
        >
        </mat-icon>
      </span>

      <span class="toolbar__spacer hidden-to-s"></span>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!isLoggedIn">
    <span
      class="toolbar__item hidden-to-s"
      data-t-id="register"
      (click)="register()"
    >
      <span
        class="toolbar__item__text--with-icon"
        translate="COMMON_UI.KEY_WORD.REGISTER"
        data-t-id="register-text"
      >
      </span>
      <mat-icon
        class="toolbar__item__text--icon"
        fontSet="fa"
        fontIcon="fa-user-plus"
      >
      </mat-icon>
    </span>

    <span class="toolbar__spacer hidden-to-s"></span>

    <span class="toolbar__item hidden-to-s" data-t-id="login" (click)="login()">
      <span
        class="toolbar__item__text--with-icon"
        translate="COMMON_UI.KEY_WORD.LOGIN"
        data-t-id="login-text"
      >
      </span>
      <mat-icon
        class="toolbar__item__text--icon"
        fontSet="fa"
        fontIcon="fa-sign-in-alt"
      >
      </mat-icon>
    </span>
  </ng-container>

  <ng-container *ngIf="isLoggedIn">
    <span
      class="toolbar__item hidden-to-m"
      data-t-id="right-nav-opener"
      (click)="toggleRightNav()"
    >
      <ng-container *ngIf="isLoggedIn">
        <span class="toolbar__item__text--with-icon">
          {{ user!.givenName }}
        </span>
        <mat-icon
          class="toolbar__item__text--icon"
          fontSet="fa"
          fontIcon="fa-user"
        >
        </mat-icon>
      </ng-container>
    </span>
  </ng-container>

  <span
    [ngClass]="isLoggedIn ? 'toolbar__item hidden-l' : 'toolbar__item hidden-m'"
    (click)="toggleRightNav()"
  >
    <mat-icon
      class="toolbar__item__text--icon toolbar__item__text--icon--center"
      fontSet="fa"
      fontIcon="fa-bars"
    >
    </mat-icon>
  </span>
</div>

<mat-sidenav-container class="sidenav-container sidenav-container__top-margin">
  <!-- Right side nav -->
  <mat-sidenav
    #sidenav
    mode="over"
    [opened]="rightOpened"
    position="end"
    [fixedTopGap]="topGap"
    fixedBottomGap="0"
  >
    <core-right-side-nav (close)="toggleRightNav()"> </core-right-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Second navigation level e.g. GroupMenu -->
    <shared-second-nav
      *ngIf="hasSecondNav()"
      [links]="$any(secondNav)"
      class="second-nav dropdown"
    ></shared-second-nav>
    <!-- Third navigation level e.g. GroupSettingsMenu -->

    <div style="margin: 5px; padding-bottom: 150px">
      <mat-card>
        <router-outlet></router-outlet>
      </mat-card>
    </div>
    <div class="footer">
      <core-footer></core-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

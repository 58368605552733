import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { GetUserDTO, UserPermissions } from "@auto/dto.models";
import { UserService } from "@core/services/user.service";
import { Subscription } from "rxjs";

@Directive({
  selector: "[sharedUserPermission]",
})
export class SharedUserPermissionDirective implements OnDestroy {
  private permission!: UserPermissions;

  @Input() set sharedUserPermission(permission: UserPermissions) {
    this.permission = permission;
    this.computeState();
  }

  // This is hide on true (when user has permission)
  @Input() hide = false;

  private previousState = false;

  private sub: Subscription;
  private user: GetUserDTO | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    userService: UserService
  ) {
    this.sub = userService.user$.subscribe((u) => {
      this.user = u;
      this.computeState();
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private computeState() {
    let state = false;
    if (this.user && this.permission) {
      state = this.user.permissions?.indexOf(this.permission) !== -1;
      if (this.hide) {
        state = !state;
      }
    }
    if (state !== this.previousState) {
      if (state) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdr.markForCheck();
      } else {
        this.viewContainer.clear();
      }
    }
    this.previousState = state;
  }
}

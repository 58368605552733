<h1 mat-dialog-title>{{ texts.title }}</h1>
<div mat-dialog-content>
  <p>{{ texts.guide }}</p>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="secondary" [mat-dialog-close]="false">
    {{ texts.cancel }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    {{ texts.confirm }}
  </button>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { NavLink, NavigationService } from "@core/services/navigation.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { SharedSubscriptionBaseComponent } from "../../base-components/shared-subscription-base/shared-subscription-base.component";

const RESIZE_DEBOUNCE = 100;
const SHOW_MORE_WIDTH = 32;

@Directive({
  selector: "[linkElement]",
})
export class LinkElement {
  constructor() {}
}

@Component({
  selector: "shared-second-nav",
  templateUrl: "./shared-nav-second.component.html",
  styleUrls: ["./shared-nav-second.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedNavSecondComponent
  extends SharedSubscriptionBaseComponent
  implements OnInit
{
  displayLinks!: NavLink[];
  @Input() externalActive = false;
  @Input() allowPartialFit = true;
  @Input() set links(value: NavLink[]) {
    if (this.didLinksChange(value) || this.externalActive) {
      this.displayLinks = value;
      this.setActive();
    }
  }
  doesAnyOverflow = false;
  isMoreVisible = false;
  private $resetWidth = new Subject<void>();
  private resizeObserver!: ResizeObserver;
  private activeUrl: string = "";
  private toggling = false;

  @ViewChildren(LinkElement, { read: ElementRef })
  linkElems!: QueryList<ElementRef>;
  @ViewChild("navContainer", { static: true }) navContainer!: ElementRef;

  constructor(
    private elementRef: ElementRef,
    private navService: NavigationService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub(this.$resetWidth.pipe(debounceTime(RESIZE_DEBOUNCE))).subscribe(
      () => this.setHidden()
    );
    this.sub(this.navService.activePath$).subscribe((path) => {
      this.activeUrl = path;
      this.setActive();
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.sub(this.linkElems.changes).subscribe(() => {
      this.resetObservers();
      this.$resetWidth.next();
    });
    this.resetObservers();
    this.$resetWidth.next();
  }

  override ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    super.ngOnDestroy();
  }

  getClasses(link: NavLink) {
    const cls = ["link"];
    if (link.isActive) {
      cls.push("link--active");
    }
    if (link.cls) {
      cls.push(link.cls);
    }
    return cls;
  }

  setActive() {
    if (!this.externalActive && this.displayLinks) {
      this.displayLinks.forEach((i) => {
        const url = i.activeUrl
          ? i.activeUrl
          : !(typeof i.url === "function")
          ? typeof i.url === "string"
            ? i.url
            : i.url.PATH
          : "";
        i.isActive = url === this.activeUrl.substring(0, url.length);
      });
    }
  }

  // need to subscrive to all span elements as they might change
  private resetObservers() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    this.resizeObserver = new ResizeObserver((entries) => {
      this.$resetWidth.next();
    });
    if (this.linkElems) {
      this.linkElems.forEach((i) => {
        this.resizeObserver.observe(i.nativeElement);
      });
    }
    if (this.navContainer) {
      this.resizeObserver.observe(this.navContainer.nativeElement);
    }
  }

  // Compute which link elements don't fit into viewport
  private setHidden() {
    if (!this.elementRef) {
      return;
    }
    let widthReduced = false;
    this.doesAnyOverflow = false;
    const elems = this.linkElems.map((i) => i.nativeElement);
    let width =
      window.innerWidth - (this.allowPartialFit ? 0 : SHOW_MORE_WIDTH);
    elems.forEach((e, index) => {
      const rect = e.getBoundingClientRect();
      let doesOverflow = rect.x + rect.width > width;
      let doesOverflowWithShowMore =
        rect.x + rect.width > width - SHOW_MORE_WIDTH;
      if (
        this.allowPartialFit &&
        !widthReduced &&
        index !== elems.length - 1 &&
        doesOverflowWithShowMore
      ) {
        width = width - SHOW_MORE_WIDTH;
        doesOverflow = true;
        widthReduced = true;
      }
      this.displayLinks[index].doesOverflow = doesOverflow;
      if (this.displayLinks[index].doesOverflow) {
        this.doesAnyOverflow = true;
      }
    });
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  open(link: NavLink, newWindow?: boolean) {
    if (typeof link.url === "function") {
      link.url(link);
      return;
    }
    if (typeof link.url !== "string") {
      link.url = link.url.PATH;
    }
    if (newWindow) {
      const url = link.url.match(/:\/\//)
        ? link.url
        : this.navService.getLink(link.url);
      window.open(url);
      return;
    }
    if (link.url.match(/:\/\//)) {
      window.location.href = link.url;
    } else {
      this.navService.goto(link.url);
    }
  }

  closeMore() {
    if (this.toggling) {
      return;
    }
    this.isMoreVisible = false;
    this.cdr.detectChanges();
  }

  toggleMore() {
    this.toggling = true;
    setTimeout(() => {
      this.isMoreVisible = !this.isMoreVisible;
      this.toggling = false;
      this.cdr.detectChanges();
    });
  }

  openShowMore(link: NavLink, newWindow?: boolean) {
    // I don't fully understand why this is needed
    this.ngZone.run(() => {
      this.open(link, newWindow);
    });
    setTimeout(() => {
      this.closeMore();
    });
  }

  private didLinksChange(links: NavLink[]) {
    if (
      !this.displayLinks ||
      !links ||
      this.displayLinks.length !== links.length
    ) {
      return true;
    }
    for (let i = 0; i < links.length; i++) {
      if (
        links[i].name !== this.displayLinks[i].name ||
        links[i].url !== this.displayLinks[i].url
      ) {
        return true;
      }
    }
    return false;
  }
}

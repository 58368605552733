import { AdminService } from "@admin/services/admin.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivationStart, Router } from "@angular/router";
import {
  FullGroupDTO,
  GetUserDTO,
  UserGroupDTO,
  GroupPermissions,
} from "@auto/dto.models";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { DatService } from "@core/services/dat.service";
import { NavLink, NavigationService } from "@core/services/navigation.service";
import { TitleService } from "@core/services/title.service";
import { TranslationLoader } from "@core/services/translation.loader";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { SharedGroupSelectorDialogComponent } from "@shared/components";
import { BusyService } from "@shared/services/busy.service";
import { CurrentGroupService } from "@shared/services/current-group.service";

const DEFAULT_TITLE = "UI.PAGE_TITLES.LANDING";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title!: string;
  leftOpened = false;
  rightOpened = false;
  isLoggedIn = false;
  topGap!: number;
  user?: GetUserDTO;
  groups: UserGroupDTO[] = [];
  selectedGroup: FullGroupDTO | false = false;
  secondNav!: NavLink[] | false;
  isGroupRestriction = false;
  @ViewChild("busyLayer") busyLayer?: ElementRef;

  constructor(
    private navService: NavigationService,
    private router: Router,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private titleService: TitleService,
    private userService: UserService,
    private currentGroupService: CurrentGroupService,
    private dialog: MatDialog,
    private datService: DatService,
    private busyService: BusyService,
    private _a: AdminService, // To init second nav
    private _t: TranslationLoader // To init translation loader
  ) {}

  ngOnInit() {
    this.isGroupRestriction = this.datService.isGroupRestriction;
    this.userService.isLoggedIn$.subscribe((is) => {
      this.isLoggedIn = is;
      this.user = this.userService.user;
      this.cdr.detectChanges();
    });

    this.userService.user$.subscribe((user: GetUserDTO | undefined) => {
      this.user = user;
      if (user) {
        // change reference
        this.groups = user.groups.slice();
        if (this.selectedGroup) {
          const updatedGroup = this.groups.find(
            (i) => i.groupId === (this.selectedGroup as FullGroupDTO).id
          );
          if (updatedGroup) {
            this.selectedGroup.name = updatedGroup.groupName;
            this.selectedGroup.shortName = updatedGroup.groupShortName;
          }
        }
      } else {
        this.groups = [];
      }
      this.cdr.detectChanges();
    });

    this.titleService.currentTitle$.subscribe((title) => {
      if (title) {
        this.title = title;
      }
    });

    this.currentGroupService.currentGroup$.subscribe((g) => {
      this.selectedGroup = g || false;
      this.cdr.markForCheck();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const data = event.snapshot.data;
        if (data && data["useServiceTitle"]) {
          return;
        }
        this.translationService
          .fromStringViaPromise(
            data && data["title"] ? data["title"] : DEFAULT_TITLE
          )
          .then((title) => {
            this.title = title;
            this.cdr.markForCheck();
          });
      }
    });

    this.navService.secondNav$.subscribe((nav) => {
      this.secondNav = nav;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.busyService.setElement(this.busyLayer!);
  }

  hasSecondNav() {
    return (
      !!this.secondNav &&
      (!this.currentGroupService.currentGroup$.value ||
        this.currentGroupService.currentGroup?.type !== "PERSONAL")
    );
  }

  home() {
    if (this.userService.isLoggedIn$) {
      this.navService.goto(ROUTE_MAP.PRI.EVENTS);
    } else {
      this.navService.goto(ROUTE_MAP.PUB.HOME);
    }
  }

  login() {
    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get("groupId");
    const invitationId = urlParams.get("invitationId");

    if (invitationId && groupId) {
      this.navService.goto(
        ROUTE_MAP.PUB.LOGIN,
        {},
        {
          groupId,
          invitationId,
        }
      );
    } else {
      this.navService.goto(ROUTE_MAP.PUB.LOGIN);
    }
  }

  newGroup() {
    this.navService.goto(ROUTE_MAP.PRI.NEW_GROUP);
  }

  newEvent() {
    const groups = this.userService.user!.groups.filter((i) =>
      i.permissions.includes(GroupPermissions.GRP_MANAGE_EVENT)
    );
    if (
      !this.currentGroupService.isGroupSelected ||
      groups
        .map((i) => i.groupId)
        .indexOf(this.currentGroupService.currentGroupId) === -1
    ) {
      const dialogRef = this.dialog.open(
        SharedGroupSelectorDialogComponent,
        {}
      );

      dialogRef.afterClosed().subscribe((groupId) => {
        if (groupId) {
          this.navService.goto(ROUTE_MAP.PRI.GROUP.EVENTS.ADD, { groupId });
        }
      });
    } else {
      this.navService.goto(ROUTE_MAP.PRI.GROUP.EVENTS.ADD);
    }
  }

  logout() {
    this.userService.logout().then((_) => {});
    this.navService.goto(ROUTE_MAP.PUB.HOME);
  }

  toggleLeftNav() {
    this.leftOpened = !this.leftOpened;
  }

  toggleRightNav() {
    this.rightOpened = !this.rightOpened;
    this.cdr.detectChanges();
  }

  register() {
    const urlParams = new URLSearchParams(window.location.search);
    const groupId = urlParams.get("groupId");
    const invitationId = urlParams.get("invitationId");

    if (invitationId && groupId) {
      this.navService.goto(
        ROUTE_MAP.PUB.REGISTER,
        {},
        {
          groupId,
          invitationId,
        }
      );
    } else {
      this.navService.goto(ROUTE_MAP.PUB.REGISTER);
    }
  }

  selectGroup(g: UserGroupDTO) {
    if (g) {
      this.navService.goto(ROUTE_MAP.PRI.GROUP.EVENTS.CALENDAR, {
        groupId: g.groupId,
      });
    } else {
      this.navService.goto(ROUTE_MAP.PRI.EVENTS);
    }
  }
}

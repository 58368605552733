import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ITranslations } from "@auto/translations.models";
import { TranslationService } from "./translation.service";

const UI_LANGUAGES = ["ui", "common_ui"];
const DEFAULT_LANGUAGE = "fi-FI";
const LOCAL_STORAGE_KEY_FOR_LANGUAGE = "ilmo-language";

// This is done to PREVENT circular reference in http client (interceptor requires toaster which requires translations (which can't inject http client))
@Injectable({ providedIn: "root" })
export class TranslationLoader {
  constructor(
    private http: HttpClient,
    private translationService: TranslationService
  ) {
    const lastLanguage = localStorage.getItem(LOCAL_STORAGE_KEY_FOR_LANGUAGE);
    this.setLanguage(lastLanguage || DEFAULT_LANGUAGE);
  }

  async setLanguage(language: string) {
    localStorage.setItem(LOCAL_STORAGE_KEY_FOR_LANGUAGE, language);
    if (
      !this.translationService.currentLanguage ||
      this.translationService.currentLanguage !== language
    ) {
      this.translationService.currentLanguage = language;
      this.translationService.translations[language] = await this.loadLanguages(
        language
      );
      this.translationService.languageReady$.next(true);
    }
  }

  private loadLanguages(lang: string): Promise<ITranslations> {
    if (lang in this.translationService.translations) {
      return Promise.resolve(this.translationService.translations[lang]);
    }
    this.translationService.languageReady$.next(false);
    const promises: Promise<Partial<ITranslations>>[] = [];

    const ts = new Date().getTime();
    UI_LANGUAGES.forEach((i) => {
      promises.push(
        firstValueFrom(
          this.http.get<Partial<ITranslations>>(
            "assets/translations/" +
              i +
              "." +
              lang +
              ".json?ts=" +
              ts.toString() // It would be better to use here hash of main.js
          )
        )
      );
    });

    return Promise.all(promises).then((values) => {
      const obj = values.reduce((o, t) => {
        return { ...o, ...t };
      }, {});
      return obj as ITranslations;
    });
  }
}

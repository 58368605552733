import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function numberValidator(
  min: number,
  max: number,
  parser?: (i: string) => number,
  allowEmpty?: boolean
): ValidatorFn {
  if (!parser) {
    parser =
      parseInt(min.toString()) === min && parseInt(max.toString()) == max
        ? parseInt
        : parseFloat;
  }

  return (control: AbstractControl): ValidationErrors | null => {
    if (allowEmpty && control.value.toString() == "") {
      return null;
    }
    const parsed = parser!(control.value.toString());
    return parsed.toString() != control.value.toString() ||
      parsed < min ||
      parsed > max
      ? { number: { value: parsed } }
      : null;
  };
}

import { Directive, OnInit } from "@angular/core";
import { MatInput } from "@angular/material/input";

// https://stackoverflow.com/questions/51510936/how-to-set-autofocus-on-a-matinput-element-on-click-event-in-angular-6
@Directive({
  selector: "[sharedMatInputAutofocus]",
})
export class SharedMatInputAutofocusDirective implements OnInit {
  constructor(private matInput: MatInput) {}

  ngOnInit() {
    setTimeout(() => this.matInput.focus());
  }
}

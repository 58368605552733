<shared-second-nav
  [allowPartialFit]="false"
  [externalActive]="true"
  [links]="tabLinks"
  class="tab-nav dropdown"
>
</shared-second-nav>

<div [ngSwitch]="activeTabId">
  <group-settings-general *ngSwitchCase="'GENERAL'"></group-settings-general>
</div>

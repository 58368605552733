import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BasicEventDTO } from "@auto/dto.models";

export interface AddNewEventDialogData {
  groupId: string;
  startDate: Date;
  eventId: string;
}

@Component({
  templateUrl: "./events-add-new-event-dialog.component.html",
  styleUrls: ["./events-add-new-event-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsAddNewEventDialogComponent implements OnInit {
  groupId: string;
  startDate: Date;
  eventId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: AddNewEventDialogData,
    private dialogRef: MatDialogRef<EventsAddNewEventDialogComponent>
  ) {
    this.groupId = data.groupId;
    this.startDate = data.startDate;
    this.eventId = data.eventId;
  }

  afterSaved(event: BasicEventDTO[] | boolean) {
    this.dialogRef.close(event);
  }

  ngOnInit(): void {}
}

<!-- https://github.com/angular/components/issues/15367 -->
<ng-container *ngIf="matType == 'mat-stroked-button'">
  <button
    *ngIf="!loading"
    mat-stroked-button
    [color]="color"
    [disabled]="disabled || isDirtyDisabled"
    [ngClass]="cls"
    [attr.data-t-id]="name"
    (click)="click()"
  >
    {{ text }}
  </button>

  <button
    *ngIf="loading"
    mat-stroked-button
    disabled
    [attr.aria-label]="text"
    [attr.data-t-id]="name || ''"
  >
    <mat-icon fontSet="fa" fontIcon="fa-spinner" class="fa-spin icon">
    </mat-icon>
  </button>
</ng-container>

<ng-container *ngIf="matType == 'mat-flat-button'">
  <button
    *ngIf="!loading"
    mat-flat-button
    [color]="color"
    [disabled]="disabled || isDirtyDisabled"
    [ngClass]="cls"
    [attr.data-t-id]="name"
    (click)="click()"
  >
    {{ text }}
  </button>

  <button
    *ngIf="loading"
    mat-flat-button
    disabled
    [attr.aria-label]="text"
    [attr.data-t-id]="name || ''"
  >
    <mat-icon fontSet="fa" fontIcon="fa-spinner" class="fa-spin icon">
    </mat-icon>
  </button>
</ng-container>

<shared-loading *ngIf="!members"></shared-loading>
<ng-container *ngIf="members">
  <div class="button-group">
    <!-- Invite member -->
    <ng-container *ngIf="!doesHaveSlaves && !isAdminInOtherGroups">
      <button
        mat-stroked-button
        color="primary"
        class="margin"
        data-t-id="add-member"
        (click)="addMember()"
        translate="UI.GROUP.MEMBERS.ADD"
      ></button>
    </ng-container>

    <ng-container *ngIf="doesHaveSlaves || isAdminInOtherGroups">
      <button
        mat-stroked-button
        color="primary"
        class="margin"
        data-t-id="leave-group"
        translate="UI.GROUP.MEMBERS.ADD_MEMBERS"
        [matMenuTriggerFor]="addMembersMenu"
      ></button>
      <mat-menu #addMembersMenu="matMenu">
        <ng-container *ngIf="doesHaveSlaves">
          <button
            mat-menu-item
            translate="UI.GROUP.MEMBERS.ADD"
            (click)="addMember()"
          ></button>

          <button
            mat-menu-item
            translate="UI.GROUP.MEMBERS.ADD_FROM_SLAVES"
            (click)="addMemberFromMySlaves()"
          ></button>
        </ng-container>
      </mat-menu>
    </ng-container>

    <!-- Groupings -->
    <button
      mat-stroked-button
      color="primary"
      class="margin"
      data-t-id="leave-group"
      translate="UI.GROUP.MEMBERS.GROUPINGS.TITLE"
      [matMenuTriggerFor]="groupingsMenus"
    ></button>

    <mat-menu #groupingsMenus="matMenu">
      <button
        (click)="addGrouping()"
        mat-menu-item
        translate="COMMON_UI.KEY_WORD.ADD"
      ></button>
      <button
        *ngIf="selectedGrouping"
        (click)="deselectGrouping()"
        mat-menu-item
        translate="UI.GROUP.MEMBERS.GROUPINGS.SHOW_ALL"
      ></button>
      <button
        *ngFor="let i of groupings"
        (click)="selectGrouping(i)"
        mat-menu-item
      >
        {{ i.name }}
      </button>
    </mat-menu>
  </div>

  <ng-container *ngIf="selectedGrouping">
    <h2>{{ selectedGrouping.name }}</h2>
    <button
      *ngIf="hasGroupingMembers"
      mat-stroked-button
      color="primary"
      class="margin"
      (click)="toggleEditGroupingMembers()"
      [translate]="
        editGroupingMembers
          ? 'UI.GROUP.MEMBERS.GROUPINGS.SHOW_MEMBERS'
          : 'UI.GROUP.MEMBERS.GROUPINGS.EDIT_MEMBERS'
      "
    ></button>
    <shared-loading-button
      color="primary"
      class="margin-to-right"
      [cb]="removeGrouping()"
      [confirm]="true"
      key="COMMON_UI.KEY_WORD.DELETE"
    ></shared-loading-button>

    <button
      mat-stroked-button
      color="primary"
      class="margin"
      (click)="editGrouping()"
      translate="COMMON_UI.KEY_WORD.MODIFY"
    ></button>
  </ng-container>

  <ng-container *ngIf="!selectedGrouping || !editGroupingMembers">
    <div class="header">
      <div class="column">
        {{ "COMMON_UI.KEY_WORD.NAME" | translate }}
      </div>
      <div class="column">
        {{ "COMMON_UI.KEY_WORD.ROLE" | translate }}
      </div>
      <div class="column">
        {{ "COMMON_UI.KEY_WORD.JOINED" | translate }}
      </div>
      <div class="last-column"></div>
    </div>
    <ng-container
      *ngFor="let member of filterMembers(members); let ind = index"
    >
      <div class="member-row" [ngClass]="{ alternate: ind % 2 == 0 }">
        <div
          class="column"
          data-t-id="name"
          [attr.data-t-member-id]="member.id"
        >
          {{ member.displayName }}
        </div>
        <div
          class="column"
          data-t-id="role"
          [attr.data-t-member-id]="member.id"
          [attr.data-t-role-type]="member.roleType"
        >
          <i
            class="fa fa-user role-icon"
            title="{{ 'COMMON_UI.KEY_WORD.MEMBER' | translate }}"
            *ngIf="member.roleType === 'MEMBER'"
          ></i>
          <i
            class="fa fa-user-cog role-icon-admin"
            title="{{ 'COMMON_UI.KEY_WORD.ADMIN' | translate }}"
            *ngIf="member.roleType === 'ADMIN'"
          ></i>
        </div>
        <div
          class="column"
          data-t-id="joined"
          [attr.data-t-member-id]="member.id"
        >
          {{ getJoinedDate(member.joined) }}
        </div>
        <div class="last-column">
          <button
            class="menu-button"
            [matMenuTriggerFor]="menu"
            *ngIf="isAdmin || (currentUser && currentUser.id === member.id)"
          >
            <i class="fa fa-cog"></i>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container>
              <button
                *ngIf="isAdmin || (currentUser && currentUser.id === member.id)"
                mat-menu-item
                data-t-id="edit"
                [attr.data-t-member-id]="member.id"
                (click)="editMember(member)"
                translate="COMMON_UI.KEY_WORD.MODIFY"
              ></button>
              <button
                *ngIf="isAdmin"
                mat-menu-item
                data-t-id="delete"
                [attr.data-t-member-id]="member.id"
                (click)="deleteMember(member.id)"
                translate="COMMON_UI.KEY_WORD.DELETE"
              ></button>
            </ng-container>
          </mat-menu>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="editGroupingMembers">
    <div class="header">
      <div class="column">
        {{ "COMMON_UI.KEY_WORD.NAME" | translate }}
      </div>
      <div class="column" translate="COMMON_UI.KEY_WORD.ADD_OR_REMOVE"></div>
    </div>
    <ng-container *ngFor="let member of members">
      <div class="member-row">
        <div
          class="column"
          data-t-id="name"
          [attr.data-t-member-id]="member.id"
        >
          {{ member.displayName }}
        </div>
        <div class="column">
          <shared-loading-button
            class="tiny"
            matType="mat-flat-button"
            [color]="member.isCurrentGroupingMember ? 'primary' : 'accent'"
            [key]="
              member.isCurrentGroupingMember
                ? 'COMMON_UI.KEY_WORD.DELETE'
                : 'COMMON_UI.KEY_WORD.ADD'
            "
            [cb]="toggleIsGroupingMember(member)"
          >
          </shared-loading-button>
        </div>
      </div> </ng-container
  ></ng-container>
</ng-container>

<shared-loading *ngIf="isLoading"></shared-loading>

<ng-container *ngIf="!isLoading">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon fontSet="fa" fontIcon="fa-list"> </mat-icon>
    </div>
    <mat-card-title
      translate="UI.GROUP.EVENTS.EVENT_LIST_TITLE"
    ></mat-card-title>
    <mat-card-subtitle>
      <span translate="UI.GROUP.EVENTS.EVENT_LIST_SUBTITLE"> </span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div *ngFor="let i of events" class="event-line">
      <events-list-item [eventId]="i" [type]="type"></events-list-item>
    </div>

    <div *ngIf="events.length === 0">Ei tapahtumia, tee uusi jönssi!</div>
  </mat-card-content>
</ng-container>

<shared-message type="OK" key="UI.GROUP.EVENTS.EVENT_ADDED_OK">
</shared-message>

<span translate="UI.GROUP.EVENTS.AFTER_ADDED.CONTINUE_TO"></span>

<ul>
  <li>
    <a
      [routerLink]="getLink('ADD')"
      translate="UI.GROUP.EVENTS.CREATE_EVENT_TITLE"
    ></a>
  </li>
  <li>
    <a
      [routerLink]="getLink('CALENDAR')"
      translate="UI.GROUP.EVENTS.AFTER_ADDED.CALENDAR"
    ></a>
  </li>

  <li>
    <a
      [routerLink]="getLink('PAGE')"
      translate="UI.GROUP.EVENTS.AFTER_ADDED.EVENTS_PAGE"
    ></a>
  </li>
</ul>

<shared-loading *ngIf="isLoading"></shared-loading>

<ng-container *ngIf="!isLoading">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon fontSet="fa" fontIcon="fa-calendar-alt"> </mat-icon>
    </div>
    <mat-card-title>{{ event.name }}</mat-card-title>
    <mat-card-subtitle>
      <events-datetime-view
        [start]="event.startTime"
        [end]="event.endTime"
        [timeZone]="event.startTimeZoneId"
      ></events-datetime-view>
      <events-event-type [eventTypeId]="event.eventTypeId"></events-event-type>
    </mat-card-subtitle>
    <events-cog-menu
      class="action-icon"
      [groupId]="groupId"
      [eventId]="eventId"
    >
    </events-cog-menu>
  </mat-card-header>

  <shared-message
    type="ERROR"
    key="UI.GROUP.EVENTS.MESSAGES.EVENT_CANT_BE_SIGNED"
    *ngIf="$any(event).maxSignUp == 0"
  >
  </shared-message>

  <shared-message
    type="ERROR"
    key="UI.GROUP.EVENTS.MESSAGES.EVENT_IS_FULL"
    *ngIf="
      $any(event).maxSignUp > 0 &&
      $any(event).signUpStatusCounts!.YES >= $any(event).maxSignUp
    "
  >
  </shared-message>

  <mat-card-content *ngIf="event.description">
    <div class="col-container">
      <div class="col-xs-12" [innerHTML]="event.description"></div>
    </div>
  </mat-card-content>

  <ng-container *ngIf="event.location?.name">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon fontSet="fa" fontIcon="fa-map-marked-alt"> </mat-icon>
      </div>
      <mat-card-title
        [ngClass]="{
          'no-subtitle': !event.locationSuffix
        }"
      >
        {{ event.location?.name }}</mat-card-title
      >
      <mat-card-subtitle *ngIf="event.locationSuffix">
        {{ event.locationSuffix }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content *ngIf="event.location?.address">
      <a
        [attr.href]="
          'https://google.com/maps/search/' + event.location?.address
        "
        [attr.target]="event.id"
        >{{ event.location?.address }}</a
      >
    </mat-card-content>
  </ng-container>

  <ng-container *ngIf="$any(event).maxSignUp != 0">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon fontSet="fa" fontIcon="fa-sign-in"> </mat-icon>
      </div>
      <mat-card-title
        class="no-subtitle"
        translate="UI.GROUP.EVENTS.SIGNUP.TITLE"
      ></mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <events-sign-up
        [event]="event"
        [currentStatus]="event.mySignUpStatus?.status"
        kind="block"
      ></events-sign-up>
    </mat-card-content>
  </ng-container>

  <ng-container
    *sharedGroupPermission="
      enrollExtraToEventPermission;
      groupId: event.groupId
    "
  >
    <ng-container *ngIf="$any(event).maxSignUp != 0">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon fontSet="fa" fontIcon="fa-sign-in"> </mat-icon>
        </div>
        <mat-card-title
          class="no-subtitle"
          translate="UI.GROUP.EVENTS.SIGNUP.ADDITIONAL_TITLE"
        ></mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <events-sign-up-additional
          [event]="event"
          kind="block"
        ></events-sign-up-additional>
      </mat-card-content>
    </ng-container>
  </ng-container>

  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon fontSet="fa" fontIcon="fa-users"> </mat-icon>
    </div>
    <mat-card-title
      class="no-subtitle"
      translate="UI.GROUP.EVENTS.SIGNUPS"
    ></mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <events-sign-ups [groupId]="groupId" [eventId]="eventId"> </events-sign-ups>
  </mat-card-content>
</ng-container>

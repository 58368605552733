import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";
import { FF_DEFAULT_CLASS_LIST, FormFactoryBase } from "./form-factory.base";

@Injectable({
  providedIn: "root",
})
export class FindUserFormFactory extends FormFactoryBase {
  constructor(private fb: FormBuilder, private utils: SharedFormEditorUtil) {
    super();
  }

  createForm(): FormGroup {
    return this.fb.group({
      username: ["", Validators.required],
    });
  }

  formGroupToConfig(fg: FormGroup, search: (username: string) => Promise<any>) {
    const config: SharedEnrichableFormEditorConfig = {
      form: fg,
      fields: [
        {
          classList: FF_DEFAULT_CLASS_LIST,
          label: "Username",
          formControlName: "username",
        },
        {
          label: "Search",
          type: "button.loading",
          callback: () => search(fg.value["username"]),
        },
      ],
    };
    return this.utils.enrichConfigToDefaults(config);
  }
}

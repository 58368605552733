import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import {
  FullGroupDTO,
  GroupingDTO,
  GroupMemberDTO,
  GroupingMapDTO,
  RoleTypeEnum,
} from "@auto/dto.models";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService } from "@core/services";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { GroupMemberFormFactory } from "@ff/group-member.ff";
import { GroupService } from "@group/services";
import { GroupMembersService } from "@group/services/group-members.service";
import { SharedFormDialogComponent } from "@shared/components/shared-form-dialog/shared-form-dialog.component";
import {
  CurrentGroupService,
  DirtyStateTrackerService,
} from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { ConfirmActionService } from "@shared/services/confirm-action.service";
import { RegionalFormattingService } from "@shared/services/regional-formatting.service";
import { ToasterService } from "@shared/services/toaster.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { combineLatest, firstValueFrom, Subject, tap } from "rxjs";
import {
  GroupingDialogData,
  MembersAddFromChildrenDialogComponent,
  MembersGroupingEditorDialogComponent,
  MembersInviteDialogComponent,
} from "./components";

interface ExtendedGroupMemberDTO extends GroupMemberDTO {
  isCurrentGroupingMember?: boolean;
}

@Component({
  templateUrl: "./group-route-members.component.html",
  styleUrls: ["./group-route-members.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupRouteMembersComponent implements OnInit {
  private allMembers?: GroupMemberDTO[];
  private groupId!: string;
  members?: ExtendedGroupMemberDTO[];
  texts = this.getTexts();
  isAdmin = false;
  currentUser!: GroupMemberDTO;
  groupings: GroupingDTO[] = [];
  selectedGrouping?: GroupingDTO;
  isGroupingSelected = false;
  editGroupingMembers = false;
  hasGroupingMembers = true;
  doesHaveSlaves = false;
  isAdminInOtherGroups = false;

  constructor(
    private groupMembersService: GroupMembersService,
    private currentGroupService: CurrentGroupService,
    private cdr: ChangeDetectorRef,
    private ffMember: GroupMemberFormFactory,
    private confirmActionService: ConfirmActionService,
    private toasterService: ToasterService,
    private translationService: TranslationService,
    private regionalFormatter: RegionalFormattingService,
    private userService: UserService,
    private nav: NavigationService,
    private dialog: MatDialog,
    private groupService: GroupService,
    private busyService: BusyService,
    private dst: DirtyStateTrackerService
  ) {}

  ngOnInit(): void {
    this.doesHaveSlaves = (this.userService.user?.slaves ?? []).length > 0;
    LifeCyclesUtil.sub(
      [this, this.cdr],
      combineLatest([
        this.nav.params$,
        this.currentGroupService.currentGroup$,
        this.groupMembersService.getMembersOfCurrentGroup(),
        this.groupMembersService.getGroupingDataOfCurrentGroup(),
      ]),
      (data) => {
        if (!data[1] || !data[2]) {
          // Group loading is not ready, no reason to do anything
          return;
        }
        const routeParams = data[0];
        const group = data[1];
        const members = data[2];
        const groupingMap = data[3];
        this.groupId = group!.id;

        // Do all kind of initializations whenver data changes
        this.initGroup(group);
        this.initGrouping(routeParams["groupingId"], group);
        this.initMembers(members);
        this.members = this.getDisplayGroupMembers(groupingMap);
        if (
          this.isGroupingSelected &&
          !this.members.some((i) => i.isCurrentGroupingMember)
        ) {
          this.editGroupingMembers = true;
          this.hasGroupingMembers = false;
        } else if (!this.isGroupingSelected) {
          this.editGroupingMembers = false;
        } else {
          this.hasGroupingMembers = true;
        }
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  // Initializers

  private initGroup(group?: FullGroupDTO) {
    if (group) {
      this.groupings = group!.groupings!.sort((a, b) => {
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
      });
    }
  }

  private initMembers(members: GroupMemberDTO[]) {
    if (members) {
      this.allMembers = members.sort((a, b) =>
        a.displayName < b.displayName ? -1 : 1
      );
      const currentUser = members.find(
        (m) => m.id === this.userService.user!.id
      );
      if (currentUser) {
        // This stinks, should use permissions
        this.isAdmin = currentUser.roleType === RoleTypeEnum.ADMIN;
        this.currentUser = currentUser;
      }
    }
  }

  private initGrouping(groupingId: string | undefined, group?: FullGroupDTO) {
    if (groupingId && group) {
      this.selectedGrouping = group.groupings?.find((i) => i.id === groupingId);
      this.isGroupingSelected = !!this.selectedGrouping;
    } else {
      this.selectedGrouping = undefined;
      this.isGroupingSelected = false;
      this.editGroupingMembers = false;
    }
  }
  getJoinedDate(joined: number) {
    return joined == 0
      ? this.texts.notJoined
      : this.regionalFormatter.defaultDate(joined);
  }

  // Basic member management

  async editMember(member: GroupMemberDTO) {
    const close = new Subject<void>();
    const form = this.ffMember.createForm(member);
    this.dialog.open(SharedFormDialogComponent, {
      width: "400px",
      data: {
        title: member.displayName,
        configGetter: (cancel: () => void) => {
          return this.ffMember.formGroupToConfig(form, cancel, async () => {
            const response = await this.saveMember(member.id, form);
            if (response) {
              cancel();
            }
            return response;
          });
        },
        close,
      },
    });
  }

  private async saveMember(memberId: string, form: FormGroup) {
    const busyId = this.busyService.show();
    await firstValueFrom(
      this.groupMembersService.saveGroupMember(memberId, form.value)
    );
    this.dst.removeDirty(this.ffMember.DIRTY_KEY);
    this.busyService.hide(busyId);
    return true;
  }

  async addMember() {
    this.dialog.open(MembersInviteDialogComponent, {
      disableClose: true,
      width: "600px",
      data: {
        groupId: this.groupId,
      },
    });
    // await this.modalService.showModalPromise(MembersAddModalComponent, {});
  }

  async deleteMember(memberId: string) {
    const okToDelete = await this.confirmActionService.confirm();

    if (okToDelete) {
      this.groupMembersService
        .deleteGroupMember(memberId)
        .subscribe((result) => {
          if (result.status === "OK") {
            this.toasterService.showSuccess(this.texts["deleted"]);
          } else {
            this.toasterService.showError(this.texts["error"]);
          }
        });
    }
  }

  filterMembers(members: ExtendedGroupMemberDTO[]) {
    return members.filter((i) => {
      return !this.selectedGrouping || i.isCurrentGroupingMember;
    });
  }

  addMemberFromMySlaves() {
    this.dialog.open(MembersAddFromChildrenDialogComponent, {
      disableClose: true,
      data: {
        groupId: this.groupId,
      },
    });
  }

  // Grouping management

  private getDisplayGroupMembers(groupingMap: GroupingMapDTO[]) {
    if (!this.allMembers) {
      return [];
    }
    if (!this.selectedGrouping) {
      return this.allMembers;
    }
    const groupingMembers = new Set(
      groupingMap.find((i) => i.id === this.selectedGrouping!.id)?.memberIds
    );
    return [
      ...this.allMembers.map((i: ExtendedGroupMemberDTO) => {
        i.isCurrentGroupingMember = groupingMembers.has(i.id);
        return i;
      }),
    ];
  }

  addGrouping() {
    const dialogRef = this.dialog.open(MembersGroupingEditorDialogComponent, {
      width: "400px",
      disableClose: true,
      data: {
        groupId: this.currentGroupService.currentGroupId,
      },
    });
    const response = firstValueFrom(dialogRef.afterClosed());
  }

  editGrouping() {
    const data: GroupingDialogData = {
      groupId: this.currentGroupService.currentGroupId,
      grouping: this.selectedGrouping,
    };
    const dialogRef = this.dialog.open(MembersGroupingEditorDialogComponent, {
      width: "400px",
      disableClose: true,
      data,
    });
    const response = firstValueFrom(dialogRef.afterClosed());
  }

  selectGrouping(grouping: GroupingDTO) {
    this.nav.goto(ROUTE_MAP.PRI.GROUP.MEMBERS.GROUPINGS, {
      groupingId: grouping.id,
    });
  }

  deselectGrouping() {
    this.nav.goto(ROUTE_MAP.PRI.GROUP.MEMBERS);
  }

  removeGrouping() {
    return () =>
      this.groupService
        .removeGrouping(
          this.currentGroupService.currentGroupId,
          this.selectedGrouping!.id
        )
        .pipe(
          tap((i) => {
            this.deselectGrouping();
          })
        );
  }

  toggleEditGroupingMembers() {
    this.editGroupingMembers = !this.editGroupingMembers;
  }

  toggleIsGroupingMember(member: ExtendedGroupMemberDTO) {
    return () => {
      if (!member.isCurrentGroupingMember) {
        return this.groupMembersService.addMemberToGrouping(
          this.selectedGrouping!.id,
          member.id
        );
      }
      return this.groupMembersService.removeMemberFromGrouping(
        this.selectedGrouping!.id,
        member.id
      );
    };
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      deleted: dict.COMMON_UI.OK.DELETED,
      error: dict.COMMON_UI.ERROR.ACTION_FAILED,
      notJoined: dict.UI.GROUP.MEMBERS.NOT_JOINED,
    };
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NewUserDTO } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { GroupMembersService } from "@group/services";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";
import { ToasterService } from "@shared/services";

@Component({
  selector: "user-login-or-register",
  templateUrl: "./user-login-or-register.component.html",
  styleUrls: ["./user-login-or-register.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLoginOrRegisterComponent implements OnInit, AfterViewInit {
  private loginForm = this.fb.group({
    username: ["", Validators.required],
    password: ["", Validators.required],
  });
  loginConfig!: SharedFormEditorConfig;

  registerConfig!: SharedFormEditorConfig;
  private registerForm = this.fb.group({
    userName: ["", Validators.required],
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    password: ["", Validators.required],
    birthYear: [
      "",
      [
        Validators.required,
        Validators.min(1900),
        Validators.max(new Date().getFullYear()),
      ],
    ],
    email: ["", Validators.compose([Validators.required, Validators.email])],
  });

  @Input() showLogin = false;
  @Input() showRegister = false;
  @Input() showTitle = false;
  @Input() registerText?: string;
  @Input() registerCallback?: (user: NewUserDTO) => Promise<true>;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private translationService: TranslationService,
    private toasterService: ToasterService,
    private groupMemberService: GroupMembersService,
    private sharedFormEditorUtil: SharedFormEditorUtil
  ) {}

  ngOnInit(): void {
    this.loginConfig = this.getLoginConfig();
    this.registerConfig = this.getRegisterConfig();
  }

  ngAfterViewInit(): void {
    const element = <HTMLElement>(
      document.querySelector(".mat-input-element[id=mat-input-0]")
    );
    if (element) {
      element.focus();
    }
  }

  private getLoginConfig() {
    const config: SharedEnrichableFormEditorConfig = {
      form: this.loginForm,
      formFieldClassList: ["full-width"],
      fields: [
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.USERNAME,
          formControlName: "username",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.PASSWORD,
          formControlName: "password",
          type: "password",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.LOGIN,
          type: "button.loading",
          id: "login-button",
          callback: this.getLoginCallback(),
        },
      ],
    };
    return this.sharedFormEditorUtil.enrichConfigToDefaults(config);
  }

  private getLoginCallback() {
    return async () => {
      if (
        await this.userService.login(
          this.loginForm.controls["username"].value,
          this.loginForm.controls["password"].value
        )
      ) {
        return true;
      } else {
        this.toasterService.showError(TRANSLATIONS.COMMON_UI.ERROR.LOGIN);
      }
      return false;
    };
  }

  private getRegisterConfig() {
    const registerLabelKey = this.registerText
      ? undefined
      : TRANSLATIONS.COMMON_UI.KEY_WORD.REGISTER;
    const config: SharedEnrichableFormEditorConfig = {
      form: this.registerForm,
      formFieldClassList: ["full-width"],
      fields: [
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.USERNAME,
          formControlName: "userName",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.GIVEN_NAME,
          formControlName: "firstName",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.FAMILY_NAME,
          formControlName: "lastName",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.PASSWORD,
          formControlName: "password",
          type: "password",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.BIRTH_YEAR,
          formControlName: "birthYear",
          type: "number",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.EMAIL,
          formControlName: "email",
        },
        {
          labelKey: registerLabelKey,
          label: this.registerText,
          type: "button.loading",
          id: "register-button",
          callback: this.getRegisterCallback(),
        },
      ],
    };
    return this.sharedFormEditorUtil.enrichConfigToDefaults(config);
  }

  private getRegisterCallback() {
    return async () => {
      const dto: NewUserDTO = {
        username: this.registerForm.get("userName")!.value,
        givenName: this.registerForm.get("firstName")!.value,
        familyName: this.registerForm.get("lastName")!.value,
        password: this.registerForm.get("password")!.value,
        birthYear: this.registerForm.get("birthYear")!.value,
        emails: [this.registerForm.get("email")!.value],
      };
      if (this.registerCallback) {
        return this.registerCallback(dto);
      }
      return await this.userService.register(dto);
    };
  }
}

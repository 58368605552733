import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from "@angular/router";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { BehaviorSubject, Subject } from "rxjs";
import { LifeCyclesUtil } from "../utils/lifecycles.util";
import { ConfirmActionService } from "./confirm-action.service";

@Injectable({
  providedIn: "root",
})
export class DirtyStateTrackerService implements CanDeactivate<any> {
  clearDirtyState$ = new Subject<void>();
  isSomethingDirty$ = new BehaviorSubject<boolean>(false);
  private dirtyStates = new Map<string, true>();

  constructor(
    private confirmActionService: ConfirmActionService,
    private tx: TranslationService
  ) {}

  setDirty(key: string) {
    if (!this.dirtyStates.has(key)) {
      this.dirtyStates.set(key, true);
      this.isSomethingDirty$.next(true);
    }
  }

  removeDirty(key: string) {
    if (this.dirtyStates.has(key)) {
      this.dirtyStates.delete(key);
    }
    this.isSomethingDirty$.next(this.dirtyStates.size > 0);
  }

  removeAllDirtyStates() {
    this.dirtyStates = new Map<string, true>();
    this.isSomethingDirty$.next(false);
  }

  isDirty(key: string) {
    return this.dirtyStates.has(key);
  }

  isAnythingDirty() {
    return this.dirtyStates.size > 0;
  }

  // Remember to stop on ngOnDestroy in component
  trackFormGroup(key: object, dirtyKey: string, form: AbstractControl) {
    LifeCyclesUtil.sub(key, form.statusChanges, (changes: any) => {
      if (form.dirty) {
        this.setDirty(dirtyKey);
      } else {
        this.removeDirty(dirtyKey);
      }
    });
  }

  trackFormGroups(
    key: object,
    formGroups: { [dirtyKey: string]: AbstractControl }
  ) {
    for (let dirtyKey in formGroups) {
      this.trackFormGroup(key, dirtyKey, formGroups[dirtyKey]);
    }
  }

  async deactivateIfAllowed(callback: () => void, dirtyKey?: string) {
    const can = await this.confirmNotDirty(dirtyKey);
    if (can) {
      callback();
    }
  }

  confirmNotDirty(dirtyKey?: string) {
    if (dirtyKey) {
      if (!this.isDirty(dirtyKey)) {
        return Promise.resolve(true);
      }
    } else if (!this.isAnythingDirty()) {
      return Promise.resolve(true);
    }
    return new Promise<boolean>((resolve, reject) => {
      this.confirmActionService
        .confirm({
          texts: {
            cancel: this.tx.fromString(
              TRANSLATIONS.COMMON_UI.UNSAVED_CHANGES.KEEP_EDITING
            ),
            confirm: this.tx.fromString(
              TRANSLATIONS.COMMON_UI.UNSAVED_CHANGES.STOP_EDITING
            ),
            title: this.tx.fromString(
              TRANSLATIONS.COMMON_UI.UNSAVED_CHANGES.TITLE
            ),
            guide: this.tx.fromString(
              TRANSLATIONS.COMMON_UI.UNSAVED_CHANGES.TEXT
            ),
          },
        })
        .then((condition) => {
          if (condition) {
            this.removeAllDirtyStates();
          }
          resolve(condition);
        });
    });
  }

  canDeactivate(
    component?: any,
    currentRoute?: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> {
    return this.confirmNotDirty();
  }
}

import { Component, OnInit } from "@angular/core";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";

@Component({
  templateUrl: "./landing-route.component.html",
  styleUrls: ["./landing-route.component.scss"],
})
export class LandingRouteComponent implements OnInit {
  constructor(
    private userService: UserService,
    private nav: NavigationService
  ) {}

  ngOnInit(): void {
    LifeCyclesUtil.sub(this, this.userService.isLoggedIn$, (is) => {
      if (is) {
        this.nav.goto(ROUTE_MAP.PRI.EVENTS);
      }
    });
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  BasicUserDTO,
  ErrorEnum,
  GetUserDTO,
  GroupInvitationDTO,
} from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { NavigationService } from "@core/services/navigation.service";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { GroupMembersService } from "@group/services/group-members.service";
import { ToasterService } from "@shared/services/toaster.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { filter, tap, combineLatest, firstValueFrom } from "rxjs";

@Component({
  templateUrl: "./group-route-accept-invitation.component.html",
  styleUrls: ["./group-route-accept-invitation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupRouteAcceptInvitationComponent implements OnInit {
  invitationText!: string;
  loggedIn: boolean | undefined = undefined;

  invitation!: GroupInvitationDTO;
  isGroupMember = false;

  joinSlave = false;

  isUsingAsSlave = false;

  hasSlaves = false;

  displayName: string = "";

  private groupId = "";
  private invitationId = "";
  private user!: GetUserDTO;
  private isInitial = true;

  slaves: BasicUserDTO[] = [];

  constructor(
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private groupMemberService: GroupMembersService,
    private toasterService: ToasterService,
    private userService: UserService,
    private navService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.groupId = this.activatedRoute.snapshot.queryParams["groupId"];
    this.invitationId =
      this.activatedRoute.snapshot.queryParams["invitationId"];

    if (!this.groupId || !this.invitationId) {
      this.navService.goto(ROUTE_MAP.PUB.HOME);
    }
    try {
      this.invitation = await firstValueFrom(
        this.groupMemberService.getGroupMemberInvitation(
          this.groupId,
          this.invitationId!
        )
      );
      if (this.invitation.accepted) {
        this.invitationUseError();
        return;
      }
    } catch (e) {
      this.invitationUseError();
      return;
    }

    LifeCyclesUtil.sub([this, this.cdr], this.userService.user$, (user) => {
      this.slaves = user?.slaves ?? [];
    });

    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.toasterService.httpErrorStream$.pipe(
        filter((i) => i === ErrorEnum.ALREADY_MEMBER)
      ),
      (_) => {
        if (this.isUsingAsSlave) {
          this.userService.returnToMaster();
        }
      }
    );

    LifeCyclesUtil.sub(
      [this, this.cdr],
      combineLatest([
        this.userService.isLoggingIn$,
        this.userService.user$,
      ]).pipe(filter((i) => !i[0])),
      (user) => {
        this.userLoggedIn(user[1]);
      }
    );
    this.cdr.detectChanges();
  }

  private invitationUseError() {
    this.navService.goto(ROUTE_MAP.PUB.HOME);
  }

  ngOnDestroy(): void {
    LifeCyclesUtil.stop(this);
  }

  private userLoggedIn(user: GetUserDTO | undefined) {
    if (user) {
      this.user = user;
      this.displayName = `${user.givenName} ${user.familyName}`;
      this.isUsingAsSlave = !!user.master;
      this.loggedIn = true;
      this.isGroupMember = this.userService.isGroupMember(this.groupId);
      this.invitationText = this.translationService.fromString(
        this.isGroupMember
          ? TRANSLATIONS.UI.GROUP.INVITATION
              .CONFIRM_INVITATION_TEXT_ALREADY_MEMBER
          : TRANSLATIONS.UI.GROUP.INVITATION.CONFIRM_INVITATION_TEXT,
        {
          groupName: this.invitation.groupName,
        }
      );

      if (this.isGroupMember) {
        this.showJoinSlave();
      }
    } else {
      this.loggedIn = false;
    }
  }

  async showJoinSlave() {
    if (this.isUsingAsSlave && this.isInitial) {
      await this.userService.returnToMaster();
      return;
    }
    this.isInitial = false;
    this.joinSlave = true;
  }

  getConfirmCallback(slave?: BasicUserDTO) {
    return async () => {
      if (slave) {
        await this.userService.selectSlave(slave);
        if (this.userService.user?.id !== slave.id) {
          // Some error occurred
          return;
        }
      }
      await firstValueFrom(
        this.groupMemberService.acceptGroupMemberInvitation(
          this.groupId!,
          this.invitationId!
        )
      );
      await this.userService.refresh();
      this.navService.goto(ROUTE_MAP.PRI.EVENTS);
      return true;
    };
  }

  createSlaveAccount() {
    this.userService.createSlaveAccount();
  }

  getJoinUserConfirmGuide(user: BasicUserDTO) {
    return this.translationService.fromString(
      TRANSLATIONS.UI.GROUP.INVITATION.CONFIRM_ACCEPT_INVITATION_AS_SLAVE,
      {
        givenName: user.givenName,
        familyName: user.familyName,
      }
    );
  }

  getAcceptInvitationAsMeGuide() {
    return this.translationService.fromString(
      TRANSLATIONS.UI.GROUP.INVITATION.CONFIRM_ACCEPT_INVITATION,
      {
        givenName: this.user.givenName,
        familyName: this.user.familyName,
      }
    );
  }
}

<div class="col-m-4 month-text">
  <h2>{{ viewDate | calendarDate : view + "ViewTitle" : locale }}</h2>
</div>
<div class="container">
  <div class="button-container">
    <div
      class="col-xs-1 button"
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()"
      (click)="monthChanged()"
    >
      <button mat-stroked-button color="primary">
        <i class="fas fa-angle-left"></i>
      </button>
    </div>
    <div
      class="col-xs-1 button"
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()"
      (click)="monthChanged()"
    >
      <button mat-stroked-button color="primary">
        <i class="fas fa-angle-right"></i>
      </button>
    </div>
  </div>
  <div *ngIf="view === CalendarView.Day">
    <button class="add-new-event-button-large">
      <i class="fa-solid fa-calendar-plus" (click)="addNewEvent(viewDate)"></i>
    </button>
  </div>
  <div class="button-container">
    <div class="col-xs-1 button" [class.active]="view === CalendarView.Month">
      <button
        mat-stroked-button
        color="primary"
        (click)="setView(CalendarView.Month)"
        [ngClass]="view === CalendarView.Month ? 'selected-button' : ''"
      >
        {{ "COMMON_UI.KEY_WORD.MONTH" | translate }}
      </button>
    </div>
    <div class="col-xs-1 button" [class.active]="view === CalendarView.Week">
      <button
        mat-stroked-button
        color="primary"
        (click)="setView(CalendarView.Week)"
        [ngClass]="view === CalendarView.Week ? 'selected-button' : ''"
      >
        {{ "COMMON_UI.KEY_WORD.WEEK" | translate }}
      </button>
    </div>
    <div class="col-xs-1 button" [class.active]="view === CalendarView.Day">
      <button
        mat-stroked-button
        color="primary"
        (click)="setView(CalendarView.Day)"
        [ngClass]="view === CalendarView.Day ? 'selected-button' : ''"
      >
        {{ "COMMON_UI.KEY_WORD.DAY" | translate }}
      </button>
    </div>
  </div>
</div>
<br />
<br />
<div style="margin-left: -15px; margin-right: -15px" [ngSwitch]="view">
  <month-view
    *ngSwitchCase="CalendarView.Month"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    [activeDayIsOpen]="activeDayIsOpen"
    (dayClicked)="dayClicked($event.day)"
    (eventTimesChanged)="eventTimesChanged($event)"
    [locale]="locale"
    [cellTemplate]="monthViewCellTemplate"
    [weekStartsOn]="1"
    [type]="type"
    (eventDeleted)="eventDeleted($event)"
    (eventEdited)="eventEdited($event)"
    (beforeViewRender)="beforeMonthViewRender($event)"
  >
  </month-view>
  <mwl-calendar-week-view
    *ngSwitchCase="CalendarView.Week"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
    [locale]="locale"
    [weekStartsOn]="1"
    [headerTemplate]="weekViewHeaderTemplate"
    [eventActionsTemplate]="actionsTemplate"
  >
  </mwl-calendar-week-view>
  <mwl-calendar-day-view
    *ngSwitchCase="CalendarView.Day"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)"
    [locale]="locale"
    [eventActionsTemplate]="actionsTemplate"
  >
  </mwl-calendar-day-view>
</div>

<ng-template #monthViewCellTemplate let-day="day" let-locale="locale">
  <div class="calendar-cell-top">
    <span class="day">{{
      day.date | calendarDate : "monthViewDayNumber" : locale
    }}</span>
    <button *ngIf="day.inMonth && !day.isPast" class="add-new-event-button">
      <i
        class="fa-solid fa-calendar-plus add-icon"
        (click)="addNewEvent(day.date)"
      ></i>
    </button>
  </div>
  <div
    class="calendar-cell-events"
    *ngIf="view === CalendarView.Month && !isMobileView()"
  >
    <div class="event" *ngFor="let e of getEventsForDay(day.date)">
      <span>{{ e.name }}</span>
      <span
        *ngIf="e.mySignUpStatus && e.mySignUpStatus.status === 'YES'"
        class="signup-indicator signup-indicator-yes"
        [title]="texts.signedUp"
      ></span>
      <span
        *ngIf="e.mySignUpStatus && e.mySignUpStatus.status === 'NO'"
        class="signup-indicator signup-indicator-no"
        style="margin-top: 2px"
        [title]="texts.signedUp"
      ></span>
      <span
        *ngIf="e.mySignUpStatus && e.mySignUpStatus.status === 'DONTKNOW'"
        class="signup-indicator signup-indicator-dontknow"
        style="margin-top: 2px"
        [title]="texts.signedUp"
      ></span>
    </div>
  </div>
  <div
    class="calendar-cell-events"
    *ngIf="view === CalendarView.Month && isMobileView()"
  >
    <div class="event" *ngFor="let e of getEventsForDay(day.date)">
      <i class="fa fa-calendar-day" [title]="e.name"></i>
      <span
        *ngIf="e.mySignUpStatus && e.mySignUpStatus.status === 'YES'"
        class="signup-indicator signup-indicator-yes"
        style="margin-top: 2px"
        [title]="texts.signedUp"
      ></span>
      <span
        *ngIf="e.mySignUpStatus && e.mySignUpStatus.status === 'NO'"
        class="signup-indicator signup-indicator-no"
        style="margin-top: 2px"
        [title]="texts.signedUp"
      ></span>
      <span
        *ngIf="e.mySignUpStatus && e.mySignUpStatus.status === 'DONTKNOW'"
        class="signup-indicator signup-indicator-dontknow"
        style="margin-top: 2px"
        [title]="texts.signedUp"
      ></span>
    </div>
  </div>
</ng-template>

<ng-template
  #weekViewHeaderTemplate
  let-days="days"
  let-locale="locale"
  let-dayClicked="dayClicked"
  let-eventDropped="eventDropped"
>
  <div class="cal-day-headers cursor-default">
    <div
      class="cal-header"
      *ngFor="let day of days"
      [class.cal-past]="day.isPast"
      [class.cal-future]="day.isFuture"
      [class.cal-drag-over]="day.dragOver"
      [class.current-day]="day.isToday"
      mwlDroppable
      (dragEnter)="day.dragOver = true"
      (dragLeave)="day.dragOver = false"
      (drop)="
        day.dragOver = false;
        eventDropped.emit({
          event: $event.dropData.event,
          newStart: day.date,
          fromHeader: true
        })
      "
    >
      <div class="top" [class.today]="day.isToday">
        <div style="display: flex; justify-content: center; flex-wrap: wrap">
          <span style="color: black; font-weight: bold; opacity: 1"
            >{{
              day.date | calendarDate : "weekViewColumnHeader" : locale
            }}&nbsp;</span
          >
          <span style="color: black; font-weight: bold; opacity: 1">{{
            formatWeekDate(day.date, locale)
          }}</span>
        </div>
      </div>
      <button class="add-new-event-button" *ngIf="!day.isPast">
        <i
          class="fa-solid fa-calendar-plus"
          (click)="addNewEvent(day.date)"
        ></i>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #actionsTemplate let-event="event">
  <div class="actions-buttons">
    <button class="event-action-button" [matMenuTriggerFor]="eventContextMenu">
      <i class="fa fa-chevron-down"></i>
    </button>
    <span
      *ngIf="
        getEventDataDTO(event).mySignUpStatus &&
        getEventDataDTO(event).mySignUpStatus!.status === 'YES'
      "
      class="signup-indicator"
      [title]="texts.signedUp"
    >
    </span>
    <mat-menu #eventContextMenu="matMenu">
      <ng-container>
        <events-sign-up
          (click)="$event.stopPropagation()"
          [event]="getEventDataDTO(event)"
          [currentStatus]="getEventDataDTO(event).mySignUpStatus?.status"
          customButtonContent="{{ 'COMMON_UI.KEY_WORD.SIGN_UP' | translate }}"
        ></events-sign-up>
        <button
          *ngIf="hasManagePermission(event.meta)"
          mat-menu-item
          translate="COMMON_UI.KEY_WORD.MODIFY"
          (click)="editEvent(getEventDataDTO(event))"
        ></button>
        <button
          *ngIf="hasManagePermission(event.meta)"
          mat-menu-item
          translate="COMMON_UI.KEY_WORD.DELETE"
          (click)="deleteEvent(getEventDataDTO(event))"
        ></button>
      </ng-container>
    </mat-menu>
  </div>
</ng-template>

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface EventSignUpsDialogData {
  groupId: string;
  eventId: string;
}

@Component({
  templateUrl: "./events-sign-ups-dialog.component.html",
  styleUrls: ["./events-sign-ups-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSignUpsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EventSignUpsDialogData,
    private dialogRef: MatDialogRef<EventsSignUpsDialogComponent>
  ) {}

  ngOnInit() {}
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  BasicEventDTO,
  EventDataDTO,
  GroupPermissions,
} from "@auto/dto.models";
import { EventApiService } from "@auto/event-api.service";
import { Translations } from "@auto/translations.models";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService, TranslationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import {
  EventInStore,
  EventsService,
  ExtendedEventData,
} from "@events/services";
import { SMOOTH_HEIGHT } from "@shared/animations";
import { ConfirmActionService } from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { firstValueFrom } from "rxjs";
import {
  EventsAddNewEventDialogComponent,
  CalendarType,
  EventsSignUpsDialogComponent,
} from "..";
import {
  EventsDeleteDialogComponent,
  DeleteEventType,
} from "../events-delete-dialog/events-delete-dialog.component";

@Component({
  animations: [SMOOTH_HEIGHT],
  selector: "events-list-item",
  templateUrl: "./events-list-item.component.html",
  styleUrls: ["./events-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsListItemComponent implements OnInit {
  @Input() groupId!: string;
  @Input() eventId!: string;
  event!: ExtendedEventData;
  @Input() type!: CalendarType;
  @Input() thin = false;

  @Output() eventDeleted: EventEmitter<EventDataDTO> = new EventEmitter();
  @Output() eventEdited: EventEmitter<BasicEventDTO> = new EventEmitter();

  texts = this.getTexts();
  menuOpen = false;
  hasPermission = false;

  constructor(
    private service: EventsService,
    private cdr: ChangeDetectorRef,
    private busyService: BusyService,
    private nav: NavigationService,
    private userService: UserService,
    private dialog: MatDialog,
    private translationService: TranslationService,
    private eventApiService: EventApiService,
    private confirmActionService: ConfirmActionService
  ) {}

  ngOnInit() {
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.service.getEvent(this.eventId),
      (data: EventInStore) => {
        this.event = data.event;
        this.event.link = this.nav.getLink(ROUTE_MAP.PRI.GROUP.EVENTS.VIEW, {
          groupId: this.event.groupId,
          eventId: this.event.id,
        });
        this.event.groupName = this.userService.getGroupsShortName(
          this.event.groupId
        );
      }
    );

    this.hasPermission = this.userService.hasGroupPermission(
      this.event.groupId,
      GroupPermissions.GRP_MANAGE_EVENT
    );
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  toggleEventDetails(event: ExtendedEventData) {
    event.isExtended = !event.isExtended;
    this.cdr.detectChanges();
  }

  async showSignUps(event: ExtendedEventData) {
    this.busyService.show();
    await firstValueFrom(
      this.service.refreshEventsSignUps(this.event.groupId, this.eventId)
    );
    this.busyService.hide();
    this.dialog.open(EventsSignUpsDialogComponent, {
      width: "100%",
      data: { groupId: event.groupId, eventId: event.id },
    });
  }

  async deleteEvent() {
    if (this.event.seriesId) {
      const dialogRef = this.dialog.open(EventsDeleteDialogComponent, {
        data: { date: new Date(this.event.startTime) },
      });

      var result: number = await firstValueFrom(dialogRef.afterClosed());
      if (result === -1) {
        return;
      }

      if (result === DeleteEventType.ThisEventOnly) {
        await firstValueFrom(
          this.eventApiService.deleteEvent(this.event.groupId, this.event.id)
        );

        this.eventDeleted.emit(this.event);
        return;
      }

      if (result === DeleteEventType.AllEvents) {
        await firstValueFrom(
          this.eventApiService.deleteEvents(
            this.event.groupId,
            this.event.id,
            this.event.seriesId,
            Date.now().toString()
          )
        );
        this.eventDeleted.emit(undefined);
      } else if (result === DeleteEventType.AllEventsFromDay) {
        await firstValueFrom(
          this.eventApiService.deleteEvents(
            this.event.groupId,
            this.event.id,
            this.event.seriesId,
            new Date(this.event.startTime).getTime().toString()
          )
        );
        this.eventDeleted.emit(undefined);
      }
    } else {
      if (
        await this.confirmActionService.confirm({
          texts: {
            cancel: this.texts.no,
            confirm: this.texts.yes,
            title: this.texts.deleteConfirmation,
          },
        })
      ) {
        await firstValueFrom(
          this.eventApiService.deleteEvent(this.event.groupId, this.event.id)
        );
        this.eventDeleted.emit(this.event);
      }
    }
  }

  editEvent() {
    const dialogRef = this.dialog.open(EventsAddNewEventDialogComponent, {
      data: { groupId: this.event.groupId, eventId: this.event.id },
    });

    dialogRef.afterClosed().subscribe((response: BasicEventDTO[]) => {
      this.eventEdited.emit(response[0]);
    });
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      deleteConfirmation: dict.UI.GROUP.EVENTS.DELETE_EVENT_CONFIRMATION,
      yes: dict.COMMON_UI.KEY_WORD.YES,
      no: dict.COMMON_UI.KEY_WORD.NO,
    };
  }

  menuOpened() {
    this.menuOpen = true;
    this.cdr.detectChanges();
  }

  menuClosed() {
    this.menuOpen = false;
    this.cdr.detectChanges();
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { StatusEnum } from "@auto/dto.models";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService, TranslationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { SharedSubscriptionBaseComponent } from "@shared/base-components/shared-subscription-base/shared-subscription-base.component";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { firstValueFrom } from "rxjs";

@Component({
  templateUrl: "./user-route-confirm-email.component.html",
  styleUrls: ["./user-route-confirm-email.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRouteConfirmEmailComponent implements OnInit {
  texts = this.getTexts();
  confirmText = "";

  constructor(
    private translationService: TranslationService,
    private userService: UserService,
    private navService: NavigationService,
    private cdr: ChangeDetectorRef,
    private snapshot: ActivatedRoute
  ) {}

  async ngOnInit() {
    const value = await firstValueFrom(this.snapshot.params);
    const status = await firstValueFrom(
      this.userService.confirmUserEmail(value["userId"], value["emailId"])
    );
    const result = status.status === StatusEnum.OK;
    if (result) {
      this.confirmText = this.texts.confirmed;
    } else {
      this.confirmText = this.texts.alreadyConfirmed;
    }
    this.cdr.detectChanges();
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      confirmed: dict.UI.USER.EMAIL_CONFIRMATION.CONFIRMED,
      alreadyConfirmed: dict.UI.USER.EMAIL_CONFIRMATION.ALREADY_CONFIRMED,
    };
  }
}

<!-- CREATE -->
<mat-card-header>
  <div mat-card-avatar>
    <mat-icon fontSet="fa" fontIcon="fa-users"> </mat-icon>
  </div>
  <mat-card-title translate="UI.GROUP.NEW_GROUP.CREATE.TITLE"></mat-card-title>
  <mat-card-subtitle translate="UI.GROUP.NEW_GROUP.CREATE.SUBTITLE">
  </mat-card-subtitle>
</mat-card-header>

<mat-card-content>
  <form [formGroup]="form">
    <div class="col-container">
      <div class="col-xs-12 col-s-6 col-m-4 col-l-3 col-xl-2">
        <mat-form-field class="full-width">
          <mat-label translate="COMMON_UI.KEY_WORD.NAME"></mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="name"
            required
            data-t-id="create-group-input"
          />
        </mat-form-field>
      </div>
    </div>

    <shared-loading-button
      name="create-group-button"
      [disabled]="form.status !== 'VALID'"
      [cb]="createCallback()"
      key="COMMON_UI.KEY_WORD.ADD"
    >
    </shared-loading-button>
  </form>
</mat-card-content>

<div class="group-spacer"></div>

<!-- JOIN -->
<mat-card-header>
  <div mat-card-avatar>
    <mat-icon fontSet="fa" fontIcon="fa-users"> </mat-icon>
  </div>
  <mat-card-title translate="UI.GROUP.NEW_GROUP.JOIN.TITLE"></mat-card-title>
  <mat-card-subtitle translate="UI.GROUP.NEW_GROUP.JOIN.SUBTITLE">
  </mat-card-subtitle>
</mat-card-header>

<mat-card-content>
  <form [formGroup]="searchForm">
    <div class="col-container">
      <div class="col-xs-12 col-s-6 col-m-4 col-l-3 col-xl-2">
        <mat-form-field class="full-width">
          <mat-label
            translate="UI.GROUP.NEW_GROUP.JOIN.GROUP_NAME_BEGIN"
          ></mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="name"
            required
            data-t-id="join-group-input"
          />
        </mat-form-field>
      </div>
    </div>

    <shared-loading-button
      name="create-group-button"
      [disabled]="searchForm.status !== 'VALID'"
      [cb]="searchCallback()"
      key="COMMON_UI.KEY_WORD.SEARCH"
    >
    </shared-loading-button>
  </form>

  <ng-container *ngIf="groups.length > 0">
    <div class="group-spacer"></div>
    <h2 translate="UI.GROUP.NEW_GROUP.JOIN.SELECT_GROUP"></h2>
    <ul>
      <li *ngFor="let i of groups">
        <a [routerLink]="" (click)="joinGroup(i)">{{ i.name }}</a>
      </li>
    </ul>
  </ng-container>
</mat-card-content>

import { Component, OnInit } from "@angular/core";
import {
  GroupPermissions,
  GroupTypeEnum,
  UserGroupDTO,
} from "@auto/dto.models";
import { UserService } from "@core/services/user.service";

@Component({
  templateUrl: "./shared-group-selector-dialog.component.html",
  styleUrls: ["./shared-group-selector-dialog.component.scss"],
})
export class SharedGroupSelectorDialogComponent implements OnInit {
  groups: UserGroupDTO[] = [];
  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.groups =
      this.userService.user?.groups.filter((i) =>
        i.permissions.includes(GroupPermissions.GRP_MANAGE_EVENT)
      ) ?? [];
    this.groups.sort((a, b) =>
      a.groupType == GroupTypeEnum.PERSONAL
        ? -1
        : b.groupType == GroupTypeEnum.PERSONAL
        ? 1
        : a.groupName.toLocaleLowerCase() < b.groupName.toLocaleLowerCase()
        ? -1
        : 1
    );
  }
}

<div>
  <user-settings-menu></user-settings-menu>
</div>

<mat-card-header>
  <div mat-card-avatar>
    <mat-icon fontSet="fa" fontIcon="fa-users"> </mat-icon>
  </div>
  <mat-card-title
    class="no-subtitle"
    translate="UI.USER.LINKED_ACCOUNTS.TITLE"
  ></mat-card-title>
</mat-card-header>

<mat-card-content>
  <div *ngIf="!isActivated" class="return-to-master">
    <shared-message type="INFO" key="UI.USER.LINKED_ACCOUNTS.ACTIVATE.HELP">
    </shared-message>
    <button
      mat-flat-button
      color="primary"
      (click)="activateAccount()"
      translate="UI.USER.LINKED_ACCOUNTS.ACTIVATE.ACTIVATE"
    ></button>
  </div>

  <div *ngIf="hasMaster && master" class="return-to-master">
    <shared-message
      type="INFO"
      key="UI.USER.LINKED_ACCOUNTS.CURRENT_MASTER.USING"
      [params]="{
        givenName: master.givenName!,
        familyName: master.familyName!
      }"
    >
    </shared-message>
    <button
      mat-flat-button
      color="primary"
      (click)="returnToMaster()"
      translate="UI.USER.LINKED_ACCOUNTS.CURRENT_MASTER.RETURN"
    ></button>
  </div>

  <ng-container *ngIf="masters">
    <h2 translate="UI.USER.LINKED_ACCOUNTS.MASTERS.TITLE"></h2>
    <shared-message
      *ngIf="masters.length === 0"
      type="INFO"
      key="UI.USER.LINKED_ACCOUNTS.MASTERS.NO_MASTERS"
    >
    </shared-message>
    <div class="col-container" *ngFor="let u of masters">
      <div class="col-xs-2">{{ u.givenName }} {{ u.familyName }}</div>
      <div class="col-xs-1">
        <button
          *ngIf="
            (user?.username && (!master || master.id !== u.id)) ||
            (!user!.username && master && master.id !== u.id)
          "
          mat-flat-button
          color="primary"
          [sharedConfirmClick]="removeMasterAccount(u)"
          class="tiny"
          translate="COMMON_UI.KEY_WORD.DELETE"
        ></button>
      </div>
    </div>

    <button
      mat-flat-button
      color="primary"
      (click)="addMasterAccount()"
      translate="COMMON_UI.KEY_WORD.ADD_NEW"
    ></button>
  </ng-container>

  <ng-container *ngIf="!hasMaster && slaves">
    <h2 translate="UI.USER.LINKED_ACCOUNTS.EXISTING_SLAVES.TITLE"></h2>
    <shared-message
      *ngIf="slaves.length === 0"
      type="INFO"
      key="UI.USER.LINKED_ACCOUNTS.EXISTING_SLAVES.NO_SLAVES"
    >
    </shared-message>
    <div class="col-container" *ngFor="let u of slaves">
      <div class="col-xs-2">{{ u.givenName }} {{ u.familyName }}</div>
      <div class="col-xs-1">
        <button
          mat-flat-button
          color="primary"
          (click)="pickUser(u)"
          class="tiny"
          translate="COMMON_UI.KEY_WORD.SELECT"
        ></button>
      </div>
    </div>

    <button
      mat-flat-button
      color="primary"
      (click)="createNewSlave()"
      translate="UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.TITLE"
    ></button>
  </ng-container>
</mat-card-content>

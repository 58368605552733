import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import {
  EventDataDTO,
  FullGroupDTO,
  GroupingDTO,
  GroupingMapDTO,
  GroupMemberDTO,
  GroupPermissions,
  SingleSignUpDTO,
} from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { EventsService } from "@events/services";
import { GroupMembersService } from "@group/services";
import {
  CurrentGroupService,
  RegionalFormattingService,
} from "@shared/services";
import { ControlStateService } from "@shared/services/control-state.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { combineLatest, filter, firstValueFrom } from "rxjs";

type SignUpMap = { [id: string]: SingleSignUpDTO & { wasFound?: boolean } };

type SignUpStatusCounts = {
  grouping: GroupingDTO;
  yes: number;
  no: number;
  dontknow: number;
  signUpData: SignUpData[];
};

type SignUpData = Partial<SingleSignUpDTO> &
  GroupMemberDTO & {
    isSignedUp: boolean;
  };

type ViewType = "ALL" | "BY_GROUPING";

const CS_PREFIX = "EVENT_SIGN_UPS_DISPLAY";
const CS_DISPLAY_ALL = CS_PREFIX + "_SHOW_ALL";
const CS_SHOW_MEMBERS_IN_GROUPINGS = CS_PREFIX + "_SHOW_MEMBERS_IN_GROUPINGS";
const CS_DISPLAY_TIME = CS_PREFIX + "_DISPLAY_TIME";

@Component({
  selector: "events-sign-ups",
  templateUrl: "./events-sign-ups.component.html",
  styleUrls: ["./events-sign-ups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSignUpsComponent implements OnInit {
  private signUpMap?: SignUpMap;
  private displayNameMap: { [memberId: string]: string } = {};
  showAll: boolean;
  signUps?: SignUpData[];
  removedUsersSignUps: SingleSignUpDTO[] = [];
  externalUsersSignUps: SingleSignUpDTO[] = [];
  canSignUpOthers: boolean = false;
  canSignUpAdditional: boolean = false;
  groupingSignUps: SignUpStatusCounts[] = [];
  event!: EventDataDTO;
  viewType: ViewType = "ALL";
  displayTime: boolean = false;

  @Input() groupId!: string;
  @Input() eventId!: string;

  constructor(
    private service: EventsService,
    private userService: UserService,
    private groupMemberService: GroupMembersService,
    private cdr: ChangeDetectorRef,
    private reg: RegionalFormattingService,
    private cs: ControlStateService,
    private currentGroupService: CurrentGroupService,
    private translationService: TranslationService
  ) {
    this.showAll = this.cs.get(CS_DISPLAY_ALL, true);
    this.displayTime = this.cs.get(CS_DISPLAY_TIME, false);
  }

  async ngOnInit() {
    this.canSignUpOthers = this.userService.hasGroupPermission(
      this.groupId,
      GroupPermissions.GRP_ENROLL_OTHERS
    );
    this.canSignUpAdditional = this.userService.hasGroupPermission(
      this.groupId,
      GroupPermissions.GRP_ENROLL_EXTRA_TO_EVENT
    );
    LifeCyclesUtil.sub(
      [this, this.cdr],
      combineLatest([
        this.groupMemberService
          .getMembersOfGroup(this.groupId)
          .pipe(filter((i) => i.length > 0)),
        this.service.getEventsSignUps(this.eventId),
        this.service.getEvent(this.eventId, this.groupId),
        this.currentGroupService.getGroup(this.groupId),
        this.groupMemberService.getGroupingData(this.groupId),
      ]),
      (i) => {
        this.event = i[2].event;
        this.signUpMap = this.getSignUpMap(i[1]);
        this.signUps = this.getEnrichedSignups(i[0]);
        this.groupingSignUps = this.getGroupingSignups(i[3], i[4]);
        this.viewType =
          this.cs.get(CS_SHOW_MEMBERS_IN_GROUPINGS, false) &&
          this.groupingSignUps.length > 0
            ? "BY_GROUPING"
            : "ALL";
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private getSignUpMap(members: SingleSignUpDTO[]) {
    return members.reduce((obj, i) => {
      obj[i.userId] = i;
      return obj;
    }, {} as SignUpMap);
  }

  private getEnrichedSignups(members: GroupMemberDTO[]) {
    const allSignUps = members
      .map((member) => {
        const obj = Object.assign(
          { isSignedUp: member.id in this.signUpMap! },
          member
        );
        if (obj.isSignedUp) {
          this.signUpMap![member.id].wasFound = true;
          return Object.assign(obj, this.signUpMap![member.id]);
        }
        return obj;
      })
      .sort((a, b) => {
        return a.displayName.toLocaleLowerCase() <
          b.displayName.toLocaleLowerCase()
          ? -1
          : 1;
      });

    // Find signups of members that are not group members anymore
    this.removedUsersSignUps = Object.keys(this.signUpMap!)
      .filter(
        (i) => !this.signUpMap![i].wasFound && !this.signUpMap![i].isExternal
      )
      .map((i) => this.signUpMap![i]);
    // Find signups of members that are not group members anymore
    this.externalUsersSignUps = Object.keys(this.signUpMap!)
      .filter((i) => this.signUpMap![i].isExternal)
      .map((i) => this.signUpMap![i]);
    return allSignUps;
  }

  private getGroupingSignups(
    group: FullGroupDTO,
    groupingData: GroupingMapDTO[]
  ) {
    const groupings = (group.groupings || []).sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    return groupings.map((i) => {
      const members = new Set(
        groupingData.find((j) => j.id == i.id)?.memberIds
      );
      const initial: SignUpStatusCounts = {
        grouping: i,
        yes: 0,
        no: 0,
        dontknow: 0,
        signUpData: [],
      };
      this.signUps?.reduce((all, i) => {
        if (members.has(i.id)) {
          all.signUpData.push(i);
          if (i.status) {
            (all as any)[i.status.toLowerCase()] += 1;
          } else {
            i.status == "";
          }
        }
        return all;
      }, initial);
      return initial;
    });
  }

  getSignedUpByText(id: string) {
    if (!(id in this.displayNameMap)) {
      this.displayNameMap[id] =
        this.groupMemberService.getMemberDisplayName(this.groupId, id) ||
        this.translationService.fromString(
          TRANSLATIONS.UI.GROUP.EVENTS.SIGNUPS_LIST.REMOVED_MEMBER
        );
    }
    return this.translationService.fromString(
      TRANSLATIONS.UI.GROUP.EVENTS.SIGNUPS_LIST.SIGNED_UP_BY,
      {
        displayName: this.displayNameMap[id],
      }
    );
  }

  setViewType(kind: ViewType) {
    this.viewType = kind;
    this.cs.set(CS_SHOW_MEMBERS_IN_GROUPINGS, kind === "BY_GROUPING");
  }

  toggleShowSignUpTime() {
    this.displayTime = !this.displayTime;
    this.cs.set(CS_DISPLAY_TIME, this.displayTime);
  }

  getSignUpTime(ts: number) {
    if (!ts) {
      return "";
    }
    return this.reg.defaultRelativeDateTime(ts);
  }

  removeSignUpOfRemovedMember(userId: string) {
    return () =>
      this.service.removeSignUpOfRemovedMember(
        this.event.groupId,
        this.event.id,
        userId
      );
  }

  removeSignUpOfAdditional(signUpId: string) {
    return () =>
      this.service.removeSignUpOfAdditional(
        this.event.groupId,
        this.event.id,
        signUpId
      );
  }
}

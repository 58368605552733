import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { FullGroupDTO, GroupPermissions } from "@auto/dto.models";
import { GroupApiService } from "@auto/index";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { NavLink, NavigationService } from "@core/services/navigation.service";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";
import { assert } from "../utils/assert.func";
import { CurrentGroupStore } from "./current-group.store";

@Injectable({
  providedIn: "root",
})
export class CurrentGroupService {
  // Refreshes group from the backend always when new group is selected even if the same group was earlier selected

  private latestGroupRequestId!: string;

  currentGroup$ = new BehaviorSubject<FullGroupDTO | undefined>(undefined);
  isInCms$ = new BehaviorSubject<boolean>(false);

  get currentGroup(): FullGroupDTO {
    assert(this.currentGroup$.value);
    return this.currentGroup$.value as FullGroupDTO;
  }

  get featureMap(): { [feature: string]: true } {
    return {};
    /*
    return this.currentGroup.features.reduce((obj, f) => {
      obj[f] = true;
      return obj;
    }, {});
    */
  }

  get isGroupSelected(): boolean {
    return !!this.currentGroup$.value;
  }

  get currentGroupId(): string {
    return this.currentGroup.id;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private navService: NavigationService,
    private translationService: TranslationService,
    private userService: UserService,
    private groupApiService: GroupApiService,
    private currentGroupStore: CurrentGroupStore
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const id = event.snapshot.params?.["groupId"];
        if (!id) {
          this.currentGroup$.next(undefined);
          this.navService.clearSecondNav("GROUP");
          return;
        }
        this.setGroupWithId(id);
      }
    });
  }

  setGroupWithId(id: string) {
    if (this.shouldRefreshGroup(id)) {
      this.getGroup(id)
        .pipe(take(1))
        .subscribe((g) => {
          if (id !== this.latestGroupRequestId) {
            return;
          }
          this.addGroupNav(g);
          this.currentGroup$.next(g);
        });
    }
  }

  hasCurrentGroupFeature(featureId: string) {
    return false;
    /*
    return this.currentGroup.features.some((f) => f === featureId);
    */
  }

  setCurrentGroup(group: FullGroupDTO) {
    this.currentGroupStore.groups.set(group.id, group);
    this.currentGroup$.next(group);
  }

  refreshGroup() {
    this.getGroup(this.currentGroupId, true)
      .pipe(take(1))
      .subscribe((g) => {
        this.addGroupNav(g);
        this.currentGroup$.next(g);
      });
  }

  private shouldRefreshGroup(id: string) {
    if (!this.currentGroup$.value) {
      return true;
    }
    return this.currentGroup$.value.id !== id;
  }

  // TODO: Create interceptor to change header if group is used via another group
  getGroup(id: string, refresh = false): Observable<FullGroupDTO> {
    this.latestGroupRequestId = id;
    return this.userService.user$.pipe(
      filter((i) => !!i),
      switchMap((i) => {
        return this.currentGroupStore.groups.subscribeToValue(
          id,
          () => this.groupApiService.getGroup(id),
          refresh
        );
      })
    );
  }

  async getSettingsNav(): Promise<NavLink[]> {
    const dict = (await this.translationService.dictViaPromise()).UI.GROUP
      .LINKS;
    const r = ROUTE_MAP.PRI.GROUP;
    const nav = [
      {
        url: r.SETTINGS,
        name: dict.SETTINGS,
      },
    ];
    return nav;
  }

  private async addGroupNav(group: FullGroupDTO) {
    const dict = await this.translationService.dictViaPromise();
    let groupNav: NavLink[] = [
      {
        id: "calendar",
        url: ROUTE_MAP.PRI.GROUP.EVENTS.CALENDAR.PATH,
        name: dict.UI.GROUP.LINKS.CALENDAR,
      },
      ...[
        {
          permission: GroupPermissions.GRP_MANAGE_MEMBER,
          link: {
            id: "members",
            url: ROUTE_MAP.PRI.GROUP.MEMBERS.PATH,
            name: dict.UI.GROUP.LINKS.MEMBERS,
          },
        },
        {
          permission: GroupPermissions.GRP_MANAGE_SETTINGS,
          link: {
            id: "members",
            url: ROUTE_MAP.PRI.GROUP.SETTINGS.PATH,
            name: dict.UI.GROUP.LINKS.SETTINGS,
          },
        },
      ]
        .filter((i) =>
          this.userService.hasGroupPermission(this.currentGroupId, i.permission)
        )
        .map((i) => i.link),
    ];

    this.navService.setSecondNav("GROUP", groupNav);
  }
}

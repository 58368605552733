<h1 mat-dialog-title>{{this.texts.title}}</h1>
<div mat-dialog-content>
    <mat-radio-group class="radio-group" [(ngModel)]="selection">
        <mat-radio-button class="radio-button" color="primary" checked value="0">{{this.texts.thisEventOnly}}</mat-radio-button>
        <mat-radio-button class="radio-button" color="primary" checked value="1">{{this.texts.allEvents}}</mat-radio-button>
        <mat-radio-button class="radio-button" color="primary" checked value="2">{{this.texts.allEventsFromDay}}</mat-radio-button>
    </mat-radio-group>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="secondary" [mat-dialog-close]="false" (click)="closeDialog(true)">
    {{this.texts.cancel}}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
    (click)="closeDialog(false)"
>
    {{this.texts.delete}}
  </button>
</div>



<user-settings-menu></user-settings-menu>

<shared-loading *ngIf="!emails"></shared-loading>

<ng-container *ngIf="emails">
  <shared-message
    type="INFO"
    key="UI.USER.EMAILS.NO_EMAILS"
    *ngIf="emails.length === 0"
  ></shared-message>
  <div class="col-container" *ngFor="let i of emails">
    <div class="col-xs-8 col-s-8 col-m-6 col-l-4">
      {{ i.email }}
      <span
        *ngIf="!i.isVerified"
        translate="UI.USER.EMAILS.NOT_VERIFIED"
      ></span>
    </div>
    <div class="col-xs-1">
      <shared-loading-button
        mat-flat-button
        matType="mat-flat-button"
        [cb]="removeEmail(i)"
        class="tiny"
        key="COMMON_UI.KEY_WORD.DELETE"
      ></shared-loading-button>
    </div>
  </div>
  <ng-container *ngIf="emails.length < 5">
    <button
      mat-flat-button
      color="primary"
      (click)="addEmail()"
      translate="COMMON_UI.KEY_WORD.ADD_NEW"
    ></button>
  </ng-container>
</ng-container>

import { Injectable } from "@angular/core";
import { TranslationService } from "./translation.service";
import { CanActivate } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CanActivateWithTranslations implements CanActivate {
  constructor(private translationService: TranslationService) {}

  canActivate() {
    return this.translationService.languageReady$.pipe(filter((s) => s));
  }
}

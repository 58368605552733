import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { EventsService } from "@events/services";
import { RegionalFormattingService } from "@shared/services";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { CalendarType } from "../events-calendar/events-calendar.component";

@Component({
  selector: "events-list",
  templateUrl: "./events-list.component.html",
  styleUrls: ["./events-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsListComponent implements OnInit {
  @Input() groupId!: string;
  @Input() type!: CalendarType;
  events: string[] = [];

  isLoading = true;
  hasMoreEvents = false;

  // Do this via store once there are methods for that
  constructor(
    private service: EventsService,
    private cdr: ChangeDetectorRef,
    private reg: RegionalFormattingService,
    private nav: NavigationService,
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.type === "group") {
      this.initForGroup();
    } else if (this.type == "my") {
      this.initForAll();
    }
  }

  private initForGroup() {
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.service.getComingEventsOfGroup(this.groupId),
      (events: string[]) => {
        this.events = events;
      }
    );

    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.service.groupsComingEventsLastKey(this.groupId),
      (key: string | undefined) => this.setLoadingAndLoadMoreBooleans(key)
    );
  }

  private initForAll() {
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.service.getComingEvensOfMyDefaultGroups([this.groupId]),
      (events: string[]) => {
        this.events = events;
      }
    );

    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.service.areMyEventsLoaded(),
      (status: boolean) => {
        this.isLoading = !status;
      }
    );
  }

  private setLoadingAndLoadMoreBooleans(lastKey: string | undefined) {
    if (lastKey === undefined) {
      this.isLoading = true;
      this.hasMoreEvents = false;
    } else if (lastKey === "") {
      this.isLoading = false;
      this.hasMoreEvents = false;
    } else {
      this.isLoading = false;
      this.hasMoreEvents = true;
    }
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }
}

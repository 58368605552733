import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CoreRouteNotFoundComponent } from "@core/components";
import { ROUTES } from "@core/routing/routes";

const CUSTOM_ROUTES: Routes = [
  { path: "", redirectTo: "/pub/home", pathMatch: "full" },
  { path: "**", component: CoreRouteNotFoundComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES.concat(CUSTOM_ROUTES)), //, { enableTracing: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<shared-loading *ngIf="eventId && !event"></shared-loading>

<ng-container *ngIf="!eventId || event">
  <mat-card-header *ngIf="!eventId">
    <div mat-card-avatar>
      <mat-icon fontSet="fa" fontIcon="fa-calendar-plus"> </mat-icon>
    </div>

    <mat-card-title
      translate="UI.GROUP.EVENTS.CREATE_EVENT_TITLE"
    ></mat-card-title>
    <mat-card-subtitle>
      <span
        *ngIf="isMyOwnGroup"
        translate="UI.GROUP.EVENTS.CREATING_EVENT_JUST_FOR_YOU"
      >
      </span>
      <span
        *ngIf="!isMyOwnGroup"
        translate="UI.GROUP.EVENTS.CREATING_EVENT_FOR_GROUP"
        [par]="{
          groupName: groupName
        }"
      >
      </span>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-header *ngIf="eventId">
    <div mat-card-avatar>
      <mat-icon fontSet="fa" fontIcon="fa-calendar-plus"> </mat-icon>
    </div>
    <mat-card-title
      translate="UI.GROUP.EVENTS.MODIFY_EVENT_TITLE"
    ></mat-card-title>
    <mat-card-subtitle>
      <span
        *ngIf="isMyOwnGroup"
        translate="UI.GROUP.EVENTS.MODIFY_EVENT_JUST_FOR_YOU"
      >
      </span>
      <span
        *ngIf="!isMyOwnGroup"
        translate="UI.GROUP.EVENTS.MODIFY_EVENT_FOR_GROUP"
        [par]="{
          groupName: groupName
        }"
      >
      </span>
    </mat-card-subtitle>
  </mat-card-header>

  <div *ngIf="event && event.seriesId">
    <shared-message type="INFO" key="UI.GROUP.EVENTS.MODIFY_EVENT_SERIES_INFO">
    </shared-message>
  </div>

  <mat-card-content>
    <shared-form-editor [config]="config"></shared-form-editor>

    <shared-loading-button
      class="margin-to-right margin-to-bottom"
      [disabled]="disableSave"
      [cb]="$any(saveDefaultCallback)"
    ></shared-loading-button>

    <ng-container *ngIf="saveAndSeparateCallback">
      <shared-loading-button
        class="margin-to-right margin-to-bottom"
        key="UI.GROUP.EVENTS.MODIFY_EVENT_SEPARATE_FROM_SERIES"
        [disabled]="disableSave"
        [cb]="$any(saveAndSeparateCallback)"
      ></shared-loading-button>
    </ng-container>
  </mat-card-content>
</ng-container>

<!-- Give for parent correct class
  .second-nav: Creates second level navigation
  .tab-nav: Creates tab nav
-->

<div class="nav-container nav-container--horizontal" #navContainer>
  <span *ngFor="let i of displayLinks" linkElement [ngClass]="getClasses(i)" (click)="open(i)"
    [attr.data-t-id]="i.id || i.name">
    <mat-icon *ngIf="i.icon" class="icon" fontSet="fa" [fontIcon]="i.icon">
    </mat-icon>
    {{i.name}}
  </span>
  <div class="show-more-button" *ngIf="doesAnyOverflow" (click)="toggleMore()"
    [ngClass]="{'show-more-button--open': isMoreVisible}">
    <mat-icon fontSet="fa" fontIcon="fa-ellipsis-h">
    </mat-icon>
  </div>
</div>

<!-- This sets dropdown container -->
<div class="nav-container" *ngIf="isMoreVisible && doesAnyOverflow">
  <div class="nav-container--show-more">
    <!--(clickOutside)="closeMore()">-->
    <ng-container *ngFor="let i of displayLinks">
      <span class="link--show-more dropdown--come-in" [ngClass]="{'link--active': i.isActive}" *ngIf="i.doesOverflow"
        (click)="openShowMore(i)">
        <mat-icon *ngIf="i.icon" class="icon" fontSet="fa" [fontIcon]="i.icon">
        </mat-icon>
        {{i.name}}
      </span>
    </ng-container>
    <span class="link--show-more link--show-more-close" (click)="closeMore()">
      <mat-icon fontSet="fa" fontIcon="fa-ellipsis-h">
      </mat-icon>
    </span>
  </div>
</div>
<mat-card-header>
    <div mat-card-avatar>
      <mat-icon fontSet="fa" fontIcon="fa-users"> </mat-icon>
    </div>
    <mat-card-title
      class="no-subtitle"
      translate="UI.VIEWS.SLIDING_MENU.MY_GROUPS"
    ></mat-card-title>
  </mat-card-header>
  
  <mat-card-content>
    <div class="group col-container" *ngFor="let group of groups">
        <div class="col-xs-3">
            {{group.groupName}}
        </div>
        <div class="col-xs-1 leave-group-button">
        <button
            mat-stroked-button
            color="primary"
            class="margin"
            data-t-id="leave-group"
            (click)="leaveGroup(group)"
            translate="UI.GROUP.MEMBERS.LEAVE_GROUP">
        </button>      
        </div>
    </div>
</mat-card-content>

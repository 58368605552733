import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NameAndIdDTO } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { NavigationService } from "@core/services/navigation.service";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { JoinGroupFormFactory } from "@ff/join-group.ff";
import { SharedFormDialogComponent } from "@shared/components/shared-form-dialog/shared-form-dialog.component";
import { DirtyStateTrackerService } from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { ToasterService } from "@shared/services/toaster.service";
import { firstValueFrom, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { NewGroupService } from "./new-group.service";

@Component({
  templateUrl: "./group-route-new.component.html",
  styleUrls: ["./group-route-new.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupRouteNewComponent {
  form = this.fb.group({
    name: ["", Validators.required],
  });

  searchForm = this.fb.group({
    name: ["", Validators.required],
  });

  groups: NameAndIdDTO[] = [];

  get controls() {
    return this.form.controls;
  }

  constructor(
    private fb: FormBuilder,
    private ts: TranslationService,
    private toasterService: ToasterService,
    private service: NewGroupService,
    private cdr: ChangeDetectorRef,
    private ff: JoinGroupFormFactory,
    private dialog: MatDialog,
    private busyService: BusyService,
    private dst: DirtyStateTrackerService,
    private userService: UserService
  ) {}

  createCallback() {
    return () =>
      this.service.createGroup(this.form.controls["name"].value).pipe(
        tap(() => {
          this.toasterService.showSuccessTranslationKey(
            TRANSLATIONS.COMMON_UI.OK.ADDED
          );
        })
      );
  }

  joinGroup(group: NameAndIdDTO) {
    const close = new Subject<void>();
    const form = this.ff.createForm();
    this.dialog.open(SharedFormDialogComponent, {
      width: "500px",
      data: {
        title: this.ts.fromString(
          TRANSLATIONS.UI.GROUP.NEW_GROUP.JOIN.DIALOG_TITLE,
          {
            groupName: group.name,
          }
        ),
        configGetter: (cancel: () => void) => {
          return this.ff.formGroupToConfig(form, cancel, async () => {
            const response = await this.sendJoinRequest(group.id, form);
            if (response) {
              cancel();
            }
            return response;
          });
        },
        close,
      },
    });
  }

  private async sendJoinRequest(groupId: string, form: FormGroup) {
    const busyId = this.busyService.show();
    this.dst.removeDirty(this.ff.DIRTY_KEY);
    this.busyService.hide(busyId);
    return true;
  }

  searchCallback() {
    const myGroups = this.userService.myGroupMap;
    return () => {
      return this.service
        .searchGroups(this.searchForm.controls["name"].value)
        .pipe(
          tap((i) => {
            const groups = i.collection.filter((i) => !(i.id in myGroups));
            if (groups.length === 0) {
              this.toasterService.showErrorTranslationKey(
                TRANSLATIONS.UI.GROUP.NEW_GROUP.JOIN.NO_RESULTS
              );
            }
            this.groups = groups.sort((a, b) => {
              return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()
                ? -1
                : 1;
            });
            this.cdr.detectChanges();
          })
        );
    };
  }
}

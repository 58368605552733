<h1 mat-dialog-title translate="UI.GROUP.MEMBERS.ADD_FROM_SLAVES"></h1>

<div mat-dialog-content>
  <div class="col-container" *ngFor="let i of slaves">
    <div class="col-xs-8 col-s-8 col-m-6 col-l-4">
      {{ i.givenName }} {{ i.familyName }}
    </div>
    <div class="col-xs-4">
      <ng-container *ngIf="i.isAlreadyMember">
        <span translate="UI.GROUP.MEMBERS.SLAVE_IS_ALREADY_MEMBER"></span>
      </ng-container>
      <ng-container *ngIf="!i.isAlreadyMember">
        <shared-loading-button
          mat-flat-button
          matType="mat-flat-button"
          [cb]="addUser(i)"
          class="tiny"
          key="COMMON_UI.KEY_WORD.ADD"
        ></shared-loading-button>
      </ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="false"
    translate="COMMON_UI.KEY_WORD.CLOSE"
  ></button>
</div>

import { Injectable } from "@angular/core";
import { RegionalFormattingService } from "@shared/services";
import {
  CalendarNativeDateFormatter,
  DateAdapter,
  DateFormatterParams,
} from "angular-calendar";

@Injectable({ providedIn: "root" })
export class CustomCalendarDateFormatter extends CalendarNativeDateFormatter {
  constructor(
    private regionalFormattingService: RegionalFormattingService,
    dateAdapter: DateAdapter
  ) {
    super(dateAdapter);
  }

  override weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.regionalFormattingService.getDate(date, {
      hour: "numeric",
      minute: "numeric",
    });
  }

  override dayViewHour({ date, locale }: DateFormatterParams): string {
    return this.weekViewHour({ date, locale });
  }

  override monthViewColumnHeader({
    date,
    locale,
  }: DateFormatterParams): string {
    return this.regionalFormattingService.getDayOfWeek(date);
  }

  override weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return this.regionalFormattingService.getDayOfWeek(date);
  }
}

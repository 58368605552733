import { Injectable } from "@angular/core";
import {
  GroupMemberDTO,
  GroupMemberInvitationDTO,
  RoleTypeEnum,
  UpdateGroupMemberDTO,
} from "@auto/dto.models";
import { GroupApiService, GroupingApiService } from "@auto/index";
import { TranslationService } from "@core/services";
import { CurrentGroupService } from "@shared/services";
import { firstValueFrom, of, catchError } from "rxjs";
import { map, tap, switchMap } from "rxjs/operators";
import { GroupMembersStore } from "./group-members.store";

@Injectable({
  providedIn: "root",
})
export class GroupMembersService {
  texts = this.getTexts();

  constructor(
    private currentGroupService: CurrentGroupService,
    private api: GroupApiService,
    private groupingApi: GroupingApiService,
    private store: GroupMembersStore,
    private translationService: TranslationService
  ) {}

  // Basic group members

  getMembersOfCurrentGroup() {
    return this.getMembersOfGroup(this.currentGroupService.currentGroupId);
  }

  getMembersOfGroup(groupId: string) {
    return this.store.members.subscribeToValue(groupId, () =>
      this.api.getGroupMembers(groupId).pipe(map((i) => i.collection))
    );
  }

  getMemberDisplayName(groupId: string, memberId: string) {
    return this.store.members.get(groupId).find((i) => i.id === memberId)
      ?.displayName;
  }

  refreshMembersOfCurrentGroup() {
    const groupId = this.currentGroupService.currentGroupId;
    return this.refreshMembersOfGroup(groupId);
  }

  async refreshMembersOfGroup(groupId: string) {
    await firstValueFrom(
      this.store.members.subscribeToValue(
        groupId,
        () => this.api.getGroupMembers(groupId).pipe(map((i) => i.collection)),
        true
      )
    );
  }

  // Grouping operations
  getGroupingDataOfCurrentGroup() {
    return this.getGroupingData(this.currentGroupService.currentGroupId);
  }

  getGroupingData(groupId: string, refresh = false) {
    return this.store.groupingData.subscribeToValue(
      groupId,
      () =>
        this.groupingApi
          .getGroupsGroupings(groupId)
          .pipe(map((i) => i.collection)),
      refresh
    );
  }

  // Group member update operations
  saveGroupMember(memberId: string, dto: UpdateGroupMemberDTO) {
    const groupId = this.currentGroupService.currentGroupId;
    return this.api.saveGroupMember(groupId, memberId, dto).pipe(
      tap((member) => {
        this.store.setMember(groupId, member);
      })
    );
  }

  inviteGroupMembers(members: GroupMemberInvitationDTO[]) {
    const dto = {
      // TODO: This should be selectable
      roleType: RoleTypeEnum.MEMBER,
      language: this.translationService.language,
      message: this.texts.invitation.replace(
        "{groupName}",
        this.currentGroupService.currentGroup.name
      ),
      members,
    };
    return this.api
      .inviteGroupMembers(this.currentGroupService.currentGroupId, dto)
      .pipe(switchMap((_) => this.refreshMembersOfCurrentGroup()));
  }

  acceptGroupMemberInvitation(groupId: string, invitationId: string) {
    return this.api.acceptGroupMemberInvitation(groupId, invitationId).pipe(
      catchError((err, caught) => {
        console.log(err);
        return of(true);
      })
    );
  }

  getGroupMemberInvitation(groupId: string, invitationId: string) {
    return this.api.getGroupMemberInvitation(groupId, invitationId);
  }

  deleteGroupMember(memberId: string) {
    // This can only be done in current group
    const groupId = this.currentGroupService.currentGroupId;
    return this.api.deleteGroupMember(groupId, memberId).pipe(
      tap((result) => {
        this.store.removeMember(groupId, memberId);
      })
    );
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      invitation: dict.UI.GROUP.INVITATION.INVITATION_EMAIL_TEXT,
    };
  }

  // Add member to grouping
  addMemberToGrouping(groupingId: string, memberId: string) {
    const groupId = this.currentGroupService.currentGroupId;
    return this.groupingApi
      .addUserToGrouping(groupId, groupingId, memberId)
      .pipe(
        switchMap((i) => {
          // If this is not yet in store (i.e. just added, need to fetch grouping data, otherwise just add to store, COULD just dynamically add, but let's refresh)
          if (
            !this.store.groupingData.has(groupId) ||
            !this.store.groupingData
              .get(groupId)
              .some((i) => i.id == groupingId)
          ) {
            return this.getGroupingData(groupId, true).pipe(
              switchMap((j) => of(i))
            );
          } else {
            this.store.addUserToGrouping(groupId, groupingId, memberId);
            return of(i);
          }
        })
      );
  }

  removeMemberFromGrouping(groupingId: string, memberId: string) {
    const groupId = this.currentGroupService.currentGroupId;
    return this.groupingApi
      .removeUserFromGrouping(groupId, groupingId, memberId)
      .pipe(
        tap((i) => {
          this.store.removeUserFromGrouping(groupId, groupingId, memberId);
        })
      );
  }

  addSlaveToGroup(groupId: string, slaveId: string) {
    return this.api
      .addFromMySlave(groupId, slaveId)
      .pipe(switchMap((i) => this.refreshMembersOfGroup(groupId)));
  }
}

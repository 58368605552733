import { Injectable } from "@angular/core";
import { EventDataDTO, EventTypeDTO, SingleSignUpDTO } from "@auto/dto.models";
import {
  IListStore,
  IMappedListStore,
  IMappedStore,
  StateManagementStoreFactory,
} from "@shared/services";
import { isThisSecond } from "date-fns";
import { BehaviorSubject } from "rxjs";

export type EventInStore = {
  event: EventDataDTO;
  signUps?: SingleSignUpDTO[];
};

@Injectable({
  providedIn: "root",
})
export class EventsStore {
  globalEventTypes: IListStore<EventTypeDTO>;

  // Undefined means, don't know i.e. still loading
  groupsComingEvents: IMappedListStore<string>;
  groupsComingEventsLastKey: IMappedStore<string | undefined>;

  myComingEvents: IListStore<string>;
  myComingEventsLoaded = new BehaviorSubject<boolean>(false);

  events: IMappedStore<EventInStore>;

  constructor(sms: StateManagementStoreFactory) {
    this.groupsComingEvents = sms.createMappedListStore<string>();
    this.myComingEvents = sms.createListStore<string>();
    this.globalEventTypes = sms.createListStore<EventTypeDTO>();
    this.groupsComingEventsLastKey = sms.createMappedStore<
      string | undefined
    >();

    this.events = sms.createMappedStore<EventInStore>();
  }

  clearStore(groupId?: string) {
    if (groupId) {
      this.groupsComingEvents.clear(groupId);
    } else {
      this.groupsComingEvents.clear();
      this.groupsComingEventsLastKey.clear();
      this.events.clear();
      this.myComingEventsLoaded.next(false);
    }
    this.myComingEvents.clear();
  }

  hasSignUps(eventId: string) {
    return this.events.has(eventId) && this.events.get(eventId).signUps;
  }

  setEventsSignUps(
    eventId: string,
    signUps: SingleSignUpDTO[],
    myStatus?: {
      status: string;
      message?: string;
    }
  ) {
    const data = this.events.get(eventId);
    data.signUps = signUps;
    const counts = signUps.reduce(
      (prev, i) => {
        (prev as any)[i.status] += 1;
        return prev;
      },
      {
        // Make this enum
        YES: 0,
        NO: 0,
        DONTKNOW: 0,
      }
    );
    data.event.signUpStatusCounts = counts;
    if (myStatus) {
      data.event.mySignUpStatus = myStatus;
    }
    this.events.set(eventId, data);
  }
}

// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { subsToUrl } from "./subs-to-url.func";
import {
  EventDataDTO,
  EventTypesDTO,
  StatusDTO,
  BasicEventsDTO,
  SignUpsDTO,
  EventListDTO,
  NewEventDTO,
} from "./dto.models";

@Injectable({
  providedIn: "root",
})
export class EventApiService {
  constructor(private http: HttpClient) {}

  createEvent(groupId: string, dto: NewEventDTO): Observable<BasicEventsDTO> {
    const url = subsToUrl("/api/v1/pri/event/group/:groupId", { groupId });
    return this.http.post<BasicEventsDTO>(url, dto);
  }

  updateEvent(
    groupId: string,
    eventId: string,
    dto: NewEventDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/event/group/:groupId/event/:eventId", {
      groupId,
      eventId,
    });
    return this.http.put<StatusDTO>(url, dto);
  }

  updateEventSeries(
    groupId: string,
    eventId: string,
    dto: NewEventDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/event/group/:groupId/event/:eventId/update-series",
      { groupId, eventId }
    );
    return this.http.put<StatusDTO>(url, dto);
  }

  getGlobalEventTypes(): Observable<EventTypesDTO> {
    const url = subsToUrl("/api/v1/pri/event/global-event-types", {});
    return this.http.get<EventTypesDTO>(url);
  }

  getComingEventsOfGroup(groupId: string): Observable<EventListDTO> {
    const url = subsToUrl("/api/v1/pri/event/group/:groupId", { groupId });
    return this.http.get<EventListDTO>(url);
  }

  getEvent(groupId: string, eventId: string): Observable<EventDataDTO> {
    const url = subsToUrl("/api/v1/pri/event/group/:groupId/event/:eventId", {
      groupId,
      eventId,
    });
    return this.http.get<EventDataDTO>(url);
  }

  getComingEventsOfGroupByTime(
    groupId: string,
    fromTimestamp: number,
    toTimestamp: number
  ): Observable<EventListDTO> {
    const url = subsToUrl(
      "/api/v1/pri/event/group/:groupId/:fromTimestamp/:toTimestamp",
      { groupId, fromTimestamp, toTimestamp }
    );
    return this.http.get<EventListDTO>(url);
  }

  getMyNextEvents(): Observable<EventListDTO> {
    const url = subsToUrl("/api/v1/pri/event/my-events", {});
    return this.http.get<EventListDTO>(url);
  }

  getMyEventsByTime(
    fromTimestamp: number,
    toTimestamp: number
  ): Observable<EventListDTO> {
    const url = subsToUrl(
      "/api/v1/pri/event/my-events/:fromTimestamp/:toTimestamp",
      { fromTimestamp, toTimestamp }
    );
    return this.http.get<EventListDTO>(url);
  }

  getEventsSignUps(groupId: string, eventId: string): Observable<SignUpsDTO> {
    const url = subsToUrl(
      "/api/v1/pri/event/group/:groupId/event/:eventId/sign-up-data",
      { groupId, eventId }
    );
    return this.http.get<SignUpsDTO>(url);
  }

  deleteEvents(
    groupId: string,
    eventId: string,
    seriesId: string,
    date: string
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/event/group/:groupId/event/:eventId/delete/:seriesId/:date",
      { groupId, eventId, seriesId, date }
    );
    return this.http.delete<StatusDTO>(url);
  }

  deleteEvent(groupId: string, eventId: string): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/event/group/:groupId/event/:eventId/delete",
      { groupId, eventId }
    );
    return this.http.delete<StatusDTO>(url);
  }
}

import { Injectable } from "@angular/core";
import { FullGroupDTO, GroupingMapDTO, GroupMemberDTO } from "@auto/dto.models";
import { IMappedStore, StateManagementStoreFactory } from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class CurrentGroupStore {
  groups: IMappedStore<FullGroupDTO>;

  constructor(sms: StateManagementStoreFactory) {
    this.groups = sms.createMappedStore<FullGroupDTO>();
  }
}

import {
  Directive,
  OnChanges,
  Input,
  HostBinding,
  ElementRef,
} from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";

// https://stackoverflow.com/questions/47315256/angular-animation-for-dynamically-changing-height/52277742
export const SMOOTH_HEIGHT = trigger("grow", [
  transition("void <=> *", []),
  transition(
    "* <=> *",
    [style({ height: "{{startHeight}}px", opacity: 0 }), animate(".3s ease")],
    {
      params: { startHeight: 0 },
    }
  ),
]);

@Directive({
  selector: "[sharedSmoothHeightAnimation]",
  host: { "[style.display]": '"block"', "[style.overflow]": '"hidden"' },
})
export class SharedSmoothHeightAnimationDirective implements OnChanges {
  @Input()
  sharedSmoothHeightAnimation: any;
  pulse!: boolean;
  startHeight!: number;

  constructor(private element: ElementRef) {}

  @HostBinding("@grow")
  get grow() {
    return { value: this.pulse, params: { startHeight: this.startHeight } };
  }

  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes: any) {
    this.setStartHeight();
    this.pulse = !this.pulse;
  }
}

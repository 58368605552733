<ng-container *ngIf="config">
  <form [formGroup]="config.form">
    <div class="col-container" *ngFor="let row of config.fields">
      <div [class]="i.classList" *ngFor="let i of row">
        <ng-container *ngIf="!i.showIf || i.showIf()">
          <!-- Editable items -->
          <mat-form-field
            [class]="config.formFieldClassList"
            *ngIf="i.isFormItem"
          >
            <mat-label>{{ i.label }}</mat-label>
            <ng-container [ngSwitch]="i.type">
              <!-- Text -->
              <input
                *ngSwitchCase="'text'"
                [attr.data-t-id]="i.id || i.formControlName"
                type="text"
                matInput
                autocomplete="off"
                [formControl]="$any(i).formControl"
              />
              <!-- Number -->
              <input
                *ngSwitchCase="'number'"
                [attr.data-t-id]="i.id || i.formControlName"
                type="number"
                matInput
                autocomplete="off"
                [formControl]="$any(i).formControl"
              />
              <input
                *ngSwitchCase="'duration'"
                [attr.data-t-id]="i.id || i.formControlName"
                type="number"
                matInput
                autocomplete="off"
                [formControl]="$any(i).formControl"
              />
              <!-- Password -->
              <input
                *ngSwitchCase="'password'"
                [attr.data-t-id]="i.id || i.formControlName"
                type="password"
                matInput
                autocomplete="off"
                [formControl]="$any(i).formControl"
              />
              <!-- Textarea -->
              <textarea
                *ngSwitchCase="'textarea'"
                [attr.data-t-id]="i.id || i.formControlName"
                matInput
                [formControl]="$any(i).formControl"
              ></textarea>
              <!-- [sharedAutosizeTextarea] -->
              <!-- Select -->
              <mat-select
                *ngSwitchCase="'select'"
                [attr.data-t-id]="i.id || i.formControlName"
                [formControl]="$any(i).formControl"
              >
                <mat-option
                  [attr.style]="getOptionStyle(opt)"
                  *ngFor="let opt of $any(i).options"
                  [value]="opt.value"
                >
                  {{ opt.label }}
                </mat-option>
              </mat-select>
            </ng-container>
          </mat-form-field>
          <!-- Checkbox -->
          <ng-container *ngIf="i.type == 'checkbox'">
            <mat-checkbox [formControl]="$any(i).formControl" color="primary">
              {{ i.label }}
            </mat-checkbox>
          </ng-container>
          <!-- Rich text editor -->
          <shared-trumbowyg
            *ngIf="i.type == 'richtext'"
            [attr.data-t-id]="i.id || i.formControlName"
            [form]="$any(i).formControl"
            [label]="i.label || ''"
          ></shared-trumbowyg>
          <!-- Duration -->
          <shared-date-time-picker
            [time]="$any(i).formControl"
            type="duration"
            *ngIf="i.type == 'duration'"
          >
          </shared-date-time-picker>
          <!-- Date -->
          <shared-date-time-picker
            [dateLabel]="i.label"
            [time]="$any(i).formControl"
            type="date"
            *ngIf="i.type == 'date'"
          >
          </shared-date-time-picker>
          <!-- SlideToggle -->
          <mat-slide-toggle
            *ngIf="i.type == 'slideToggle'"
            color="primary"
            [formControl]="$any(i).formControl"
          >
            {{ i.label }}
          </mat-slide-toggle>
          <!-- Buttons -->
          <ng-container *ngIf="i.isButtonItem">
            <ng-container [ngSwitch]="i.type">
              <!-- Loading button -->
              <shared-loading-button
                *ngSwitchCase="'button.loading'"
                [text]="i.label"
                [confirm]="$any(i).confirm"
                [name]="i.id || 'button.loading'"
                [disabled]="
                  (!config.form.valid || config.form.untouched) &&
                  !$any(i).enableAlways
                "
                [cb]="$any(i).callback"
              >
              </shared-loading-button>
              <!-- Plain button -->
              <button
                *ngSwitchCase="'button.plain'"
                mat-stroked-button
                color="primary"
                (click)="$any(i).callback()"
                [attr.data-t-id]="i.id || 'button.plain'"
                [disabled]="
                  (!config.form.valid || config.form.pristine) &&
                  !$any(i).enableAlways
                "
              >
                {{ i.label }}
              </button>
            </ng-container>
          </ng-container>
          <!-- Errors -->
          <ng-container *ngIf="i.hasErrors">
            <ng-container *ngFor="let e of i.errors">
              <mat-error *ngIf="e.when(i.formControl)">
                {{ e.label }}
              </mat-error>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </form>
</ng-container>

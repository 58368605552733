import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { GroupDTO, UserGroupDTO } from "@auto/dto.models";
import { DatService } from "@core/services/dat.service";

@Component({
  selector: "core-group-selector",

  templateUrl: "./core-group-selector.component.html",
  styleUrls: ["./core-group-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreGroupSelectorComponent implements OnInit {
  @Input() selectedGroup!: GroupDTO | false;
  @Input() groups!: UserGroupDTO[];
  @Output() onSelect = new EventEmitter<UserGroupDTO | undefined>();

  @HostListener("click", ["$event.target"])
  onClick() {
    this.toggleGroupSelector();
  }

  @HostBinding("class") get cls() {
    return this.isGroupSelectorOpen ? "toolbar__item--hover" : "";
  }

  restrictedGroupName = "";
  isGroupSelectorOpen = false;
  isGroupRestriction = false;
  private togglingGroups = false;

  constructor(private cdr: ChangeDetectorRef, private datService: DatService) {}

  ngOnInit(): void {
    this.isGroupRestriction = this.datService.isGroupRestriction;
    // If length is not 1, user doesn't belong to restricted group and there is nothing that can be done
    if (this.isGroupRestriction && this.groups.length === 1) {
      this.restrictedGroupName = this.groups[0].groupName;
    }
  }

  selectGroup(group?: UserGroupDTO) {
    this.onSelect.emit(group);
  }

  closeGroupSelector() {
    if (this.togglingGroups) {
      return;
    }
    this.isGroupSelectorOpen = false;
    this.cdr.markForCheck();
  }

  toggleGroupSelector() {
    if (this.isGroupRestriction) {
      // This is like select this group
      if (this.groups.length !== 1) {
        return;
      }
      this.selectGroup(this.groups[0]);
      return;
    }
    this.togglingGroups = true;
    setTimeout(() => {
      this.togglingGroups = false;
      this.isGroupSelectorOpen = !this.isGroupSelectorOpen;
      this.cdr.markForCheck();
    });
  }
}

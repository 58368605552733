import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";

import {
  SharedAccordionToggleButtonsComponent,
  SharedConfirmDialogComponent,
  SharedDateTimePickerComponent,
  SharedFormEditorComponent,
  SharedGroupSelectorDialogComponent,
  SharedLoadingButtonComponent,
  SharedLoadingComponent,
  SharedMessageComponent,
  SharedNavSecondComponent,
  SharedNotImplementedComponent,
  SharedOrderEditorComponent,
  SharedOverlayComponent,
  SharedTrumbowygComponent,
} from "./components";
import {
  SharedMatInputAutofocusDirective,
  SharedAutosizeTextareaDirective,
  SharedConfirmClickDirective,
  SharedAppContent,
  SharedGroupPermissionDirective,
  SharedTranslateDirective,
  SharedTranslatePipe,
  SharedUserPermissionDirective,
} from "./directives";
import { SharedSmoothHeightAnimationDirective } from "./animations";
import { SharedFormDialogComponent } from './components/shared-form-dialog/shared-form-dialog.component';
@NgModule({
  declarations: [
    // Components
    SharedAccordionToggleButtonsComponent,
    SharedConfirmDialogComponent,
    SharedFormEditorComponent,
    SharedDateTimePickerComponent,
    SharedGroupSelectorDialogComponent,
    SharedLoadingComponent,
    SharedLoadingButtonComponent,
    SharedMessageComponent,
    SharedNavSecondComponent,
    SharedNotImplementedComponent,
    SharedOrderEditorComponent,
    SharedOverlayComponent,
    SharedTrumbowygComponent,
    // Directives
    SharedMatInputAutofocusDirective,
    SharedTranslateDirective,
    SharedUserPermissionDirective,
    SharedAppContent,
    SharedGroupPermissionDirective,
    SharedConfirmClickDirective,
    SharedAutosizeTextareaDirective,
    SharedSmoothHeightAnimationDirective,
    // Pipes
    SharedTranslatePipe,
    SharedFormDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
  ],
  exports: [
    // Components
    SharedAccordionToggleButtonsComponent,
    SharedConfirmDialogComponent,
    SharedFormEditorComponent,
    SharedDateTimePickerComponent,
    SharedGroupSelectorDialogComponent,
    SharedLoadingComponent,
    SharedLoadingButtonComponent,
    SharedMessageComponent,
    SharedNavSecondComponent,
    SharedNotImplementedComponent,
    SharedOrderEditorComponent,
    SharedOverlayComponent,
    SharedTrumbowygComponent,
    // Directives
    SharedMatInputAutofocusDirective,
    SharedTranslateDirective,
    SharedUserPermissionDirective,
    SharedAppContent,
    SharedGroupPermissionDirective,
    SharedConfirmClickDirective,
    SharedAutosizeTextareaDirective,
    SharedSmoothHeightAnimationDirective,
    // Pipes
    SharedTranslatePipe,
  ],
})
export class SharedModule {
  constructor() {}
}

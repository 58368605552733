import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { UserEmailDTO } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { UserProfileAddEmailFormFactory } from "@ff/user-profile-add-email.ff";
import {
  SharedFormDialogComponent,
  SharedFormDialogData,
} from "@shared/components/shared-form-dialog/shared-form-dialog.component";
import { Subject } from "rxjs";

@Component({
  templateUrl: "./user-route-my-emails.component.html",
  styleUrls: ["./user-route-my-emails.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRouteMyEmailsComponent implements OnInit {
  emails?: UserEmailDTO[];
  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private ff: UserProfileAddEmailFormFactory,
    private ts: TranslationService
  ) {}

  ngOnInit() {
    this.refreshEmails();
  }

  private async refreshEmails() {
    this.emails = (await this.userService.getEmails()).collection;
    this.cdr.detectChanges();
  }

  removeEmail(email: UserEmailDTO) {
    return async () => {
      this.emails = (
        await this.userService.removeEmail(email.email)
      ).collection;
      return true;
    };
  }

  addEmail() {
    const form = this.ff.createForm();
    const close = new Subject<boolean>();
    this.dialog.open(SharedFormDialogComponent, {
      data: {
        title: this.ts.fromString(TRANSLATIONS.COMMON_UI.KEY_WORD.ADD),
        configGetter: (cancel: () => void) => {
          return this.ff.formGroupToConfig(
            form,
            async () => {
              await this.saveNewEmail(form);
              close.next(true);
              return true;
            },
            cancel
          );
        },
        close,
      } as SharedFormDialogData<boolean>,
    });
  }

  private async saveNewEmail(form: FormGroup) {
    this.emails = (
      await this.userService.addEmail(form.controls["email"].value)
    ).collection;
    this.cdr.detectChanges();
    return true;
  }
}

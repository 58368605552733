<ng-container *ngIf="event">
  <h2
    translate="UI.GROUP.EVENTS.SIGNUPS_LIST.SUMMARY"
    *ngIf="groupingSignUps.length == 0"
  ></h2>

  <div class="col-container">
    <div class="col-auto-flex" [class.layer]="groupingSignUps.length > 0">
      <h3
        translate="UI.GROUP.EVENTS.SIGNUPS_LIST.ALL"
        *ngIf="groupingSignUps.length > 0"
      ></h3>
      <table>
        <tr>
          <td>
            <span
              class="yes"
              translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.YES"
            ></span
            >:
          </td>
          <td>
            {{ $any(event).signUpStatusCounts["YES"] }}
            <ng-container *ngIf="$any(event).maxSignUp > -1">
              / {{ event.maxSignUp }}
            </ng-container>
          </td>
        </tr>
        <tr>
          <td>
            <span
              class="no"
              translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.NO"
            ></span
            >:
          </td>
          <td>
            {{ $any(event).signUpStatusCounts["NO"] }}
          </td>
        </tr>
        <tr>
          <td>
            <span
              class="dontknow"
              translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.DONTKNOW"
            ></span
            >:
          </td>
          <td>
            {{ $any(event).signUpStatusCounts["DONTKNOW"] }}
          </td>
        </tr>
      </table>
    </div>

    <ng-container *ngFor="let i of groupingSignUps">
      <div class="col-auto-flex layer">
        <h3>{{ i.grouping.name }}</h3>
        <table>
          <tr>
            <td>
              <span
                class="yes"
                translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.YES"
              ></span
              >:
            </td>
            <td>
              {{ i.yes }}
              <!-- TODO: Make this grouping dependent -->
              <ng-container *ngIf="$any(event).maxSignUp > -1">
                / {{ event.maxSignUp }}
              </ng-container>
            </td>
          </tr>
          <tr>
            <td>
              <span
                class="no"
                translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.NO"
              ></span
              >:
            </td>
            <td>
              {{ i.no }}
            </td>
          </tr>
          <tr>
            <td>
              <span
                class="dontknow"
                translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.DONTKNOW"
              ></span
              >:
            </td>
            <td>
              {{ i.dontknow }}
            </td>
          </tr>
        </table>
      </div>
    </ng-container>
  </div>

  <h2
    translate="UI.GROUP.EVENTS.SIGNUPS_LIST.MEMBERS"
    *ngIf="groupingSignUps.length === 0"
  ></h2>

  <ng-container *ngIf="groupingSignUps.length > 0">
    <button
      mat-stroked-button
      color="primary"
      class="margin"
      translate="UI.GROUP.EVENTS.SIGNUPS_LIST.ALL"
      [ngClass]="{ selected: viewType === 'ALL' }"
      (click)="setViewType('ALL')"
    ></button>

    <button
      mat-stroked-button
      color="primary"
      class="margin"
      [ngClass]="{ selected: viewType === 'BY_GROUPING' }"
      translate="UI.GROUP.EVENTS.SIGNUPS_LIST.BY_GROUPINGS"
      (click)="setViewType('BY_GROUPING')"
    ></button>
  </ng-container>
  <button
    mat-stroked-button
    color="primary"
    class="margin"
    [ngClass]="{ selected: displayTime }"
    translate="UI.GROUP.EVENTS.SIGNUPS_LIST.SIGNUP_TIME_TOGGLE"
    (click)="toggleShowSignUpTime()"
  ></button>

  <ng-container *ngIf="viewType === 'ALL'">
    <ng-container *ngFor="let i of signUps; let ind = index">
      <ng-container
        *ngIf="i.isSignedUp || showAll"
        [ngTemplateOutlet]="signUpRowTemplate"
        [ngTemplateOutletContext]="{ member: i, index: ind }"
      ></ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="viewType === 'BY_GROUPING'">
    <ng-container *ngFor="let i of groupingSignUps">
      <h2>{{ i.grouping.name }}</h2>
      <ng-container *ngFor="let j of i.signUpData; let ind = index">
        <ng-container
          *ngIf="j.isSignedUp || showAll"
          [ngTemplateOutlet]="signUpRowTemplate"
          [ngTemplateOutletContext]="{ member: j, index: ind }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Old member signups -->
  <ng-container *ngIf="removedUsersSignUps.length > 0">
    <h2 translate="UI.GROUP.EVENTS.SIGNUPS_LIST.EXITED_MEMBERS_SIGNUPS"></h2>
    <div class="col-container" *ngFor="let i of removedUsersSignUps">
      <div class="col-xs-6 col-m-4">
        {{ getSignUpTime(i.signUpTime) }}
        <shared-loading-button
          *ngIf="canSignUpOthers"
          class="tiny"
          matType="mat-flat-button"
          key="COMMON_UI.KEY_WORD.DELETE"
          [cb]="removeSignUpOfRemovedMember(i.userId)"
        >
        </shared-loading-button>
      </div>
      <div class="col-xs-6 col-m-1">
        <ng-container
          [ngTemplateOutlet]="signUpStatus"
          [ngTemplateOutletContext]="{ status: i.status }"
        ></ng-container>
      </div>
      <div class="col-xs-12 col-m-7" *ngIf="i.message">
        {{ i.message }}
      </div>
    </div>
  </ng-container>

  <!-- External users signups -->
  <ng-container *ngIf="externalUsersSignUps.length > 0">
    <h2 translate="UI.GROUP.EVENTS.SIGNUPS_LIST.EXTERNAL_SIGNUPS"></h2>
    <div class="col-container" *ngFor="let i of externalUsersSignUps">
      <div class="col-xs-6 col-m-4">
        {{ i.name }}
        <shared-loading-button
          *ngIf="canSignUpOthers"
          class="tiny"
          matType="mat-flat-button"
          key="COMMON_UI.KEY_WORD.DELETE"
          [cb]="removeSignUpOfAdditional(i.userId)"
        >
          <!-- userid here is signup id-->
        </shared-loading-button>
        <div *ngIf="displayTime" class="small-text-under-name">
          {{ getSignUpTime(i.signUpTime) }}
        </div>
        <div class="small-text-under-name">
          {{ getSignedUpByText(i.signedUpById) }}
        </div>
      </div>
      <div class="col-xs-6 col-m-1">
        <ng-container
          [ngTemplateOutlet]="signUpStatus"
          [ngTemplateOutletContext]="{ status: i.status }"
        ></ng-container>
      </div>
      <div class="col-xs-12 col-m-7" *ngIf="i.message">
        {{ i.message }}
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Templates -->
<ng-template #signUpRowTemplate let-member="member" let-index="index">
  <div class="col-container" [ngClass]="{ alternate: index % 2 == 0 }">
    <div class="col-xs-6 col-m-4">
      <events-sign-up
        *ngIf="canSignUpOthers"
        kind="list"
        [user]="member"
        [event]="event"
      >
      </events-sign-up>
      <ng-container *ngIf="!canSignUpOthers">
        {{ member.displayName }}
      </ng-container>
      <div *ngIf="displayTime" class="small-text-under-name">
        {{ getSignUpTime(member.signUpTime) }}
      </div>
      <div
        *ngIf="member.signedUpById && member.signedUpById !== member.id"
        class="small-text-under-name"
      >
        {{ getSignedUpByText(member.signedUpById) }}
      </div>
    </div>
    <div class="col-xs-6 col-m-1" *ngIf="member.isSignedUp && member.status">
      <ng-container
        [ngTemplateOutlet]="signUpStatus"
        [ngTemplateOutletContext]="{ status: member.status }"
      ></ng-container>
    </div>
    <div class="col-xs-12 col-m-7" *ngIf="member.isSignedUp && member.message">
      {{ member.message }}
    </div>
  </div>
</ng-template>

<ng-template #signUpStatus let-status="status">
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="'YES'">
      <span class="yes" translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.YES"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'NO'">
      <span class="no" translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.NO"></span>
    </ng-container>
    <ng-container *ngSwitchCase="'DONTKNOW'">
      <span
        class="dontknow"
        translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.DONTKNOW"
      ></span>
    </ng-container>
  </ng-container>
</ng-template>

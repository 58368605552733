import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService } from "@core/services";

type Kind = "ADD" | "CALENDAR" | "PAGE";

@Component({
  templateUrl: "./events-route-after-added.component.html",
  styleUrls: ["./events-route-after-added.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsRouteAfterAddedComponent implements OnInit {
  constructor(private nav: NavigationService) {}

  ngOnInit(): void {}

  getLink(kind: Kind) {
    switch (kind) {
      case "ADD":
        return this.nav.getLink(ROUTE_MAP.PRI.GROUP.EVENTS.ADD);
      case "CALENDAR":
        return this.nav.getLink(ROUTE_MAP.PRI.GROUP.EVENTS.CALENDAR);
      case "PAGE":
        return this.nav.getLink(ROUTE_MAP.PRI.GROUP.EVENTS.VIEW);
    }
  }
}

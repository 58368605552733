import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { StatusEnum } from "@auto/dto.models";
import { UserService } from "@core/services/user.service";
import { UserProfileActivateSlaveFormFactory } from "@ff/user-profile-activate-slave.ff";
import { SharedFormEditorConfig } from "@shared/components";
import { BusyService } from "@shared/services/busy.service";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "user-activate-slave-dialog",
  templateUrl: "./user-activate-slave-dialog.component.html",
  styleUrls: ["./user-activate-slave-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserActivateSlaveDialogComponent implements OnInit {
  config!: SharedFormEditorConfig;

  private form!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<UserActivateSlaveDialogComponent>,
    private ff: UserProfileActivateSlaveFormFactory,
    private userService: UserService,
    private busyService: BusyService
  ) {}

  ngOnInit(): void {
    this.form = this.ff.createForm();
    this.config = this.ff.formGroupToConfig(
      this.form,
      () => this.activate(),
      () => this.onCancelClick()
    );
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  private async activate() {
    const busyId = this.busyService.show();
    const response = await firstValueFrom(
      this.userService.activateUser(this.form.value)
    );
    this.busyService.hide(busyId);
    if (response.status === StatusEnum.OK) {
      this.dialogRef.close(true);
    }
    return true;
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BasicUserDTO, GetUserDTO, GroupPermissions } from "@auto/dto.models";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { DatService } from "@core/services/dat.service";
import { TokenStore } from "@core/services/token.store";
import { SharedGroupSelectorDialogComponent } from "@shared/components";
import { CurrentGroupService } from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { NavigationService } from "../../services/navigation.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "core-right-side-nav",
  templateUrl: "./core-right-side-nav.component.html",
  styleUrls: ["./core-right-side-nav.component.scss"],
})
export class CoreRightSideNavComponent implements OnInit {
  @Output() close = new EventEmitter<void>();

  isGroupRestriction = false;
  isLoggedIn = false;
  user?: GetUserDTO;
  isAdmin = false;
  master?: BasicUserDTO;
  slaves: BasicUserDTO[] = [];

  constructor(
    private nav: NavigationService,
    private userService: UserService,
    private datService: DatService,
    private dialog: MatDialog,
    private currentGroupService: CurrentGroupService,
    private cdr: ChangeDetectorRef,
    private tokenStore: TokenStore,
    private busyService: BusyService
  ) {}

  ngOnInit() {
    this.isGroupRestriction = this.datService.isGroupRestriction;
    LifeCyclesUtil.sub([this, this.cdr], this.userService.isLoggedIn$, (is) => {
      this.isLoggedIn = is;
      this.user = this.userService.user;
      if (this.user) {
        this.isAdmin = (this.user.permissions || []).some((i) =>
          i.startsWith("SYS_ADMIN")
        );
      }
    });

    LifeCyclesUtil.sub([this, this.cdr], this.userService.user$, (user) => {
      this.master = user?.master;
      this.slaves = user?.slaves ?? [];
    });
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  me() {
    this.close.emit();
    this.nav.goto(ROUTE_MAP.PRI.USER_DETAILS.PROFILE);
  }

  login() {
    this.close.emit();
    this.nav.goto(ROUTE_MAP.PUB.LOGIN);
  }

  register() {
    this.close.emit();
    this.nav.goto(ROUTE_MAP.PUB.REGISTER);
  }

  logout(): void {
    this.userService.logout().then(() => {
      this.nav.goto(ROUTE_MAP.PUB.HOME);
      this.close.emit();
    });
  }

  admin() {
    this.close.emit();
    this.nav.goto(ROUTE_MAP.PRI.ADMIN.HOME);
  }

  async returnToMaster() {
    this.close.emit();
    await this.userService.returnToMaster();
    this.nav.goto(ROUTE_MAP.PRI.EVENTS);
  }

  newGroup() {
    this.close.emit();
    this.nav.goto(ROUTE_MAP.PRI.NEW_GROUP);
  }

  newEvent() {
    this.close.emit();
    const groups = this.userService.user!.groups.filter((i) =>
      i.permissions.includes(GroupPermissions.GRP_MANAGE_EVENT)
    );
    if (
      !this.currentGroupService.isGroupSelected ||
      groups
        .map((i) => i.groupId)
        .indexOf(this.currentGroupService.currentGroupId) === -1
    ) {
      const dialogRef = this.dialog.open(
        SharedGroupSelectorDialogComponent,
        {}
      );
      dialogRef.afterClosed().subscribe((groupId) => {
        if (groupId) {
          this.nav.goto(ROUTE_MAP.PRI.GROUP.EVENTS.ADD, { groupId });
        }
      });
    } else {
      this.nav.goto(ROUTE_MAP.PRI.GROUP.EVENTS.ADD);
    }
  }

  myGroups() {
    this.close.emit();
    this.nav.goto(ROUTE_MAP.PRI.USER_DETAILS.MY_GROUPS);
  }

  async selectUser(slave: BasicUserDTO) {
    await this.userService.selectSlave(slave);
    this.nav.goto(ROUTE_MAP.PRI.EVENTS);
    this.close.emit();
  }
}

<div *ngIf="isLoggedIn" class="user">
  <div class="line slave-accounts-title">
    <span
      class="text"
      translate="UI.VIEWS.SLIDING_MENU.LOGGED_IN_AS"
      [par]="{ givenName: user!.givenName, familyName: user!.familyName }"
    ></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-user"></mat-icon>
  </div>

  <div
    class="line line-hoverable hidden-from-m"
    (click)="newEvent()"
    *sharedUserPermission="$any('SYS_CREATE_EVENT')"
  >
    <span class="text" translate="COMMON_UI.KEY_WORD.NEW_EVENT"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-plus-square"></mat-icon>
  </div>

  <ng-container *ngIf="!isGroupRestriction">
    <div
      class="line line-hoverable hidden-from-l"
      (click)="newGroup()"
      *sharedUserPermission="$any('SYS_CREATE_GROUP')"
    >
      <span class="text" translate="COMMON_UI.KEY_WORD.NEW_GROUP"></span>
      <span class="global__flex-spacer"></span>
      <mat-icon class="icon" fontSet="fa" fontIcon="fa-plus-square"></mat-icon>
    </div>
  </ng-container>

  <div class="line line-hoverable" (click)="me()">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.MY_PROFILE"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-cogs"></mat-icon>
  </div>

  <div class="line line-hoverable" (click)="myGroups()">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.MY_GROUPS"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-users"></mat-icon>
  </div>

  <div class="line line-hoverable" (click)="admin()" *ngIf="isAdmin">
    <span class="text">Admin</span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-ambulance"></mat-icon>
  </div>

  <div
    class="line line-hoverable slave-accounts"
    (click)="returnToMaster()"
    *ngIf="master"
  >
    <span
      class="text"
      translate="UI.VIEWS.SLIDING_MENU.RETURN_TO_MASTER"
      [par]="{ givenName: master.givenName, familyName: master.familyName }"
    ></span>
    <span class="global__flex-spacer"></span>
    <mat-icon
      class="icon rotated"
      fontSet="fa"
      fontIcon="fa-level-up-alt"
    ></mat-icon>
  </div>

  <div class="line slave-accounts-title" *ngIf="slaves.length > 0">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.SLAVE_ACCOUNTS"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-users"></mat-icon>
  </div>

  <ng-container *ngFor="let u of slaves">
    <div
      class="line line-hoverable slave-accounts"
      *ngIf="slaves.length > 0"
      (click)="selectUser(u)"
    >
      <span class="text"> {{ u.givenName }} {{ u.familyName }} </span>
      <span class="global__flex-spacer"></span>
      <mat-icon class="icon" fontSet="fa" fontIcon="fa-user"></mat-icon>
    </div>
  </ng-container>

  <div class="line line-hoverable" data-t-id="logout" (click)="logout()">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.LOGOUT"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-sign-in-alt"></mat-icon>
  </div>
</div>

<div *ngIf="!isLoggedIn" class="user">
  <div (click)="register()" class="line line-hoverable">
    <span class="text" translate="COMMON_UI.KEY_WORD.REGISTER"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-angle-right"></mat-icon>
  </div>

  <div (click)="login()" class="line line-hoverable">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.LOGIN"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-angle-right"></mat-icon>
  </div>
</div>

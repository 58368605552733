import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  templateUrl: "./admin-route-main.component.html",
  styleUrls: ["./admin-route-main.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminRouteMainComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

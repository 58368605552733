<h1 mat-dialog-title [translate]="'UI.GROUP.EVENTS.SELECT_GROUP_TO_CREATE_EVENT_FOR'"></h1>
<div mat-dialog-content>
  <ng-container *ngFor="let i of groups">
    <button mat-flat-button color="primary" [mat-dialog-close]="i.groupId" class="margin">
      <ng-container *ngIf="i.groupType == 'PERSONAL'">
        <span [translate]="'UI.GROUP.EVENTS.PERSONAL_EVENT'"></span>
      </ng-container>
      <ng-container *ngIf="i.groupType != 'PERSONAL'">
        {{ i.groupName }}
      </ng-container>      
    </button>
    <br>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="secondary" [mat-dialog-close]="false">
    Cancel
  </button>
</div>
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { EventsService, ExtendedEventData } from "@events/services";
import { BusyService } from "@shared/services/busy.service";

@Component({
  selector: "events-sign-up-additional",
  templateUrl: "./events-sign-up-additional.component.html",
  styleUrls: ["./events-sign-up-additional.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSignUpAdditionalComponent implements OnInit {
  @Input() event!: ExtendedEventData;

  name = "";
  message = "";

  constructor(
    private service: EventsService,
    private busyService: BusyService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  async signUp() {
    this.busyService.show();
    await this.service.signUpAdditionalToEvent(
      this.event.groupId,
      this.event.id,
      this.name,
      this.message
    );
    this.name = "";
    this.message = "";
    this.cdr.detectChanges();
    this.busyService.hide();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { GetUserDTO } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { SharedFormEditorUtil } from "@shared/components";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorConfig,
} from "@shared/components/shared-form-editor/shared-form-editor.model";
import { ToasterService } from "@shared/services/toaster.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { filter, tap } from "rxjs/operators";
import { LANGUAGES, REGIONS } from "./languages.const";

const MIN_YEAR = new Date().getFullYear() - 110;
const MAX_YEAR = new Date().getFullYear();

@Component({
  templateUrl: "./user-route-profile.component.html",
  styleUrls: ["./user-route-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRouteProfileComponent implements OnInit, OnDestroy {
  config!: SharedFormEditorConfig;

  private form = this.fb.group({
    username: [{ value: "", disabled: true }],
    givenName: ["", Validators.required],
    familyName: ["", Validators.required],
    locale: ["", Validators.required],
    language: ["", Validators.required],
    birthYear: [
      null,
      Validators.compose([
        Validators.min(MIN_YEAR),
        Validators.max(MAX_YEAR),
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.required,
      ]),
    ],
  });

  private dict = this.translationService.dict();

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private translationService: TranslationService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
    private sharedFormEditorUtil: SharedFormEditorUtil
  ) {}

  ngOnInit() {
    this.observeUser();
    this.config = this.getConfig();
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.translationService.languageReady$.pipe(filter((i) => i)),
      () => {
        this.config = this.getConfig();
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private observeUser() {
    LifeCyclesUtil.sub(
      this,
      this.userService.user$,
      (user: GetUserDTO | undefined) => {
        if (user) {
          this.form.patchValue(user);
        }
      }
    );
  }

  private getConfig() {
    const config: SharedEnrichableFormEditorConfig = {
      form: this.form,
      formFieldClassList: ["full-width"],
      fields: [
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.USERNAME,
          formControlName: "username",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.GIVEN_NAME,
          formControlName: "givenName",
          required: true,
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.FAMILY_NAME,
          formControlName: "familyName",
          required: true,
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.BIRTH_YEAR,
          formControlName: "birthYear",
          required: true,
          error: {
            label: this.getBirthYearErrorLabel(),
          },
        },
        {
          type: "select",
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.LANGUAGE,
          formControlName: "language",
          options: LANGUAGES,
          required: true,
        },
        {
          type: "select",
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.LOCALE,
          formControlName: "locale",
          options: REGIONS,
          required: true,
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.SAVE,
          type: "button.loading",
          callback: this.getSaveCallback(),
        },
      ],
    };
    return this.sharedFormEditorUtil.enrichConfigToDefaults(config);
  }

  private getBirthYearErrorLabel() {
    return this.translationService.substitute(
      this.translationService.dict().COMMON_UI.KEY_WORD.BIRTH_YEAR_VALIDATION,
      {
        minYear: MIN_YEAR,
        maxYear: MAX_YEAR,
      }
    );
  }

  private getSaveCallback() {
    return () => {
      const data = Object.assign({}, this.form.value);
      delete data.username;
      data.birthYear = parseInt(data.birthYear);
      return this.userService.saveProfile(data).pipe(
        tap(() => {
          this.toasterService.showSuccess(this.dict.COMMON_UI.OK.SAVED);
        })
      );
    };
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "shared-loading",
  templateUrl: "./shared-loading.component.html",
  styleUrls: ["./shared-loading.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedLoadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

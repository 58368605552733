import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TRANSLATIONS } from "@auto/index";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";

import { FormFactoryBase } from "./form-factory.base";

@Injectable({
  providedIn: "root",
})
export class GroupMembersInviteFormFactory extends FormFactoryBase {
  readonly DIRTY_KEY = "GROUP_MEMBERS_INVITE_DIALOG_EDITOR";

  constructor(private fb: FormBuilder, private utils: SharedFormEditorUtil) {
    super();
  }

  createForm(): FormGroup {
    return this.fb.group({
      users: this.fb.array([this.getUserFormGroup()]),
    });
  }

  getUserFormGroup() {
    return this.fb.group({
      displayName: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.email])],
    });
  }

  formGroupToConfig(fg: FormGroup, remove?: () => void) {
    const classList = ["col-xs-5", "col-xs-5"];

    const config: SharedEnrichableFormEditorConfig = {
      dirtyStateTracker: this.DIRTY_KEY,
      form: fg,
      fields: [
        [
          {
            classList,
            labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.NAME,
            formControlName: "displayName",
          },
          {
            required: true,
            classList,
            labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.EMAIL,
            formControlName: "email",
          },
          remove
            ? {
                classList: ["col-xs-2"],
                type: "button.plain",
                enableAlways: true,
                callback: remove,
                labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.DELETE,
              }
            : (null as any),
        ].filter((i) => !!i),
      ],
    };
    return this.utils.enrichConfigToDefaults(config);
  }
}

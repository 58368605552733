<ng-container *ngIf="toggled">
  <shared-second-nav
    [externalActive]="true"
    [allowPartialFit]="false"
    [links]="tabLinks"
    class="tab-nav dropdown"
  >
  </shared-second-nav>

  <div [ngSwitch]="activeTabId">
    <events-list type="my" *ngSwitchCase="'LIST'"></events-list>
    <events-calendar type="my" *ngSwitchCase="'CALENDAR'"></events-calendar>
  </div>
</ng-container>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "@shared/shared.module";
import {
  UserRouteLoginComponent,
  UserRouteProfileComponent,
  UserRouteRegisterComponent,
  UserLoginOrRegisterComponent,
  UserRouteConfirmEmailComponent,
  UserSettingsMenuComponent,
  UserRouteMyEmailsComponent,
  UserRouteLinkedAccountsComponent,
  UserAddMasterDialogComponent,
  UserActivateSlaveDialogComponent,
} from "./components";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    UserRouteRegisterComponent,
    UserRouteLoginComponent,
    UserRouteProfileComponent,
    UserLoginOrRegisterComponent,
    UserRouteConfirmEmailComponent,
    UserSettingsMenuComponent,
    UserRouteMyEmailsComponent,
    UserRouteLinkedAccountsComponent,
    UserAddMasterDialogComponent,
    UserActivateSlaveDialogComponent,
  ],
  exports: [UserLoginOrRegisterComponent],
})
export class UserModule {
  constructor() {}
}

// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { subsToUrl } from "./subs-to-url.func";
import {
  StatusDTO,
  SignUpToEventRequestDTO,
  SignUpToAdditionalEventRequestDTO,
  SignUpToEventAsAdminRequestDTO,
} from "./dto.models";

@Injectable({
  providedIn: "root",
})
export class SignUpApiService {
  constructor(private http: HttpClient) {}

  signUpToEvent(
    groupId: string,
    eventId: string,
    dto: SignUpToEventRequestDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/sign-up/group/:groupId/event/:eventId", {
      groupId,
      eventId,
    });
    return this.http.post<StatusDTO>(url, dto);
  }

  signUpToEventAsAdmin(
    groupId: string,
    eventId: string,
    dto: SignUpToEventAsAdminRequestDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/sign-up/group/:groupId/event/:eventId/as-admin",
      { groupId, eventId }
    );
    return this.http.post<StatusDTO>(url, dto);
  }

  removeSignUpOfRemovedMember(
    groupId: string,
    eventId: string,
    userId: string
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/sign-up/group/:groupId/event/:eventId/sign-up/:userId/remove-of-removed-member",
      { groupId, eventId, userId }
    );
    return this.http.get<StatusDTO>(url);
  }

  signUpAdditionalToEvent(
    groupId: string,
    eventId: string,
    dto: SignUpToAdditionalEventRequestDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/sign-up/group/:groupId/event/:eventId/additional",
      { groupId, eventId }
    );
    return this.http.post<StatusDTO>(url, dto);
  }

  removeSignUpAdditional(
    groupId: string,
    signupId: string
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/sign-up/group/:groupId/singup-id/:signupId",
      { groupId, signupId }
    );
    return this.http.delete<StatusDTO>(url);
  }
}

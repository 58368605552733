import { Directive, Input, OnDestroy, ElementRef } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslationService } from "@core/services/translation.service";
import { TranslationKey } from "@auto/translations.models";
import { PipeTransform, Pipe } from "@angular/core";
import { filter } from "rxjs/operators";

@Pipe({ name: "translate" })
export class SharedTranslatePipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}
  transform(key: TranslationKey) {
    return this.translationService.fromString(key);
  }
}

@Directive({
  selector: "[translate]",
})
export class SharedTranslateDirective implements OnDestroy {
  private key: TranslationKey | undefined;
  @Input() set translate(value: TranslationKey) {
    this.key = value;
    this.setContent();
  }
  private params: { [key: string]: string | number | undefined } = {};
  @Input()
  set par(value: { [key: string]: string | number | undefined }) {
    this.params = value;
    this.setContent();
  }

  private sub: Subscription;

  constructor(
    private elementRef: ElementRef<any>,
    private translationService: TranslationService
  ) {
    this.sub = translationService.languageReady$
      .pipe(filter((i) => i))
      .subscribe((t) => this.setContent());
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setContent() {
    if (!this.key) {
      return;
    }
    const value = this.translationService.fromString(this.key, this.params);
    this.elementRef.nativeElement.innerHTML = value;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { DateEditorType, getTimeZones } from ".";
import { SharedDateAdapter } from "./shared-date.adapter";
import { DateEvent } from "./shared-date.models";

@Component({
  selector: "shared-date-time-picker",
  templateUrl: "./shared-date-time-picker.component.html",
  styleUrls: ["./shared-date-time-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: SharedDateAdapter,
    },
  ],
})
export class SharedDateTimePickerComponent implements OnInit {
  @Input() type!: DateEditorType;
  @Input() time!: FormControl;
  @Input() endTime?: FormControl;
  @Input() dateLabel?: string;

  startDate = new FormControl();
  startH = getTimeFormControl(23);
  startMin = getTimeFormControl(59);
  startTimeZone = new FormControl("");
  timeZoneOptions = getTimeZones();

  endDate = new FormControl();
  endH = getTimeFormControl(23);
  endMin = getTimeFormControl(59);

  showTimeZone = false;
  showEndDate = false;

  private duration = 0;

  differentEndDate = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initEmitters();
    this.patchValues();
    if (this.time.disabled) {
      this.startDate.disable();
      this.endDate.disable();
    }
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  changeTimeZoneToggleValue(value: MatSlideToggleChange) {
    this.showTimeZone = value.checked;
    this.cdr.detectChanges();
  }

  changeShowEndDateToggleValue(value: MatSlideToggleChange) {
    this.showEndDate = value.checked;
    this.cdr.detectChanges();
  }

  private initEmitters() {
    if (this.type == "date") {
      LifeCyclesUtil.sub(this, this.startDate.valueChanges, (newDate: Date) => {
        newDate.setHours(23);
        newDate.setMinutes(59);
        this.time.patchValue(newDate.getTime());
      });
      return;
    }
    LifeCyclesUtil.sub(this, this.startDate.valueChanges, (changes) => {
      this.autoEditEndOnStartChange();
      this.backComputeDates();
    });
    LifeCyclesUtil.sub(this, this.startH.valueChanges, (changes) => {
      this.autoEditEndOnStartChange();
      this.backComputeDates();
    });
    LifeCyclesUtil.sub(this, this.startMin.valueChanges, (changes) => {
      this.autoEditEndOnStartChange();
      this.backComputeDates();
    });
    LifeCyclesUtil.sub(this, this.endDate.valueChanges, (changes) => {
      this.resetDuration();
      this.backComputeDates();
    });
    LifeCyclesUtil.sub(this, this.endH.valueChanges, (changes) => {
      this.resetDuration();
      this.backComputeDates();
    });
    LifeCyclesUtil.sub(this, this.endMin.valueChanges, (changes) => {
      this.resetDuration();
      this.backComputeDates();
    });
    LifeCyclesUtil.sub(this, this.startTimeZone.valueChanges, (changes) => {
      this.autoEditEndOnStartChange();
      this.backComputeDates();
    });
  }

  private autoEditEndOnStartChange() {
    const ts = getTimeStamp(this.startDate, this.startH, this.startMin);
    if (ts > 0 && this.duration > 0) {
      const end = new Date(ts + this.duration);
      this.endDate.patchValue(end, { emitEvent: false });
      this.endH.patchValue(end.getHours(), { emitEvent: false });
      this.endMin.patchValue(end.getMinutes(), { emitEvent: true });
    }
  }

  private resetDuration() {
    const startTimestamp = getTimeStamp(
      this.startDate,
      this.startH,
      this.startMin
    );
    const endTimestamp = getTimeStamp(this.endDate, this.endH, this.endMin);
    if (startTimestamp > 0 && endTimestamp > 0) {
      this.duration = endTimestamp - startTimestamp;
    }
  }

  private patchValues() {
    let startTimestamp =
      this.type == "duration" ? this.time?.value?.startTime : this.time?.value;
    console.log(this.time?.value);
    let endTimestamp = this.time?.value?.endTime;
    let start = new Date();
    if (startTimestamp) {
      start = new Date(startTimestamp);
    } else {
      start = new Date(start.getTime() + 3600 * 1000);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      startTimestamp = start.getTime();
    }
    this.startDate.patchValue(start, { emitEvent: false });
    this.startH.patchValue(start.getHours(), { emitEvent: false });
    this.startMin.patchValue(start.getMinutes(), { emitEvent: false });
    if (this.type == "duration") {
      let end = new Date();
      if (endTimestamp) {
        end = new Date(endTimestamp);
      } else {
        end = new Date(start.getTime() + 3600 * 1000);
        end.setMinutes(0);
        end.setSeconds(0);
        end.setMilliseconds(0);
      }
      this.endDate.patchValue(end, { emitEvent: false });
      this.endH.patchValue(end.getHours(), { emitEvent: false });
      this.endMin.patchValue(end.getMinutes(), { emitEvent: false });
      endTimestamp = end.getTime();
    }
    this.startTimeZone.patchValue(this.time?.value?.startTimeZone);
    this.duration = endTimestamp - startTimestamp;
    const isSameDate = isDateSame(this.startDate, this.endDate);
    if (isSameDate !== undefined) {
      this.showEndDate = isSameDate;
    }
  }

  private backComputeDates() {
    let startTime = -1;
    let endTime = -1;
    let startDiff = 0;
    let endDiff = 0;
    if (this.startDate.valid && this.startH.valid && this.startMin.valid) {
      startTime = getTimeStamp(this.startDate, this.startH, this.startMin);
      const timeZoneTime = new Date(
        new Date(startTime).toLocaleString("en-US", {
          timeZone: this.startTimeZone.value,
        })
      ).getTime();
      startDiff = startTime - timeZoneTime;
    }
    if (
      ((this.differentEndDate && this.endDate.valid) ||
        (!this.differentEndDate && this.startDate.valid)) &&
      this.endH.valid &&
      this.endMin.valid
    ) {
      endTime = getTimeStamp(this.endDate, this.endH, this.endMin);
      const timeZoneTime = new Date(
        new Date(endTime).toLocaleString("en-US", {
          timeZone: this.startTimeZone.value,
        })
      ).getTime();
      endDiff = endTime - timeZoneTime;
    }
    if (this.type == "duration") {
      this.time.patchValue({
        startTime: startTime + startDiff,
        endTime: endTime + endDiff,
        startTimeZone: this.startTimeZone.value,
      });
    }
    this.differentEndDate = !!isDateSame(this.endDate, this.startDate);
  }
}

function getTimeStamp(date: FormControl, h: FormControl, min: FormControl) {
  let timeStamp = -1;
  if (date.valid && h.valid && min.valid) {
    const dateObj = new Date(date.value as Date);
    dateObj.setHours(h.value);
    dateObj.setMinutes(min.value);
    timeStamp = dateObj.getTime();
  }
  return timeStamp;
}

function getTimeFormControl(maxValue: number): FormControl {
  return new FormControl("", [
    Validators.required,
    Validators.min(0),
    Validators.max(maxValue),
    Validators.pattern("^\\d{1,2}$"),
  ]);
}

function isDateSame(a: FormControl, b: FormControl) {
  if (!a.valid || !b.valid) {
    return undefined;
  }
  const dateA = new Date(a.value as Date);
  const dateB = new Date(b.value as Date);
  return (
    dateA.getFullYear() !== dateB.getFullYear() ||
    dateA.getMonth() !== dateB.getMonth() ||
    dateA.getDate() !== dateB.getDate()
  );
}

export const LANGUAGES = [
  {
    value: "fi-FI",
    label: "Suomi",
  },
  {
    value: "en-US",
    label: "English",
  },
];

export const REGIONS = [
  {
    value: "fi-FI",
    label: "Suomi",
  },
  {
    value: "en-US",
    label: "English",
  },
  {
    value: "sv-SE",
    label: "Svenska",
  },
];

import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import {
  ITranslations,
  TranslationKey,
  Translations,
} from "@auto/translations.models";

@Injectable({ providedIn: "root" })
export class TranslationService {
  translations: { [key: string]: ITranslations } = {};
  currentLanguage!: string;

  get language() {
    return this.currentLanguage;
  }

  // Use this static to get typed translation keys to string
  static translationKeyMap: Translations | undefined = undefined;
  static get KM(): Translations {
    if (!this.translationKeyMap) {
      this.translationKeyMap = new Translations();
    }
    return this.translationKeyMap;
  }
  // When this has value false, don't allow resetting new locale, that will cause race condition
  languageReady$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  dict(): ITranslations {
    return this.translations[this.currentLanguage];
  }

  dictViaPromise(): Promise<ITranslations> {
    const dict = this.dict();
    if (dict) {
      return Promise.resolve(dict);
    }
    return firstValueFrom(
      this.languageReady$.pipe(
        filter((s) => s),
        take(1),
        map((_) => this.dict())
      )
    );
  }

  fromString(
    key: TranslationKey,
    params?: { [key: string]: string | number | undefined }
  ) {
    const dict = this.dict();
    if (!dict) {
      return "";
    }
    let val = this.findTranslation(key.split("."), dict);
    if (params) {
      val = this.substitute(val, params);
    }
    return val;
  }

  substitute(
    value: string,
    params: { [key: string]: string | number | undefined }
  ) {
    for (const p in params) {
      if (params.hasOwnProperty(p)) {
        value = value.replace(
          "{" + p + "}",
          params[p] !== undefined ? params[p]!.toString() : ""
        );
      }
    }
    return value;
  }

  fromStringViaPromise(
    key: TranslationKey,
    params?: { [key: string]: string | number }
  ) {
    const dict = this.dict();
    if (dict) {
      return Promise.resolve(this.fromString(key, params));
    }
    return firstValueFrom(
      this.languageReady$.pipe(
        filter((s) => s),
        take(1),
        map((_) => this.fromString(key, params))
      )
    );
  }

  private findTranslation(key: string[], obj: { [key: string]: any }) {
    const originalKey = key.join(".");
    const rec: any = (key: string[], obj: { [key: string]: any }) => {
      if (!obj) {
        return "";
      }
      if (key.length === 1) {
        return obj[key[0]];
      }
      const first = key.splice(0, 1)[0];
      return rec(key, obj[first]);
    };
    const translation = rec(key.slice(), obj);
    if (translation == "") {
      console.error("Translation not found for key " + originalKey);
    }
    return translation;
  }
}

import { Injectable } from "@angular/core";
import { ActivationEnd, ActivationStart, Router } from "@angular/router";
import { AdminApiService } from "@auto/admin-api.service";
import { ROUTE_MAP } from "@core/routing";
import { NavLink, NavigationService } from "@core/services";

@Injectable({ providedIn: "root" })
export class AdminService {
  constructor(
    private navService: NavigationService,
    private router: Router,
    private api: AdminApiService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.routeConfig?.path?.startsWith("pri/admin")) {
          this.addAdminSecondNav();
        } else {
          this.navService.clearSecondNav("ADMIN");
        }
      }
    });
  }

  private async addAdminSecondNav() {
    let nav: NavLink[] = [
      {
        url: ROUTE_MAP.PRI.ADMIN.HOME,
        name: "Main",
      },
      {
        url: ROUTE_MAP.PRI.ADMIN.ERRORS,
        name: "Errors",
      },
      {
        url: ROUTE_MAP.PRI.ADMIN.PERF,
        name: "Perf",
      },
      {
        url: ROUTE_MAP.PRI.ADMIN.FIND_USER,
        name: "Users",
      },
    ];
    this.navService.setSecondNav("ADMIN", nav);
  }

  getErrorLogs() {
    return this.api.getErrorLogs();
  }

  getPerfLogs() {
    return this.api.getPerfLogs();
  }
}

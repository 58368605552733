export const ROUTE_MAP = {
  PUB: {
    HOME: {
      PATH: "/pub/home",
    },
    LOGIN: {
      PATH: "/pub/login",
    },
    REGISTER: {
      PATH: "/pub/register",
    },
    LOGIN_OR_REGISTER: {
      PATH: "/pub/login-or-register",
    },
    ACCEPT_INVITATION: {
      PATH: "/pub/accept-invitation",
    },
    CONFIRM_EMAIL: {
      PATH: "/pub/confirm-email/:userId/:emailId",
    },
    NOT_FOUND: {
      PATH: "/pub/not-found",
    },
  },
  PRI: {
    ADMIN: {
      HOME: {
        PATH: "/pri/admin/home",
      },
      ERRORS: {
        PATH: "/pri/admin/errors",
      },
      PERF: {
        PATH: "/pri/admin/perf",
      },
      FIND_USER: {
        PATH: "/pri/admin/find-user",
      },
      MANAGE_USER: {
        PATH: "/pri/admin/manage-user/:userId",
      },
    },
    NEW_GROUP: {
      PATH: "/pri/new-group",
    },
    USER_DETAILS: {
      PROFILE: {
        PATH: "/pri/user/profile",
      },
      EMAILS: {
        PATH: "/pri/user/emails",
      },
      LINKED_ACCOUNTS: {
        PATH: "/pri/user/linked-accounts",
      },
      MY_GROUPS: {
        PATH: "/pri/user/my-groups",
      },
    },
    EVENTS: {
      PATH: "/pri/events",
    },
    GROUP: {
      MEMBERS: {
        PATH: "/pri/group/:groupId/members",
        GROUPINGS: {
          PATH: "/pri/group/:groupId/members/groupings/:groupingId",
        },
      },
      EVENTS: {
        CALENDAR: {
          PATH: "/pri/group/:groupId/events/calendar",
        },
        ADDED: {
          PATH: "/pri/group/:groupId/events/:eventId/added",
        },
        ADD: {
          PATH: "/pri/group/:groupId/events/add",
        },
        EDIT: {
          PATH: "/pri/group/:groupId/events/:eventId/edit",
        },
        VIEW: {
          PATH: "/pri/group/:groupId/events/:eventId/view",
        },
      },
      SETTINGS: {
        PATH: "/pri/group/:groupId/settings",
      },
    },
  },
};

<div>
  <user-settings-menu></user-settings-menu>
</div>

<mat-card-header>
  <div mat-card-avatar>
    <mat-icon fontSet="fa" fontIcon="fa-user"> </mat-icon>
  </div>
  <mat-card-title
    class="no-subtitle"
    translate="UI.VIEWS.SLIDING_MENU.MY_PROFILE"
  ></mat-card-title>
</mat-card-header>

<mat-card-content>
  <shared-form-editor [config]="config"></shared-form-editor>
</mat-card-content>

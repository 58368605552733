import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { TranslationKey } from "@auto/translations.models";
import { TranslationService } from "@core/services/translation.service";
import { SharedFormItemSelect } from ".";
import {
  SharedAnyFormItem,
  SharedEnrichableFormEditorConfig,
  SharedFormEditorConfig,
  SharedFormItemButton,
  FormItemDefaults,
  SharedFormItemError,
  FormItemTypeMap,
} from "./shared-form-editor.model";
@Injectable({
  providedIn: "root",
})
export class SharedFormEditorUtil {
  constructor(private tx: TranslationService) {}

  enrichConfigToDefaults(
    config: SharedEnrichableFormEditorConfig
  ): SharedFormEditorConfig {
    const conf = {
      form: config.form,
      formFieldClassList: config.formFieldClassList,
      dirtyStateTracker: config.dirtyStateTracker,
      fields: this.getRowArrays(config),
    };
    return conf as any;
  }

  getRowArrays(
    config: SharedEnrichableFormEditorConfig
  ): Array<Partial<SharedAnyFormItem[]>> {
    return (config.fields as Array<any>).map((i) => {
      return (Array.isArray(i) ? i : [i]).map((i) =>
        this.enrichField(i, config)
      );
    });
  }

  enrichField(
    item: Partial<SharedAnyFormItem>,
    config: SharedEnrichableFormEditorConfig
  ): SharedAnyFormItem {
    if (item.labelKey) {
      item.label = this.tx.fromString(item.labelKey);
    }
    if (item.error) {
      // Might be missing label, but added later
      item.errors = [item.error as SharedFormItemError];
    }
    if (item.formControlName) {
      item.formControl = config.form.controls[item.formControlName];
    }
    if ("classList" in config && !("classList" in item)) {
      item.classList = config.classList;
    }
    if ("options" in item) {
      item.options = (item! as SharedFormItemSelect).options.map((i) => {
        if (i.labelKey) {
          i.label = this.tx.fromString(i.labelKey);
        }
        return i;
      });
    }
    item.hasErrors = item.errors && item.errors?.length > 0;
    if (item.hasErrors) {
      item.errors = item.errors?.map((i: Partial<SharedFormItemError>) => {
        if (i.labelKey) {
          i.label = this.tx.fromString(item!.labelKey as TranslationKey);
        }
        if (!("when" in i)) {
          i.when = (fc: AbstractControl) => fc.invalid && fc.touched;
        }
        return i as SharedFormItemError;
      });
    }
    for (let key in FormItemDefaults) {
      if (!(key in item)) {
        (item as any)[key] = (FormItemDefaults as any)[key];
      }
    }
    item.isButtonItem = item?.type?.startsWith("button.");
    if (item.isButtonItem) {
      (item as SharedFormItemButton).enableAlways =
        (item as SharedFormItemButton).enableAlways || false;
      (item as SharedFormItemButton).confirm =
        (item as SharedFormItemButton).confirm || false;
    }
    item.isFormItem = item.type && item.type in FormItemTypeMap;
    return item as SharedAnyFormItem;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BasicUserDTO } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { UserService } from "@core/services/user.service";
import { GroupMembersService } from "@group/services";
import { ToasterService } from "@shared/services";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { firstValueFrom } from "rxjs";

export interface MembersAddFromChildrenDialogData {
  groupId: string;
}

interface ExtendedBasicUser extends BasicUserDTO {
  isAlreadyMember: boolean;
}

@Component({
  selector: "members-add-from-children-dialog",
  templateUrl: "./members-add-from-children-dialog.component.html",
  styleUrls: ["./members-add-from-children-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersAddFromChildrenDialogComponent implements OnInit {
  slaves?: ExtendedBasicUser[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MembersAddFromChildrenDialogData,
    private dialogRef: MatDialogRef<MembersAddFromChildrenDialogComponent>,
    private userService: UserService,
    private groupMembersService: GroupMembersService,
    private cdr: ChangeDetectorRef,
    private toaster: ToasterService
  ) {}

  async ngOnInit() {
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.groupMembersService.getMembersOfGroup(this.data.groupId),
      (members) => {
        const memberIds = new Set(members.map((i) => i.id));
        this.slaves = this.userService.user!.slaves!.map((i) => {
          return Object.assign({ isAlreadyMember: memberIds.has(i.id!) }, i);
        });
      }
    );
  }

  addUser(i: ExtendedBasicUser) {
    return async () => {
      await firstValueFrom(
        this.groupMembersService.addSlaveToGroup(this.data.groupId, i.id!)
      );
      this.toaster.showSuccessTranslationKey(TRANSLATIONS.COMMON_UI.OK.ADDED);
      return true;
    };
  }

  async cancel() {
    this.dialogRef.close(false);
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { NavigationService } from "@core/services/navigation.service";
import { UserService } from "@core/services/user.service";
import { filter } from "rxjs/operators";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";

@Component({
  templateUrl: "./user-route-register.component.html",
  styleUrls: ["./user-route-register.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRouteRegisterComponent implements OnInit {
  constructor(
    private userService: UserService,
    private navService: NavigationService
  ) {}

  ngOnInit(): void {
    LifeCyclesUtil.sub(
      this,
      this.userService.isLoggedIn$.pipe(filter((i) => i)),
      (i) => {
        this.navService.goto(ROUTE_MAP.PRI.EVENTS);
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  templateUrl: "./core-route-not-found.component.html",
  styleUrls: ["./core-route-not-found.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreRouteNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { SharedModule } from "@shared/shared.module";
import {
  CoreGroupSelectorComponent,
  CoreLeftSideNavComponent,
  CoreRouteNotFoundComponent,
  CoreRightSideNavComponent,
} from "./components";
import { CoreFooterComponent } from "./components/core-footer/core-footer.component";

@NgModule({
  declarations: [
    CoreRouteNotFoundComponent,
    CoreGroupSelectorComponent,
    CoreLeftSideNavComponent,
    CoreRightSideNavComponent,
    CoreFooterComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    SharedModule,
  ],
  exports: [
    CoreGroupSelectorComponent,
    CoreLeftSideNavComponent,
    CoreRightSideNavComponent,
    CoreFooterComponent,
  ],
})
export class CoreModule {
  constructor() {}
}

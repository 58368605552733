import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { Subject } from "rxjs";
import { SharedFormEditorConfig } from "../shared-form-editor";

export interface SharedFormDialogData<T> {
  title: string;
  help?: string;
  configGetter: (cancel: () => void) => SharedFormEditorConfig;
  close: Subject<T>;
}

@Component({
  templateUrl: "./shared-form-dialog.component.html",
  styleUrls: ["./shared-form-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedFormDialogComponent<T> implements OnInit {
  config!: SharedFormEditorConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SharedFormDialogData<T>,
    private dialogRef: MatDialogRef<SharedFormDialogComponent<T>>
  ) {}

  ngOnInit(): void {
    this.config = this.data.configGetter(() => this.onCancelClick());
    LifeCyclesUtil.sub(this, this.data.close, (data) =>
      this.dialogRef.close(data)
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }
}

import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TRANSLATIONS } from "@auto/index";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";

import { FF_DEFAULT_CLASS_LIST, FormFactoryBase } from "./form-factory.base";

@Injectable({
  providedIn: "root",
})
export class UserProfileAddMasterAccountFormFactory extends FormFactoryBase {
  constructor(private fb: FormBuilder, private utils: SharedFormEditorUtil) {
    super();
  }

  createForm(): FormGroup {
    return this.fb.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  formGroupToConfig(
    fg: FormGroup,
    save: () => Promise<any>,
    cancel: () => void
  ) {
    const config: SharedEnrichableFormEditorConfig = {
      form: fg,
      fields: [
        {
          classList: FF_DEFAULT_CLASS_LIST,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.USERNAME,
          formControlName: "username",
        },
        {
          classList: FF_DEFAULT_CLASS_LIST,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.PASSWORD,
          formControlName: "password",
          type: "password",
        },
        [
          {
            classList: ["col-auto-flex"],
            labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.ADD,
            type: "button.loading",
            callback: () => save(),
          },
          {
            classList: ["col-auto-flex"],
            labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.CANCEL,
            type: "button.plain",
            enableAlways: true,
            callback: () => cancel(),
          },
        ],
      ],
    };
    return this.utils.enrichConfigToDefaults(config);
  }
}

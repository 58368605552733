import { CalendarMonthViewComponent } from "angular-calendar";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CalendarType } from "../../events-calendar.component";
import { SMOOTH_HEIGHT } from "@shared/animations";
import { BasicEventDTO, EventDataDTO } from "@auto/dto.models";

@Component({
  animations: [SMOOTH_HEIGHT],
  selector: "month-view",
  template: `
    <div class="cal-month-view" role="grid">
      <div style="padding-left:27px" class="cal-cell-row cal-header" role="row">
        <div
          class="cal-cell"
          *ngFor="let day of columnHeaders"
          [class.cal-past]="day.isPast"
          [class.cal-today]="day.isToday"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          (click)="
            columnHeaderClicked.emit({
              isoDayNumber: day.day,
              sourceEvent: $event
            })
          "
          [ngClass]="day.cssClass!"
          tabindex="0"
          role="columnheader"
        >
          {{ day.date | calendarDate: "monthViewColumnHeader":locale }}
        </div>
      </div>
      <div class="cal-days">
        <div
          *ngFor="let rowIndex of view.rowOffsets; trackBy: trackByRowOffset"
        >
          <div role="row" class="cal-cell-row">
            <div
              style="border-right: 1px solid #ccc; 
                padding: 4px; 
                font-size: 14px; 
                font-weight: bold; 
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-width:18px;
                text-align:center"
            >
              {{ getWeek(view.days[rowIndex].date) }}
            </div>
            <mwl-calendar-month-cell
              role="gridcell"
              *ngFor="
                let day of view.days
                  | slice: rowIndex:rowIndex + view.totalDaysVisibleInWeek;
                trackBy: trackByDate
              "
              [class]="day?.cssClass"
              [day]="day"
              [openDay]="openDay"
              [locale]="locale"
              [tooltipPlacement]="tooltipPlacement"
              [tooltipAppendToBody]="tooltipAppendToBody"
              [tooltipTemplate]="tooltipTemplate"
              [tooltipDelay]="tooltipDelay"
              [customTemplate]="cellTemplate"
              [ngStyle]="{
                opacity: day.inMonth && !day.isPast ? 1 : 0.3
              }"
              (mwlClick)="dayClicked.emit({ day: day, sourceEvent: $event })"
              [clickListenerDisabled]="dayClicked.observers.length === 0"
              (mwlKeydownEnter)="
                dayClicked.emit({ day: day, sourceEvent: $event })
              "
              (highlightDay)="toggleDayHighlight($event.event, true)"
              (unhighlightDay)="toggleDayHighlight($event.event, false)"
              mwlDroppable
              dragOverClass="cal-drag-over"
              (drop)="
                eventDropped(
                  day,
                  $event.dropData.event,
                  $event.dropData.draggedFrom
                )
              "
              (eventClicked)="
                eventClicked.emit({
                  event: $event.event,
                  sourceEvent: $event.sourceEvent
                })
              "
              [attr.tabindex]="{} | calendarA11y: 'monthCellTabIndex'"
            >
            </mwl-calendar-month-cell>
          </div>
          <div [sharedSmoothHeightAnimation]="openRowIndex === rowIndex">
            <div *ngIf="openRowIndex === rowIndex">
              <div
                *ngFor="let i of $any(openDay)?.events"
                style="padding-top: 8px; border-bottom: 1px solid var(--color-lightgrey);padding-left:8px;background-color:#fafafa"
              >
                <events-list-item
                  [eventId]="i.id"
                  [groupId]="i.meta"
                  [type]="type"
                  [thin]="true"
                  (eventDeleted)="eventDeletedPassthrough($event)"
                  (eventEdited)="eventEditedPassthrough($event)"
                ></events-list-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .current-day {
        background-color: var(--calendar-background) !important;
        &:hover {
          background-color: #ededed !important;
        }
      }
    `,
  ],
})
export class IlmoCalendarMonthViewComponent extends CalendarMonthViewComponent {
  @Input() type!: CalendarType;
  @Output() eventDeleted: EventEmitter<EventDataDTO> = new EventEmitter();
  @Output() eventEdited: EventEmitter<BasicEventDTO> = new EventEmitter();

  eventDeletedPassthrough(event: EventDataDTO) {
    this.eventDeleted.emit(event);
  }

  eventEditedPassthrough(event: BasicEventDTO) {
    this.eventEdited.emit(event);
  }

  getWeek(date: Date) {
    const dowOffset = 1;
    const newYear = new Date(date.getFullYear(), 0, 1);
    let day = newYear.getDay() - dowOffset;
    day = day >= 0 ? day : day + 7;
    const daynum =
      Math.floor(
        (date.getTime() -
          newYear.getTime() -
          (date.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
          86400000
      ) + 1;
    let weeknum: number;
    if (day < 4) {
      weeknum = Math.floor((daynum + day - 1) / 7) + 1;
      if (weeknum > 52) {
        const nYear = new Date(date.getFullYear() + 1, 0, 1);
        let nday = nYear.getDay() - dowOffset;
        nday = nday >= 0 ? nday : nday + 7;
        weeknum = nday < 4 ? 1 : 53;
      }
    } else {
      weeknum = Math.floor((daynum + day - 1) / 7);
    }
    return weeknum;
  }
}

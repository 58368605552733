import { Injectable } from "@angular/core";
import { AdminApiService } from "@auto/admin-api.service";
import { GetUserDTO, NewUserDTO, RoleTypeEnum } from "@auto/dto.models";

@Injectable({ providedIn: "root" })
export class AdminUserService {
  constructor(private api: AdminApiService) {}

  addUser(dto: NewUserDTO) {
    return this.api.createUserAsAdmin(dto);
  }

  getUser(id: string) {
    return this.api.getUserAsAdmin(id);
  }

  searchGroups(namePrefix: string) {
    return this.api.searchGroups({ name: namePrefix });
  }

  addUserToGroup(userId: string, groupId: string, roleType: RoleTypeEnum) {
    return this.api.addUserToGroup(userId, groupId, roleType);
  }
}

<button
  mat-button
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
  [matMenuTriggerFor]="menu"
  class="icon-button"
>
  <mat-icon fontSet="fa" fontIcon="fa-cog"> </mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngIf="isOpen">
    <button
      mat-menu-item
      (click)="edit()"
      translate="COMMON_UI.KEY_WORD.MODIFY"
    ></button>

    <!-- Missing actions -->

    <!-- Delete -->

    <!-- If series: delete all forthcoming -->

    <!-- If series: delete all forthcoming from this -->

    <!-- Copy (open editor and use this as template, show possibility to change group) -->

    <!-- Move (deletes this event from this group (only single event) and create new event to another group, removes all grouping limitations and if not global event type, selects some)-->
  </ng-container>
</mat-menu>

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { BasicUserDTO, GetUserDTO } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { TokenStore } from "@core/services/token.store";
import { UserService } from "@core/services/user.service";
import { UserProfileCreateChildAccountFormFactory } from "@ff/user-profile-create-child-account.ff";
import { SharedFormEditorConfig } from "@shared/components";
import { SharedFormDialogComponent } from "@shared/components/shared-form-dialog/shared-form-dialog.component";
import { ToasterService } from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { firstValueFrom, Subject } from "rxjs";
import { UserActivateSlaveDialogComponent } from "../user-activate-slave-dialog/user-activate-slave-dialog.component";
import { UserAddMasterDialogComponent } from "../user-add-master-dialog/user-add-master-dialog.component";

@Component({
  templateUrl: "./user-route-linked-accounts.component.html",
  styleUrls: ["./user-route-linked-accounts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRouteLinkedAccountsComponent implements OnInit {
  isActivated = true;
  slaves?: BasicUserDTO[];
  hasMaster = false;
  masters?: BasicUserDTO[];
  master?: BasicUserDTO;
  user?: GetUserDTO;

  constructor(
    private userService: UserService,
    private tokenStore: TokenStore,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private busyService: BusyService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    LifeCyclesUtil.sub([this, this.cdr], this.userService.user$, (user) => {
      this.hasMaster = !!user?.master;
      this.master = user?.master;
      this.slaves = user?.slaves;
      this.masters = user?.masters;
      this.user = user;
      this.isActivated = !!user?.username;
    });
  }

  async pickUser(u: BasicUserDTO) {
    this.tokenStore.setSlaveUserId(u.id!);
    await this.userService.refresh();
  }

  async returnToMaster() {
    this.tokenStore.clearSlaveUserId();
    await this.userService.refresh();
  }

  addMasterAccount() {
    this.dialog.open(UserAddMasterDialogComponent, {
      disableClose: true,
    });
  }

  activateAccount() {
    this.dialog.open(UserActivateSlaveDialogComponent, {
      disableClose: true,
    });
  }

  removeMasterAccount(u: BasicUserDTO) {
    return async () => {
      const busyId = this.busyService.show();
      await firstValueFrom(this.userService.removeMaster(u.id!));
      this.busyService.hide(busyId);
      this.toaster.showSuccessTranslationKey(
        TRANSLATIONS.UI.USER.LINKED_ACCOUNTS.MASTERS.SUCCESS_REMOVE
      );
      return true;
    };
  }

  createNewSlave() {
    this.userService.createSlaveAccount();
  }
}

<h1 mat-dialog-title translate="UI.GROUP.MEMBERS.INVITE.TITLE"></h1>

<div mat-dialog-content>
  <ng-container *ngFor="let i of configs">
    <shared-form-editor [config]="i"></shared-form-editor>
  </ng-container>
</div>

<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    (click)="add()"
    translate="COMMON_UI.KEY_WORD.ADD"
  ></button>
  <button
    mat-flat-button
    color="primary"
    (click)="close()"
    translate="COMMON_UI.KEY_WORD.CLOSE"
  ></button>
  <div class="last">
    <button
      mat-flat-button
      [disabled]="form.invalid"
      color="primary"
      (click)="invite()"
      translate="UI.GROUP.MEMBERS.INVITE.ACTION"
    ></button>
  </div>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { NavigationService } from "@core/services/navigation.service";
import { TranslationService } from "@core/services/translation.service";
import { UserService } from "@core/services/user.service";
import { GroupMembersService } from "@group/services/group-members.service";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/index";
import { ToasterService } from "@shared/services";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { filter } from "rxjs";

@Component({
  templateUrl: "./user-route-login.component.html",
  styleUrls: ["./user-route-login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRouteLoginComponent implements OnInit {
  config!: SharedFormEditorConfig;

  constructor(
    private navService: NavigationService,
    private userService: UserService,
    private fb: FormBuilder,
    private translationService: TranslationService,
    private toasterService: ToasterService,
    private groupMemberService: GroupMembersService,
    private sharedFormEditorUtil: SharedFormEditorUtil
  ) {}

  ngOnInit(): void {
    LifeCyclesUtil.sub(
      this,
      this.userService.isLoggedIn$.pipe(filter((i) => i)),
      (i) => {
        // If there is user
        this.navService.goto(ROUTE_MAP.PRI.EVENTS);
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { GroupMemberDTO, SingleSignUpDTO } from "@auto/dto.models";
import { EventsService, ExtendedEventData } from "@events/services";
import { canISignUpToEventWithYes } from "@events/services/events.utils";
import { BusyService } from "@shared/services/busy.service";
import { firstValueFrom } from "rxjs";

type SignUpKind = "dropdown" | "block" | "list";

@Component({
  selector: "events-sign-up",
  templateUrl: "./events-sign-up.component.html",
  styleUrls: ["./events-sign-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSignUpComponent implements OnInit {
  @Input() event!: ExtendedEventData;
  @Input() currentStatus?: string;
  @Input() kind: SignUpKind = "dropdown";
  @Input() user?: Partial<SingleSignUpDTO> &
    GroupMemberDTO & {
      isSignedUp: boolean;
    };
  @Input() customButtonContent?: string;
  message = "";
  isYesDisabled = false;
  canSignUp = true;

  isOpen = false;
  currentSignUpStatus = "";

  constructor(
    private service: EventsService,
    private busyService: BusyService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.ngOnChanges();
  }

  ngOnChanges(changes?: SimpleChanges) {
    if (this.event) {
      this.currentSignUpStatus = this.event.mySignUpStatus?.status ?? "??";
      this.message = this.event.mySignUpStatus?.message ?? "";
      this.isYesDisabled =
        this.kind !== "list" && !canISignUpToEventWithYes(this.event);
      this.cdr.detectChanges();
      // Here, one needs to consider also grouping limitations
      this.canSignUp = this.kind === "list" || this.event.maxSignUp !== 0;
    }
  }

  async signUp(status: string) {
    this.busyService.show();

    if (this.kind === "list") {
      await this.service.signOtherMemberUpToEvent(
        this.event.groupId,
        this.event.id,
        this.user!.id,
        status,
        this.message
      );
    } else {
      await this.service.signUpToEvent(
        this.event.groupId,
        this.event.id,
        status,
        this.message
      );
    }
    this.busyService.hide();
  }

  menuOpened() {
    this.isOpen = true;
    this.cdr.detectChanges();
    if (this.kind === "list") {
      this.message = this.user?.message || "";
    }
  }

  menuClosed() {
    this.isOpen = false;
    this.cdr.detectChanges();
  }
}

import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TRANSLATIONS } from "@auto/index";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";
import { GroupMemberDTO } from "@auto/dto.models";

import { FormFactoryBase } from "./form-factory.base";
import { DirtyStateTrackerService } from "@shared/services";
import { UserService } from "@core/services/user.service";

@Injectable({
  providedIn: "root",
})
export class GroupMemberFormFactory extends FormFactoryBase {
  readonly DIRTY_KEY = "GROUP_MEMBER_EDITOR";

  constructor(
    private fb: FormBuilder,
    private utils: SharedFormEditorUtil,
    private userService: UserService,
    private dst: DirtyStateTrackerService
  ) {
    super();
  }

  createForm(member: GroupMemberDTO): FormGroup {
    const form = this.fb.group({
      displayName: ["", Validators.required],
      roleType: ["", Validators.required],
    });
    if (member.id === this.userService.user!.id) {
      form.controls["roleType"].disable();
    }
    form.patchValue(member);
    return form;
  }

  formGroupToConfig(fg: FormGroup, cancel: () => void, save: () => void) {
    const classList = ["col-xs-12"];

    const config: SharedEnrichableFormEditorConfig = {
      dirtyStateTracker: this.DIRTY_KEY,
      form: fg,
      fields: [
        {
          classList,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.NAME,
          formControlName: "displayName",
        },
        {
          classList,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.ROLE,
          formControlName: "roleType",
          type: "select",
          options: [
            {
              value: "ADMIN",
              labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.ADMIN,
            },
            {
              value: "MEMBER",
              labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.MEMBER,
            },
          ],
        },
        [
          {
            classList: ["col-auto-flex"],
            labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.CANCEL,
            type: "button.plain",
            enableAlways: true,
            callback: async () => {
              const allowClose = await this.dst.confirmNotDirty();
              if (allowClose) {
                cancel();
              }
            },
          },
          {
            classList: ["col-auto-flex-last-right"],
            labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.SAVE,
            type: "button.loading",
            callback: () => save(),
          },
        ],
      ],
    };
    return this.utils.enrichConfigToDefaults(config);
  }
}

import { AdminUserService } from "@admin/services";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { GetUserDTO, NameAndIdDTO, RoleTypeEnum } from "@auto/dto.models";
import { NavigationService } from "@core/services";
import { ToasterService } from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { filter, firstValueFrom, map, take, tap } from "rxjs";

@Component({
  selector: "admin-route-manage-user",
  templateUrl: "./admin-route-manage-user.component.html",
  styleUrls: ["./admin-route-manage-user.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminRouteManageUserComponent implements OnInit {
  user?: GetUserDTO;
  groups: NameAndIdDTO[] = [];
  searchForm = this.fb.group({
    name: ["", Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private adminUserService: AdminUserService,
    private cdr: ChangeDetectorRef,
    private busy: BusyService,
    private nav: NavigationService,
    private tostar: ToasterService
  ) {}

  ngOnInit() {
    this.nav.params$
      .pipe(
        filter((i) => "userId" in i),
        take(1),
        map((i) => i["userId"])
      )
      .subscribe(async (userId: string) => {
        this.busy.show();
        this.user = await firstValueFrom(this.adminUserService.getUser(userId));
        this.busy.hide();
        this.cdr.detectChanges();
      });
  }

  searchCallback() {
    const myGroups = this.user!.groups.map((i) => i.groupId).reduce(
      (obj, val) => {
        obj[val] = true;
        return obj;
      },
      {} as { [key: string]: true }
    );
    return () => {
      this.busy.show();
      return this.adminUserService
        .searchGroups(this.searchForm.controls["name"].value)
        .pipe(
          tap((i) => {
            this.busy.hide();
            const groups = i.collection.filter((i) => !(i.id in myGroups));
            if (groups.length === 0) {
              this.tostar.showError("No groups found");
            }
            this.groups = groups.sort((a, b) => {
              return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()
                ? -1
                : 1;
            });
            this.cdr.detectChanges();
          })
        );
    };
  }

  async addUserToGroup(group: NameAndIdDTO, isAdmin: boolean) {
    this.busy.show();
    this.user = await firstValueFrom(
      this.adminUserService.addUserToGroup(
        this.user!.id,
        group.id,
        isAdmin ? RoleTypeEnum.ADMIN : RoleTypeEnum.MEMBER
      )
    );
    this.busy.hide();
    this.cdr.detectChanges();
  }
}

<ng-container *ngIf="currentGroup">
  <shared-second-nav
    [allowPartialFit]="false"
    [externalActive]="true"
    [links]="tabLinks"
    class="tab-nav dropdown"
  >
  </shared-second-nav>

  <ng-container *ngIf="groupChangeToggle">
    <div [ngSwitch]="activeTabId">
      <events-list
        [groupId]="currentGroup"
        type="group"
        *ngSwitchCase="'LIST'"
      ></events-list>
      <events-calendar
        [groupId]="currentGroup"
        type="group"
        *ngSwitchCase="'CALENDAR'"
      ></events-calendar>
    </div>
  </ng-container>
</ng-container>

// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { subsToUrl } from "./subs-to-url.func";
import { NamesAndIdsDTO, NameDTO } from "./dto.models";

@Injectable({
  providedIn: "root",
})
export class GroupJoinApiService {
  constructor(private http: HttpClient) {}

  searchGroups(dto: NameDTO): Observable<NamesAndIdsDTO> {
    const url = subsToUrl("/api/v1/pri/group-join/search", {});
    return this.http.post<NamesAndIdsDTO>(url, dto);
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Inject,
} from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TRANSLATIONS } from "@auto/translations.models";
import { GroupMembersInviteFormFactory } from "@ff/group-members-invite.ff";
import { GroupMembersService } from "@group/services";
import { SharedFormEditorConfig } from "@shared/components";
import { DirtyStateTrackerService, ToasterService } from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { firstValueFrom } from "rxjs";

export interface MembersInviteDialogData {
  groupId: string;
}

@Component({
  templateUrl: "./members-invite-dialog.component.html",
  styleUrls: ["./members-invite-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersInviteDialogComponent implements OnInit {
  form!: FormGroup;
  configs: SharedFormEditorConfig[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MembersInviteDialogData,
    private dialogRef: MatDialogRef<MembersInviteDialogComponent>,
    private groupMembersService: GroupMembersService,
    private cdr: ChangeDetectorRef,
    private ff: GroupMembersInviteFormFactory,
    private toaster: ToasterService,
    private busyService: BusyService,
    private dst: DirtyStateTrackerService
  ) {}

  ngOnInit(): void {
    this.form = this.ff.createForm();
    this.computeFormGroups();
  }

  add() {
    (this.form.controls["users"] as FormArray).push(this.ff.getUserFormGroup());
    this.computeFormGroups();
    this.cdr.detectChanges();
  }

  private computeFormGroups() {
    this.configs = (this.form.controls["users"] as FormArray).controls.map(
      (i, index) => {
        const remove =
          index === 0
            ? undefined
            : () => {
                (this.form.controls["users"] as FormArray).removeAt(index);
                this.computeFormGroups();
                this.cdr.detectChanges();
              };
        return this.ff.formGroupToConfig(i as FormGroup, remove);
      }
    );
  }

  async close() {
    const allowClose = await this.dst.confirmNotDirty();
    if (allowClose) {
      this.dialogRef.close(true);
    }
  }

  async invite() {
    const busyId = this.busyService.show();
    const members = (this.form.controls["users"] as FormArray).controls.map(
      (i) => {
        return {
          displayName: (i as FormGroup).controls["displayName"].value,
          email: (i as FormGroup).controls["email"].value,
        };
      }
    );
    await firstValueFrom(this.groupMembersService.inviteGroupMembers(members));
    this.dst.removeDirty(this.ff.DIRTY_KEY);
    this.busyService.hide(busyId);
    this.toaster.showSuccessTranslationKey(TRANSLATIONS.COMMON_UI.OK.EXECUTED);
    this.dialogRef.close(true);
  }
}

<h1>Performance</h1>
<shared-loading *ngIf="!logs"></shared-loading>

<ng-container *ngIf="logs">
  <div class="col-container" *ngFor="let i of logs">
    <div class="col-xs-2">
      <events-datetime-view [start]="i.created * 1000"></events-datetime-view>
    </div>
    <div class="col-xs-4">
      {{ i.routePattern }}
    </div>
    <div class="col-xs-3">{{ duration(i) }} ms</div>
    <div class="col-xs-1">
      <button
        mat-flat-button
        color="primary"
        (click)="viewBreakdown(i)"
        class="tiny"
      >
        View
      </button>
    </div>
  </div>
</ng-container>

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  BasicEventDTO,
  EventDataDTO,
  GroupPermissions,
} from "@auto/dto.models";
import { EventApiService } from "@auto/event-api.service";
import { UserService } from "@core/services/user.service";
import { SharedGroupSelectorDialogComponent } from "@shared/components";
import {
  ConfirmActionService,
  CurrentGroupService,
  RegionalFormattingService,
  ToasterService,
} from "@shared/services";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
} from "angular-calendar";
import { firstValueFrom, Subject } from "rxjs";
import { EventsAddNewEventDialogComponent } from "../events-add-new-event-dialog/events-add-new-event-dialog.component";
import {
  startOfMonth,
  endOfMonth,
  isSameMonth,
  isSameDay,
  startOfWeek,
  endOfWeek,
  isPast,
} from "date-fns";
import { TranslationService } from "@core/services";
import { EventsService } from "@events/services";
import { TRANSLATIONS } from "@auto/translations.models";
import { MatMenuTrigger } from "@angular/material/menu";
import {
  EventsDeleteDialogComponent,
  DeleteEventType,
} from "../events-delete-dialog/events-delete-dialog.component";

export type CalendarType = "group" | "my" | "family";

@Component({
  selector: "events-calendar",
  templateUrl: "./events-calendar.component.html",
  styleUrls: ["./events-calendar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsCalendarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;
  @Input() groupId!: string;
  @Input() type!: CalendarType;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  activeDayIsOpen: boolean = false;

  locale = "en";

  width!: number;
  height!: number;

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];
  eventData: EventDataDTO[] = [];
  texts = this.getTexts();

  // Do this via store once there are methods for that
  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private dialog: MatDialog,
    private currentGroupService: CurrentGroupService,
    private eventApiService: EventApiService,
    private confirmActionService: ConfirmActionService,
    private regionalFormatterService: RegionalFormattingService,
    private translationService: TranslationService,
    private eventsService: EventsService,
    private toasterService: ToasterService
  ) {
    this.locale = this.regionalFormatterService.getLocale();
  }

  ngOnInit(): void {
    this.userService.user$.subscribe((user) => {
      this.fetchEvents().then(() => {});
      if (user && user.language) {
        this.locale = user.language.split("-")[0];
      }
    });

    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }

  async fetchEvents() {
    // TODO cache these
    if (this.groupId) {
      const result = await firstValueFrom(
        this.eventApiService.getComingEventsOfGroupByTime(
          this.groupId,
          startOfWeek(startOfMonth(this.viewDate), {
            weekStartsOn: 1,
          }).getTime(),
          endOfWeek(endOfMonth(this.viewDate), { weekStartsOn: 1 }).getTime()
        )
      );
      this.events = result.collection.map<CalendarEvent>((e) => {
        const converted: CalendarEvent = {
          title: e.name,
          start: new Date(e.startTime),
          end: new Date(e.endTime),
          id: e.id,
          meta: e.groupId,
          color: {
            primary: "#b1b1b1",
            secondary: "var(--calendar-background)",
          },
        };
        return converted;
      });
      this.eventData = result.collection;
      this.eventsService.setEvents(result.collection);
      this.cdr.detectChanges();
    } else {
      let evs: CalendarEvent[] = [];
      let eventDatas: EventDataDTO[] = [];
      setTimeout(async () => {
        const groups = this.userService.user!.groups.filter(
          (i) => i && i.permissions.includes(GroupPermissions.GRP_MANAGE_EVENT)
        );
        for (const group of groups) {
          const result = await firstValueFrom(
            this.eventApiService.getComingEventsOfGroupByTime(
              group.groupId,
              startOfMonth(this.viewDate).getTime(),
              endOfMonth(this.viewDate).getTime()
            )
          );
          evs = evs.concat(
            result.collection.map<CalendarEvent>((e) => {
              const converted: CalendarEvent = {
                title: e.name,
                start: new Date(e.startTime),
                end: new Date(e.endTime),
                id: e.id,
                meta: e.groupId,
                color: {
                  primary: "var(--toolbar-background-color)",
                  secondary: "var(--calendar-background)",
                },
              };
              return converted;
            })
          );
          eventDatas = eventDatas.concat(result.collection);
        }
        this.eventData = eventDatas;
        this.eventsService.setEvents(eventDatas);
        this.events = evs;
        this.cdr.detectChanges();
      });
    }
  }

  getEventDataDTO(event: CalendarEvent): EventDataDTO {
    const ev = this.eventData.filter((e) => e.id === event.id)[0];
    return ev;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (
      this.dialog.openDialogs.length === 0 &&
      isSameMonth(date, this.viewDate) /* &&
      !isPast(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          23,
          59,
          59
        )
      )*/
    ) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
  }

  addNewEvent(startDate: Date) {
    if (
      this.view === CalendarView.Month &&
      !isSameMonth(startDate, this.viewDate)
    ) {
      return;
    }
    const groups = this.userService.user!.groups.filter((i) =>
      i.permissions.includes(GroupPermissions.GRP_MANAGE_EVENT)
    );
    if (
      !this.currentGroupService.isGroupSelected ||
      groups
        .map((i) => i.groupId)
        .indexOf(this.currentGroupService.currentGroupId) === -1
    ) {
      const selectGroupdialogRef = this.dialog.open(
        SharedGroupSelectorDialogComponent,
        {}
      );
      selectGroupdialogRef.afterClosed().subscribe((groupId) => {
        if (groupId) {
          this.openAddNewEventDialog(groupId, startDate);
        }
      });
    } else {
      this.openAddNewEventDialog(
        this.currentGroupService.currentGroupId,
        startDate
      );
    }
  }

  formatWeekDate(date: Date, locale: string): string {
    return new Intl.DateTimeFormat(locale, {
      day: "numeric",
      month: "numeric",
    }).format(date);
  }

  openAddNewEventDialog(groupId: string, startDate: Date) {
    const addNewEventdialogRef = this.dialog.open(
      EventsAddNewEventDialogComponent,
      {
        data: { groupId, startDate: startDate },
      }
    );

    addNewEventdialogRef
      .afterClosed()
      .subscribe((response: BasicEventDTO[]) => {
        if (!response) {
          return;
        }
        this.events = [
          ...this.events,
          ...response.map((event) => {
            return {
              id: event.id,
              title: event.name,
              start: new Date(event.startTime),
              end: new Date(event.endTime),
              meta: event.groupId,
              color: {
                primary: "var(--toolbar-background-color)",
                secondary: "primary",
              },
            };
          }),
        ];
        this.eventData = [...this.eventData, ...response];
        this.eventsService.setEvents([...this.eventData, ...response]);
        this.cdr.detectChanges();
      });
  }

  eventDeleted(event: EventDataDTO | undefined) {
    this.toasterService.showSuccessTranslationKey(
      TRANSLATIONS.UI.GROUP.EVENTS.EVENT_DELETED
    );
    if (!event) {
      this.fetchEvents();
    } else {
      this.events = this.events.filter((ev) => ev.id !== event.id);
      if (this.getEventsForDay(this.viewDate).length === 0) {
        this.activeDayIsOpen = false;
      }
      this.eventData = this.eventData.filter((ev) => ev.id !== event.id);
      this.eventsService.setEvents(this.eventData);
      this.cdr.detectChanges();
    }
  }

  eventEdited(event: BasicEventDTO) {
    // TODO something
    console.log(event);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  async monthChanged() {
    await this.fetchEvents();
  }

  isMobileView(): boolean {
    // TODO better logic for this
    return this.width < 1024;
  }

  getEventsForDay(date: Date): EventDataDTO[] {
    const eventIds = this.events
      .filter((e) => {
        const day = new Date(date.toDateString());
        const start = !e.start ? undefined : new Date(e.start.toDateString());
        const end = !e.end ? undefined : new Date(e.end.toDateString());

        return (
          (day >= (start as Date) && day <= (end as Date)) ||
          (day >= (start as Date) && !end) ||
          (!start && day <= (end as Date))
        );
      })
      .map((e) => e.id);

    return this.eventData.filter((e) => eventIds.includes(e.id));
  }

  handleEvent(a: any, b: any) {}

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      signedUp: dict.UI.GROUP.EVENTS.SIGNED_UP,
      deleteConfirmation: dict.UI.GROUP.EVENTS.DELETE_EVENT_CONFIRMATION,
      yes: dict.COMMON_UI.KEY_WORD.YES,
      no: dict.COMMON_UI.KEY_WORD.NO,
    };
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    const currentDay = new Date();
    renderEvent.body.forEach((day) => {
      if (
        day.date.getDate() === currentDay.getDate() &&
        day.date.getMonth() === currentDay.getMonth()
      ) {
        day.cssClass = "current-day";
      }
    });
  }

  hasManagePermission(groupId: string): boolean {
    return (
      this.userService.user!.groups.filter(
        (g) =>
          g &&
          g.groupId === groupId &&
          g.permissions.includes(GroupPermissions.GRP_MANAGE_EVENT)
      ).length > 0
    );
  }

  // TODO commonize with events-list-item.component
  editEvent(event: EventDataDTO) {
    const dialogRef = this.dialog.open(EventsAddNewEventDialogComponent, {
      data: { groupId: event.groupId, eventId: event.id },
    });

    dialogRef.afterClosed().subscribe((response: BasicEventDTO[]) => {
      this.eventEdited(response[0]);
    });
  }

  async deleteEvent(event: EventDataDTO) {
    if (event.seriesId) {
      const dialogRef = this.dialog.open(EventsDeleteDialogComponent, {
        data: { date: new Date(event.startTime) },
      });

      var result: number = await firstValueFrom(dialogRef.afterClosed());
      if (result === -1) {
        return;
      }

      if (result === DeleteEventType.ThisEventOnly) {
        await firstValueFrom(
          this.eventApiService.deleteEvent(event.groupId, event.id)
        );

        this.eventDeleted(event);
        return;
      }

      if (result === DeleteEventType.AllEvents) {
        await firstValueFrom(
          this.eventApiService.deleteEvents(
            event.groupId,
            event.id,
            event.seriesId,
            Date.now().toString()
          )
        );
        this.eventDeleted(undefined);
      } else if (result === DeleteEventType.AllEventsFromDay) {
        await firstValueFrom(
          this.eventApiService.deleteEvents(
            event.groupId,
            event.id,
            event.seriesId,
            new Date(event.startTime).getTime().toString()
          )
        );
        this.eventDeleted(undefined);
      }
    } else {
      if (
        await this.confirmActionService.confirm({
          texts: {
            cancel: this.texts.no,
            confirm: this.texts.yes,
            title: this.texts.deleteConfirmation,
          },
        })
      ) {
        await firstValueFrom(
          this.eventApiService.deleteEvent(event.groupId, event.id)
        );
        this.eventDeleted(event);
      }
    }
  }
}

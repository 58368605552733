<div *ngIf="kind == 'block'" class="col-container">
  <div class="col-xs-12 col-s-6 col-m-300">
    <mat-form-field class="full-width">
      <mat-label translate="UI.GROUP.EVENTS.SIGNUP.MESSAGE"></mat-label>
      <input
        (click)="$event.stopPropagation()"
        type="text"
        matInput
        sharedMatInputAutofocus
        [(ngModel)]="message"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-auto-flex button-container">
    <button
      mat-flat-button
      [ngClass]="{
        yes: currentStatus == 'YES' || !currentStatus
      }"
      (click)="signUp('YES')"
      translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.YES"
      [disabled]="isYesDisabled"
    ></button>
  </div>
  <div class="col-xs-auto-flex button-container">
    <button
      mat-flat-button
      [ngClass]="{
        dontknow: currentStatus == 'DONTKNOW' || !currentStatus
      }"
      (click)="signUp('DONTKNOW')"
      translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.DONTKNOW"
    ></button>
  </div>
  <div class="col-xs-auto-flex button-container">
    <button
      mat-flat-button
      [ngClass]="{
        no: currentStatus == 'NO' || !currentStatus
      }"
      (click)="signUp('NO')"
      translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.NO"
    ></button>
  </div>
</div>

<ng-container *ngIf="kind == 'dropdown'">
  <ng-container *ngIf="!canSignUp">
    <button mat-button class="dontknow" class="visible-status-button">
      <ng-container [ngSwitch]="currentSignUpStatus">
        <mat-icon fontSet="fa" fontIcon="fa-check" *ngSwitchCase="'YES'">
        </mat-icon>
        <mat-icon fontSet="fa" fontIcon="fa-ban" *ngSwitchCase="'NO'">
        </mat-icon>
        <mat-icon
          fontSet="fa"
          fontIcon="fa-question"
          *ngSwitchCase="'DONTKNOW'"
        >
        </mat-icon>
        <mat-icon fontSet="fa" fontIcon="fa-minus-circle" *ngSwitchDefault>
        </mat-icon>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngIf="canSignUp">
    <button
      *ngIf="!customButtonContent"
      mat-button
      [ngClass]="{
        yes: currentStatus == 'YES',
        dontknow: currentStatus == 'DONTKNOW',
        no: currentStatus == 'NO'
      }"
      (menuOpened)="menuOpened()"
      (menuClosed)="menuClosed()"
      [matMenuTriggerFor]="menu"
      class="visible-status-button"
    >
      <ng-container [ngSwitch]="currentSignUpStatus">
        <mat-icon fontSet="fa" fontIcon="fa-check" *ngSwitchCase="'YES'">
        </mat-icon>
        <mat-icon fontSet="fa" fontIcon="fa-ban" *ngSwitchCase="'NO'">
        </mat-icon>
        <mat-icon
          fontSet="fa"
          fontIcon="fa-question"
          *ngSwitchCase="'DONTKNOW'"
        >
        </mat-icon>
        <mat-icon fontSet="fa" fontIcon="fa-chevron-down" *ngSwitchDefault>
        </mat-icon>
      </ng-container>
    </button>
    <button
      class="mat-menu-item"
      *ngIf="customButtonContent"
      (menuOpened)="menuOpened()"
      (menuClosed)="menuClosed()"
      [matMenuTriggerFor]="menu"
    >
      {{ customButtonContent }}
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngTemplateOutlet="dropdown"></ng-container>
    </mat-menu>
  </ng-container>
</ng-container>

<ng-container *ngIf="kind == 'list'">
  <a
    class="change-status"
    [routerLink]=""
    [matMenuTriggerFor]="menu"
    (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()"
    >{{ user?.displayName }}</a
  >
  <mat-menu #menu="matMenu">
    <ng-container *ngTemplateOutlet="dropdown"></ng-container>
  </mat-menu>
</ng-container>

<ng-template #dropdown>
  <ng-container *ngIf="isOpen">
    <mat-form-field>
      <mat-label translate="UI.GROUP.EVENTS.SIGNUP.MESSAGE"></mat-label>
      <input
        (click)="$event.stopPropagation()"
        type="text"
        matInput
        sharedMatInputAutofocus
        [(ngModel)]="message"
      />
    </mat-form-field>
    <button
      [disabled]="isYesDisabled"
      mat-menu-item
      class="yes"
      (click)="signUp('YES')"
      translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.YES"
    ></button>
    <button
      mat-menu-item
      class="dontknow"
      (click)="signUp('DONTKNOW')"
      translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.DONTKNOW"
    ></button>
    <button
      mat-menu-item
      class="no"
      (click)="signUp('NO')"
      translate="UI.GROUP.EVENTS.SIGNUP.STATUSES.NO"
    ></button>
  </ng-container>
</ng-template>

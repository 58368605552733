import { AbstractControl, FormGroup } from "@angular/forms";
import { TranslationKey } from "@auto/translations.models";
import { Observable } from "rxjs";

export interface SharedEnrichableFormEditorConfig {
  formFieldClassList?: string[];
  classList?: string[];
  dirtyStateTracker?: string;
  form: FormGroup;
  fields:
    | Array<Partial<SharedAnyFormItem>[]>
    | Partial<SharedAnyFormItem | Partial<SharedAnyFormItem>[]>[];
}

// This includes those standard values that are wrapped to field container
export const FormItemTypeMap = {
  text: true,
  password: true,
  textarea: true,
  select: true,
  number: true,
};

export type SharedFormItemButtonType = "button.loading" | "button.plain";

export type FormItemType =
  | "text"
  | "password"
  | "textarea"
  | "slideToggle"
  | "select"
  | "number"
  | "duration"
  | "date"
  | "richtext"
  | "checkbox"
  | SharedFormItemButtonType;

export const FormItemDefaults = {
  type: "text" as FormItemType,
  classList: ["col-xs-12", "col-sm-6", "col-m-4", "col-l-3"],
  disabled: false,
  required: false,
};

export interface SharedFormItemError {
  when: (control: AbstractControl) => boolean;
  label: string;
  labelKey?: string;
}

export interface SharedFormItemDurationConfig {}

export interface SharedFormItem {
  // Configurable
  label: string;
  id?: string;
  formControl: AbstractControl;
  type: FormItemType;
  classList: string[];
  required: boolean;
  errors?: SharedFormItemError[];
  // Computable, use enrichConfigToDefaults to compute
  hasErrors: boolean;
  isFormItem: boolean;
  isButtonItem: boolean;
  showIf?: () => boolean;
  // Items from which to enrich
  labelKey?: TranslationKey;
  error?: Partial<SharedFormItemError>;
  formControlName?: string;
  config?: SharedFormItemDurationConfig;
}

export interface SharedFormItemButton extends SharedFormItem {
  type: SharedFormItemButtonType;
  enableAlways?: boolean;
  confirm?: boolean;
  callback: () => Promise<any> | Observable<any> | boolean | void;
}

export interface SharedFormItemOption {
  value: any;
  label?: string;
  labelKey?: TranslationKey;
  color?: string;
  backgroundColor?: string;
}

export interface SharedFormItemSelect extends SharedFormItem {
  type: "select";
  options: SharedFormItemOption[];
}

export type SharedAnyFormItem = SharedFormItem | SharedFormItemButton | SharedFormItemSelect;

export interface SharedFormEditorConfig {
  dirtyStateTracker?: string; // Key for tracking dirty state
  formFieldClassList?: string[]; //
  form: FormGroup;
  fields: Array<SharedAnyFormItem[]>;
}

import { ExtendedEventData } from ".";

export function canISignUpToEventWithYes(event: ExtendedEventData): boolean {
  if (
    event.maxSignUp !== undefined &&
    event.maxSignUp > -1 &&
    event.maxSignUp <= (event as any).signUpStatusCounts["YES"]
  ) {
    return event.mySignUpStatus?.status === "YES" ? true : false;
  }
  return true;
}

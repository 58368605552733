import { Injectable } from "@angular/core";
import { GetUserDTO } from "@auto/dto.models";
import { BehaviorSubject } from "rxjs";

const LOCAL_STORAGE_KEY = "ilmo-token";
const LOCAL_STORAGE_SLAVE_ID_KEY = "SLAVE_ID";

@Injectable({ providedIn: "root" })
export class TokenStore {
  user$ = new BehaviorSubject<GetUserDTO | undefined>(undefined);
  private slaveUserId_?: string;
  private token_: string | undefined = undefined;

  get slaveUserId() {
    return this.slaveUserId_;
  }

  get token() {
    return this.token_;
  }

  constructor() {
    const currentToken = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (currentToken) {
      this.token_ = currentToken;
    }
    this.slaveUserId_ =
      localStorage.getItem(LOCAL_STORAGE_SLAVE_ID_KEY) || undefined;
  }

  setSlaveUserId(id: string) {
    localStorage.setItem(LOCAL_STORAGE_SLAVE_ID_KEY, id);
    this.slaveUserId_ = id;
  }

  clearSlaveUserId() {
    this.slaveUserId_ = undefined;
    localStorage.removeItem(LOCAL_STORAGE_SLAVE_ID_KEY);
  }

  set(userId: string, sessionId: string) {
    const token = userId + "," + sessionId;
    localStorage.setItem(LOCAL_STORAGE_KEY, token);
    this.token_ = token;
  }

  clear() {
    this.token_ = undefined;
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }
}

import { Injectable } from "@angular/core";
import { GroupApiService } from "@auto/index";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { NavigationService } from "@core/services/navigation.service";
import { UserService } from "@core/services/user.service";
import { tap } from "rxjs/operators";
import { GroupJoinApiService } from "@auto/group-join-api.service";

@Injectable({
  providedIn: "root",
})
export class NewGroupService {
  constructor(
    private navService: NavigationService,
    private userService: UserService,
    private groupApiService: GroupApiService,
    private joinService: GroupJoinApiService
  ) {}

  createGroup(name: string) {
    // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    return this.groupApiService
      .createGroup({
        name,
      })
      .pipe(
        tap(async (group) => {
          await this.userService.refresh();
          this.navService.goto(ROUTE_MAP.PRI.GROUP.EVENTS.CALENDAR, {
            groupId: group.id,
          });
        })
      );
  }

  searchGroups(name: string) {
    return this.joinService.searchGroups({ name });
  }
}

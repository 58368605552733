import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import { TranslationService } from "@core/services";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RegionalFormattingService } from "@shared/services";

export interface DeleteEventsDialogData {
  date: Date;
}

export enum DeleteEventType {
  ThisEventOnly = 0,
  AllEvents = 1,
  AllEventsFromDay = 2,
}

@Component({
  templateUrl: "./events-delete-dialog.component.html",
  styleUrls: ["./events-delete-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsDeleteDialogComponent implements OnInit {
  selection: string = "0";
  texts: any;
  data: DeleteEventsDialogData;

  constructor(
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) data: DeleteEventsDialogData,
    private dialogRef: MatDialogRef<EventsDeleteDialogComponent>,
    private regionalFormatter: RegionalFormattingService
  ) {
    this.data = data;
    this.texts = this.getTexts();
  }

  ngOnInit(): void {}

  closeDialog(cancel: boolean) {
    if (cancel) {
      this.dialogRef.close(-1);
    } else {
      this.dialogRef.close(parseInt(this.selection));
    }
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      title: dict.UI.GROUP.EVENTS.DELETE_EVENTS_DIALOG_TITLE,
      thisEventOnly: dict.UI.GROUP.EVENTS.DELETE_EVENTS_THIS_EVENT_ONLY,
      allEvents: dict.UI.GROUP.EVENTS.DELETE_EVENTS_ALL_EVENTS,
      allEventsFromDay:
        dict.UI.GROUP.EVENTS.DELETE_EVENTS_ALL_EVENTS_FROM_DAY.replace(
          "{date}",
          this.regionalFormatter.getDate(this.data.date)
        ),
      cancel: dict.COMMON_UI.KEY_WORD.CANCEL,
      delete: dict.COMMON_UI.KEY_WORD.DELETE,
    };
  }
}

import { AdminService } from "@admin/services/admin.service";
import { isNgTemplate } from "@angular/compiler";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { PerfLogChildDTO, PerfLogDTO } from "@auto/dto.models";
import { firstValueFrom } from "rxjs";

@Component({
  templateUrl: "./admin-route-perf.component.html",
  styleUrls: ["./admin-route-perf.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminRoutePerfComponent implements OnInit {
  logs?: PerfLogDTO[];
  constructor(private service: AdminService, private cdr: ChangeDetectorRef) {}

  async ngOnInit() {
    this.logs = (await firstValueFrom(this.service.getPerfLogs())).collection;
    this.cdr.detectChanges();
  }

  viewBreakdown(i: PerfLogDTO) {
    const w = window.open("");
    console.log(i);
    const content =
      `Function: ${i.func}\n` +
      `Route: ${i.route}\n` +
      `Route pattern: ${i.routePattern}\n` +
      "Duration: " +
      this.duration(i) +
      " ms\n\n" +
      "Breakdown\n\n" +
      i.children.map((j) => this.formatBreakdown(j, 0)).join("");
    (w as any).document.write(`<pre>${content}</pre>`);
  }

  duration(i: PerfLogDTO) {
    return Math.round((i.end - i.start) * 1000);
  }

  formatBreakdown(i: PerfLogChildDTO, indent: number = 0) {
    let s = "";
    while (s.length < indent) s = " " + s;
    let str = s + i.func + ": " + this.duration(i as any) + " ms\n";
    return (i.children || []).reduce((tot, j) => {
      tot += this.formatBreakdown(j, indent + 2);
      return tot;
    }, str);
  }
}

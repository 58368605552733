import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TokenStore } from "@core/services/token.store";
import { ToasterService } from "@shared/services";
import { Observable, of, Subject } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../environments/environment";
import { TRANSLATIONS, TranslationKey } from "./auto/translations.models";
import { ErrorEnum } from "./auto/dto.models";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private tokenStore: TokenStore,
    private toaster: ToasterService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenStore.token;

    let overwrite: { [key: string]: any } = {};

    if (token) {
      overwrite["headers"] = request.headers.set(
        "Authorization",
        "Token " + token
      );
      if (this.tokenStore.slaveUserId) {
        overwrite["headers"] = overwrite["headers"].set(
          "slave-user-id",
          this.tokenStore.slaveUserId
        );
      }
    }

    if (
      document.location.host.indexOf("ilmo.team") > -1 &&
      request.url.startsWith("/api/v1")
    ) {
      const domain = document.location.host;
      //const domain = document.location.host.replace("www", "api");
      overwrite["url"] = "https://" + domain + request.url;
      overwrite["urlWithParams"] = "https://" + domain + request.urlWithParams;
    }

    request = request.clone(overwrite);

    return next.handle(request).pipe(
      catchError((err, caught) => {
        // Perhaps handle errors here
        if (err?.error?.detail == "UNAUTHORIZED") {
          localStorage.clear();
        }
        this.errorToaster(err?.error?.detail);
        return of(err);
      }),
      map((event: any) => {
        if (
          event.headers &&
          event.headers.has("x-ilmo-version") &&
          event.headers.get("x-ilmo-version") !== environment.version
        ) {
          console.log("UI and backend not in sync. TODO: Force reload.");
        }
        return event;
      })
    );
  }

  private errorToaster(detail: ErrorEnum) {
    if (detail in TRANSLATIONS.UI.AUTOMATIC_ERRORS) {
      this.toaster.httpErrorStream$.next(detail);
      this.toaster.showErrorTranslationKey(
        (TRANSLATIONS.UI.AUTOMATIC_ERRORS as any)[detail] as TranslationKey
      );
    }
  }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { assert } from "../utils/assert.func";
import { CurrentGroupService } from "./current-group.service";

@Injectable({ providedIn: "root" })
export class CanActivateWithCurrentGroup implements CanActivate {
  constructor(private service: CurrentGroupService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const value = this.service.currentGroup$.value;
    assert(route.params?.["groupId"]);
    const groupId = route.params["groupId"];
    if (!value || value.id !== groupId) {
      return this.service.currentGroup$.pipe(
        filter((t) => !!t && t.id === groupId),
        map((t) => true)
      );
    }
    return true;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { FullGroupDTO } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services";
import { NavLink } from "@core/services/navigation.service";
import { CurrentGroupService } from "@shared/services";
import { ControlStateService } from "@shared/services/control-state.service";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";

type Tabs = "LIST" | "CALENDAR";
const TAB_CS_KEY = "EVENTS_VIEW_TYPE_GROUP";

@Component({
  templateUrl: "./group-route-events.component.html",
  styleUrls: ["./group-route-events.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupRouteEventsComponent implements OnInit {
  currentGroup?: string;
  activeTabId!: Tabs;
  tabLinks!: NavLink[];
  groupChangeToggle = true;

  constructor(
    private currentGroupService: CurrentGroupService,
    private cdr: ChangeDetectorRef,
    private cs: ControlStateService,
    private tx: TranslationService
  ) {}

  ngOnInit(): void {
    LifeCyclesUtil.sub<false | FullGroupDTO>(
      [this, this.cdr],
      this.currentGroupService.currentGroup$ as any,
      (i) => {
        let doesChange = true;
        if (i) {
          doesChange = this.currentGroup !== i.id;
          this.currentGroup = i.id;
        } else {
          this.currentGroup = undefined;
        }
        if (doesChange) {
          this.groupChangeToggle = false;
          this.cdr.detectChanges();
          this.groupChangeToggle = true;
        }
      }
    );
    this.activeTabId = this.cs.get(TAB_CS_KEY, "LIST");
    this.tabLinks = this.getTabLinks();
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private clickTab(link: NavLink) {
    this.activeTabId = link.id as Tabs;
    this.tabLinks = this.getTabLinks();
    this.cs.set(TAB_CS_KEY, link.id);
    this.cdr.detectChanges();
  }

  private getTabLinks() {
    let links: NavLink[] = [
      {
        name: this.tx.fromString(TRANSLATIONS.UI.GROUP.EVENTS.VIEW_TYPE_LIST),
        id: "LIST",
        isActive: this.activeTabId == "LIST",
        url: (i) => this.clickTab(i),
      },
      {
        name: this.tx.fromString(
          TRANSLATIONS.UI.GROUP.EVENTS.VIEW_TYPE_CALENDAR
        ),
        id: "CALENDAR",
        isActive: this.activeTabId == "CALENDAR",
        url: (i) => this.clickTab(i),
      },
    ];
    return links;
  }
}

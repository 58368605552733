// This file is automatically generated by create_translations.py. Don't edit this file.

export interface ITranslations {
  COMMON_SERVER: {
    CONFIRMATION_EMAIL: {
      BUTTON_TEXT: string;
      CONTENT: string;
      SUBJECT: string;
      TEXT_CONTENT: string;
    };
    INVITATION_EMAIL: {
      CONFIRM: string;
      HEADER_TEXT: string;
      INVITATION_TEXT: string;
      SUBJECT: string;
      TEXT_CONTENT: string;
    };
    MAILING_LIST: {
      SUBSCRIBE_CONFIRMATION: string;
      SUBSCRIBE_ERROR_EMAIL_NOT_FOUND: string;
      SUBSCRIBE_TITLE: string;
      UNSUBSCRIBE_CONFIRMATION: string;
    };
    MAILING_LIST_JOIN_EMAIL: {
      TEXT1: string;
      TEXT2: string;
    };
    MAILING_LIST_MESSAGE_EMAIL: {
      UNSUBSCRIBE: string;
    };
  };
  COMMON_UI: {
    CONFIRM_ACTION: {
      TEXT: string;
      TITLE: string;
    };
    ERROR: {
      ACTION_FAILED: string;
      LOGIN: string;
      UNKNOWN: string;
    };
    FEATURE: {
      WEB_PORTAL: string;
      WEB_PORTAL_DESCRIPTION: string;
      WEB_PORTAL_MAILING_LIST: string;
      WEB_PORTAL_MAILING_LIST_DESCRIPTION: string;
    };
    KEY_WORD: {
      ACTIVATE: string;
      ADD: string;
      ADD_NEW: string;
      ADD_OR_REMOVE: string;
      ADMIN: string;
      ALL_GROUPS: string;
      BIRTH_YEAR: string;
      BIRTH_YEAR_VALIDATION: string;
      CANCEL: string;
      CLOSE: string;
      COLLAPSE_ALL: string;
      COLOR: string;
      CONFIRM: string;
      CONFIRM_ACTION: string;
      CONFIRM_ACTION_TEXT: string;
      DATA_SHOW_END_DATE: string;
      DATE_END: string;
      DATE_H_SHORT: string;
      DATE_MIN_SHORT: string;
      DATE_SHOW_TIMEZONE: string;
      DATE_START: string;
      DATE_TIMEZONE: string;
      DAY: string;
      DEACTIVATE: string;
      DELETE: string;
      DESCRIPTION: string;
      DISCARD: string;
      EMAIL: string;
      EMAIL_REPEAT: string;
      ERROR: string;
      EXPAND_ALL: string;
      FAMILY_NAME: string;
      GIVEN_NAME: string;
      JOIN: string;
      JOINED: string;
      LANGUAGE: string;
      LOCALE: string;
      LOGIN: string;
      MEMBER: string;
      MODIFY: string;
      MONTH: string;
      NAME: string;
      NEW_EVENT: string;
      NEW_GROUP: string;
      NO: string;
      ORDER: string;
      PASSWORD: string;
      PREVIEW: string;
      REGISTER: string;
      REGISTERED: string;
      ROLE: string;
      SAVE: string;
      SEARCH: string;
      SELECT: string;
      SEND: string;
      SET_AS_DEFAULT: string;
      SIGN_UP: string;
      SUBSCRIBE: string;
      USERNAME: string;
      WEEK: string;
      YES: string;
    };
    OK: {
      ADDED: string;
      DELETED: string;
      EXECUTED: string;
      SAVED: string;
    };
    PERMISSIONS: {
      SEARCH_USER: string;
    };
    UNSAVED_CHANGES: {
      KEEP_EDITING: string;
      STOP_EDITING: string;
      TEXT: string;
      TITLE: string;
    };
  };
  UI: {
    AUTOMATIC_ERRORS: {
      ALREADY_MEMBER: string;
      CREATE_USER_USERNAME_EXISTS: string;
    };
    GROUP: {
      EVENTS: {
        AFTER_ADDED: {
          CALENDAR: string;
          CONTINUE_TO: string;
          EVENTS_PAGE: string;
        };
        CREATE_EVENT_TITLE: string;
        CREATING_EVENT_FOR_GROUP: string;
        CREATING_EVENT_JUST_FOR_YOU: string;
        DELETE_EVENTS_ALL_EVENTS: string;
        DELETE_EVENTS_ALL_EVENTS_FROM_DAY: string;
        DELETE_EVENTS_DIALOG_TITLE: string;
        DELETE_EVENTS_THIS_EVENT_ONLY: string;
        DELETE_EVENT_CONFIRMATION: string;
        DESCRIPTION: string;
        ERRORS: {
          RECURRING: {
            SELECT_DAYS: string;
          };
        };
        EVENT_ADDED_OK: string;
        EVENT_DELETED: string;
        EVENT_LIST_SUBTITLE: string;
        EVENT_LIST_TITLE: string;
        EVENT_MODIFIED_OK: string;
        EVENT_NAME: string;
        EVENT_TYPE: string;
        GLOBAL_EVENT_TYPES: {
          MATCH: string;
          OTHER: string;
          PAYMENT: string;
          SINGLE: string;
          WEEKLY: string;
        };
        IS_RECURRING: string;
        LOCATION: string;
        LOCATION_ADDRESS: string;
        LOCATION_SUFFIX: string;
        MESSAGES: {
          EVENT_CANT_BE_SIGNED: string;
          EVENT_IS_FULL: string;
        };
        MODIFY_EVENT_FOR_GROUP: string;
        MODIFY_EVENT_JUST_FOR_YOU: string;
        MODIFY_EVENT_SEPARATE_FROM_SERIES: string;
        MODIFY_EVENT_SERIES_INFO: string;
        MODIFY_EVENT_TITLE: string;
        PERSONAL_EVENT: string;
        RECURRING_LAST_EVENT_AT: string;
        RECURRING_N_TH: string;
        RECURRING_TYPE: {
          ANNUALLY: string;
          DAYS_OF_WEEK: string;
          DAY_OF_MONTH: string;
          DAY_OF_MONTH_FROM_END: string;
          EVERY_N_TH_DAY: string;
          N_TH_OCCURENCE_IN_MONTH: string;
          N_TH_OCCURENCE_IN_MONTH_FROM_END: string;
        };
        RECURRING_TYPE_LABEL: string;
        SELECT_GROUP_TO_CREATE_EVENT_FOR: string;
        SIGNED_UP: string;
        SIGNUP: {
          ADDITIONAL_TITLE: string;
          MESSAGE: string;
          SIGNUP_ADDITIONAL_ACTION_BUTTON: string;
          STATUSES: {
            DONTKNOW: string;
            NO: string;
            YES: string;
          };
          TITLE: string;
        };
        SIGNUPS: string;
        SIGNUPS_LIST: {
          ALL: string;
          BY_GROUPINGS: string;
          EXITED_MEMBERS_SIGNUPS: string;
          EXTERNAL_SIGNUPS: string;
          MEMBERS: string;
          NOT_MEMBERS: string;
          REMOVED_MEMBER: string;
          SIGNED_UP_BY: string;
          SIGNUP_TIME_TOGGLE: string;
          SUMMARY: string;
        };
        START_TIME_IN_TIME_ZONE: string;
        TIME: string;
        VIEW_TYPE_CALENDAR: string;
        VIEW_TYPE_LIST: string;
      };
      INVITATION: {
        ACCEPT: string;
        ACCEPTED: string;
        ALREADY_ACCEPTED: string;
        CONFIRM_ACCEPT_INVITATION: string;
        CONFIRM_ACCEPT_INVITATION_AS_SLAVE: string;
        CONFIRM_INVITATION_TEXT: string;
        CONFIRM_INVITATION_TEXT_ALREADY_MEMBER: string;
        HI: string;
        INVITATION_EMAIL_TEXT: string;
        INVITATION_SENT: string;
        JOIN_SLAVE_TITLE: string;
        NOT_LOGGED_IN: string;
        NO_SLAVES: string;
        SELECT_SLAVE: string;
        START_JOINING_SLAVE: string;
        USING_AS_SLAVE: string;
      };
      LINKS: {
        CALENDAR: string;
        GDPR: string;
        MEMBERS: string;
        MY_DETAILS: string;
        SETTINGS: string;
        SETTINGS_EVENTS: string;
        SETTINGS_FEATURES: string;
        SETTINGS_LOOKUP_LISTS: string;
        SETTINGS_ROLES: string;
        STATISTICS: string;
        WEB_PORTAL: string;
      };
      MEMBERS: {
        ADD: string;
        ADD_FROM_SLAVES: string;
        ADD_MEMBERS: string;
        ADD_MEMBER_DIALOG_HEADER: string;
        GROUPINGS: {
          EDIT_MEMBERS: string;
          NAME: string;
          NAME_EXISTS: string;
          SHOW_ALL: string;
          SHOW_MEMBERS: string;
          TITLE: string;
        };
        INVITE: {
          ACTION: string;
          TITLE: string;
        };
        LEAVE_GROUP: string;
        NOT_JOINED: string;
        RESIGNED_FROM_GROUP: string;
        RESIGN_FROM_GROUP_CONFIRMATION: string;
        RESIGN_FROM_GROUP_FAILED: string;
        SLAVE_IS_ALREADY_MEMBER: string;
      };
      NEW_GROUP: {
        CREATE: {
          SUBTITLE: string;
          TITLE: string;
        };
        JOIN: {
          DIALOG_TITLE: string;
          GROUP_NAME_BEGIN: string;
          JOIN_REQUEST_MESSAGE: string;
          NO_RESULTS: string;
          SELECT_GROUP: string;
          SEND_REQUEST_TO_JOIN: string;
          SUBTITLE: string;
          TITLE: string;
        };
      };
      SETTINGS: {
        EVENTS: {
          CALENDARS: string;
          CALENDARS_HELP: string;
          EVENT_TYPES: string;
          EVENT_TYPES_HELP: string;
          EVENT_VIEWS: string;
          EVENT_VIEWS_HELP: string;
          SIGN_UP_FORMS: string;
          SIGN_UP_FORMS_HELP: string;
          TITLE: string;
        };
        GENERAL: {
          ALLOW_JOIN_REQUESTS: string;
          GROUP_NAME: string;
          SHORT_NAME: string;
          TITLE: string;
        };
        LOOKUPS: {
          ADD_LIST: string;
          LIST_ADDED_OK: string;
          NO_LISTS: string;
        };
        SIGN_UP_FORM: {
          CUSTOM_STATUS_TEXT: string;
          CUSTOM_STATUS_TRANSLATIONS: string;
          IS_MAYBE_STATUS: string;
          IS_MESSAGE: string;
          IS_NO_STATUS: string;
          IS_STATUS_4: string;
          IS_STATUS_5: string;
          IS_STATUS_6: string;
          SET_DEFAULT: string;
        };
      };
    };
    PAGE_TITLES: {
      CALENDAR: string;
      EVENT_EDITOR_ADD: string;
      EVENT_EDITOR_MODIFY: string;
      LANDING: string;
      LOGIN: string;
      MAIN: string;
      NOT_IMPLEMENTED: string;
      PORTAL: string;
      REGISTER: string;
      USER: string;
    };
    USER: {
      EMAILS: {
        NOT_VERIFIED: string;
        NO_EMAILS: string;
      };
      EMAIL_CONFIRMATION: {
        ALREADY_CONFIRMED: string;
        CONFIRMED: string;
      };
      LINKED_ACCOUNTS: {
        ACTIVATE: {
          ACTIVATE: string;
          HELP: string;
          HELP_DIALOG: string;
        };
        CURRENT_MASTER: {
          RETURN: string;
          USING: string;
        };
        EXISTING_SLAVES: {
          NO_SLAVES: string;
          TITLE: string;
        };
        MASTERS: {
          NO_MASTERS: string;
          SUCCESS_REMOVE: string;
          TITLE: string;
        };
        NEW_ACCOUNT: {
          HELP: string;
          TITLE: string;
        };
        NEW_MASTER_DIALOG: {
          ERROR: string;
          ERROR_ALREADY_MASTER: string;
          HELP: string;
          SUCCESS: string;
          TITLE: string;
        };
        TITLE: string;
      };
      PROFILE: {
        TAB_NAV: {
          BASIC: string;
          EMAIL: string;
          LINKED_ACCOUNTS: string;
        };
      };
    };
    VIEWS: {
      FOOTER: {
        CONTACT: string;
        FAQ: string;
        REPORT_PROBLEM: string;
      };
      SLIDING_MENU: {
        LOGGED_IN_AS: string;
        LOGIN: string;
        LOGOUT: string;
        MY_GROUPS: string;
        MY_PROFILE: string;
        RETURN_TO_MASTER: string;
        SLAVE_ACCOUNTS: string;
      };
    };
  };
}

export class Translations implements ITranslations {
  COMMON_SERVER = {
    CONFIRMATION_EMAIL: {
      BUTTON_TEXT:
        "COMMON_SERVER.CONFIRMATION_EMAIL.BUTTON_TEXT" as TranslationKey,
      CONTENT: "COMMON_SERVER.CONFIRMATION_EMAIL.CONTENT" as TranslationKey,
      SUBJECT: "COMMON_SERVER.CONFIRMATION_EMAIL.SUBJECT" as TranslationKey,
      TEXT_CONTENT:
        "COMMON_SERVER.CONFIRMATION_EMAIL.TEXT_CONTENT" as TranslationKey,
    },
    INVITATION_EMAIL: {
      CONFIRM: "COMMON_SERVER.INVITATION_EMAIL.CONFIRM" as TranslationKey,
      HEADER_TEXT:
        "COMMON_SERVER.INVITATION_EMAIL.HEADER_TEXT" as TranslationKey,
      INVITATION_TEXT:
        "COMMON_SERVER.INVITATION_EMAIL.INVITATION_TEXT" as TranslationKey,
      SUBJECT: "COMMON_SERVER.INVITATION_EMAIL.SUBJECT" as TranslationKey,
      TEXT_CONTENT:
        "COMMON_SERVER.INVITATION_EMAIL.TEXT_CONTENT" as TranslationKey,
    },
    MAILING_LIST: {
      SUBSCRIBE_CONFIRMATION:
        "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_CONFIRMATION" as TranslationKey,
      SUBSCRIBE_ERROR_EMAIL_NOT_FOUND:
        "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_ERROR_EMAIL_NOT_FOUND" as TranslationKey,
      SUBSCRIBE_TITLE:
        "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_TITLE" as TranslationKey,
      UNSUBSCRIBE_CONFIRMATION:
        "COMMON_SERVER.MAILING_LIST.UNSUBSCRIBE_CONFIRMATION" as TranslationKey,
    },
    MAILING_LIST_JOIN_EMAIL: {
      TEXT1: "COMMON_SERVER.MAILING_LIST_JOIN_EMAIL.TEXT1" as TranslationKey,
      TEXT2: "COMMON_SERVER.MAILING_LIST_JOIN_EMAIL.TEXT2" as TranslationKey,
    },
    MAILING_LIST_MESSAGE_EMAIL: {
      UNSUBSCRIBE:
        "COMMON_SERVER.MAILING_LIST_MESSAGE_EMAIL.UNSUBSCRIBE" as TranslationKey,
    },
  };
  COMMON_UI = {
    CONFIRM_ACTION: {
      TEXT: "COMMON_UI.CONFIRM_ACTION.TEXT" as TranslationKey,
      TITLE: "COMMON_UI.CONFIRM_ACTION.TITLE" as TranslationKey,
    },
    ERROR: {
      ACTION_FAILED: "COMMON_UI.ERROR.ACTION_FAILED" as TranslationKey,
      LOGIN: "COMMON_UI.ERROR.LOGIN" as TranslationKey,
      UNKNOWN: "COMMON_UI.ERROR.UNKNOWN" as TranslationKey,
    },
    FEATURE: {
      WEB_PORTAL: "COMMON_UI.FEATURE.WEB_PORTAL" as TranslationKey,
      WEB_PORTAL_DESCRIPTION:
        "COMMON_UI.FEATURE.WEB_PORTAL_DESCRIPTION" as TranslationKey,
      WEB_PORTAL_MAILING_LIST:
        "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST" as TranslationKey,
      WEB_PORTAL_MAILING_LIST_DESCRIPTION:
        "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST_DESCRIPTION" as TranslationKey,
    },
    KEY_WORD: {
      ACTIVATE: "COMMON_UI.KEY_WORD.ACTIVATE" as TranslationKey,
      ADD: "COMMON_UI.KEY_WORD.ADD" as TranslationKey,
      ADD_NEW: "COMMON_UI.KEY_WORD.ADD_NEW" as TranslationKey,
      ADD_OR_REMOVE: "COMMON_UI.KEY_WORD.ADD_OR_REMOVE" as TranslationKey,
      ADMIN: "COMMON_UI.KEY_WORD.ADMIN" as TranslationKey,
      ALL_GROUPS: "COMMON_UI.KEY_WORD.ALL_GROUPS" as TranslationKey,
      BIRTH_YEAR: "COMMON_UI.KEY_WORD.BIRTH_YEAR" as TranslationKey,
      BIRTH_YEAR_VALIDATION:
        "COMMON_UI.KEY_WORD.BIRTH_YEAR_VALIDATION" as TranslationKey,
      CANCEL: "COMMON_UI.KEY_WORD.CANCEL" as TranslationKey,
      CLOSE: "COMMON_UI.KEY_WORD.CLOSE" as TranslationKey,
      COLLAPSE_ALL: "COMMON_UI.KEY_WORD.COLLAPSE_ALL" as TranslationKey,
      COLOR: "COMMON_UI.KEY_WORD.COLOR" as TranslationKey,
      CONFIRM: "COMMON_UI.KEY_WORD.CONFIRM" as TranslationKey,
      CONFIRM_ACTION: "COMMON_UI.KEY_WORD.CONFIRM_ACTION" as TranslationKey,
      CONFIRM_ACTION_TEXT:
        "COMMON_UI.KEY_WORD.CONFIRM_ACTION_TEXT" as TranslationKey,
      DATA_SHOW_END_DATE:
        "COMMON_UI.KEY_WORD.DATA_SHOW_END_DATE" as TranslationKey,
      DATE_END: "COMMON_UI.KEY_WORD.DATE_END" as TranslationKey,
      DATE_H_SHORT: "COMMON_UI.KEY_WORD.DATE_H_SHORT" as TranslationKey,
      DATE_MIN_SHORT: "COMMON_UI.KEY_WORD.DATE_MIN_SHORT" as TranslationKey,
      DATE_SHOW_TIMEZONE:
        "COMMON_UI.KEY_WORD.DATE_SHOW_TIMEZONE" as TranslationKey,
      DATE_START: "COMMON_UI.KEY_WORD.DATE_START" as TranslationKey,
      DATE_TIMEZONE: "COMMON_UI.KEY_WORD.DATE_TIMEZONE" as TranslationKey,
      DAY: "COMMON_UI.KEY_WORD.DAY" as TranslationKey,
      DEACTIVATE: "COMMON_UI.KEY_WORD.DEACTIVATE" as TranslationKey,
      DELETE: "COMMON_UI.KEY_WORD.DELETE" as TranslationKey,
      DESCRIPTION: "COMMON_UI.KEY_WORD.DESCRIPTION" as TranslationKey,
      DISCARD: "COMMON_UI.KEY_WORD.DISCARD" as TranslationKey,
      EMAIL: "COMMON_UI.KEY_WORD.EMAIL" as TranslationKey,
      EMAIL_REPEAT: "COMMON_UI.KEY_WORD.EMAIL_REPEAT" as TranslationKey,
      ERROR: "COMMON_UI.KEY_WORD.ERROR" as TranslationKey,
      EXPAND_ALL: "COMMON_UI.KEY_WORD.EXPAND_ALL" as TranslationKey,
      FAMILY_NAME: "COMMON_UI.KEY_WORD.FAMILY_NAME" as TranslationKey,
      GIVEN_NAME: "COMMON_UI.KEY_WORD.GIVEN_NAME" as TranslationKey,
      JOIN: "COMMON_UI.KEY_WORD.JOIN" as TranslationKey,
      JOINED: "COMMON_UI.KEY_WORD.JOINED" as TranslationKey,
      LANGUAGE: "COMMON_UI.KEY_WORD.LANGUAGE" as TranslationKey,
      LOCALE: "COMMON_UI.KEY_WORD.LOCALE" as TranslationKey,
      LOGIN: "COMMON_UI.KEY_WORD.LOGIN" as TranslationKey,
      MEMBER: "COMMON_UI.KEY_WORD.MEMBER" as TranslationKey,
      MODIFY: "COMMON_UI.KEY_WORD.MODIFY" as TranslationKey,
      MONTH: "COMMON_UI.KEY_WORD.MONTH" as TranslationKey,
      NAME: "COMMON_UI.KEY_WORD.NAME" as TranslationKey,
      NEW_EVENT: "COMMON_UI.KEY_WORD.NEW_EVENT" as TranslationKey,
      NEW_GROUP: "COMMON_UI.KEY_WORD.NEW_GROUP" as TranslationKey,
      NO: "COMMON_UI.KEY_WORD.NO" as TranslationKey,
      ORDER: "COMMON_UI.KEY_WORD.ORDER" as TranslationKey,
      PASSWORD: "COMMON_UI.KEY_WORD.PASSWORD" as TranslationKey,
      PREVIEW: "COMMON_UI.KEY_WORD.PREVIEW" as TranslationKey,
      REGISTER: "COMMON_UI.KEY_WORD.REGISTER" as TranslationKey,
      REGISTERED: "COMMON_UI.KEY_WORD.REGISTERED" as TranslationKey,
      ROLE: "COMMON_UI.KEY_WORD.ROLE" as TranslationKey,
      SAVE: "COMMON_UI.KEY_WORD.SAVE" as TranslationKey,
      SEARCH: "COMMON_UI.KEY_WORD.SEARCH" as TranslationKey,
      SELECT: "COMMON_UI.KEY_WORD.SELECT" as TranslationKey,
      SEND: "COMMON_UI.KEY_WORD.SEND" as TranslationKey,
      SET_AS_DEFAULT: "COMMON_UI.KEY_WORD.SET_AS_DEFAULT" as TranslationKey,
      SIGN_UP: "COMMON_UI.KEY_WORD.SIGN_UP" as TranslationKey,
      SUBSCRIBE: "COMMON_UI.KEY_WORD.SUBSCRIBE" as TranslationKey,
      USERNAME: "COMMON_UI.KEY_WORD.USERNAME" as TranslationKey,
      WEEK: "COMMON_UI.KEY_WORD.WEEK" as TranslationKey,
      YES: "COMMON_UI.KEY_WORD.YES" as TranslationKey,
    },
    OK: {
      ADDED: "COMMON_UI.OK.ADDED" as TranslationKey,
      DELETED: "COMMON_UI.OK.DELETED" as TranslationKey,
      EXECUTED: "COMMON_UI.OK.EXECUTED" as TranslationKey,
      SAVED: "COMMON_UI.OK.SAVED" as TranslationKey,
    },
    PERMISSIONS: {
      SEARCH_USER: "COMMON_UI.PERMISSIONS.SEARCH_USER" as TranslationKey,
    },
    UNSAVED_CHANGES: {
      KEEP_EDITING: "COMMON_UI.UNSAVED_CHANGES.KEEP_EDITING" as TranslationKey,
      STOP_EDITING: "COMMON_UI.UNSAVED_CHANGES.STOP_EDITING" as TranslationKey,
      TEXT: "COMMON_UI.UNSAVED_CHANGES.TEXT" as TranslationKey,
      TITLE: "COMMON_UI.UNSAVED_CHANGES.TITLE" as TranslationKey,
    },
  };
  UI = {
    AUTOMATIC_ERRORS: {
      ALREADY_MEMBER: "UI.AUTOMATIC_ERRORS.ALREADY_MEMBER" as TranslationKey,
      CREATE_USER_USERNAME_EXISTS:
        "UI.AUTOMATIC_ERRORS.CREATE_USER_USERNAME_EXISTS" as TranslationKey,
    },
    GROUP: {
      EVENTS: {
        AFTER_ADDED: {
          CALENDAR: "UI.GROUP.EVENTS.AFTER_ADDED.CALENDAR" as TranslationKey,
          CONTINUE_TO:
            "UI.GROUP.EVENTS.AFTER_ADDED.CONTINUE_TO" as TranslationKey,
          EVENTS_PAGE:
            "UI.GROUP.EVENTS.AFTER_ADDED.EVENTS_PAGE" as TranslationKey,
        },
        CREATE_EVENT_TITLE:
          "UI.GROUP.EVENTS.CREATE_EVENT_TITLE" as TranslationKey,
        CREATING_EVENT_FOR_GROUP:
          "UI.GROUP.EVENTS.CREATING_EVENT_FOR_GROUP" as TranslationKey,
        CREATING_EVENT_JUST_FOR_YOU:
          "UI.GROUP.EVENTS.CREATING_EVENT_JUST_FOR_YOU" as TranslationKey,
        DELETE_EVENTS_ALL_EVENTS:
          "UI.GROUP.EVENTS.DELETE_EVENTS_ALL_EVENTS" as TranslationKey,
        DELETE_EVENTS_ALL_EVENTS_FROM_DAY:
          "UI.GROUP.EVENTS.DELETE_EVENTS_ALL_EVENTS_FROM_DAY" as TranslationKey,
        DELETE_EVENTS_DIALOG_TITLE:
          "UI.GROUP.EVENTS.DELETE_EVENTS_DIALOG_TITLE" as TranslationKey,
        DELETE_EVENTS_THIS_EVENT_ONLY:
          "UI.GROUP.EVENTS.DELETE_EVENTS_THIS_EVENT_ONLY" as TranslationKey,
        DELETE_EVENT_CONFIRMATION:
          "UI.GROUP.EVENTS.DELETE_EVENT_CONFIRMATION" as TranslationKey,
        DESCRIPTION: "UI.GROUP.EVENTS.DESCRIPTION" as TranslationKey,
        ERRORS: {
          RECURRING: {
            SELECT_DAYS:
              "UI.GROUP.EVENTS.ERRORS.RECURRING.SELECT_DAYS" as TranslationKey,
          },
        },
        EVENT_ADDED_OK: "UI.GROUP.EVENTS.EVENT_ADDED_OK" as TranslationKey,
        EVENT_DELETED: "UI.GROUP.EVENTS.EVENT_DELETED" as TranslationKey,
        EVENT_LIST_SUBTITLE:
          "UI.GROUP.EVENTS.EVENT_LIST_SUBTITLE" as TranslationKey,
        EVENT_LIST_TITLE: "UI.GROUP.EVENTS.EVENT_LIST_TITLE" as TranslationKey,
        EVENT_MODIFIED_OK:
          "UI.GROUP.EVENTS.EVENT_MODIFIED_OK" as TranslationKey,
        EVENT_NAME: "UI.GROUP.EVENTS.EVENT_NAME" as TranslationKey,
        EVENT_TYPE: "UI.GROUP.EVENTS.EVENT_TYPE" as TranslationKey,
        GLOBAL_EVENT_TYPES: {
          MATCH: "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.MATCH" as TranslationKey,
          OTHER: "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.OTHER" as TranslationKey,
          PAYMENT:
            "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.PAYMENT" as TranslationKey,
          SINGLE: "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.SINGLE" as TranslationKey,
          WEEKLY: "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.WEEKLY" as TranslationKey,
        },
        IS_RECURRING: "UI.GROUP.EVENTS.IS_RECURRING" as TranslationKey,
        LOCATION: "UI.GROUP.EVENTS.LOCATION" as TranslationKey,
        LOCATION_ADDRESS: "UI.GROUP.EVENTS.LOCATION_ADDRESS" as TranslationKey,
        LOCATION_SUFFIX: "UI.GROUP.EVENTS.LOCATION_SUFFIX" as TranslationKey,
        MESSAGES: {
          EVENT_CANT_BE_SIGNED:
            "UI.GROUP.EVENTS.MESSAGES.EVENT_CANT_BE_SIGNED" as TranslationKey,
          EVENT_IS_FULL:
            "UI.GROUP.EVENTS.MESSAGES.EVENT_IS_FULL" as TranslationKey,
        },
        MODIFY_EVENT_FOR_GROUP:
          "UI.GROUP.EVENTS.MODIFY_EVENT_FOR_GROUP" as TranslationKey,
        MODIFY_EVENT_JUST_FOR_YOU:
          "UI.GROUP.EVENTS.MODIFY_EVENT_JUST_FOR_YOU" as TranslationKey,
        MODIFY_EVENT_SEPARATE_FROM_SERIES:
          "UI.GROUP.EVENTS.MODIFY_EVENT_SEPARATE_FROM_SERIES" as TranslationKey,
        MODIFY_EVENT_SERIES_INFO:
          "UI.GROUP.EVENTS.MODIFY_EVENT_SERIES_INFO" as TranslationKey,
        MODIFY_EVENT_TITLE:
          "UI.GROUP.EVENTS.MODIFY_EVENT_TITLE" as TranslationKey,
        PERSONAL_EVENT: "UI.GROUP.EVENTS.PERSONAL_EVENT" as TranslationKey,
        RECURRING_LAST_EVENT_AT:
          "UI.GROUP.EVENTS.RECURRING_LAST_EVENT_AT" as TranslationKey,
        RECURRING_N_TH: "UI.GROUP.EVENTS.RECURRING_N_TH" as TranslationKey,
        RECURRING_TYPE: {
          ANNUALLY: "UI.GROUP.EVENTS.RECURRING_TYPE.ANNUALLY" as TranslationKey,
          DAYS_OF_WEEK:
            "UI.GROUP.EVENTS.RECURRING_TYPE.DAYS_OF_WEEK" as TranslationKey,
          DAY_OF_MONTH:
            "UI.GROUP.EVENTS.RECURRING_TYPE.DAY_OF_MONTH" as TranslationKey,
          DAY_OF_MONTH_FROM_END:
            "UI.GROUP.EVENTS.RECURRING_TYPE.DAY_OF_MONTH_FROM_END" as TranslationKey,
          EVERY_N_TH_DAY:
            "UI.GROUP.EVENTS.RECURRING_TYPE.EVERY_N_TH_DAY" as TranslationKey,
          N_TH_OCCURENCE_IN_MONTH:
            "UI.GROUP.EVENTS.RECURRING_TYPE.N_TH_OCCURENCE_IN_MONTH" as TranslationKey,
          N_TH_OCCURENCE_IN_MONTH_FROM_END:
            "UI.GROUP.EVENTS.RECURRING_TYPE.N_TH_OCCURENCE_IN_MONTH_FROM_END" as TranslationKey,
        },
        RECURRING_TYPE_LABEL:
          "UI.GROUP.EVENTS.RECURRING_TYPE_LABEL" as TranslationKey,
        SELECT_GROUP_TO_CREATE_EVENT_FOR:
          "UI.GROUP.EVENTS.SELECT_GROUP_TO_CREATE_EVENT_FOR" as TranslationKey,
        SIGNED_UP: "UI.GROUP.EVENTS.SIGNED_UP" as TranslationKey,
        SIGNUP: {
          ADDITIONAL_TITLE:
            "UI.GROUP.EVENTS.SIGNUP.ADDITIONAL_TITLE" as TranslationKey,
          MESSAGE: "UI.GROUP.EVENTS.SIGNUP.MESSAGE" as TranslationKey,
          SIGNUP_ADDITIONAL_ACTION_BUTTON:
            "UI.GROUP.EVENTS.SIGNUP.SIGNUP_ADDITIONAL_ACTION_BUTTON" as TranslationKey,
          STATUSES: {
            DONTKNOW:
              "UI.GROUP.EVENTS.SIGNUP.STATUSES.DONTKNOW" as TranslationKey,
            NO: "UI.GROUP.EVENTS.SIGNUP.STATUSES.NO" as TranslationKey,
            YES: "UI.GROUP.EVENTS.SIGNUP.STATUSES.YES" as TranslationKey,
          },
          TITLE: "UI.GROUP.EVENTS.SIGNUP.TITLE" as TranslationKey,
        },
        SIGNUPS: "UI.GROUP.EVENTS.SIGNUPS" as TranslationKey,
        SIGNUPS_LIST: {
          ALL: "UI.GROUP.EVENTS.SIGNUPS_LIST.ALL" as TranslationKey,
          BY_GROUPINGS:
            "UI.GROUP.EVENTS.SIGNUPS_LIST.BY_GROUPINGS" as TranslationKey,
          EXITED_MEMBERS_SIGNUPS:
            "UI.GROUP.EVENTS.SIGNUPS_LIST.EXITED_MEMBERS_SIGNUPS" as TranslationKey,
          EXTERNAL_SIGNUPS:
            "UI.GROUP.EVENTS.SIGNUPS_LIST.EXTERNAL_SIGNUPS" as TranslationKey,
          MEMBERS: "UI.GROUP.EVENTS.SIGNUPS_LIST.MEMBERS" as TranslationKey,
          NOT_MEMBERS:
            "UI.GROUP.EVENTS.SIGNUPS_LIST.NOT_MEMBERS" as TranslationKey,
          REMOVED_MEMBER:
            "UI.GROUP.EVENTS.SIGNUPS_LIST.REMOVED_MEMBER" as TranslationKey,
          SIGNED_UP_BY:
            "UI.GROUP.EVENTS.SIGNUPS_LIST.SIGNED_UP_BY" as TranslationKey,
          SIGNUP_TIME_TOGGLE:
            "UI.GROUP.EVENTS.SIGNUPS_LIST.SIGNUP_TIME_TOGGLE" as TranslationKey,
          SUMMARY: "UI.GROUP.EVENTS.SIGNUPS_LIST.SUMMARY" as TranslationKey,
        },
        START_TIME_IN_TIME_ZONE:
          "UI.GROUP.EVENTS.START_TIME_IN_TIME_ZONE" as TranslationKey,
        TIME: "UI.GROUP.EVENTS.TIME" as TranslationKey,
        VIEW_TYPE_CALENDAR:
          "UI.GROUP.EVENTS.VIEW_TYPE_CALENDAR" as TranslationKey,
        VIEW_TYPE_LIST: "UI.GROUP.EVENTS.VIEW_TYPE_LIST" as TranslationKey,
      },
      INVITATION: {
        ACCEPT: "UI.GROUP.INVITATION.ACCEPT" as TranslationKey,
        ACCEPTED: "UI.GROUP.INVITATION.ACCEPTED" as TranslationKey,
        ALREADY_ACCEPTED:
          "UI.GROUP.INVITATION.ALREADY_ACCEPTED" as TranslationKey,
        CONFIRM_ACCEPT_INVITATION:
          "UI.GROUP.INVITATION.CONFIRM_ACCEPT_INVITATION" as TranslationKey,
        CONFIRM_ACCEPT_INVITATION_AS_SLAVE:
          "UI.GROUP.INVITATION.CONFIRM_ACCEPT_INVITATION_AS_SLAVE" as TranslationKey,
        CONFIRM_INVITATION_TEXT:
          "UI.GROUP.INVITATION.CONFIRM_INVITATION_TEXT" as TranslationKey,
        CONFIRM_INVITATION_TEXT_ALREADY_MEMBER:
          "UI.GROUP.INVITATION.CONFIRM_INVITATION_TEXT_ALREADY_MEMBER" as TranslationKey,
        HI: "UI.GROUP.INVITATION.HI" as TranslationKey,
        INVITATION_EMAIL_TEXT:
          "UI.GROUP.INVITATION.INVITATION_EMAIL_TEXT" as TranslationKey,
        INVITATION_SENT:
          "UI.GROUP.INVITATION.INVITATION_SENT" as TranslationKey,
        JOIN_SLAVE_TITLE:
          "UI.GROUP.INVITATION.JOIN_SLAVE_TITLE" as TranslationKey,
        NOT_LOGGED_IN: "UI.GROUP.INVITATION.NOT_LOGGED_IN" as TranslationKey,
        NO_SLAVES: "UI.GROUP.INVITATION.NO_SLAVES" as TranslationKey,
        SELECT_SLAVE: "UI.GROUP.INVITATION.SELECT_SLAVE" as TranslationKey,
        START_JOINING_SLAVE:
          "UI.GROUP.INVITATION.START_JOINING_SLAVE" as TranslationKey,
        USING_AS_SLAVE: "UI.GROUP.INVITATION.USING_AS_SLAVE" as TranslationKey,
      },
      LINKS: {
        CALENDAR: "UI.GROUP.LINKS.CALENDAR" as TranslationKey,
        GDPR: "UI.GROUP.LINKS.GDPR" as TranslationKey,
        MEMBERS: "UI.GROUP.LINKS.MEMBERS" as TranslationKey,
        MY_DETAILS: "UI.GROUP.LINKS.MY_DETAILS" as TranslationKey,
        SETTINGS: "UI.GROUP.LINKS.SETTINGS" as TranslationKey,
        SETTINGS_EVENTS: "UI.GROUP.LINKS.SETTINGS_EVENTS" as TranslationKey,
        SETTINGS_FEATURES: "UI.GROUP.LINKS.SETTINGS_FEATURES" as TranslationKey,
        SETTINGS_LOOKUP_LISTS:
          "UI.GROUP.LINKS.SETTINGS_LOOKUP_LISTS" as TranslationKey,
        SETTINGS_ROLES: "UI.GROUP.LINKS.SETTINGS_ROLES" as TranslationKey,
        STATISTICS: "UI.GROUP.LINKS.STATISTICS" as TranslationKey,
        WEB_PORTAL: "UI.GROUP.LINKS.WEB_PORTAL" as TranslationKey,
      },
      MEMBERS: {
        ADD: "UI.GROUP.MEMBERS.ADD" as TranslationKey,
        ADD_FROM_SLAVES: "UI.GROUP.MEMBERS.ADD_FROM_SLAVES" as TranslationKey,
        ADD_MEMBERS: "UI.GROUP.MEMBERS.ADD_MEMBERS" as TranslationKey,
        ADD_MEMBER_DIALOG_HEADER:
          "UI.GROUP.MEMBERS.ADD_MEMBER_DIALOG_HEADER" as TranslationKey,
        GROUPINGS: {
          EDIT_MEMBERS:
            "UI.GROUP.MEMBERS.GROUPINGS.EDIT_MEMBERS" as TranslationKey,
          NAME: "UI.GROUP.MEMBERS.GROUPINGS.NAME" as TranslationKey,
          NAME_EXISTS:
            "UI.GROUP.MEMBERS.GROUPINGS.NAME_EXISTS" as TranslationKey,
          SHOW_ALL: "UI.GROUP.MEMBERS.GROUPINGS.SHOW_ALL" as TranslationKey,
          SHOW_MEMBERS:
            "UI.GROUP.MEMBERS.GROUPINGS.SHOW_MEMBERS" as TranslationKey,
          TITLE: "UI.GROUP.MEMBERS.GROUPINGS.TITLE" as TranslationKey,
        },
        INVITE: {
          ACTION: "UI.GROUP.MEMBERS.INVITE.ACTION" as TranslationKey,
          TITLE: "UI.GROUP.MEMBERS.INVITE.TITLE" as TranslationKey,
        },
        LEAVE_GROUP: "UI.GROUP.MEMBERS.LEAVE_GROUP" as TranslationKey,
        NOT_JOINED: "UI.GROUP.MEMBERS.NOT_JOINED" as TranslationKey,
        RESIGNED_FROM_GROUP:
          "UI.GROUP.MEMBERS.RESIGNED_FROM_GROUP" as TranslationKey,
        RESIGN_FROM_GROUP_CONFIRMATION:
          "UI.GROUP.MEMBERS.RESIGN_FROM_GROUP_CONFIRMATION" as TranslationKey,
        RESIGN_FROM_GROUP_FAILED:
          "UI.GROUP.MEMBERS.RESIGN_FROM_GROUP_FAILED" as TranslationKey,
        SLAVE_IS_ALREADY_MEMBER:
          "UI.GROUP.MEMBERS.SLAVE_IS_ALREADY_MEMBER" as TranslationKey,
      },
      NEW_GROUP: {
        CREATE: {
          SUBTITLE: "UI.GROUP.NEW_GROUP.CREATE.SUBTITLE" as TranslationKey,
          TITLE: "UI.GROUP.NEW_GROUP.CREATE.TITLE" as TranslationKey,
        },
        JOIN: {
          DIALOG_TITLE:
            "UI.GROUP.NEW_GROUP.JOIN.DIALOG_TITLE" as TranslationKey,
          GROUP_NAME_BEGIN:
            "UI.GROUP.NEW_GROUP.JOIN.GROUP_NAME_BEGIN" as TranslationKey,
          JOIN_REQUEST_MESSAGE:
            "UI.GROUP.NEW_GROUP.JOIN.JOIN_REQUEST_MESSAGE" as TranslationKey,
          NO_RESULTS: "UI.GROUP.NEW_GROUP.JOIN.NO_RESULTS" as TranslationKey,
          SELECT_GROUP:
            "UI.GROUP.NEW_GROUP.JOIN.SELECT_GROUP" as TranslationKey,
          SEND_REQUEST_TO_JOIN:
            "UI.GROUP.NEW_GROUP.JOIN.SEND_REQUEST_TO_JOIN" as TranslationKey,
          SUBTITLE: "UI.GROUP.NEW_GROUP.JOIN.SUBTITLE" as TranslationKey,
          TITLE: "UI.GROUP.NEW_GROUP.JOIN.TITLE" as TranslationKey,
        },
      },
      SETTINGS: {
        EVENTS: {
          CALENDARS: "UI.GROUP.SETTINGS.EVENTS.CALENDARS" as TranslationKey,
          CALENDARS_HELP:
            "UI.GROUP.SETTINGS.EVENTS.CALENDARS_HELP" as TranslationKey,
          EVENT_TYPES: "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES" as TranslationKey,
          EVENT_TYPES_HELP:
            "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES_HELP" as TranslationKey,
          EVENT_VIEWS: "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS" as TranslationKey,
          EVENT_VIEWS_HELP:
            "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS_HELP" as TranslationKey,
          SIGN_UP_FORMS:
            "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS" as TranslationKey,
          SIGN_UP_FORMS_HELP:
            "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS_HELP" as TranslationKey,
          TITLE: "UI.GROUP.SETTINGS.EVENTS.TITLE" as TranslationKey,
        },
        GENERAL: {
          ALLOW_JOIN_REQUESTS:
            "UI.GROUP.SETTINGS.GENERAL.ALLOW_JOIN_REQUESTS" as TranslationKey,
          GROUP_NAME: "UI.GROUP.SETTINGS.GENERAL.GROUP_NAME" as TranslationKey,
          SHORT_NAME: "UI.GROUP.SETTINGS.GENERAL.SHORT_NAME" as TranslationKey,
          TITLE: "UI.GROUP.SETTINGS.GENERAL.TITLE" as TranslationKey,
        },
        LOOKUPS: {
          ADD_LIST: "UI.GROUP.SETTINGS.LOOKUPS.ADD_LIST" as TranslationKey,
          LIST_ADDED_OK:
            "UI.GROUP.SETTINGS.LOOKUPS.LIST_ADDED_OK" as TranslationKey,
          NO_LISTS: "UI.GROUP.SETTINGS.LOOKUPS.NO_LISTS" as TranslationKey,
        },
        SIGN_UP_FORM: {
          CUSTOM_STATUS_TEXT:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TEXT" as TranslationKey,
          CUSTOM_STATUS_TRANSLATIONS:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TRANSLATIONS" as TranslationKey,
          IS_MAYBE_STATUS:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MAYBE_STATUS" as TranslationKey,
          IS_MESSAGE:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MESSAGE" as TranslationKey,
          IS_NO_STATUS:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_NO_STATUS" as TranslationKey,
          IS_STATUS_4:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_4" as TranslationKey,
          IS_STATUS_5:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_5" as TranslationKey,
          IS_STATUS_6:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_6" as TranslationKey,
          SET_DEFAULT:
            "UI.GROUP.SETTINGS.SIGN_UP_FORM.SET_DEFAULT" as TranslationKey,
        },
      },
    },
    PAGE_TITLES: {
      CALENDAR: "UI.PAGE_TITLES.CALENDAR" as TranslationKey,
      EVENT_EDITOR_ADD: "UI.PAGE_TITLES.EVENT_EDITOR_ADD" as TranslationKey,
      EVENT_EDITOR_MODIFY:
        "UI.PAGE_TITLES.EVENT_EDITOR_MODIFY" as TranslationKey,
      LANDING: "UI.PAGE_TITLES.LANDING" as TranslationKey,
      LOGIN: "UI.PAGE_TITLES.LOGIN" as TranslationKey,
      MAIN: "UI.PAGE_TITLES.MAIN" as TranslationKey,
      NOT_IMPLEMENTED: "UI.PAGE_TITLES.NOT_IMPLEMENTED" as TranslationKey,
      PORTAL: "UI.PAGE_TITLES.PORTAL" as TranslationKey,
      REGISTER: "UI.PAGE_TITLES.REGISTER" as TranslationKey,
      USER: "UI.PAGE_TITLES.USER" as TranslationKey,
    },
    USER: {
      EMAILS: {
        NOT_VERIFIED: "UI.USER.EMAILS.NOT_VERIFIED" as TranslationKey,
        NO_EMAILS: "UI.USER.EMAILS.NO_EMAILS" as TranslationKey,
      },
      EMAIL_CONFIRMATION: {
        ALREADY_CONFIRMED:
          "UI.USER.EMAIL_CONFIRMATION.ALREADY_CONFIRMED" as TranslationKey,
        CONFIRMED: "UI.USER.EMAIL_CONFIRMATION.CONFIRMED" as TranslationKey,
      },
      LINKED_ACCOUNTS: {
        ACTIVATE: {
          ACTIVATE:
            "UI.USER.LINKED_ACCOUNTS.ACTIVATE.ACTIVATE" as TranslationKey,
          HELP: "UI.USER.LINKED_ACCOUNTS.ACTIVATE.HELP" as TranslationKey,
          HELP_DIALOG:
            "UI.USER.LINKED_ACCOUNTS.ACTIVATE.HELP_DIALOG" as TranslationKey,
        },
        CURRENT_MASTER: {
          RETURN:
            "UI.USER.LINKED_ACCOUNTS.CURRENT_MASTER.RETURN" as TranslationKey,
          USING:
            "UI.USER.LINKED_ACCOUNTS.CURRENT_MASTER.USING" as TranslationKey,
        },
        EXISTING_SLAVES: {
          NO_SLAVES:
            "UI.USER.LINKED_ACCOUNTS.EXISTING_SLAVES.NO_SLAVES" as TranslationKey,
          TITLE:
            "UI.USER.LINKED_ACCOUNTS.EXISTING_SLAVES.TITLE" as TranslationKey,
        },
        MASTERS: {
          NO_MASTERS:
            "UI.USER.LINKED_ACCOUNTS.MASTERS.NO_MASTERS" as TranslationKey,
          SUCCESS_REMOVE:
            "UI.USER.LINKED_ACCOUNTS.MASTERS.SUCCESS_REMOVE" as TranslationKey,
          TITLE: "UI.USER.LINKED_ACCOUNTS.MASTERS.TITLE" as TranslationKey,
        },
        NEW_ACCOUNT: {
          HELP: "UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.HELP" as TranslationKey,
          TITLE: "UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.TITLE" as TranslationKey,
        },
        NEW_MASTER_DIALOG: {
          ERROR:
            "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.ERROR" as TranslationKey,
          ERROR_ALREADY_MASTER:
            "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.ERROR_ALREADY_MASTER" as TranslationKey,
          HELP: "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.HELP" as TranslationKey,
          SUCCESS:
            "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.SUCCESS" as TranslationKey,
          TITLE:
            "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.TITLE" as TranslationKey,
        },
        TITLE: "UI.USER.LINKED_ACCOUNTS.TITLE" as TranslationKey,
      },
      PROFILE: {
        TAB_NAV: {
          BASIC: "UI.USER.PROFILE.TAB_NAV.BASIC" as TranslationKey,
          EMAIL: "UI.USER.PROFILE.TAB_NAV.EMAIL" as TranslationKey,
          LINKED_ACCOUNTS:
            "UI.USER.PROFILE.TAB_NAV.LINKED_ACCOUNTS" as TranslationKey,
        },
      },
    },
    VIEWS: {
      FOOTER: {
        CONTACT: "UI.VIEWS.FOOTER.CONTACT" as TranslationKey,
        FAQ: "UI.VIEWS.FOOTER.FAQ" as TranslationKey,
        REPORT_PROBLEM: "UI.VIEWS.FOOTER.REPORT_PROBLEM" as TranslationKey,
      },
      SLIDING_MENU: {
        LOGGED_IN_AS: "UI.VIEWS.SLIDING_MENU.LOGGED_IN_AS" as TranslationKey,
        LOGIN: "UI.VIEWS.SLIDING_MENU.LOGIN" as TranslationKey,
        LOGOUT: "UI.VIEWS.SLIDING_MENU.LOGOUT" as TranslationKey,
        MY_GROUPS: "UI.VIEWS.SLIDING_MENU.MY_GROUPS" as TranslationKey,
        MY_PROFILE: "UI.VIEWS.SLIDING_MENU.MY_PROFILE" as TranslationKey,
        RETURN_TO_MASTER:
          "UI.VIEWS.SLIDING_MENU.RETURN_TO_MASTER" as TranslationKey,
        SLAVE_ACCOUNTS:
          "UI.VIEWS.SLIDING_MENU.SLAVE_ACCOUNTS" as TranslationKey,
      },
    },
  };
}

export const TRANSLATIONS = new Translations();

export type TranslationKey =
  | "COMMON_SERVER.CONFIRMATION_EMAIL.BUTTON_TEXT"
  | "COMMON_SERVER.CONFIRMATION_EMAIL.CONTENT"
  | "COMMON_SERVER.CONFIRMATION_EMAIL.SUBJECT"
  | "COMMON_SERVER.CONFIRMATION_EMAIL.TEXT_CONTENT"
  | "COMMON_SERVER.INVITATION_EMAIL.CONFIRM"
  | "COMMON_SERVER.INVITATION_EMAIL.HEADER_TEXT"
  | "COMMON_SERVER.INVITATION_EMAIL.INVITATION_TEXT"
  | "COMMON_SERVER.INVITATION_EMAIL.SUBJECT"
  | "COMMON_SERVER.INVITATION_EMAIL.TEXT_CONTENT"
  | "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_CONFIRMATION"
  | "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_ERROR_EMAIL_NOT_FOUND"
  | "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_TITLE"
  | "COMMON_SERVER.MAILING_LIST.UNSUBSCRIBE_CONFIRMATION"
  | "COMMON_SERVER.MAILING_LIST_JOIN_EMAIL.TEXT1"
  | "COMMON_SERVER.MAILING_LIST_JOIN_EMAIL.TEXT2"
  | "COMMON_SERVER.MAILING_LIST_MESSAGE_EMAIL.UNSUBSCRIBE"
  | "COMMON_UI.CONFIRM_ACTION.TEXT"
  | "COMMON_UI.CONFIRM_ACTION.TITLE"
  | "COMMON_UI.ERROR.ACTION_FAILED"
  | "COMMON_UI.ERROR.LOGIN"
  | "COMMON_UI.ERROR.UNKNOWN"
  | "COMMON_UI.FEATURE.WEB_PORTAL"
  | "COMMON_UI.FEATURE.WEB_PORTAL_DESCRIPTION"
  | "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST"
  | "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST_DESCRIPTION"
  | "COMMON_UI.KEY_WORD.ACTIVATE"
  | "COMMON_UI.KEY_WORD.ADD"
  | "COMMON_UI.KEY_WORD.ADD_NEW"
  | "COMMON_UI.KEY_WORD.ADD_OR_REMOVE"
  | "COMMON_UI.KEY_WORD.ADMIN"
  | "COMMON_UI.KEY_WORD.ALL_GROUPS"
  | "COMMON_UI.KEY_WORD.BIRTH_YEAR"
  | "COMMON_UI.KEY_WORD.BIRTH_YEAR_VALIDATION"
  | "COMMON_UI.KEY_WORD.CANCEL"
  | "COMMON_UI.KEY_WORD.CLOSE"
  | "COMMON_UI.KEY_WORD.COLLAPSE_ALL"
  | "COMMON_UI.KEY_WORD.COLOR"
  | "COMMON_UI.KEY_WORD.CONFIRM"
  | "COMMON_UI.KEY_WORD.CONFIRM_ACTION"
  | "COMMON_UI.KEY_WORD.CONFIRM_ACTION_TEXT"
  | "COMMON_UI.KEY_WORD.DATA_SHOW_END_DATE"
  | "COMMON_UI.KEY_WORD.DATE_END"
  | "COMMON_UI.KEY_WORD.DATE_H_SHORT"
  | "COMMON_UI.KEY_WORD.DATE_MIN_SHORT"
  | "COMMON_UI.KEY_WORD.DATE_SHOW_TIMEZONE"
  | "COMMON_UI.KEY_WORD.DATE_START"
  | "COMMON_UI.KEY_WORD.DATE_TIMEZONE"
  | "COMMON_UI.KEY_WORD.DAY"
  | "COMMON_UI.KEY_WORD.DEACTIVATE"
  | "COMMON_UI.KEY_WORD.DELETE"
  | "COMMON_UI.KEY_WORD.DESCRIPTION"
  | "COMMON_UI.KEY_WORD.DISCARD"
  | "COMMON_UI.KEY_WORD.EMAIL"
  | "COMMON_UI.KEY_WORD.EMAIL_REPEAT"
  | "COMMON_UI.KEY_WORD.ERROR"
  | "COMMON_UI.KEY_WORD.EXPAND_ALL"
  | "COMMON_UI.KEY_WORD.FAMILY_NAME"
  | "COMMON_UI.KEY_WORD.GIVEN_NAME"
  | "COMMON_UI.KEY_WORD.JOIN"
  | "COMMON_UI.KEY_WORD.JOINED"
  | "COMMON_UI.KEY_WORD.LANGUAGE"
  | "COMMON_UI.KEY_WORD.LOCALE"
  | "COMMON_UI.KEY_WORD.LOGIN"
  | "COMMON_UI.KEY_WORD.MEMBER"
  | "COMMON_UI.KEY_WORD.MODIFY"
  | "COMMON_UI.KEY_WORD.MONTH"
  | "COMMON_UI.KEY_WORD.NAME"
  | "COMMON_UI.KEY_WORD.NEW_EVENT"
  | "COMMON_UI.KEY_WORD.NEW_GROUP"
  | "COMMON_UI.KEY_WORD.NO"
  | "COMMON_UI.KEY_WORD.ORDER"
  | "COMMON_UI.KEY_WORD.PASSWORD"
  | "COMMON_UI.KEY_WORD.PREVIEW"
  | "COMMON_UI.KEY_WORD.REGISTER"
  | "COMMON_UI.KEY_WORD.REGISTERED"
  | "COMMON_UI.KEY_WORD.ROLE"
  | "COMMON_UI.KEY_WORD.SAVE"
  | "COMMON_UI.KEY_WORD.SEARCH"
  | "COMMON_UI.KEY_WORD.SELECT"
  | "COMMON_UI.KEY_WORD.SEND"
  | "COMMON_UI.KEY_WORD.SET_AS_DEFAULT"
  | "COMMON_UI.KEY_WORD.SIGN_UP"
  | "COMMON_UI.KEY_WORD.SUBSCRIBE"
  | "COMMON_UI.KEY_WORD.USERNAME"
  | "COMMON_UI.KEY_WORD.WEEK"
  | "COMMON_UI.KEY_WORD.YES"
  | "COMMON_UI.OK.ADDED"
  | "COMMON_UI.OK.DELETED"
  | "COMMON_UI.OK.EXECUTED"
  | "COMMON_UI.OK.SAVED"
  | "COMMON_UI.PERMISSIONS.SEARCH_USER"
  | "COMMON_UI.UNSAVED_CHANGES.KEEP_EDITING"
  | "COMMON_UI.UNSAVED_CHANGES.STOP_EDITING"
  | "COMMON_UI.UNSAVED_CHANGES.TEXT"
  | "COMMON_UI.UNSAVED_CHANGES.TITLE"
  | "UI.AUTOMATIC_ERRORS.ALREADY_MEMBER"
  | "UI.AUTOMATIC_ERRORS.CREATE_USER_USERNAME_EXISTS"
  | "UI.GROUP.EVENTS.AFTER_ADDED.CALENDAR"
  | "UI.GROUP.EVENTS.AFTER_ADDED.CONTINUE_TO"
  | "UI.GROUP.EVENTS.AFTER_ADDED.EVENTS_PAGE"
  | "UI.GROUP.EVENTS.CREATE_EVENT_TITLE"
  | "UI.GROUP.EVENTS.CREATING_EVENT_FOR_GROUP"
  | "UI.GROUP.EVENTS.CREATING_EVENT_JUST_FOR_YOU"
  | "UI.GROUP.EVENTS.DELETE_EVENTS_ALL_EVENTS"
  | "UI.GROUP.EVENTS.DELETE_EVENTS_ALL_EVENTS_FROM_DAY"
  | "UI.GROUP.EVENTS.DELETE_EVENTS_DIALOG_TITLE"
  | "UI.GROUP.EVENTS.DELETE_EVENTS_THIS_EVENT_ONLY"
  | "UI.GROUP.EVENTS.DELETE_EVENT_CONFIRMATION"
  | "UI.GROUP.EVENTS.DESCRIPTION"
  | "UI.GROUP.EVENTS.ERRORS.RECURRING.SELECT_DAYS"
  | "UI.GROUP.EVENTS.EVENT_ADDED_OK"
  | "UI.GROUP.EVENTS.EVENT_DELETED"
  | "UI.GROUP.EVENTS.EVENT_LIST_SUBTITLE"
  | "UI.GROUP.EVENTS.EVENT_LIST_TITLE"
  | "UI.GROUP.EVENTS.EVENT_MODIFIED_OK"
  | "UI.GROUP.EVENTS.EVENT_NAME"
  | "UI.GROUP.EVENTS.EVENT_TYPE"
  | "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.MATCH"
  | "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.OTHER"
  | "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.PAYMENT"
  | "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.SINGLE"
  | "UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES.WEEKLY"
  | "UI.GROUP.EVENTS.IS_RECURRING"
  | "UI.GROUP.EVENTS.LOCATION"
  | "UI.GROUP.EVENTS.LOCATION_ADDRESS"
  | "UI.GROUP.EVENTS.LOCATION_SUFFIX"
  | "UI.GROUP.EVENTS.MESSAGES.EVENT_CANT_BE_SIGNED"
  | "UI.GROUP.EVENTS.MESSAGES.EVENT_IS_FULL"
  | "UI.GROUP.EVENTS.MODIFY_EVENT_FOR_GROUP"
  | "UI.GROUP.EVENTS.MODIFY_EVENT_JUST_FOR_YOU"
  | "UI.GROUP.EVENTS.MODIFY_EVENT_SEPARATE_FROM_SERIES"
  | "UI.GROUP.EVENTS.MODIFY_EVENT_SERIES_INFO"
  | "UI.GROUP.EVENTS.MODIFY_EVENT_TITLE"
  | "UI.GROUP.EVENTS.PERSONAL_EVENT"
  | "UI.GROUP.EVENTS.RECURRING_LAST_EVENT_AT"
  | "UI.GROUP.EVENTS.RECURRING_N_TH"
  | "UI.GROUP.EVENTS.RECURRING_TYPE.ANNUALLY"
  | "UI.GROUP.EVENTS.RECURRING_TYPE.DAYS_OF_WEEK"
  | "UI.GROUP.EVENTS.RECURRING_TYPE.DAY_OF_MONTH"
  | "UI.GROUP.EVENTS.RECURRING_TYPE.DAY_OF_MONTH_FROM_END"
  | "UI.GROUP.EVENTS.RECURRING_TYPE.EVERY_N_TH_DAY"
  | "UI.GROUP.EVENTS.RECURRING_TYPE.N_TH_OCCURENCE_IN_MONTH"
  | "UI.GROUP.EVENTS.RECURRING_TYPE.N_TH_OCCURENCE_IN_MONTH_FROM_END"
  | "UI.GROUP.EVENTS.RECURRING_TYPE_LABEL"
  | "UI.GROUP.EVENTS.SELECT_GROUP_TO_CREATE_EVENT_FOR"
  | "UI.GROUP.EVENTS.SIGNED_UP"
  | "UI.GROUP.EVENTS.SIGNUP.ADDITIONAL_TITLE"
  | "UI.GROUP.EVENTS.SIGNUP.MESSAGE"
  | "UI.GROUP.EVENTS.SIGNUP.SIGNUP_ADDITIONAL_ACTION_BUTTON"
  | "UI.GROUP.EVENTS.SIGNUP.STATUSES.DONTKNOW"
  | "UI.GROUP.EVENTS.SIGNUP.STATUSES.NO"
  | "UI.GROUP.EVENTS.SIGNUP.STATUSES.YES"
  | "UI.GROUP.EVENTS.SIGNUP.TITLE"
  | "UI.GROUP.EVENTS.SIGNUPS"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.ALL"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.BY_GROUPINGS"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.EXITED_MEMBERS_SIGNUPS"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.EXTERNAL_SIGNUPS"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.MEMBERS"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.NOT_MEMBERS"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.REMOVED_MEMBER"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.SIGNED_UP_BY"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.SIGNUP_TIME_TOGGLE"
  | "UI.GROUP.EVENTS.SIGNUPS_LIST.SUMMARY"
  | "UI.GROUP.EVENTS.START_TIME_IN_TIME_ZONE"
  | "UI.GROUP.EVENTS.TIME"
  | "UI.GROUP.EVENTS.VIEW_TYPE_CALENDAR"
  | "UI.GROUP.EVENTS.VIEW_TYPE_LIST"
  | "UI.GROUP.INVITATION.ACCEPT"
  | "UI.GROUP.INVITATION.ACCEPTED"
  | "UI.GROUP.INVITATION.ALREADY_ACCEPTED"
  | "UI.GROUP.INVITATION.CONFIRM_ACCEPT_INVITATION"
  | "UI.GROUP.INVITATION.CONFIRM_ACCEPT_INVITATION_AS_SLAVE"
  | "UI.GROUP.INVITATION.CONFIRM_INVITATION_TEXT"
  | "UI.GROUP.INVITATION.CONFIRM_INVITATION_TEXT_ALREADY_MEMBER"
  | "UI.GROUP.INVITATION.HI"
  | "UI.GROUP.INVITATION.INVITATION_EMAIL_TEXT"
  | "UI.GROUP.INVITATION.INVITATION_SENT"
  | "UI.GROUP.INVITATION.JOIN_SLAVE_TITLE"
  | "UI.GROUP.INVITATION.NOT_LOGGED_IN"
  | "UI.GROUP.INVITATION.NO_SLAVES"
  | "UI.GROUP.INVITATION.SELECT_SLAVE"
  | "UI.GROUP.INVITATION.START_JOINING_SLAVE"
  | "UI.GROUP.INVITATION.USING_AS_SLAVE"
  | "UI.GROUP.LINKS.CALENDAR"
  | "UI.GROUP.LINKS.GDPR"
  | "UI.GROUP.LINKS.MEMBERS"
  | "UI.GROUP.LINKS.MY_DETAILS"
  | "UI.GROUP.LINKS.SETTINGS"
  | "UI.GROUP.LINKS.SETTINGS_EVENTS"
  | "UI.GROUP.LINKS.SETTINGS_FEATURES"
  | "UI.GROUP.LINKS.SETTINGS_LOOKUP_LISTS"
  | "UI.GROUP.LINKS.SETTINGS_ROLES"
  | "UI.GROUP.LINKS.STATISTICS"
  | "UI.GROUP.LINKS.WEB_PORTAL"
  | "UI.GROUP.MEMBERS.ADD"
  | "UI.GROUP.MEMBERS.ADD_FROM_SLAVES"
  | "UI.GROUP.MEMBERS.ADD_MEMBERS"
  | "UI.GROUP.MEMBERS.ADD_MEMBER_DIALOG_HEADER"
  | "UI.GROUP.MEMBERS.GROUPINGS.EDIT_MEMBERS"
  | "UI.GROUP.MEMBERS.GROUPINGS.NAME"
  | "UI.GROUP.MEMBERS.GROUPINGS.NAME_EXISTS"
  | "UI.GROUP.MEMBERS.GROUPINGS.SHOW_ALL"
  | "UI.GROUP.MEMBERS.GROUPINGS.SHOW_MEMBERS"
  | "UI.GROUP.MEMBERS.GROUPINGS.TITLE"
  | "UI.GROUP.MEMBERS.INVITE.ACTION"
  | "UI.GROUP.MEMBERS.INVITE.TITLE"
  | "UI.GROUP.MEMBERS.LEAVE_GROUP"
  | "UI.GROUP.MEMBERS.NOT_JOINED"
  | "UI.GROUP.MEMBERS.RESIGNED_FROM_GROUP"
  | "UI.GROUP.MEMBERS.RESIGN_FROM_GROUP_CONFIRMATION"
  | "UI.GROUP.MEMBERS.RESIGN_FROM_GROUP_FAILED"
  | "UI.GROUP.MEMBERS.SLAVE_IS_ALREADY_MEMBER"
  | "UI.GROUP.NEW_GROUP.CREATE.SUBTITLE"
  | "UI.GROUP.NEW_GROUP.CREATE.TITLE"
  | "UI.GROUP.NEW_GROUP.JOIN.DIALOG_TITLE"
  | "UI.GROUP.NEW_GROUP.JOIN.GROUP_NAME_BEGIN"
  | "UI.GROUP.NEW_GROUP.JOIN.JOIN_REQUEST_MESSAGE"
  | "UI.GROUP.NEW_GROUP.JOIN.NO_RESULTS"
  | "UI.GROUP.NEW_GROUP.JOIN.SELECT_GROUP"
  | "UI.GROUP.NEW_GROUP.JOIN.SEND_REQUEST_TO_JOIN"
  | "UI.GROUP.NEW_GROUP.JOIN.SUBTITLE"
  | "UI.GROUP.NEW_GROUP.JOIN.TITLE"
  | "UI.GROUP.SETTINGS.EVENTS.CALENDARS"
  | "UI.GROUP.SETTINGS.EVENTS.CALENDARS_HELP"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES_HELP"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS_HELP"
  | "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS"
  | "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS_HELP"
  | "UI.GROUP.SETTINGS.EVENTS.TITLE"
  | "UI.GROUP.SETTINGS.GENERAL.ALLOW_JOIN_REQUESTS"
  | "UI.GROUP.SETTINGS.GENERAL.GROUP_NAME"
  | "UI.GROUP.SETTINGS.GENERAL.SHORT_NAME"
  | "UI.GROUP.SETTINGS.GENERAL.TITLE"
  | "UI.GROUP.SETTINGS.LOOKUPS.ADD_LIST"
  | "UI.GROUP.SETTINGS.LOOKUPS.LIST_ADDED_OK"
  | "UI.GROUP.SETTINGS.LOOKUPS.NO_LISTS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TEXT"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TRANSLATIONS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MAYBE_STATUS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MESSAGE"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_NO_STATUS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_4"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_5"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_6"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.SET_DEFAULT"
  | "UI.PAGE_TITLES.CALENDAR"
  | "UI.PAGE_TITLES.EVENT_EDITOR_ADD"
  | "UI.PAGE_TITLES.EVENT_EDITOR_MODIFY"
  | "UI.PAGE_TITLES.LANDING"
  | "UI.PAGE_TITLES.LOGIN"
  | "UI.PAGE_TITLES.MAIN"
  | "UI.PAGE_TITLES.NOT_IMPLEMENTED"
  | "UI.PAGE_TITLES.PORTAL"
  | "UI.PAGE_TITLES.REGISTER"
  | "UI.PAGE_TITLES.USER"
  | "UI.USER.EMAILS.NOT_VERIFIED"
  | "UI.USER.EMAILS.NO_EMAILS"
  | "UI.USER.EMAIL_CONFIRMATION.ALREADY_CONFIRMED"
  | "UI.USER.EMAIL_CONFIRMATION.CONFIRMED"
  | "UI.USER.LINKED_ACCOUNTS.ACTIVATE.ACTIVATE"
  | "UI.USER.LINKED_ACCOUNTS.ACTIVATE.HELP"
  | "UI.USER.LINKED_ACCOUNTS.ACTIVATE.HELP_DIALOG"
  | "UI.USER.LINKED_ACCOUNTS.CURRENT_MASTER.RETURN"
  | "UI.USER.LINKED_ACCOUNTS.CURRENT_MASTER.USING"
  | "UI.USER.LINKED_ACCOUNTS.EXISTING_SLAVES.NO_SLAVES"
  | "UI.USER.LINKED_ACCOUNTS.EXISTING_SLAVES.TITLE"
  | "UI.USER.LINKED_ACCOUNTS.MASTERS.NO_MASTERS"
  | "UI.USER.LINKED_ACCOUNTS.MASTERS.SUCCESS_REMOVE"
  | "UI.USER.LINKED_ACCOUNTS.MASTERS.TITLE"
  | "UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.HELP"
  | "UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.TITLE"
  | "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.ERROR"
  | "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.ERROR_ALREADY_MASTER"
  | "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.HELP"
  | "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.SUCCESS"
  | "UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.TITLE"
  | "UI.USER.LINKED_ACCOUNTS.TITLE"
  | "UI.USER.PROFILE.TAB_NAV.BASIC"
  | "UI.USER.PROFILE.TAB_NAV.EMAIL"
  | "UI.USER.PROFILE.TAB_NAV.LINKED_ACCOUNTS"
  | "UI.VIEWS.FOOTER.CONTACT"
  | "UI.VIEWS.FOOTER.FAQ"
  | "UI.VIEWS.FOOTER.REPORT_PROBLEM"
  | "UI.VIEWS.SLIDING_MENU.LOGGED_IN_AS"
  | "UI.VIEWS.SLIDING_MENU.LOGIN"
  | "UI.VIEWS.SLIDING_MENU.LOGOUT"
  | "UI.VIEWS.SLIDING_MENU.MY_GROUPS"
  | "UI.VIEWS.SLIDING_MENU.MY_PROFILE"
  | "UI.VIEWS.SLIDING_MENU.RETURN_TO_MASTER"
  | "UI.VIEWS.SLIDING_MENU.SLAVE_ACCOUNTS";

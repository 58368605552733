import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslationService } from "@core/services";
import { RecursivePartial } from "@shared/models";

export interface ConfirmDialogTextsConfig {
  cancel: string;
  confirm: string;
  title: string;
  guide: string;
}

export interface ConfirmDialogConfig {
  texts: ConfirmDialogTextsConfig;
}

@Component({
  templateUrl: "./shared-confirm-dialog.component.html",
  styleUrls: ["./shared-confirm-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedConfirmDialogComponent implements OnInit {
  texts: ConfirmDialogTextsConfig;
  localizations = this.getTexts();
  constructor(
    @Inject(MAT_DIALOG_DATA) data: RecursivePartial<ConfirmDialogConfig>,
    private dialogRef: MatDialogRef<SharedConfirmDialogComponent>,
    private translationService: TranslationService
  ) {
    if (!data) {
      data = {
        texts: {},
      };
    }
    if (!data.texts) {
      data.texts = {};
    }
    data.texts!.confirm = data.texts?.confirm || this.localizations.confirm;
    data.texts!.cancel = data.texts?.cancel || this.localizations.cancel;
    data.texts!.title = data.texts?.title || this.localizations.confirmAction;
    data.texts!.guide =
      data.texts?.guide || this.localizations.confirmActionText;
    this.texts = data.texts as ConfirmDialogTextsConfig;
  }

  ngOnInit(): void {}

  /* These are here for example, see other way to close the dialog form the template */
  onCancelClick() {
    this.dialogRef.close(false);
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      confirm: dict.COMMON_UI.KEY_WORD.CONFIRM,
      cancel: dict.COMMON_UI.KEY_WORD.CANCEL,
      confirmAction: dict.COMMON_UI.KEY_WORD.CONFIRM_ACTION,
      confirmActionText: dict.COMMON_UI.KEY_WORD.CONFIRM_ACTION_TEXT,
    };
  }
}

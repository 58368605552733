// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { subsToUrl } from "./subs-to-url.func";
import { StatusDTO, GroupingMapsDTO, NameDTO } from "./dto.models";

@Injectable({
  providedIn: "root",
})
export class GroupingApiService {
  constructor(private http: HttpClient) {}

  createGrouping(groupId: string, dto: NameDTO): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/grouping/group/:groupId", { groupId });
    return this.http.post<StatusDTO>(url, dto);
  }

  deleteGrouping(groupId: string, groupingId: string): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/grouping/group/:groupId/grouping/:groupingId",
      { groupId, groupingId }
    );
    return this.http.delete<StatusDTO>(url);
  }

  updateGroupingName(
    groupId: string,
    groupingId: string,
    dto: NameDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/grouping/group/:groupId/grouping/:groupingId",
      { groupId, groupingId }
    );
    return this.http.put<StatusDTO>(url, dto);
  }

  addUserToGrouping(
    groupId: string,
    groupingId: string,
    userId: string
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/grouping/group/:groupId/grouping/:groupingId/member/:userId",
      { groupId, groupingId, userId }
    );
    return this.http.get<StatusDTO>(url);
  }

  removeUserFromGrouping(
    groupId: string,
    groupingId: string,
    userId: string
  ): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/grouping/group/:groupId/grouping/:groupingId/member/:userId",
      { groupId, groupingId, userId }
    );
    return this.http.delete<StatusDTO>(url);
  }

  getGroupsGroupings(groupId: string): Observable<GroupingMapsDTO> {
    const url = subsToUrl("/api/v1/pri/grouping/group/:groupId", { groupId });
    return this.http.get<GroupingMapsDTO>(url);
  }
}

// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { subsToUrl } from "./subs-to-url.func";
import {
  SearchUserDTO,
  NameDTO,
  GetUserDTO,
  NewUserDTO,
  FoundUsersDTO,
  NamesAndIdsDTO,
  ErrorLogsDTO,
  PerfLogsDTO,
} from "./dto.models";

@Injectable({
  providedIn: "root",
})
export class AdminApiService {
  constructor(private http: HttpClient) {}

  getErrorLogs(): Observable<ErrorLogsDTO> {
    const url = subsToUrl("/api/v1/pri/admin/error-logs", {});
    return this.http.get<ErrorLogsDTO>(url);
  }

  getPerfLogs(): Observable<PerfLogsDTO> {
    const url = subsToUrl("/api/v1/pri/admin/perf-logs", {});
    return this.http.get<PerfLogsDTO>(url);
  }

  createUserAsAdmin(dto: NewUserDTO): Observable<GetUserDTO> {
    const url = subsToUrl("/api/v1/pri/admin/create-user-as-admin", {});
    return this.http.post<GetUserDTO>(url, dto);
  }

  searchUsers(dto: SearchUserDTO): Observable<FoundUsersDTO> {
    const url = subsToUrl("/api/v1/pri/admin/search-users", {});
    return this.http.post<FoundUsersDTO>(url, dto);
  }

  getUserAsAdmin(userId: string): Observable<GetUserDTO> {
    const url = subsToUrl("/api/v1/pri/admin/user/:userId", { userId });
    return this.http.get<GetUserDTO>(url);
  }

  searchGroups(dto: NameDTO): Observable<NamesAndIdsDTO> {
    const url = subsToUrl("/api/v1/pri/admin/search", {});
    return this.http.post<NamesAndIdsDTO>(url, dto);
  }

  addUserToGroup(
    userId: string,
    groupId: string,
    roleType: string
  ): Observable<GetUserDTO> {
    const url = subsToUrl(
      "/api/v1/pri/admin/user/:userId/add-to-group/:groupId/role-type/:roleType",
      { userId, groupId, roleType }
    );
    return this.http.get<GetUserDTO>(url);
  }
}

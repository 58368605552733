import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { AdminApiService } from "@auto/index";
import { FindUserFormFactory } from "@ff/admin-find-user.ff";
import { SharedFormEditorConfig } from "@shared/components";
import { catchError, firstValueFrom, of, tap } from "rxjs";
import { FoundUserDTO, NewUserDTO } from "@auto/dto.models";
import { TokenStore } from "@core/services/token.store";
import { NavigationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { ROUTE_MAP } from "@core/routing";
import { AdminUserService } from "@admin/services";
import { ToasterService } from "@shared/services";

@Component({
  selector: "admin-route-find-user",
  templateUrl: "./admin-route-find-user.component.html",
  styleUrls: ["./admin-route-find-user.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminRouteFindUserComponent implements OnInit {
  config!: SharedFormEditorConfig;
  users: FoundUserDTO[] = [];
  constructor(
    private ff: FindUserFormFactory,
    private api: AdminApiService,
    private cdr: ChangeDetectorRef,
    private tokenStore: TokenStore,
    private nav: NavigationService,
    private userService: UserService,
    private adminUserService: AdminUserService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    const form = this.ff.createForm();
    this.config = this.ff.formGroupToConfig(form, this.search.bind(this));
  }

  registerCallback(): (dto: NewUserDTO) => Promise<true> {
    return async (dto: NewUserDTO) => {
      try {
        const response = await firstValueFrom(
          this.adminUserService.addUser(dto)
        );
        console.log(response);
        this.toaster.showSuccess("User added successfully");
      } catch (error) {
        console.log(error);
        this.toaster.showError("Failed to add user");
      }
      return true;
    };
  }

  private search(searchStr: string) {
    return firstValueFrom(
      this.api.searchUsers({ searchStr }).pipe(
        tap((i) => {
          this.users = i.collection;
          this.cdr.detectChanges();
        }),
        catchError((err, caught) => {
          console.log("no");
          return of(false);
        })
      )
    );
  }

  async pickUser(i: FoundUserDTO) {
    this.tokenStore.setSlaveUserId(i.id);
    await this.userService.refresh();
    this.nav.goto(ROUTE_MAP.PRI.EVENTS);
  }

  viewUser(user: FoundUserDTO) {
    this.nav.goto(ROUTE_MAP.PRI.ADMIN.MANAGE_USER, {
      userId: user.id,
    });
  }
}

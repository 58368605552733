<ng-container *ngIf="user">
  <h1>{{ user.givenName }} {{ user.familyName }}</h1>

  <h2>Groups</h2>

  <h3>Current memberships</h3>

  <ul>
    <li *ngFor="let i of user.groups">
      <b>{{ i.groupName }}</b> Name in group: {{ i.displayName }} | role type:
      {{ i.roleType }} | group type: {{ i.groupType }}
    </li>
  </ul>

  <h3>Add user to new group</h3>

  <form [formGroup]="searchForm">
    <div class="col-container">
      <div class="col-xs-12 col-s-6 col-m-4 col-l-3 col-xl-2">
        <mat-form-field class="full-width">
          <mat-label
            translate="UI.GROUP.NEW_GROUP.JOIN.GROUP_NAME_BEGIN"
          ></mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="name"
            required
            data-t-id="join-group-input"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
  <shared-loading-button
    name="create-group-button"
    [disabled]="searchForm.status !== 'VALID'"
    [cb]="searchCallback()"
    key="COMMON_UI.KEY_WORD.SEARCH"
  >
  </shared-loading-button>

  <ng-container *ngIf="groups.length > 0">
    <ul>
      <li *ngFor="let i of groups">
        {{ i.name }}
        <button
          mat-flat-button
          color="primary"
          (click)="addUserToGroup(i, true)"
          class="tiny first-button"
        >
          Add to group as admin
        </button>

        <button
          mat-flat-button
          color="primary"
          (click)="addUserToGroup(i, false)"
          class="tiny"
        >
          Add to group as member
        </button>
      </li>
    </ul>
  </ng-container>
</ng-container>

import { Directive, HostListener, Input } from "@angular/core";
import { Observable } from "rxjs";
import { ConfirmActionService } from "../services/confirm-action.service";

@Directive({
  selector: "[sharedConfirmClick]",
})
export class SharedConfirmClickDirective {
  @Input() sharedConfirmClick!: () => Observable<any> | Promise<any> | void;

  @HostListener("click") async onClick() {
    const toConfirm = await this.confirmModalService.confirm();
    if (!toConfirm) {
      return false;
    }
    // Open confirm modal
    this.sharedConfirmClick();

    return false;
  }

  constructor(private confirmModalService: ConfirmActionService) {}
}

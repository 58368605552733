import { Platform } from "@angular/cdk/platform";
import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
// import { UserService } from "@core/services/user.service";

@Injectable()
export class SharedDateAdapter extends NativeDateAdapter {
  //constructor(userService: UserService, platform: Platform) {
  constructor(platform: Platform) {
    super("fi-FI", platform);
    //userService.user$.subscribe((i) => super.setLocale(i.locale!));
  }
  override getFirstDayOfWeek(): number {
    // TODO: Make this user setting
    return 1;
  }
}

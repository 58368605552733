<div [ngClass]="type">
  <ng-container [ngSwitch]="type">
    <mat-icon *ngSwitchCase="'INFO'" fontSet="fa" fontIcon="fa-info-circle">
    </mat-icon>
    <mat-icon *ngSwitchCase="'ERROR'" fontSet="fa" fontIcon="fa-thumbs-down">
    </mat-icon>
    <mat-icon
      *ngSwitchCase="'WARNING'"
      fontSet="fa"
      fontIcon="fa-exclamation-triangle"
    >
    </mat-icon>
    <mat-icon *ngSwitchCase="'OK'" fontSet="fa" fontIcon="fa-thumbs-up">
    </mat-icon>
  </ng-container>

  {{ text }}
</div>

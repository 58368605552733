import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ErrorEnum, StatusEnum } from "@auto/dto.models";
import { TRANSLATIONS } from "@auto/translations.models";
import { UserService } from "@core/services/user.service";
import { UserProfileAddMasterAccountFormFactory } from "@ff/user-profile-add-master-account.ff";
import { SharedFormEditorConfig } from "@shared/components";
import { ToasterService } from "@shared/services";
import { BusyService } from "@shared/services/busy.service";
import { firstValueFrom } from "rxjs";

@Component({
  templateUrl: "./user-add-master-dialog.component.html",
  styleUrls: ["./user-add-master-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAddMasterDialogComponent implements OnInit {
  config!: SharedFormEditorConfig;

  private form!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<UserAddMasterDialogComponent>,
    private ff: UserProfileAddMasterAccountFormFactory,
    private userService: UserService,
    private busyService: BusyService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.form = this.ff.createForm();
    this.config = this.ff.formGroupToConfig(
      this.form,
      () => this.addNew(),
      () => this.onCancelClick()
    );
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  private async addNew() {
    const busyId = this.busyService.show();
    const response = await firstValueFrom(
      this.userService.addMaster(this.form.value)
    );
    console.log(response);
    this.busyService.hide(busyId);
    if (response.status === StatusEnum.OK) {
      this.toaster.showSuccessTranslationKey(
        TRANSLATIONS.UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.SUCCESS
      );
      this.dialogRef.close(true);
    } else if (response.message) {
      switch (response.message) {
        case ErrorEnum.INVALID_CREDENTIALS:
          this.toaster.showErrorTranslationKey(
            TRANSLATIONS.UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG.ERROR
          );
          break;
        case ErrorEnum.ALREADY_SLAVE:
          this.toaster.showErrorTranslationKey(
            TRANSLATIONS.UI.USER.LINKED_ACCOUNTS.NEW_MASTER_DIALOG
              .ERROR_ALREADY_MASTER
          );
          break;
      }
    }
    return true;
  }
}

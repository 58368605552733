<span class="toolbar__item__text--with-icon" *ngIf="isGroupRestriction">
  {{restrictedGroupName}}
</span>

<ng-container *ngIf="!isGroupRestriction">
  <span class="toolbar__item__text--with-icon" *ngIf="selectedGroup && selectedGroup.type !== 'PERSONAL'" data-t-id="selected-group-name">
    {{selectedGroup.name}}
  </span>
  <span class="toolbar__item__text--with-icon" *ngIf="!selectedGroup || selectedGroup.type === 'PERSONAL'" data-t-id="selected-group-name"
    translate="COMMON_UI.KEY_WORD.ALL_GROUPS">
  </span>
  <mat-icon class="toolbar__item__text--icon" fontSet="fa" fontIcon="fa-caret-down"></mat-icon>

  <div class="dropdown" *ngIf="isGroupSelectorOpen">

    <!-- (clickOutside)="closeGroupSelector($event)"> -->
    <div class="nav-container--standalone">
      <div class="nav-container--show-more nav-container--show-more--left">
        <!-- Add here select all groups -->
        <span class="link link--show-more dropdown--come-in nowrap" *ngIf="selectedGroup" data-t-id="select-all-groups"
          translate="COMMON_UI.KEY_WORD.ALL_GROUPS" (click)="selectGroup()"></span>
        <ng-container *ngFor="let g of groups">
          <ng-container *ngIf="g.groupType !== 'PERSONAL'">
            <span (click)="selectGroup(g)" data-t-id="select-group"
              class="link link--show-more dropdown--come-in nowrap">
              {{g.groupName}}
            </span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
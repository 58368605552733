<h1>Errors in logs</h1>
<shared-loading *ngIf="!errors"></shared-loading>

<ng-container *ngIf="errors">
  <div class="col-container" *ngFor="let i of errors">
    <div class="col-xs-2">
      <events-datetime-view [start]="i.created * 1000"></events-datetime-view>
    </div>
    <div class="col-xs-2">
      {{ i.source }}
    </div>
    <div class="col-xs-3">
      {{ i.path }}
    </div>
    <div class="col-xs-4">
      {{ i.message }}
    </div>
    <div class="col-xs-1">
      <button
        mat-flat-button
        color="primary"
        (click)="viewTrace(i)"
        class="tiny"
      >
        Trace
      </button>
    </div>
  </div>
</ng-container>

import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TRANSLATIONS } from "@auto/index";
import { TranslationService } from "@core/services";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";

import { FF_DEFAULT_CLASS_LIST, FormFactoryBase } from "./form-factory.base";

const MIN_YEAR = new Date().getFullYear() - 110;
const MAX_YEAR = new Date().getFullYear();

@Injectable({
  providedIn: "root",
})
export class UserProfileCreateChildAccountFormFactory extends FormFactoryBase {
  constructor(
    private fb: FormBuilder,
    private utils: SharedFormEditorUtil,
    private tx: TranslationService
  ) {
    super();
  }

  createForm(): FormGroup {
    return this.fb.group({
      givenName: ["", Validators.required],
      familyName: ["", Validators.required],
      birthYear: [
        "",
        Validators.compose([
          Validators.required,
          Validators.min(MIN_YEAR),
          Validators.max(MAX_YEAR),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
      ],
    });
  }

  formGroupToConfig(fg: FormGroup, save: () => Promise<any>) {
    const config: SharedEnrichableFormEditorConfig = {
      form: fg,
      fields: [
        {
          classList: FF_DEFAULT_CLASS_LIST,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.GIVEN_NAME,
          formControlName: "givenName",
        },
        {
          classList: FF_DEFAULT_CLASS_LIST,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.FAMILY_NAME,
          formControlName: "familyName",
        },
        {
          classList: FF_DEFAULT_CLASS_LIST,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.BIRTH_YEAR,
          formControlName: "birthYear",
          error: {
            label: this.getBirthYearErrorLabel(),
          },
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.ADD_NEW,
          type: "button.loading",
          callback: () => save(),
        },
      ],
    };
    return this.utils.enrichConfigToDefaults(config);
  }

  private getBirthYearErrorLabel() {
    return this.tx.substitute(
      this.tx.dict().COMMON_UI.KEY_WORD.BIRTH_YEAR_VALIDATION,
      {
        minYear: MIN_YEAR,
        maxYear: MAX_YEAR,
      }
    );
  }
}

import { Routes } from "@angular/router";
import {
  DirtyStateTrackerService,
  CanActivateWithCurrentGroup,
  CanActivateUserLoggedIn,
} from "@shared/services";
import { CanActivateWithTranslations } from "@core/services/translation.guard";
import { EventsRouteAllGroupsComponent } from "@events/index";
import { GroupRouteAcceptInvitationComponent } from "@group/index";
import { UserRouteRegisterComponent } from "@user/index";
import { UserRouteLoginComponent } from "@user/index";
import { LandingRouteComponent } from "@landing/index";
import { GroupRouteSettingsComponent } from "@group/index";
import { AdminRouteMainComponent } from "@admin/index";
import { AdminRouteErrorsComponent } from "@admin/index";
import { AdminRoutePerfComponent } from "@admin/index";
import { CoreRouteNotFoundComponent } from "@core/index";
import { GroupRouteEventsComponent } from "@group/index";
import { AdminRouteManageUserComponent } from "@admin/index";
import { EventsRouteEditorComponent } from "@events/index";
import { EventsRouteAfterAddedComponent } from "@events/index";
import { GroupRouteNewComponent } from "@group/index";
import { UserLoginOrRegisterComponent } from "@user/index";
import { AdminRouteFindUserComponent } from "@admin/index";
import { GroupRouteMembersComponent } from "@group/index";
import { EventsRoutePageComponent } from "@events/index";
import { UserRouteConfirmEmailComponent } from "@user/index";
import { UserRouteProfileComponent } from "@user/index";
import { UserRouteMyEmailsComponent } from "@user/index";
import { GroupRouteMyGroupsComponent } from "@group/index";
import { UserRouteLinkedAccountsComponent } from "@user/index";

export const ROUTES: Routes = [
  {
    path: "pub/home",
    canActivate: [CanActivateWithTranslations],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: true,
    },
    component: LandingRouteComponent,
  },
  {
    path: "pub/login",
    canActivate: [CanActivateWithTranslations],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isLoginPage: true,
      isPublic: true,
    },
    component: UserRouteLoginComponent,
  },
  {
    path: "pub/register",
    canActivate: [CanActivateWithTranslations],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: true,
    },
    component: UserRouteRegisterComponent,
  },
  {
    path: "pub/login-or-register",
    canActivate: [CanActivateWithTranslations],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: true,
    },
    component: UserLoginOrRegisterComponent,
  },
  {
    path: "pub/accept-invitation",
    canActivate: [CanActivateWithTranslations],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: true,
    },
    component: GroupRouteAcceptInvitationComponent,
  },
  {
    path: "pub/confirm-email/:userId/:emailId",
    canActivate: [CanActivateWithTranslations],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: true,
    },
    component: UserRouteConfirmEmailComponent,
  },
  {
    path: "pub/not-found",
    canActivate: [CanActivateWithTranslations],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: true,
    },
    component: CoreRouteNotFoundComponent,
  },
  {
    path: "pri/admin/home",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      secondNav: "ADMIN",
      isPublic: false,
    },
    component: AdminRouteMainComponent,
  },
  {
    path: "pri/admin/errors",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      secondNav: "ADMIN",
      isPublic: false,
    },
    component: AdminRouteErrorsComponent,
  },
  {
    path: "pri/admin/perf",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      secondNav: "ADMIN",
      isPublic: false,
    },
    component: AdminRoutePerfComponent,
  },
  {
    path: "pri/admin/find-user",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      secondNav: "ADMIN",
      isPublic: false,
    },
    component: AdminRouteFindUserComponent,
  },
  {
    path: "pri/admin/manage-user/:userId",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      secondNav: "ADMIN",
      isPublic: false,
    },
    component: AdminRouteManageUserComponent,
  },
  {
    path: "pri/new-group",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: false,
    },
    component: GroupRouteNewComponent,
  },
  {
    path: "pri/user/profile",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: false,
    },
    component: UserRouteProfileComponent,
  },
  {
    path: "pri/user/emails",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: false,
    },
    component: UserRouteMyEmailsComponent,
  },
  {
    path: "pri/user/linked-accounts",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: false,
    },
    component: UserRouteLinkedAccountsComponent,
  },
  {
    path: "pri/user/my-groups",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: false,
    },
    component: GroupRouteMyGroupsComponent,
  },
  {
    path: "pri/events",
    canActivate: [CanActivateWithTranslations, CanActivateUserLoggedIn],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isPublic: false,
    },
    component: EventsRouteAllGroupsComponent,
  },
  {
    path: "pri/group/:groupId/members",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: GroupRouteMembersComponent,
  },
  {
    path: "pri/group/:groupId/members/groupings/:groupingId",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      groupings: true,
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: GroupRouteMembersComponent,
  },
  {
    path: "pri/group/:groupId/events/calendar",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: GroupRouteEventsComponent,
  },
  {
    path: "pri/group/:groupId/events/:eventId/added",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: EventsRouteAfterAddedComponent,
  },
  {
    path: "pri/group/:groupId/events/add",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: EventsRouteEditorComponent,
  },
  {
    path: "pri/group/:groupId/events/:eventId/edit",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      edit: true,
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: EventsRouteEditorComponent,
  },
  {
    path: "pri/group/:groupId/events/:eventId/view",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: EventsRoutePageComponent,
  },
  {
    path: "pri/group/:groupId/settings",
    canActivate: [
      CanActivateWithTranslations,
      CanActivateUserLoggedIn,
      CanActivateWithCurrentGroup,
    ],
    canDeactivate: [DirtyStateTrackerService],
    data: {
      isGroup: true,
      secondNav: "GROUP",
      isPublic: false,
    },
    component: GroupRouteSettingsComponent,
  },
];

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";

declare var $: any;

@Component({
  selector: "shared-trumbowyg",
  templateUrl: "./shared-trumbowyg.component.html",
  styleUrls: ["./shared-trumbowyg.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedTrumbowygComponent implements OnInit {
  @ViewChild("element") set div(value: ElementRef) {
    if (value) {
      this.initialize(value);
    }
  }

  @Input() form!: FormControl;
  @Input() label = "";

  constructor() {}

  ngOnInit(): void {}

  private initialize(div: ElementRef) {
    $.trumbowyg.svgPath = "assets/trumbowyg.icons.svg";
    $(div.nativeElement)
      .trumbowyg({
        lang: "fi",
        defaultLinkTarget: "blank",
        btns: [
          ["formatting"],
          ["link"],
          ["strong", "em", "del"],
          ["unorderedList", "orderedList"],
          ["table"],
          ["preformatted"],
          ["foreColor", "backColor"],
          ["horizontalRule"],
          ["removeformat"],
        ],
      })
      .on("tbwchange", (i: any) => {
        const value = $(i.currentTarget).trumbowyg("html");
        this.form.setValue(value);
      });

    $(div.nativeElement).trumbowyg("html", this.form.value);
  }
}

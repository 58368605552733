// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { subsToUrl } from "./subs-to-url.func";
import {
  UserEmailsDTO,
  CreateSlavelUserDTO,
  StatusDTO,
  NameDTO,
  GetUserDTO,
  BasicUserDTO,
  NewUserDTO,
  UpdateUserSettingsDTO,
  LoginRequestDTO,
} from "./dto.models";

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  constructor(private http: HttpClient) {}

  register(dto: NewUserDTO): Observable<GetUserDTO> {
    const url = subsToUrl("/api/v1/pub/user/register", {});
    return this.http.post<GetUserDTO>(url, dto);
  }

  login(dto: LoginRequestDTO): Observable<GetUserDTO> {
    const url = subsToUrl("/api/v1/pub/user/login", {});
    return this.http.post<GetUserDTO>(url, dto);
  }

  confirmUserEmail(userId: string, emailId: string): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pub/user/confirm-email/:userId/:emailId", {
      userId,
      emailId,
    });
    return this.http.get<StatusDTO>(url);
  }

  getMe(): Observable<GetUserDTO> {
    const url = subsToUrl("/api/v1/pri/user/me", {});
    return this.http.get<GetUserDTO>(url);
  }

  logout(): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/user/logout", {});
    return this.http.get<StatusDTO>(url);
  }

  updateMe(dto: UpdateUserSettingsDTO): Observable<GetUserDTO> {
    const url = subsToUrl("/api/v1/pri/user/me", {});
    return this.http.put<GetUserDTO>(url, dto);
  }

  myEmails(): Observable<UserEmailsDTO> {
    const url = subsToUrl("/api/v1/pri/user/emails", {});
    return this.http.get<UserEmailsDTO>(url);
  }

  removeEmail(dto: NameDTO): Observable<UserEmailsDTO> {
    const url = subsToUrl("/api/v1/pri/user/remove-email", {});
    return this.http.put<UserEmailsDTO>(url, dto);
  }

  addEmail(dto: NameDTO): Observable<UserEmailsDTO> {
    const url = subsToUrl("/api/v1/pri/user/email", {});
    return this.http.put<UserEmailsDTO>(url, dto);
  }

  createSlaveAccount(dto: CreateSlavelUserDTO): Observable<BasicUserDTO> {
    const url = subsToUrl("/api/v1/pri/user/slave-account", {});
    return this.http.post<BasicUserDTO>(url, dto);
  }

  addMasterAccount(dto: LoginRequestDTO): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/user/add-master", {});
    return this.http.post<StatusDTO>(url, dto);
  }

  removeMasterAccount(masterUserId: string): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/user/master/:masterUserId", {
      masterUserId,
    });
    return this.http.delete<StatusDTO>(url);
  }

  activateSlaveAccount(dto: LoginRequestDTO): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/user/slave-account/activate", {});
    return this.http.post<StatusDTO>(url, dto);
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { EventsModule } from "@events/events.module";
import { SharedModule } from "@shared/shared.module";
import { UserModule } from "@user/user.module";
import {
  GroupRouteAcceptInvitationComponent,
  GroupRouteMembersComponent,
  GroupRouteNewComponent,
  GroupRouteEventsComponent,
  GroupSettingsGeneralComponent,
  GroupRouteSettingsComponent,
  MembersGroupingEditorDialogComponent,
  GroupRouteMyGroupsComponent,
  MembersAddFromChildrenDialogComponent,
  MembersInviteDialogComponent,
} from "./components";

@NgModule({
  declarations: [
    GroupRouteAcceptInvitationComponent,
    GroupSettingsGeneralComponent,
    GroupRouteMembersComponent,
    GroupRouteNewComponent,
    GroupRouteEventsComponent,
    GroupRouteSettingsComponent,
    MembersGroupingEditorDialogComponent,
    GroupRouteMyGroupsComponent,
    MembersAddFromChildrenDialogComponent,
    MembersInviteDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    EventsModule,
    MatMenuModule,
    UserModule,
  ],
})
export class GroupModule {}

<div class="container">
  <!--
    <div class="area">
        <div class="item">
            <a href="" translate="UI.VIEWS.FOOTER.CONTACT"></a>
        </div>
        <div class="item">
            <a href="" translate="UI.VIEWS.FOOTER.REPORT_PROBLEM"></a>
        </div>    
        <div class="item">
            <a href="" translate="UI.VIEWS.FOOTER.FAQ"></a>
        </div>  
    </div>
    -->
  <div class="area">
    <img
      src="./assets/images/ilmo.png"
      alt="ILMO"
      width="80px"
      height="45px"
      class="logo"
    />
    © 2022-{{ thisYear }}
  </div>
</div>

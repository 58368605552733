<h1 mat-dialog-title>
  <ng-container *ngIf="isAddingNew">
    <span translate="COMMON_UI.KEY_WORD.ADD_NEW"></span>
  </ng-container>
  <ng-container *ngIf="!isAddingNew">
    <span translate="COMMON_UI.KEY_WORD.MODIFY"></span>
  </ng-container>
</h1>
<div mat-dialog-content>
  <shared-form-editor [config]="config"></shared-form-editor>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="secondary"
    translate="COMMON_UI.KEY_WORD.CANCEL"
    (click)="cancel()"
  ></button>

  <shared-loading-button
    class="margin-to-right margin-to-bottom"
    [disabled]="!shouldEnableSave"
    [cb]="getSaveCabllback()"
  ></shared-loading-button>
</div>

<shared-loading *ngIf="!invitation || loggedIn === undefined"></shared-loading>

<ng-container *ngIf="invitation && loggedIn !== undefined">
  <h2
    translate="UI.GROUP.INVITATION.HI"
    [par]="{ displayName: invitation.displayName }"
  ></h2>
  <ng-container *ngIf="!loggedIn">
    <p
      translate="UI.GROUP.INVITATION.NOT_LOGGED_IN"
      [par]="{ groupName: invitation.groupName }"
    ></p>
    <user-login-or-register
      [showLogin]="true"
      [showRegister]="true"
      [showTitle]="true"
    ></user-login-or-register>
  </ng-container>
  <ng-container *ngIf="loggedIn">
    <p [innerHtml]="invitationText"></p>

    <shared-message
      type="INFO"
      *ngIf="isUsingAsSlave"
      key="UI.GROUP.INVITATION.USING_AS_SLAVE"
      [params]="{ displayName: displayName }"
    >
    </shared-message>

    <ng-container *ngIf="!isGroupMember">
      <shared-loading-button
        matType="mat-flat-button"
        [cb]="getConfirmCallback()"
        key="UI.GROUP.INVITATION.ACCEPT"
        cls="margin"
        [confirm]="true"
        [confirmTexts]="{
          texts: { guide: getAcceptInvitationAsMeGuide() }
        }"
      ></shared-loading-button>

      <button
        mat-flat-button
        color="primary"
        *ngIf="!joinSlave"
        (click)="showJoinSlave()"
        translate="UI.GROUP.INVITATION.START_JOINING_SLAVE"
        class="margin"
      ></button>
    </ng-container>
    <ng-container *ngIf="joinSlave">
      <h2 translate="UI.GROUP.INVITATION.JOIN_SLAVE_TITLE"></h2>
      <ng-container *ngIf="slaves.length > 0">
        <p translate="UI.GROUP.INVITATION.SELECT_SLAVE"></p>
        <ul>
          <li *ngFor="let i of slaves">
            <shared-loading-button
              [cb]="getConfirmCallback(i)"
              text="{{ i.givenName + ' ' + i.familyName }}"
              [confirm]="true"
              [confirmTexts]="{
                texts: { guide: getJoinUserConfirmGuide(i) }
              }"
            ></shared-loading-button>
          </li>
        </ul>
      </ng-container>
      <p
        *ngIf="slaves.length === 0"
        translate="UI.GROUP.INVITATION.NO_SLAVES"
      ></p>
      <div>
        <button
          mat-flat-button
          color="primary"
          (click)="createSlaveAccount()"
          translate="UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.TITLE"
        ></button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

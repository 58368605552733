import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TRANSLATIONS } from "@auto/translations.models";
import { TranslationService } from "@core/services/translation.service";
import { GroupService } from "@group/services/group.service";
import { SharedFormEditorUtil } from "@shared/components";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorConfig,
} from "@shared/components/shared-form-editor/shared-form-editor.model";
import { DirtyStateTrackerService } from "@shared/services";
import { CurrentGroupService } from "@shared/services/current-group.service";
import { ToasterService } from "@shared/services/toaster.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

const DIRTY_KEY = "GENERAL_EVENT_EDITOR";

@Component({
  selector: "group-settings-general",
  templateUrl: "./group-settings-general.component.html",
  styleUrls: ["./group-settings-general.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupSettingsGeneralComponent implements OnInit {
  config!: SharedFormEditorConfig;

  constructor(
    private fb: FormBuilder,
    private service: GroupService,
    private currentGroupService: CurrentGroupService,
    private translationService: TranslationService,
    private toasterService: ToasterService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private sharedFormEditorUtil: SharedFormEditorUtil
  ) {}

  ngOnInit() {
    const form = this.initForm();
    this.config = this.formGroupToConfig(form);
  }

  private initForm() {
    const currentGroup = this.currentGroupService.currentGroup;
    return this.fb.group({
      name: [currentGroup.name, Validators.required],
      shortName: [currentGroup.shortName],
      allowJoinRequests: [currentGroup.allowJoinRequests],
    });
  }

  formGroupToConfig(fg: FormGroup): SharedFormEditorConfig {
    const config: SharedEnrichableFormEditorConfig = {
      form: fg,
      dirtyStateTracker: DIRTY_KEY,
      formFieldClassList: ["full-width"],
      fields: [
        {
          classList: ["col-xs-12", "max-width-500"],
          labelKey: TRANSLATIONS.UI.GROUP.SETTINGS.GENERAL.GROUP_NAME,
          formControlName: "name",
        },
        {
          classList: ["col-xs-12", "max-width-500"],
          labelKey: TRANSLATIONS.UI.GROUP.SETTINGS.GENERAL.SHORT_NAME,
          formControlName: "shortName",
        },
        {
          classList: ["col-xs-12", "max-width-500"],
          labelKey: TRANSLATIONS.UI.GROUP.SETTINGS.GENERAL.ALLOW_JOIN_REQUESTS,
          type: "slideToggle",
          formControlName: "allowJoinRequests",
        },
        {
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.SAVE,
          type: "button.loading",
          callback: this.getSaveCallback(fg),
        },
      ],
    };
    return this.sharedFormEditorUtil.enrichConfigToDefaults(config);
  }

  getSaveCallback(fg: FormGroup): () => Observable<any> {
    return () => {
      this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
      return this.service.updateGroup(
        this.currentGroupService.currentGroupId,
        fg.value
      );
    };
  }
}

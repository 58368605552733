// This file is automatically generated. Don't edit. Created 2023-08-18 16:10:51.
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { subsToUrl } from "./subs-to-url.func";
import {
  StatusDTO,
  IdDTO,
  NameDTO,
  FullGroupDTO,
  GetUserDTO,
  GroupMemberDTO,
  UpdateGroupDTO,
  GroupMembersInvitationDTO,
  GroupMembersDTO,
  GroupInvitationDTO,
  UpdateGroupMemberDTO,
} from "./dto.models";

@Injectable({
  providedIn: "root",
})
export class GroupApiService {
  constructor(private http: HttpClient) {}

  getGroupMemberInvitation(
    groupId: string,
    invitationId: string
  ): Observable<GroupInvitationDTO> {
    const url = subsToUrl(
      "/api/v1/pub/group/:groupId/invitation/:invitationId",
      { groupId, invitationId }
    );
    return this.http.get<GroupInvitationDTO>(url);
  }

  createGroup(dto: NameDTO): Observable<IdDTO> {
    const url = subsToUrl("/api/v1/pri/group", {});
    return this.http.post<IdDTO>(url, dto);
  }

  updateGroup(groupId: string, dto: UpdateGroupDTO): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/group/:groupId", { groupId });
    return this.http.put<StatusDTO>(url, dto);
  }

  getGroup(groupId: string): Observable<FullGroupDTO> {
    const url = subsToUrl("/api/v1/pri/group/:groupId", { groupId });
    return this.http.get<FullGroupDTO>(url);
  }

  getGroupMembers(groupId: string): Observable<GroupMembersDTO> {
    const url = subsToUrl("/api/v1/pri/group/:groupId/members", { groupId });
    return this.http.get<GroupMembersDTO>(url);
  }

  inviteGroupMembers(
    groupId: string,
    dto: GroupMembersInvitationDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/group/:groupId/invite-members", {
      groupId,
    });
    return this.http.post<StatusDTO>(url, dto);
  }

  addFromMySlave(groupId: string, slaveId: string): Observable<StatusDTO> {
    const url = subsToUrl(
      "/api/v1/pri/group/:groupId/add-from-my-slave/:slaveId",
      { groupId, slaveId }
    );
    return this.http.get<StatusDTO>(url);
  }

  leaveGroup(groupId: string, memberId: string): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/group/:groupId/leave/:memberId", {
      groupId,
      memberId,
    });
    return this.http.delete<StatusDTO>(url);
  }

  deleteGroupMember(groupId: string, memberId: string): Observable<StatusDTO> {
    const url = subsToUrl("/api/v1/pri/group/:groupId/member/:memberId", {
      groupId,
      memberId,
    });
    return this.http.delete<StatusDTO>(url);
  }

  saveGroupMember(
    groupId: string,
    memberId: string,
    dto: UpdateGroupMemberDTO
  ): Observable<GroupMemberDTO> {
    const url = subsToUrl("/api/v1/pri/group/:groupId/member/:memberId", {
      groupId,
      memberId,
    });
    return this.http.put<GroupMemberDTO>(url, dto);
  }

  acceptGroupMemberInvitation(
    groupId: string,
    invitationId: string
  ): Observable<GetUserDTO> {
    const url = subsToUrl(
      "/api/v1/pri/group/:groupId/invitation/:invitationId/accept",
      { groupId, invitationId }
    );
    return this.http.get<GetUserDTO>(url);
  }
}

import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  SharedConfirmDialogComponent,
  ConfirmDialogConfig,
} from "@shared/components";
import { RecursivePartial } from "@shared/models";

@Injectable({
  providedIn: "root",
})
export class ConfirmActionService {
  constructor(private dialog: MatDialog) {}
  confirm(texts?: RecursivePartial<ConfirmDialogConfig>) {
    return new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.dialog.open(SharedConfirmDialogComponent, {
        data: texts,
      });

      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }
}

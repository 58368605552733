import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from "@angular/core";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService } from "@core/services";

@Component({
  selector: "events-cog-menu",
  templateUrl: "./events-cog-menu.component.html",
  styleUrls: ["./events-cog-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsCogMenuComponent implements OnInit {
  @Input() groupId!: string;
  @Input() eventId!: string;

  isOpen = false;

  constructor(private cdr: ChangeDetectorRef, private nav: NavigationService) {}

  ngOnInit(): void {}

  menuOpened() {
    this.isOpen = true;
    this.cdr.detectChanges();
  }

  menuClosed() {
    this.isOpen = false;
    this.cdr.detectChanges();
  }

  edit() {
    this.nav.goto(ROUTE_MAP.PRI.GROUP.EVENTS.EDIT, {
      groupId: this.groupId,
      eventId: this.eventId,
    });
  }
}

<div class="col-container main-line" *ngIf="event">
  <div
    class="col-xs-6 col-m-3 name"
    [ngClass]="{
      'with-group': type === 'my'
    }"
  >
    <div class="actions-container">
      <a
        class="event-name"
        [ngClass]="{ thin: thin }"
        [routerLink]="event.link"
        >{{ event.name }}</a
      >
      <div *ngIf="hasPermission">
        <button
          class="menu-button"
          (menuOpened)="menuOpened()"
          (menuClosed)="menuClosed()"
          [matMenuTriggerFor]="menu"
        >
          <i class="fa fa-cog"></i>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngIf="menuOpen">
            <button
              mat-menu-item
              (click)="editEvent()"
              translate="COMMON_UI.KEY_WORD.MODIFY"
            ></button>
            <button
              mat-menu-item
              (click)="deleteEvent()"
              translate="COMMON_UI.KEY_WORD.DELETE"
            ></button>
          </ng-container>
        </mat-menu>
      </div>
    </div>
    <span class="group-name" *ngIf="type === 'my'">
      {{ event.groupName }}
    </span>
  </div>
  <div class="col-xs-6 col-m-3">
    <events-datetime-view
      [start]="event.startTime"
      [end]="event.endTime"
    ></events-datetime-view>
  </div>
  <div class="col-xs-7 col-m-3">
    {{ event.location?.name }} {{ event.locationSuffix }}
  </div>
  <div class="col-xs-1 clickable" (click)="toggleEventDetails(event)">
    <div class="details-icon-container">
      <mat-icon
        [ngClass]="{ rotate: true, rotate180: event.isExtended }"
        fontSet="fa"
        fontIcon="fa-caret-down"
      ></mat-icon>
    </div>
  </div>
  <div class="col-xs-1">
    <span class="sign-up-count" (click)="showSignUps(event)">
      {{
        event.signUpStatusCounts === undefined
          ? 0
          : $any(event.signUpStatusCounts).YES ?? 0
      }}
    </span>
  </div>
  <div class="col-xs-2 col-s-1 align-right">
    <events-sign-up
      [ngClass]="{ thin: thin }"
      [event]="event"
      [currentStatus]="event.mySignUpStatus?.status"
    ></events-sign-up>
  </div>
</div>

<div [sharedSmoothHeightAnimation]="!!event.isExtended">
  <div *ngIf="event.isExtended" class="extension">
    <div *ngIf="$any(event).maxSignUp == 0">
      <shared-message
        type="ERROR"
        key="UI.GROUP.EVENTS.MESSAGES.EVENT_CANT_BE_SIGNED"
      >
      </shared-message>
    </div>

    <div>
      <events-event-type [eventTypeId]="event.eventTypeId"></events-event-type>
    </div>

    <div [innerHTML]="event.description"></div>
  </div>
</div>

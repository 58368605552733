import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { FullGroupDTO, GetUserDTO, GroupPermissions } from "@auto/dto.models";
import { UserService } from "@core/services/user.service";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinct, filter } from "rxjs/operators";
import { CurrentGroupService } from "../services/current-group.service";

// Debounce in computing state ms
const SOLVE_DEBOUNCE_TIME = 200;

@Directive({
  selector: "[sharedGroupPermission]",
})
export class SharedGroupPermissionDirective implements OnDestroy {
  private groupId_: string | undefined;
  @Input("sharedGroupPermissionGroupId") set groupId(
    value: string | undefined
  ) {
    this.groupId_ = value;
    this.solvePermissions.next();
  }
  get groupId(): string | undefined {
    return this.groupId_;
  }

  private permission!: GroupPermissions;
  @Input() set sharedGroupPermission(permission: GroupPermissions) {
    this.permission = permission;
    this.solvePermissions.next();
  }

  private readonly solvePermissions = new Subject<void>();

  // This is hide on true (when user has permission)
  @Input() hide = false;

  private previousState = false;

  private user: GetUserDTO | undefined;

  private subs: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private currentGroupService: CurrentGroupService,
    userService: UserService
  ) {
    this.subs.push(
      userService.user$.subscribe((u) => {
        this.user = u;
        this.solvePermissions.next();
      })
    );
    this.subs.push(
      currentGroupService.currentGroup$
        .pipe(
          filter((g: any) => !!g),
          distinct((g: FullGroupDTO) => g.id)
        )
        .subscribe(() => this.solvePermissions.next())
    );
    this.subs.push(
      this.solvePermissions
        .pipe(debounceTime(SOLVE_DEBOUNCE_TIME))
        .subscribe(() => this.computeState())
    );
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private computeState() {
    let groupId: string | undefined = this.groupId;
    if (!this.groupId && this.currentGroupService.currentGroup$.value) {
      groupId = this.currentGroupService.currentGroupId;
    }
    if (!groupId) {
      // Not necessarily error because might still be computing groupId
      return;
    }
    let state = this.previousState;
    if (this.user && this.permission) {
      const group = this.user.groups.find((i) => i.groupId === groupId);
      if (group) {
        state = group.permissions.indexOf(this.permission) !== -1;
        if (this.hide) {
          state = !state;
        }
      }
    }
    if (state !== this.previousState) {
      if (state) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdr.markForCheck();
      } else {
        this.viewContainer.clear();
      }
    }
    this.previousState = state;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TRANSLATIONS } from "@auto/translations.models";
import { NavLink, NavigationService, TranslationService } from "@core/services";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";

type Tabs = "GENERAL" | "EVENTS";

@Component({
  templateUrl: "./group-route-settings.component.html",
  styleUrls: ["./group-route-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupRouteSettingsComponent implements OnInit {
  currentGroup?: string;
  activeTabId!: Tabs;
  tabLinks!: NavLink[];

  constructor(
    private cdr: ChangeDetectorRef,
    private ts: TranslationService,
    private activatedRoute: ActivatedRoute,
    private nav: NavigationService
  ) {}

  ngOnInit(): void {
    if (!this.activatedRoute.snapshot.queryParams["tabId"]) {
      this.activeTabId = "GENERAL";
    } else {
      this.activeTabId = this.activatedRoute.snapshot.queryParams["tabId"];
    }

    this.tabLinks = this.getTabLinks();
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.activatedRoute.queryParams,
      (params) => {
        if ("tabId" in params) {
          this.activeTabId = params["tabId"];
          this.tabLinks = this.getTabLinks();
        }
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private getTabLinks() {
    let links: NavLink[] = [
      {
        name: this.ts.fromString(TRANSLATIONS.UI.GROUP.SETTINGS.GENERAL.TITLE),
        id: "GENERAL",
        isActive: this.activeTabId === "GENERAL",
        url: (i) => this.clickTab(i),
      },
      {
        name: this.ts.fromString(TRANSLATIONS.UI.GROUP.SETTINGS.EVENTS.TITLE),
        id: "EVENTS",
        isActive: this.activeTabId === "EVENTS",
        url: (i) => this.clickTab(i),
      },
    ];
    return links;
  }

  private clickTab(link: NavLink) {
    this.nav.setQueryParams({
      tabId: link.id,
    });
  }
}

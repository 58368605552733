<div class="col-container">
  <div class="col-xs-12 col-s-6 col-m-300">
    <mat-form-field class="full-width">
      <mat-label translate="COMMON_UI.KEY_WORD.NAME"></mat-label>
      <input
        (click)="$event.stopPropagation()"
        type="text"
        matInput
        [(ngModel)]="name"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-12 col-s-6 col-m-300">
    <mat-form-field class="full-width">
      <mat-label translate="UI.GROUP.EVENTS.SIGNUP.MESSAGE"></mat-label>
      <input
        (click)="$event.stopPropagation()"
        type="text"
        matInput
        [(ngModel)]="message"
      />
    </mat-form-field>
  </div>
  <div class="col-xs-auto-flex">
    <button
      [disabled]="name == ''"
      mat-flat-button
      class="yes"
      (click)="signUp()"
      translate="UI.GROUP.EVENTS.SIGNUP.SIGNUP_ADDITIONAL_ACTION_BUTTON"
    ></button>
  </div>
</div>

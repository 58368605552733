import { EventEmitter, Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  BasicEventDTO,
  BasicEventsDTO,
  EventDataDTO,
  NewEventDTO,
  StatusDTO,
} from "@auto/dto.models";
import { TranslationKey, TRANSLATIONS } from "@auto/translations.models";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService, TranslationService } from "@core/services";
import { EventsService } from "@events/services";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorConfig,
  numberValidator,
  SharedFormEditorUtil,
} from "@shared/index";
import {
  DirtyStateTrackerService,
  RegionalFormattingService,
  ToasterService,
  WEEKDAY_DATE_MAP,
} from "@shared/services";
import { Rgba } from "ngx-color-picker";
import { from, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { EventsAddNewEventDialogComponent } from "../events-add-new-event-dialog/events-add-new-event-dialog.component";

const DIRTY_KEY = "CREATE_EVENT";

@Injectable({
  providedIn: "root",
})
export class EventsEditorUtils {
  private dict = this.translationService.dict();

  constructor(
    private fb: FormBuilder,
    private translationService: TranslationService,
    private eventService: EventsService,
    private toasterService: ToasterService,
    private reg: RegionalFormattingService,
    private dirtyStateTracker: DirtyStateTrackerService,
    private nav: NavigationService,
    private sharedFormEditorUtil: SharedFormEditorUtil
  ) {}

  getForm(
    calendarId: string,
    eventTypeId: string,
    startDate?: Date
  ): FormGroup {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const lastEventAt = new Date(
      (startDate || new Date()).getTime() + 3600 * 1000 * 24 * 60
    );
    lastEventAt.setHours(23);
    lastEventAt.setMinutes(59);
    return this.fb.group({
      name: ["", Validators.required],
      locationName: [""],
      locationSuffix: [""],
      locationAddress: [""],
      duration: [
        {
          startTime: startDate,
          startTimeZone: timeZone,
          endTime: "",
        },
        Validators.required,
      ],
      description: [""],
      calendarId: [calendarId, Validators.required],
      eventTypeId: [eventTypeId, Validators.required],
      isRecurring: false,
      showMaxSignUp: false,
      maxSignUp: [""],
      lastEventAt: lastEventAt.getTime(),
      recurrenceType: ["DAYS_OF_WEEK"],
      recurrenceMon: false,
      recurrenceTue: false,
      recurrenceWed: false,
      recurrenceThu: false,
      recurrenceFri: false,
      recurrenceSat: false,
      recurrenceSun: false,
      nthDay: ["", this.getMinMaxValidator(1, 31)],
      publicEvent: false,
    });
  }

  patchFromEvent(form: FormGroup, event: EventDataDTO) {
    const obj = {
      name: event.name,
      eventTypeId: event.eventTypeId,
      description: event.description,
      duration: {
        startTime: new Date(event.startTime),
        endTime: new Date(event.endTime),
        startTimeZone: event.startTimeZoneId,
      },
      locationName: event.location?.name,
      locationSuffix: event.locationSuffix,
      locationAddress: event.location?.address,
      showMaxSignUp: (event.maxSignUp || 0) > -1,
      maxSignUp: (event.maxSignUp || 0) > -1 ? event.maxSignUp : "",
    };
    form.patchValue(obj);
    if (event.seriesId) {
      const disable = [
        "isRecurring",
        "nthDay",
        "lastEventAt",
        "recurrenceType",
        ...this.reg.getShortWeekDaysEnglish().map((i, index) => {
          const controlName = "recurrence" + i;
          if ((event.recurrence?.daySchema || []).indexOf(index) > -1) {
            form.patchValue({
              [controlName]: true,
            });
          }
          return controlName;
        }),
      ];
      disable.forEach((i) => form.controls[i].disable());

      form.patchValue({
        isRecurring: true,
        recurrenceType: event.recurrence?.type,
        lastEventAt: event.recurrence?.lastEventAt,
      });
    }
  }

  formGroupToConfig(
    fg: FormGroup,
    groupId: string,
    eventId?: string
  ): SharedFormEditorConfig {
    const config: SharedEnrichableFormEditorConfig = {
      form: fg,
      dirtyStateTracker: DIRTY_KEY,
      formFieldClassList: ["full-width"],
      fields: [
        {
          classList: ["col-xs-12", "max-width-500"],
          labelKey: TRANSLATIONS.UI.GROUP.EVENTS.EVENT_NAME,
          formControlName: "name",
        },
        {
          classList: ["col-xs-12", "max-width-500"],
          labelKey: TRANSLATIONS.UI.GROUP.EVENTS.EVENT_TYPE,
          formControlName: "eventTypeId",
          type: "select",
          options: this.getEventTypeOptions(),
        },
        {
          classList: ["col-xs-12"],
          formControl: fg.controls["duration"],
          type: "duration",
        },
        [
          {
            classList: ["col-xs-12", "col-s-5"],
            labelKey: TRANSLATIONS.UI.GROUP.EVENTS.LOCATION,
            formControlName: "locationName",
          },
          {
            classList: ["col-xs-12", "col-s-3"],
            labelKey: TRANSLATIONS.UI.GROUP.EVENTS.LOCATION_SUFFIX,
            formControlName: "locationSuffix",
          },
        ],
        {
          classList: ["col-xs-12", "max-width-500"],
          labelKey: TRANSLATIONS.UI.GROUP.EVENTS.LOCATION_ADDRESS,
          formControlName: "locationAddress",
        },
        [
          {
            classList: ["col-to-xs-12", "col-auto-flex", "form-toggle"],
            label: "Rajoita osallistujamäärää",
            formControlName: "showMaxSignUp",
            type: "slideToggle",
          },
          {
            classList: ["col-to-xs-12", "col-auto-flex"],
            label: "Maksimi",
            formControlName: "maxSignUp",
            showIf: () => fg.controls["showMaxSignUp"].value,
          },
        ],
        [
          {
            classList: ["col-to-xs-12", "col-auto-flex", "form-toggle"],
            labelKey: TRANSLATIONS.UI.GROUP.EVENTS.IS_RECURRING,
            formControlName: "isRecurring",
            type: "slideToggle",
          },
          {
            classList: [
              "col-to-xs-12",
              "col-auto-flex",
              "max-width-500",
              "min-width-400",
            ],
            labelKey: TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE_LABEL,
            formControlName: "recurrenceType",
            options: this.getRecurringTypeOptions(),
            type: "select",
            showIf: () => fg.controls["isRecurring"].value,
          },
          {
            classList: ["col-to-xs-12", "col-auto-flex", "max-width-500"],
            labelKey: TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_N_TH,
            formControlName: "nthDay",
            type: "text",
            showIf: () =>
              fg.controls["isRecurring"].value &&
              fg.controls["recurrenceType"].value !== "DAYS_OF_WEEK" &&
              fg.controls["recurrenceType"].value !== "ANNUALLY",
          },
        ],
        this.getDaysCheckboxes(fg) as any,
        {
          classList: ["col-xs-12"],
          formControl: fg.controls["lastEventAt"],
          type: "date",
          labelKey: TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_LAST_EVENT_AT,
          showIf: () => fg.controls["isRecurring"].value,
        },
        {
          classList: ["col-xs-12"],
          formControl: fg.controls["description"],
          type: "richtext",
          labelKey: TRANSLATIONS.UI.GROUP.EVENTS.DESCRIPTION,
        },
      ],
    };
    return this.sharedFormEditorUtil.enrichConfigToDefaults(config);
  }

  static getGlobalEventTypeColors(eventTypeId: string): {
    color: string;
    backgroundColor: string;
  } {
    switch (eventTypeId) {
      case "MATCH":
        return {
          color: "white",
          backgroundColor: "red",
        };
      case "PAYMENT":
        return {
          color: "white",
          backgroundColor: "rgb(30,150,30)",
        };
      case "SINGLE":
        return {
          color: "white",
          backgroundColor: "rgb(3, 169, 244)",
        };
      case "WEEKLY":
        return {
          color: "white",
          backgroundColor: "rgb(0,0,139)",
        };
      case "OTHER":
        return {
          color: "white",
          backgroundColor: "rgb(84, 50, 2)",
        };
    }
    return {
      color: "black",
      backgroundColor: "white",
    };
  }

  getEventTypeOptions() {
    const data = ["SINGLE", "WEEKLY", "MATCH", "PAYMENT", "OTHER"].map(
      (i: string) =>
        Object.assign(
          { value: i },
          EventsEditorUtils.getGlobalEventTypeColors(i),
          {
            labelKey: (TRANSLATIONS.UI.GROUP.EVENTS.GLOBAL_EVENT_TYPES as any)[
              i
            ],
          }
        )
    );
    return data;
  }

  private getRecurringTypeOptions() {
    return [
      {
        value: "DAYS_OF_WEEK",
        labelKey: TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE.DAYS_OF_WEEK,
      },
      {
        value: "DAY_OF_MONTH",
        labelKey: TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE.DAY_OF_MONTH,
      },
      {
        value: "EVERY_N_TH_DAY",
        labelKey: TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE.EVERY_N_TH_DAY,
      },
      {
        value: "DAY_OF_MONTH_FROM_END",
        labelKey:
          TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE.DAY_OF_MONTH_FROM_END,
      },
      {
        value: "N_TH_OCCURENCE_IN_MONTH",
        labelKey:
          TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE.N_TH_OCCURENCE_IN_MONTH,
      },
      {
        value: "N_TH_OCCURENCE_IN_MONTH_FROM_END",
        labelKey:
          TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE
            .N_TH_OCCURENCE_IN_MONTH_FROM_END,
      },
      {
        value: "ANNUALLY",
        labelKey: TRANSLATIONS.UI.GROUP.EVENTS.RECURRING_TYPE.ANNUALLY,
      },
    ];
  }

  private getDaysCheckboxes(fg: FormGroup) {
    return Object.keys(WEEKDAY_DATE_MAP).map((i: any) => {
      return {
        classList: ["col-xs-1"],
        label: this.reg.getDayOfWeekFromEnglishDay(i),
        formControl: fg.controls[`recurrence${i}`],
        type: "checkbox",
        showIf: () =>
          fg.controls["isRecurring"].value &&
          fg.controls["recurrenceType"].value === "DAYS_OF_WEEK",
      };
    });
  }

  getSaveCallback(
    fg: FormGroup,
    groupId: string,
    emitter: EventEmitter<BasicEventDTO[] | true>,
    eventId?: string
  ): () => Observable<BasicEventsDTO | StatusDTO> | Promise<true> {
    return () => {
      const data = Object.assign({}, fg.value);
      const newEvent: NewEventDTO = {
        calendarId: data.calendarId,
        name: data.name,
        startTime: data.duration.startTime,
        endTime: data.duration.endTime,
        eventTypeId: data.eventTypeId,
        description: data.description,
        location: {
          name: data.locationName,
          address: data.locationAddress,
        },
        locationSuffix: data.locationSuffix,
        startTimeZoneId: data.duration.startTimeZone,
        endTimeZoneId: data.duration.startTimeZone,
        maxSignUp: data.showMaxSignUp ? parseInt(data.maxSignUp) : -1,
      };
      if (data.isRecurring) {
        const daySchema = this.getDaySchemaForRecurringEvent(data);
        if (!daySchema) {
          return Promise.resolve(true);
        }
        newEvent.recurrence = {
          type: data.recurrenceType,
          daySchema,
          lastEventAt: data.lastEventAt,
        };
      }
      if (eventId) {
        return this.eventService.updateEvent(groupId, eventId, newEvent).pipe(
          tap((i) => {
            this.toasterService.showSuccessTranslationKey(
              TRANSLATIONS.UI.GROUP.EVENTS.EVENT_MODIFIED_OK
            );
            this.dirtyStateTracker.removeDirty(DIRTY_KEY);
            emitter.next(true);
          })
        );
      }
      return this.eventService.createEvent(groupId, newEvent).pipe(
        tap((i) => {
          this.toasterService.showSuccessTranslationKey(
            TRANSLATIONS.UI.GROUP.EVENTS.EVENT_ADDED_OK
          );
          this.dirtyStateTracker.removeDirty(DIRTY_KEY);
          emitter.next(i.collection);
        })
      );
    };
  }

  getUpdateSeriesCallback(
    fg: FormGroup,
    groupId: string,
    emitter: EventEmitter<BasicEventDTO[] | true>,
    eventId: string
  ): () => Observable<BasicEventsDTO | StatusDTO> | Promise<true> {
    return () => {
      const data = Object.assign({}, fg.value);
      const newEvent: NewEventDTO = {
        calendarId: data.calendarId,
        name: data.name,
        startTime: data.duration.startTime,
        endTime: data.duration.endTime,
        eventTypeId: data.eventTypeId,
        description: data.description,
        location: {
          name: data.locationName,
          address: data.locationAddress,
        },
        locationSuffix: data.locationSuffix,
        startTimeZoneId: data.duration.startTimeZone,
        endTimeZoneId: data.duration.startTimeZone,
        maxSignUp: data.showMaxSignUp ? parseInt(data.maxSignUp) : -1,
      };
      return this.eventService
        .updateEventSeries(groupId, eventId, newEvent)
        .pipe(
          tap((i) => {
            this.toasterService.showSuccessTranslationKey(
              TRANSLATIONS.UI.GROUP.EVENTS.EVENT_MODIFIED_OK
            );
            this.dirtyStateTracker.removeDirty(DIRTY_KEY);
            emitter.next(true);
          })
        );
    };
  }

  private getDaySchemaForRecurringEvent(data: any) {
    if (data.recurrenceType === "DAYS_OF_WEEK") {
      const recurring = Object.keys(WEEKDAY_DATE_MAP)
        .map((i) => {
          return data["recurrence" + i];
        })
        .map((value: boolean, index: number) => {
          return value ? index : -1;
        })
        .filter((i) => i > -1);
      if (recurring.length === 0) {
        this.toasterService.showErrorTranslationKey(
          TRANSLATIONS.UI.GROUP.EVENTS.ERRORS.RECURRING.SELECT_DAYS
        );
        return false;
      }
      return recurring;
    }
    return [];
  }

  private getMinMaxValidator(
    min: number,
    max: number,
    parser: (i: string) => number = parseInt
  ) {
    return numberValidator(min, max, parser, true);
  }
}

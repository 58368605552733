import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  TRANSLATIONS,
  TranslationKey,
  ErrorEnum,
  GroupingDTO,
} from "@auto/index";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService, TranslationService } from "@core/services";
import { GroupService } from "@group/services";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";
import { DirtyStateTrackerService, ToasterService } from "@shared/services";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { tap } from "rxjs";

export interface GroupingDialogData {
  groupId: string;
  grouping?: GroupingDTO;
}

const DIRTY_KEY = "GROUPING_EDITOR";

@Component({
  templateUrl: "./members-grouping-editor-dialog.component.html",
  styleUrls: ["./members-grouping-editor-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersGroupingEditorDialogComponent implements OnInit {
  form!: FormGroup;
  config!: SharedFormEditorConfig;
  isAddingNew = false;
  shouldEnableSave = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GroupingDialogData,
    private dialogRef: MatDialogRef<MembersGroupingEditorDialogComponent>,
    private fb: FormBuilder,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private service: GroupService,
    private toaster: ToasterService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private navService: NavigationService,
    private sharedFormEditorUtil: SharedFormEditorUtil
  ) {}

  ngOnInit(): void {
    this.isAddingNew = !this.data.grouping;
    this.form = this.fb.group({
      name: [
        this.isAddingNew ? "" : this.data.grouping!.name,
        Validators.required,
      ],
    });

    const config: SharedEnrichableFormEditorConfig = {
      form: this.form,
      dirtyStateTracker: DIRTY_KEY,
      formFieldClassList: ["full-width"],
      fields: [
        {
          classList: ["col-xs-12", "max-width-500"],
          labelKey: TRANSLATIONS.UI.GROUP.MEMBERS.GROUPINGS.NAME,
          formControlName: "name",
        },
      ],
    };

    this.config = this.sharedFormEditorUtil.enrichConfigToDefaults(config);
    LifeCyclesUtil.sub([this, this.cdr], this.form.statusChanges, (changes) => {
      this.shouldEnableSave = this.form.valid && !this.form.pristine;
    });
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  async cancel() {
    const ok = await this.dirtyStateTrackerService.confirmNotDirty();
    if (ok) {
      this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
      this.dialogRef.close(false);
    }
  }

  getSaveCabllback() {
    return () => {
      if (this.isAddingNew) {
        return this.service
          .addGrouping(this.data.groupId, this.form.controls["name"].value)
          .pipe(
            tap((i) => {
              this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
              if (i.status === "OK") {
                this.toaster.showSuccessTranslationKey(
                  TRANSLATIONS.COMMON_UI.OK.ADDED
                );
                this.dialogRef.close(true);
                this.navService.goto(ROUTE_MAP.PRI.GROUP.MEMBERS.GROUPINGS, {
                  groupingId: i.id!,
                });
              } else {
                this.toaster.showErrorTranslationKey(
                  this.getTranslationKey(i.message)
                );
              }
            })
          );
      }
      return this.service
        .updateGroupingName(
          this.data.groupId,
          this.data.grouping!.id,
          this.form.controls["name"].value
        )
        .pipe(
          tap((i) => {
            this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
            if (i.status === "OK") {
              this.toaster.showSuccessTranslationKey(
                TRANSLATIONS.COMMON_UI.OK.SAVED
              );
              this.dialogRef.close(true);
            } else {
              this.toaster.showErrorTranslationKey(
                this.getTranslationKey(i.message)
              );
            }
          })
        );
    };
  }

  private getTranslationKey(message?: string): TranslationKey {
    switch (message) {
      case ErrorEnum.GROUPING_WITH_NAME_EXISTS:
        return TRANSLATIONS.UI.GROUP.MEMBERS.GROUPINGS.NAME_EXISTS;
    }
    return TRANSLATIONS.COMMON_UI.ERROR.UNKNOWN;
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BasicEventDTO } from "@auto/dto.models";
import { ROUTE_MAP } from "@core/routing";
import { NavigationService, TranslationService } from "@core/services";
import { EventsService } from "@events/services";
import { CurrentGroupService, ToasterService } from "@shared/services";
import { EventsEditorUtils } from "../events-editor/events-editor.utils";

@Component({
  templateUrl: "./events-route-editor.component.html",
  styleUrls: ["./events-route-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsRouteEditorComponent implements OnInit {
  groupId!: string;
  eventId?: string;
  startDate?: Date;

  private isEditing = false;

  constructor(
    private nav: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isEditing = this.activatedRoute.snapshot.data["edit"] || false;
    this.groupId = this.activatedRoute.snapshot.params["groupId"];
    if (this.isEditing) {
      this.eventId = this.activatedRoute.snapshot.params["eventId"];
    }
  }

  afterSaved(events: BasicEventDTO[] | true) {
    console.log(events);
    if (Array.isArray(events)) {
      this.nav.goto(
        ROUTE_MAP.PRI.GROUP.EVENTS.ADDED,
        {
          eventId: events[0].id,
        },
        {
          isSeries: events.length > 1,
        }
      );
    } else {
      this.nav.goto(ROUTE_MAP.PRI.GROUP.EVENTS.CALENDAR, {
        groupId: this.groupId,
      });
    }
  }
}

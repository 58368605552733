import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { TRANSLATIONS } from "@auto/translations.models";
import {
  BasicUserDTO,
  CreateSlavelUserDTO,
  GetUserDTO,
  GroupPermissions,
  LoginRequestDTO,
  NewUserDTO,
  StatusEnum,
  UpdateUserSettingsDTO,
  UserGroupDTO,
} from "@auto/dto.models";
import { UserApiService } from "@auto/index";
import { ROUTE_MAP } from "@core/routing/routes.map";
import { UserProfileCreateChildAccountFormFactory } from "@ff/user-profile-create-child-account.ff";
import { SharedFormDialogComponent } from "@shared/components/shared-form-dialog/shared-form-dialog.component";
import { BusyService } from "@shared/services/busy.service";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  Observable,
  of,
  Subject,
} from "rxjs";
import { filter, map, tap, switchMap } from "rxjs/operators";
import { NavigationService } from "./navigation.service";
import { TokenStore } from "./token.store";
import { TranslationService } from "./translation.service";
import { UserStore } from "./user.store";
import { FormGroup } from "@angular/forms";

@Injectable({ providedIn: "root" })
export class UserService implements CanActivate {
  private _isLoggingIn$ = new BehaviorSubject<boolean>(true);

  get isLoggedIn$(): Observable<boolean> {
    return this.store.user.pipe(map((user) => user !== undefined));
  }

  get isLoggingIn$(): BehaviorSubject<boolean> {
    return this._isLoggingIn$;
  }

  get user(): GetUserDTO | undefined {
    return this.store.user$.getValue();
  }

  get myGroupMap(): { [groupId: string]: UserGroupDTO } {
    return this.user!.groups.reduce(
      (prev: { [groupId: string]: UserGroupDTO }, now) => {
        prev[now.groupId] = now;
        return prev;
      },
      {}
    );
  }

  get user$(): BehaviorSubject<GetUserDTO | undefined> {
    return this.store.user$;
  }

  constructor(
    private api: UserApiService,
    private router: Router,
    private navService: NavigationService,
    private store: UserStore,
    private tokenStore: TokenStore,
    private ffNew: UserProfileCreateChildAccountFormFactory,
    private translationsService: TranslationService,
    private dialog: MatDialog,
    private busyService: BusyService
  ) {
    if (tokenStore.token) {
      this._isLoggingIn$.next(true);
      this.refresh();
    } else {
      this._isLoggingIn$.next(false);
    }
  }

  async refresh(showBusy = true): Promise<GetUserDTO | false> {
    await this.busyService.waitUntilReady();
    if (showBusy) {
      this.busyService.show();
    }
    return new Promise((resolve, reject) => {
      this.api.getMe().subscribe({
        next: (user) => {
          this.store.set(user);
          this._isLoggingIn$.next(false);
          resolve(user);
          if (showBusy) {
            this.busyService.hide();
          }
        },
        error: (_) => {
          this.store.afterLogOut();
          this._isLoggingIn$.next(false);
          resolve(false);
          if (showBusy) {
            this.busyService.hide();
          }
        },
      });
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    const hasUser = (): true | UrlTree => {
      const has = this.store.user$.getValue() !== undefined;
      if (route.data && route.data["isLoginPage"]) {
        return has ? this.router.parseUrl("/pri/me") : true;
      }
      return has ? true : this.router.parseUrl("/pub/login");
    };
    if (!this._isLoggingIn$.getValue()) {
      return hasUser();
    }
    return this._isLoggingIn$.pipe(
      filter((val) => !val),
      map((_) => hasUser())
    );
  }

  saveProfile(profile: UpdateUserSettingsDTO) {
    return this.api.updateMe(profile).pipe(
      tap((user) => {
        this.store.set(user);
      })
    );
  }

  activateUser(dto: LoginRequestDTO) {
    return this.api.activateSlaveAccount(dto).pipe(
      tap((_) => {
        // User must be defined
        const user = this.store.user$.value!;
        user.username = dto.username;
        this.store.set(Object.assign({}, user));
      })
    );
  }

  confirmUserEmail(userId: string, emailId: string) {
    return this.api.confirmUserEmail(userId, emailId);
  }

  async register(dto: NewUserDTO) {
    try {
      const result = await firstValueFrom(this.api.register(dto));
      this.store.set(result);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async login(username: string, password: string) {
    this._isLoggingIn$.next(true);
    this.tokenStore.clear();
    try {
      const user = await firstValueFrom(
        this.api.login({
          username,
          password,
        })
      );
      this.store.set(user);
      return true;
    } catch {
      return false;
    } finally {
      this._isLoggingIn$.next(false);
    }
  }

  logout() {
    const promise = firstValueFrom(this.api.logout()).then(() => {});
    this.store.set(undefined);
    return promise;
  }

  gotoMe() {
    this.navService.goto(ROUTE_MAP.PRI.USER_DETAILS.PROFILE);
  }

  gotoLogin() {
    this.navService.goto(ROUTE_MAP.PUB.LOGIN);
  }

  getGroupsShortName(groupId: string) {
    // Perhaps do some computable hashTable for this
    return (
      this.user?.groups?.find((i) => i.groupId === groupId)?.groupShortName ??
      ""
    );
  }

  isGroupMember(groupId: string) {
    return !!this.user?.groups?.some((i) => i.groupId == groupId);
  }

  hasGroupPermission(groupId: string, permission: GroupPermissions) {
    return (
      this.user?.groups?.find((i) => i.groupId === groupId)?.permissions || []
    ).includes(permission);
  }

  createSlave(slaveUser: CreateSlavelUserDTO) {
    return this.api.createSlaveAccount(slaveUser).pipe(
      switchMap((i) => combineLatest([of(i), this.refresh()])),
      map((i) => i[0])
    );
  }

  addMaster(credentials: LoginRequestDTO) {
    return this.api.addMasterAccount(credentials).pipe(
      switchMap((i) => {
        if (i.status === StatusEnum.OK) {
          return combineLatest([of(i), this.refresh()]);
        }
        return combineLatest([of(i)]);
      }),
      map((i) => i[0])
    );
  }

  removeMaster(masterUserId: string) {
    return this.api
      .removeMasterAccount(masterUserId)
      .pipe(switchMap((i) => this.refresh()));
  }

  async returnToMaster() {
    this.tokenStore.clearSlaveUserId();
    const busyId = this.busyService.show();
    await this.refresh();
    this.busyService.hide(busyId);
  }

  async selectSlave(slave: BasicUserDTO) {
    const busyId = this.busyService.show();
    this.tokenStore.setSlaveUserId(slave.id!);
    await this.refresh();
    this.busyService.hide(busyId);
  }

  getEmails() {
    return firstValueFrom(this.api.myEmails());
  }

  removeEmail(email: string) {
    return firstValueFrom(this.api.removeEmail({ name: email }));
  }

  addEmail(email: string) {
    return firstValueFrom(this.api.addEmail({ name: email }));
  }

  createSlaveAccount() {
    const close = new Subject<void>();
    const addNewForm = this.ffNew.createForm();
    this.dialog.open(SharedFormDialogComponent, {
      data: {
        title: this.translationsService.fromString(
          TRANSLATIONS.UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.TITLE
        ),
        help: this.translationsService.fromString(
          TRANSLATIONS.UI.USER.LINKED_ACCOUNTS.NEW_ACCOUNT.HELP
        ),
        configGetter: (cancel: () => void) => {
          return this.ffNew.formGroupToConfig(addNewForm, async () => {
            const response = await this.addNew(addNewForm);
            if (response) {
              cancel();
            }
            return response;
          });
        },
        close,
      },
    });
  }

  private async addNew(addNewForm: FormGroup) {
    const busyId = this.busyService.show();
    const slave = await firstValueFrom(this.createSlave(addNewForm.value));
    this.busyService.hide(busyId);
    return true;
  }
}

import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TRANSLATIONS } from "@auto/index";
import { TranslationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import {
  SharedEnrichableFormEditorConfig,
  SharedFormEditorUtil,
} from "@shared/components";
import { DirtyStateTrackerService } from "@shared/services";

import { FF_DEFAULT_CLASS_LIST, FormFactoryBase } from "./form-factory.base";

@Injectable({
  providedIn: "root",
})
export class JoinGroupFormFactory extends FormFactoryBase {
  readonly DIRTY_KEY = "JOIN_GROUP_FORM_FACTORY";

  constructor(
    private fb: FormBuilder,
    private utils: SharedFormEditorUtil,
    private tx: TranslationService,
    private dst: DirtyStateTrackerService,
    private userService: UserService
  ) {
    super();
  }

  createForm(): FormGroup {
    const nameGuess =
      this.userService.user!.givenName +
      " " +
      this.userService.user!.familyName;
    const form = this.fb.group({
      displayName: [nameGuess, Validators.required],
      message: [""],
    });
    form.controls["displayName"].markAsTouched();
    return form;
  }

  formGroupToConfig(
    fg: FormGroup,
    cancel: () => void,
    save: () => Promise<any>
  ) {
    const classList = ["col-xs-12"];
    const config: SharedEnrichableFormEditorConfig = {
      form: fg,
      dirtyStateTracker: this.DIRTY_KEY,
      fields: [
        {
          classList,
          labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.NAME,
          formControlName: "displayName",
        },
        {
          classList,
          labelKey: TRANSLATIONS.UI.GROUP.NEW_GROUP.JOIN.JOIN_REQUEST_MESSAGE,
          type: "textarea",
          formControlName: "message",
        },
        [
          {
            classList: ["col-auto-flex"],
            labelKey: TRANSLATIONS.COMMON_UI.KEY_WORD.CANCEL,
            type: "button.plain",
            enableAlways: true,
            callback: async () => {
              const allowClose = await this.dst.confirmNotDirty();
              if (allowClose) {
                cancel();
              }
            },
          },
          {
            classList: ["col-auto-flex-last-right"],
            labelKey: TRANSLATIONS.UI.GROUP.NEW_GROUP.JOIN.SEND_REQUEST_TO_JOIN,
            type: "button.loading",
            callback: () => save(),
          },
        ],
      ],
    };
    return this.utils.enrichConfigToDefaults(config);
  }
}

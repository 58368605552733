import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GroupPermissions } from "@auto/dto.models";
import { NavigationService } from "@core/services";
import { UserService } from "@core/services/user.service";
import { EventsService, ExtendedEventData } from "@events/services";
import { LifeCyclesUtil } from "@shared/utils/lifecycles.util";
import { switchMap, tap } from "rxjs";

@Component({
  templateUrl: "./events-route-page.component.html",
  styleUrls: ["./events-route-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsRoutePageComponent implements OnInit {
  groupId!: string;
  eventId!: string;
  event!: ExtendedEventData;
  isLoading = true;

  readonly enrollExtraToEventPermission =
    GroupPermissions.GRP_ENROLL_EXTRA_TO_EVENT;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: EventsService,
    private cdr: ChangeDetectorRef,
    private nav: NavigationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.groupId = this.activatedRoute.snapshot.params["groupId"];
    this.eventId = this.activatedRoute.snapshot.params["eventId"];

    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.service.getEvent(this.eventId, this.groupId).pipe(
        tap((data) => {
          this.event = data.event;
          this.event.groupName = this.userService.getGroupsShortName(
            this.event.groupId
          );
        }),
        // This ensures now that sign ups are loaded for the event
        switchMap((i) => this.service.getEventsSignUps(this.eventId))
      ),
      (data) => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }
}

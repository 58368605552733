import { Injectable } from "@angular/core";
import { StatusDTO, StatusEnum, UpdateGroupDTO } from "@auto/dto.models";
import { GroupApiService } from "@auto/group-api.service";
import { GroupingApiService } from "@auto/grouping-api.service";
import { UserService } from "@core/services/user.service";
import { CurrentGroupService } from "@shared/services";
import {
  catchError,
  firstValueFrom,
  forkJoin,
  merge,
  Observable,
  of,
  switchMap,
  tap,
} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GroupService {
  constructor(
    private api: GroupApiService,
    private groupingApi: GroupingApiService,
    private userService: UserService,
    private currentGroupService: CurrentGroupService
  ) {}

  updateGroup(groupId: string, dto: UpdateGroupDTO) {
    return this.api.updateGroup(groupId, dto).pipe(
      tap(async (i) => {
        this.userService.refresh(false);
        await firstValueFrom(this.currentGroupService.getGroup(groupId, true));
        // return this.currentGroupService.getGroup(groupId, true);
      })
    );
  }

  addGrouping(groupId: string, name: string): Observable<StatusDTO> {
    return this.groupingApi
      .createGrouping(groupId, {
        name,
      })
      .pipe(
        tap((i) => {
          this.currentGroupService.refreshGroup();
        }),
        catchError((i) => {
          return of({
            message: i.error.detail,
            status: StatusEnum.FAILED,
          });
        })
      );
  }

  leaveGroup(groupId: string, userId: string) {
    return this.api.leaveGroup(groupId, userId);
  }

  removeGrouping(groupId: string, groupingId: string) {
    return this.groupingApi.deleteGrouping(groupId, groupingId).pipe(
      tap((i) => {
        this.currentGroupService.refreshGroup();
      })
    );
  }

  updateGroupingName(groupId: string, groupingId: string, name: string) {
    return this.groupingApi
      .updateGroupingName(groupId, groupingId, {
        name,
      })
      .pipe(
        tap((i) => {
          this.currentGroupService.refreshGroup();
        })
      );
  }
}

import { Injectable } from "@angular/core";
import { GroupingMapDTO, GroupMemberDTO } from "@auto/dto.models";
import {
  IMappedListStore,
  StateManagementStoreFactory,
} from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class GroupMembersStore {
  members: IMappedListStore<GroupMemberDTO>;
  groupingData: IMappedListStore<GroupingMapDTO>;

  constructor(sms: StateManagementStoreFactory) {
    this.members = sms.createMappedListStore<GroupMemberDTO>();
    this.groupingData = sms.createMappedListStore<GroupingMapDTO>();
  }

  setMember(groupId: string, member: GroupMemberDTO) {
    const members = this.members.get(groupId);
    this.members.set(groupId, [
      ...members.filter((i) => i.id !== member.id),
      member,
    ]);
  }

  removeMember(groupId: string, memberId: string) {
    const members = this.members.get(groupId);
    this.members.set(groupId, [...members.filter((i) => i.id !== memberId)]);
  }

  addUserToGrouping(groupId: string, groupingId: string, memberId: string) {
    const groupings = this.groupingData.get(groupId);
    const grouping = groupings.find((i) => i.id === groupingId);
    if (grouping) {
      grouping.memberIds.push(memberId);
      const groupingData = [
        ...groupings.filter((i) => i.id !== groupingId),
        grouping,
      ];
      this.groupingData.set(groupId, groupingData);
    }
    return false;
  }

  removeUserFromGrouping(
    groupId: string,
    groupingId: string,
    memberId: string
  ) {
    const groupings = this.groupingData.get(groupId);
    const grouping = groupings.find((i) => i.id === groupingId);
    if (grouping) {
      grouping.memberIds = grouping.memberIds.filter((i) => i !== memberId);
      const groupingData = [
        ...groupings.filter((i) => i.id !== groupingId),
        grouping,
      ];
      this.groupingData.set(groupId, groupingData);
    }
    return false;
  }
}

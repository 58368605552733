import { Injectable } from "@angular/core";
import { GetUserDTO } from "@auto/dto.models";
import { RegionalFormattingService } from "@shared/services/regional-formatting.service";
import { BehaviorSubject, Observable } from "rxjs";
import { TokenStore } from "./token.store";
import { TranslationLoader } from "./translation.loader";
import { TranslationService } from "./translation.service";

@Injectable({ providedIn: "root" })
export class UserStore {
  user$ = new BehaviorSubject<GetUserDTO | undefined>(undefined);

  constructor(
    private translationLoader: TranslationLoader,
    private regionalFormattingService: RegionalFormattingService,
    private tokenStore: TokenStore
  ) {}

  get user(): Observable<GetUserDTO | undefined> {
    return this.user$.asObservable();
  }

  set(data: GetUserDTO | undefined) {
    if (data) {
      if (data.locale) {
        this.regionalFormattingService.setLocale(data.locale);
      }
      if (data.language) {
        this.translationLoader.setLanguage(data.language);
      }
      if (data.id && data.sessionId) {
        this.tokenStore.set(data.id, data.sessionId);
      }
    } else {
      this.tokenStore.clear();
    }
    this.user$.next(data);
  }

  afterLogOut() {
    this.tokenStore.clear();
    this.user$.next(undefined);
  }
}
